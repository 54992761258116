import React from "react";
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactTable from 'react-table-6';

class ContractAmendmentTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dates: this.props.dates
        }
    }

    componentDidUpdate(prevProps) {
        const { dates } = this.props
        if (dates !== prevProps.dates) {
            this.setState({ dates });
        }
    }
    render() {
        const { disableButton, contractFacAmendmentIndex, duration, nbHours } = this.props
        const { dates } = this.state
        const columns = [
            {
                Header: 'Date',
                accessor: 'dateech',
                width: 200,
                Cell: props => {
                    return <input name={props.column.Header + props.row._index}
                        style={{
                            border: "none", height: "100%", width: "100%", pointerEvents: "visible", backgroundColor: "transparent"
                            , textAlign: "center", fontSize: "14px"
                        }}
                        onChange={(event) => this.props.setEcheancesDates(event.target.value, props.row._index)}
                        type="date"
                        value={dates[props.row._index] ? dates[props.row._index].dateech : ""}
                        required
                        autoComplete="off" />
                }
            }, {
                Header: 'Désignation',
                accessor: 'raison'
            }
        ]
        const data = dates.map(item => ({
            dateech: item.dateech,
            raison: item.raison
        }));
        return (
            <div className="contract-amendment-table">
                <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={5}
                    pageSizeOptions={[5, 10, 20]}
                    previousText={"Précédent"}
                    nextText={"Suivant"}
                    loadingText={"Chargement en cours..."}
                    noDataText={"Aucune donnée trouvée."}
                    rowsText={"lignes"}
                    ofText={"de"}
                />
                <div className="btn-group">
                    {disableButton && (
                        <CircularProgress className="spinner" color="inherit" />
                    )}
                    <Button onClick={this.props.handleUpdateClick} variant="contained" disabled={disableButton || contractFacAmendmentIndex == 0 || duration == 0 || nbHours == 0} >Enregistrer</Button>
                    <Button onClick={this.props.handleGenClick} variant="contained" disabled={disableButton || contractFacAmendmentIndex == 0 || duration == 0 || nbHours == 0} >Générer</Button>
                    <Button onClick={this.props.handleShowClick} variant="contained" disabled={disableButton || contractFacAmendmentIndex == 0} >Afficher</Button>
                    <Button className="btn-create" onClick={this.props.handleValidClick} variant="contained" disabled={disableButton} >Nouvel Avenant</Button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    contractFacAmendmentIndex: state.facAmendment.contractFacAmendmentIndex,
});
export default connect(mapStateToProps)(ContractAmendmentTable)