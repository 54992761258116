import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  fetchDevis,
  newDossierValue,
  newNpropValue,
  fetchNewPropo
} from "../../../../redux/actions/devisActions";
import { fetchDossier } from "../../../../redux/actions/dossierActions";

class DialogModifOp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      operation: "",
      categorie_op: "",
      adresse_op: "",
      cp_op: "",
      ville_op: "",
      loading: false
    };
    this.handleCloseProcess = this.handleCloseProcess.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    const details = this.getOp()
    this.setState({ 
      operation: details.operation,
      categorie_op: details.categorie_op,
      adresse_op: details.adresse_op,
      cp_op: details.cp_op,
      ville_op: details.ville_op,
    });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleCloseProcess() {
    this.setState({ isOpen: false });
    this.props.closeDialog();
  }
  handleOpen() {
    this.setState({ isOpen: true });
  }
  async handleOpModif() {
    this.setState({ loading: true });
    const { dossierValue, nprop } = this.props;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        operation: this.state.operation,
        categorie_op: this.state.categorie_op,
        adresse_op: this.state.adresse_op,
        cp_op: this.state.cp_op,
        ville_op: this.state.ville_op,
      }),
    };
  
    try {
      await fetch("/api/devis/modifOp", requestOptions);
      await this.props.dispatch(fetchDossier());
      await this.props.dispatch(fetchDevis());
      await this.props.dispatch(fetchNewPropo(dossierValue));
      await this.props.dispatch(newDossierValue(dossierValue));
      await this.props.dispatch(newNpropValue(nprop));
      await this.setState({ loading: false });
      await this.props.showNextDialog(3);
    } catch (err) {
      console.log(err);
    }
  }
  
  getOp() {
    for (let i = 0; i < this.props.dossier.length; i++) {
      if (this.props.dossier[i].ndossier == this.props.dossierValue) {
        return this.props.dossier[i];
      }
    }
    return "";
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleCloseProcess}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            MODIFICATION DÉTAILS DE L'OPÉRATION
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="operation"
              defaultValue={this.state.operation}
              label="Opération"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="categorie_op"
              defaultValue={this.state.categorie_op}
              label="Catégorie"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="adresse_op"
              defaultValue={this.state.adresse_op}
              label="Adresse"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="cp_op"
              defaultValue={this.state.cp_op}
              label="Code postal"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="ville_op"
              defaultValue={this.state.ville_op}
              label="Ville"
              onChange={this.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseProcess} color="primary">
              Annuler la Modification
            </Button>
            <Button
              onClick={() => this.props.showNextDialog(3)}
              color="primary"
            >
              Ne pas Enregistrer
            </Button>
            <Button onClick={this.handleOpModif.bind(this)} color="primary">
              Enregistrer
            </Button>
            {this.state.loading && (<CircularProgress className="spinner" color="inherit" />)}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(DialogModifOp);
