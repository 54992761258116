import React from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { contactExists, getDossier, getContactInfo } from "./Utils";
import { connect } from "react-redux";
import { fetchContact } from "../../redux/actions/contactActions";
import { fetchEcheances } from '../../redux/actions/echeancesActions';
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import { numberToFormat } from "../Utils";
import Header from "../Header/Header";
import GenPdf from "../GenPdf";
import DocMenu from "../DocMenu";
import NumDoss from "../NumDoss";
import Autosuggest from "react-autosuggest";
import "../../Styles/Contrat/ContratData.scss";

const getSuggestionValue = (suggestion) => suggestion.nom;

const renderSuggestion = (suggestion) => (
  <span style={{ padding: "auto" }}>{suggestion.nom}</span>
);

class ContratData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentContact: {},
      nom: "",
      prenom: "",
      idcontact: null,
      siret: "",
      adresse: "",
      sexe: "",
      fonction: "",
      ville: "",
      cp: "",
      comp: "",
      telf: "",
      telp: "",
      tva: "",
      mail: "",
      datedem: "",
      datepgcp: "",
      datepgce: "",
      bank: "",
      paymentMethod: 0,
      pageNumber: 1,
      numPages: 13,
      suggestions: [],
      isOpen: false,
      file: null,
      disableButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { dossier, dossierValue } = this.props;
    if (dossierValue !== "") {
      this.loadData(dossier, dossierValue);
      this.setState({ paymentMethod: this.getDevisCon()?.payment_method })
    }
  }

  componentDidUpdate(prevProps) {
    const { dossier, dossierValue } = this.props;
    if (dossierValue !== "" && dossierValue !== prevProps.dossierValue) {
      this.props.dispatch(changePdfFileName(""))
      this.loadData(dossier, dossierValue);
      this.setState({ paymentMethod: this.getDevisCon()?.payment_method, file: null })
    }
  }

  loadData(dossiers, dossierValue) {
    let currentFolder = getDossier(dossiers, dossierValue)
    let idcontact = currentFolder?.idcontact;
    let currentContact = idcontact ? getContactInfo(this.props.contact, idcontact) : null;

    if (currentContact) {
      this.setState({
        currentContact,
        nom: currentContact.nom,
        prenom: currentContact.prenom,
        idcontact: currentContact.idcontact,
        adresse: currentContact.adress,
        ville: currentContact.city,
        cp: currentContact.cp,
        mail: currentContact.mail,
        sexe: currentContact.sexe,
        telp: currentContact.tel_port,
        comp: currentFolder.sci_sccv,
      })
    } else this.handleReset()
  }
  openDoc() {
    this.setState({ isOpen: true });
  }
  closeDoc() {
    this.setState({ isOpen: false });
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    if ([name] == "nom") {
      if (!contactExists(value, this.props.contact)) {
        this.setState({
          currentContact: {},
          idcontact: null,
          siret: "",
          prenom: "",
          adresse: "",
          ville: "",
          cp: "",
          comp: "",
          telf: "",
          telp: "",
          mail: "",
          sexe: "",
          datedem: "",
          datepgcp: "",
          datepgce: "",
          paymentMethod: 0,
        });
      }
    }
  }

  /**
   * Récupère le dossier choisi
   * @returns dossier choisi
   */
  getDossier() {
    for (let i = 0; i < this.props.dossier.length; i++) {
      if (this.props.dossier[i].ndossier == this.props.dossierValue) {
        return this.props.dossier[i];
      }
    }
    return "";
  }
  /**
   * Récupère les devis Conception validés en fonction du dossier choisi
   * @returns devis validé ou chaîne vide
   */
  getDevisCon() {
    var arr = [];
    for (let i = 0; i < this.props.deviscon.length; i++) {
      if (
        this.props.deviscon[i].ndossier == this.props.dossierValue
      ) {
        arr.push(this.props.deviscon[i]);
      }
    }
    var max = Math.max.apply(
      Math,
      arr.map((item) => item.iddeviscon)
    );
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].iddeviscon == max) {
        return arr[i];
      }
    }
    return "";
  }
  /**
   * Récupère les devis Réalisation validés en fonction du dossier choisi
   * @returns devis validé ou chaîne vide
   */
  getDevisRea() {
    var arr = [];
    for (let i = 0; i < this.props.devisrea.length; i++) {
      if (
        this.props.devisrea[i].ndossier == this.props.dossierValue
      ) {
        arr.push(this.props.devisrea[i]);
      }
    }
    var max = Math.max.apply(
      Math,
      arr.map((item) => item.iddevisrea)
    );
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].iddevisrea == max) {
        return arr[i];
      }
    }
    return "";
  }

  /**
   * Récupère le nombre d'échéances pour la partie Réalisation du dossier choisi
   * @returns Nombre d'échéances
   */
  getEchNum(paymentMethod, duree) {
    if (paymentMethod == 26) {
      return Math.ceil(duree / 2) + " échéances bimestrielles";
    } else return duree + " échéances mensuelles";
  }
  /**
   * Récupère les délais entre les échéances
   * @returns Délais
   */
  getEchDelais(paymentMethod) {
    if (paymentMethod == 26) {
      return 60;
    } else return 30;
  }

  /**
   * Récupère le prix à payer pour chaque échéance
   * @returns Prix
   */
  getEchPrice(paymentMethod, duree) {
    if (paymentMethod == 26) {
      return this.getDevisRea().totalht / Math.ceil(duree / 2)
    } else return ((this.getDevisRea().totalht + this.getDevisCon().totalht) * 0.66) / duree
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.contact.filter(
        (item) =>
          item.nom.toLowerCase().slice(0, inputLength) === inputValue &&
          item.fonction === "Maitre d'ouvrage"
      );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({
      idcontact: suggestion.idcontact,
      siret: suggestion.siret,
      nom: suggestionValue,
      prenom: suggestion.prenom,
      adresse: suggestion.adress,
      ville: suggestion.city,
      cp: suggestion.cp,
      comp: suggestion.entreprise,
      telp: suggestion.tel_port,
      mail: suggestion.mail,
      sexe: suggestion.sexe,
    });
  };

  formatCategory(dossier) {
    switch (dossier.categorie_op) {
      case 1:
        return "I";
      case 2:
        return "II";
      case 3:
        return "III";
    }
  }


  calculateNextDueDate(startDate, monthsToAdd) {
    const date = new Date(startDate);
    date.setUTCMonth(date.getUTCMonth() + monthsToAdd);
    date.setDate(2);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  generateEcheances(startDate, numberOfEcheances, intervalInMonths) {
    let echeancesDates = [];
    let echeancesArray = [];
    for (let i = 1; i <= numberOfEcheances; i++) {
      const echeanceDate = this.calculateNextDueDate(startDate, i * intervalInMonths);
      const [day, month, year] = echeanceDate.split('/');
      const transformedDate = `${year}-${month}-${day}`;
      const echeance = `Echéance N° ${i}`;
      echeancesDates.push({ dateech: transformedDate, raison: echeance });
      echeancesArray.push({ echeance, date: echeanceDate });
    }
    return { echeancesDates, echeancesArray };
  }

  genDueDate(paymentMethod, duree) {
    const startDate = this.state.datedem;
    let numberOfEcheances;
    let intervalInMonths;
    if (paymentMethod == 26) {
      numberOfEcheances = Math.ceil(duree / 2);
      intervalInMonths = 2;
    } else {
      numberOfEcheances = duree;
      intervalInMonths = 1;
    }
    return this.generateEcheances(startDate, numberOfEcheances, intervalInMonths);
  }

  verifInfoMO() {
    const { currentContact, nom, prenom, adresse, ville, cp, mail, sexe, telp } = this.state
    if (nom === currentContact.nom &&
      prenom === currentContact.prenom &&
      adresse === currentContact.adress &&
      ville === currentContact.city &&
      cp === currentContact.cp &&
      mail === currentContact.mail &&
      sexe === currentContact.sexe &&
      telp === currentContact.tel_port) return false;
    return true;
  }

  async handleGenClick() {
    await this.setState({ file: null, disableButton: true });
    const { paymentMethod } = this.state;
    let json = {};
    let jsonContact = {};
    let jsonEcheances = {};
    jsonContact.idcontact = this.state.idcontact;
    jsonContact.nom = this.state.nom.toUpperCase();
    jsonContact.prenom = this.state.prenom;
    jsonContact.sexe = this.state.sexe;
    jsonContact.adress = this.state.adresse;
    jsonContact.city = this.state.ville.toUpperCase();
    jsonContact.cp = this.state.cp;
    jsonContact.mail = this.state.mail;
    jsonContact.tel_port = this.state.telp;

    json.payment_method = paymentMethod;
    json.tva = this.state.tva;
    json.dossier = this.getDossier();
    json.category = this.formatCategory(json.dossier);
    json.dossier.operation_maj = json.dossier.operation.toUpperCase();
    json.dossier.ndossier_ = json.dossier.ndossier.toString().split('').join('-')
    json.year_1 = new Date().getFullYear() - 1;
    json.opprice = (json.dossier.cout_op * 1000000).toLocaleString();
    json.devis_con = this.getDevisCon();
    json.nprop = json.devis_con.nprop.toString().length === 1 ? "0" + json.devis_con.nprop : json.devis_con.nprop;
    json.devis_rea = this.getDevisRea();
    json.idcontact = this.state.idcontact;
    json.siret = this.state.siret;
    json.adresse_mo = this.state.adresse;
    json.ville_mo = this.state.ville.toUpperCase();
    json.cp_mo = this.state.cp;
    json.sci_sccv = this.state.comp.toUpperCase();
    json.tel_fixe = this.state.telf ? this.state.telf : "";
    json.tel_port = this.state.telp;
    json.mail_mo = this.state.mail;
    json.mail_mo2 = "";
    json.nom_mo = this.state.nom.toUpperCase();
    json.prenom_mo = this.state.prenom;
    json.sexe = this.state.sexe == "homme" ? "Monsieur" : "Madame";
    const devisConTotalht = this.getDevisCon().totalht;
    const devisReaTotalht = this.getDevisRea().totalht;
    json.totalht_con = numberToFormat(devisConTotalht);
    json.totalttc_con = numberToFormat(devisConTotalht * 1.2);
    json.totalht_rea = numberToFormat(devisReaTotalht);
    json.totalttc_rea = numberToFormat(devisReaTotalht * 1.2);
    json.totalht = numberToFormat(Number(devisConTotalht) + Number(devisReaTotalht));
    json.totalttc = numberToFormat(Number(devisConTotalht * 1.2) + Number(devisReaTotalht * 1.2));

    if (paymentMethod == 26) {
      json.totalht_con26 = numberToFormat(devisConTotalht * 0.26);
      json.totalttc_con26 = numberToFormat(devisConTotalht * 0.26 * 1.2);
      json.totalht_con74 = numberToFormat(devisConTotalht * 0.74);
      json.totalttc_con74 = numberToFormat(devisConTotalht * 0.74 * 1.2);
    } else {
      json.slice34 = numberToFormat((devisConTotalht + devisReaTotalht) * 0.34);
    }
    const duree = json.devis_rea.duree;
    json.datedem = this.state.datedem;
    json.datepgcp = this.state.datepgcp;
    json.datepgce = this.state.datepgce;
    json.echnum = this.getEchNum(paymentMethod, duree);
    json.delai = this.getEchDelais(paymentMethod);
    json.echprice_ht = numberToFormat(this.getEchPrice(paymentMethod, duree));
    json.echprice_ttc = numberToFormat(this.getEchPrice(paymentMethod, duree) * 1.2);
    json.bank_name = this.state.bank;

    let echeances = this.genDueDate(paymentMethod, duree)
    json.due_date = echeances.echeancesArray;

    let today = new Date().toISOString().slice(0, 10);
    jsonEcheances.ndossier = json.dossier.ndossier;
    jsonEcheances.datepgcp = this.state.datepgcp;
    jsonEcheances.datepgce = this.state.datepgce;
    jsonEcheances.datedem = this.state.datedem;
    jsonEcheances.dates = echeances.echeancesDates;
    jsonEcheances.payment_method = paymentMethod;
    jsonEcheances.amendment = 1;
    jsonEcheances.created_date = today;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(json),
    };
    const requestOptionsContact = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(jsonContact),
    };
    const requestOptionsEchances = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(jsonEcheances),
    };
    this.openDoc();
    let blob = await fetch("/api/contrat/genPDF", requestOptions).then((res) =>
      res.blob()
    );
    if (blob) {
      this.setState({ file: blob });

      await fetch("/api/contrat/registerEchContrat", requestOptionsEchances)
        .catch((err) => {
          console.log(err);
        });

      if (this.verifInfoMO() && this.state.idcontact !== null) {
        await fetch("/api/contact/updateContact", requestOptionsContact)
          .catch((err) => {
            console.log(err);
          });
        await this.props.dispatch(fetchContact())
      };

      await fetch("/api/contrat/saveContrat", requestOptions)
        .catch((err) => {
          console.log(err);
        });
      await this.props.dispatch(fetchEcheances());
      await this.setState({ disableButton: false });
    }
  }

  async handleSaveClick() {
    await this.setState({ disableButton: true });
    const { paymentMethod } = this.state;
    let json = {};
    let jsonContact = {};
    let jsonEcheances = {};

    jsonContact.idcontact = this.state.idcontact;
    jsonContact.nom = this.state.nom.toUpperCase();
    jsonContact.prenom = this.state.prenom;
    jsonContact.sexe = this.state.sexe;
    jsonContact.adress = this.state.adresse;
    jsonContact.city = this.state.ville.toUpperCase();
    jsonContact.cp = this.state.cp;
    jsonContact.mail = this.state.mail;
    jsonContact.tel_port = this.state.telp;

    json.payment_method = paymentMethod;
    json.devis_rea = this.getDevisRea();
    json.dossier = this.getDossier();
    json.siret = this.state.siret;
    json.adresse_mo = this.state.adresse;
    json.ville_mo = this.state.ville.toUpperCase();
    json.cp_mo = this.state.cp;
    json.sci_sccv = this.state.comp.toUpperCase();
    json.tel_fixe = this.state.telf ? this.state.telf : "";
    json.tel_port = this.state.telp;
    json.mail_mo = this.state.mail;
    json.nom_mo = this.state.nom.toUpperCase();
    json.prenom_mo = this.state.prenom;
    json.datedem = this.state.datedem;
    json.datepgcp = this.state.datepgcp;
    json.datepgce = this.state.datepgce;
    json.bank_name = this.state.bank;

    const duree = json.devis_rea.duree;
    let echeances = this.genDueDate(paymentMethod, duree)
    json.due_date = echeances.echeancesArray;

    let today = new Date().toISOString().slice(0, 10);
    jsonEcheances.ndossier = json.dossier.ndossier;
    jsonEcheances.datepgcp = this.state.datepgcp;
    jsonEcheances.datepgce = this.state.datepgce;
    jsonEcheances.datedem = this.state.datedem;
    jsonEcheances.dates = echeances.echeancesDates;
    jsonEcheances.payment_method = paymentMethod;
    jsonEcheances.amendment = 1;
    jsonEcheances.created_date = today;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(json),
    };
    const requestOptionsContact = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(jsonContact),
    };
    const requestOptionsEchances = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(jsonEcheances),
    };

    await fetch("/api/contrat/saveContrat", requestOptions)
      .catch((err) => {
        console.log(err);
      });

    await fetch("/api/contrat/registerEchContrat", requestOptionsEchances)
      .catch((err) => {
        console.log(err);
      });

    if (this.verifInfoMO() && this.state.idcontact !== null) {
      await fetch("/api/contact/updateContact", requestOptionsContact)
        .catch((err) => {
          console.log(err);
        });
      await this.props.dispatch(fetchContact())
    };
    await this.props.dispatch(fetchEcheances());
    await this.setState({ disableButton: false });
  }

  async handleGetContrat() {
    this.setState({ file: null, disableButton: true });
    let response = await this.getFile('pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, disableButton: false }, () => {
        this.openDoc();
      });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ disableButton: false })
    }
  }


  async handleGetPdfFile() {
    this.setState({ file: null });
    let response = await this.getFile('pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await this.getFile('word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  async getFile(type) {
    try {
      const res = await fetch("/api/contrat/showPDF", {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ ndossier: this.props.dossierValue, type })
      });
      const blob = await res.blob();
      const fileName = res.headers.get('X-FileName');
      return { blob, fileName };
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  handleReset() {
    this.setState({
      currentContact: {},
      idcontact: null,
      siret: "",
      nom: "",
      prenom: "",
      adresse: "",
      ville: "",
      cp: "",
      comp: "",
      telf: "",
      telp: "",
      mail: "",
      sexe: "",
      datedem: "",
      datepgcp: "",
      datepgce: "",
      tva: "",
      paymentMethod: 0,
      bank: "",
    });
  }

  isDataEmpty() {
    return (
      this.state.datepgce === "" ||
      this.state.datepgcp === "" ||
      this.state.datedem === "" ||
      this.state.bank === "" ||
      this.state.paymentMethod == 0
    );
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName("Contrat"))
  }

  render() {
    const inputProps = {
      name: "nom",
      value: this.state.nom,
      onChange: this.handleChange,
    };
    return (
      <div className="contratData">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <NumDoss />
        <h1 className="title">Données du contrat</h1>
        <div className="data">
          <div className="table-data">
            <TableContainer style={{ width: "500px" }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead className="table-head">
                  <TableRow className="head-row">
                    <TableCell className="MuiTableCell-head" colSpan={2}>
                      Contrat
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Nom Maître d'Ouvrage</TableCell>
                    <TableCell>
                      <Autosuggest
                        onSuggestionSelected={this.onSuggestionSelected}
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Prénom Maître d'Ouvrage</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="prenom"
                        value={this.state.prenom}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Genre</TableCell>
                    <TableCell>
                      <select
                        name="sexe"
                        style={{ width: "104%", textAlign: "center" }}
                        value={this.state.sexe}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value={""}></option>
                        <option value={"homme"}>Homme</option>
                        <option value={"femme"}>Femme</option>
                      </select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Adresse</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="adresse"
                        value={this.state.adresse}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ville</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="ville"
                        value={this.state.ville}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Code Postal</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="cp"
                        value={this.state.cp}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Téléphone fixe</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="telf"
                        value={this.state.telf}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Téléphone Portable</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="telp"
                        value={this.state.telp}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mail</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="mail"
                        value={this.state.mail}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Entreprise</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="comp"
                        value={this.state.comp}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SIRET</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="siret"
                        value={this.state.siret}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Démarrage du chantier</TableCell>
                    <TableCell>
                      <input
                        type="date"
                        name="datedem"
                        value={this.state.datedem}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Paiement 1ere tranche (26% ou 34%)</TableCell>
                    <TableCell>
                      <input
                        type="date"
                        name="datepgcp"
                        value={this.state.datepgcp}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Envoi du PGC</TableCell>
                    <TableCell>
                      <input
                        type="date"
                        name="datepgce"
                        value={this.state.datepgce}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Banque</TableCell>
                    <TableCell>
                      <select
                        name="bank"
                        style={{ width: "104%", textAlign: "center" }}
                        value={this.state.bank}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value={""}></option>
                        <option value={"bred"}>BRED</option>
                        <option value={"ca"}>CREDIT AGRICOLE</option>
                      </select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type de paiement</TableCell>
                    <TableCell>
                      <span>{this.state.paymentMethod}%</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TVA intracommunautaire</TableCell>
                    <TableCell>
                      <input
                        type="text"
                        name="tva"
                        value={this.state.tva}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="subtable-btn">
              {this.state.disableButton && (
                <CircularProgress className="spinner" color="inherit" />
              )}
              <Button
                variant="contained"
                className="btn-valid"
                onClick={this.handleSaveClick.bind(this)}
                disabled={this.isDataEmpty() || this.state.disableButton}
              >
                Enregistrer
              </Button>
              <Button
                variant="contained"
                className="btn-valid"
                onClick={this.handleGenClick.bind(this)}
                disabled={this.isDataEmpty() || this.state.disableButton}
              >
                Générer
              </Button>
              <Button
                variant="contained"
                className="btn-valid"
                onClick={this.handleGetContrat.bind(this)}
                disabled={this.state.disableButton}
              >
                Afficher
              </Button>
              <Button
                variant="contained"
                className="btn-reset"
                onClick={this.handleReset}
                disabled={this.state.disableButton}
              >
                Annuler
              </Button>
            </div>
          </div>
          <GenPdf
            isOpen={this.state.isOpen}
            file={this.state.file}
            closeDoc={this.closeDoc.bind(this)}
            scale={this.state.file !== null ? 1 : 0.5}
            type={"contrat"}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  contact: state.contact.items,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(ContratData);
