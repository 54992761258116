import React from 'react';
import Button from '@material-ui/core/Button';
import history from '../../../Routes/history';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { List, ListItem, ListItemText } from '@material-ui/core';

class DialogModifDevis extends React.Component {
  constructor(props){
    super(props)
    this.state={
      isOpen:true
    }
    this.handleCloseProcess=this.handleCloseProcess.bind(this)
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
  }
  handleChange(event){
    this.setState({[event.target.name]:event.target.value})
  }
  handleCloseProcess(){
    this.setState({isOpen:false})
    this.props.closeDialog()
}
  handleOpen(){
    this.setState({isOpen:true})
}
    render() {
      return (
        <div>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseProcess} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modification Entreprise Représentante</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Veuillez choisir la partie que vous voulez modififer
                pour le dossier N°{this.props.dossierValue}.
            </DialogContentText>
            <List>
              <ListItem button onClick={() => history.push("/devis/conception")}>
                <ListItemText primary="Conception"/>
              </ListItem>
              <ListItem button onClick={() => history.push("/devis/realisation")}>
                <ListItemText primary="Réalisation"/>
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
    devisrea : state.devis.devisrea,
    deviscon : state.devis.deviscon,
    dossierValue : state.devis.dossierValue,
    nprop : state.devis.nprop,
    attachment: state.email.file
  });
export default connect(mapStateToProps)(DialogModifDevis)
