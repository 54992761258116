import React from "react"
import { connect } from "react-redux";
import AgencementTable from "./AgencementTable"
import ArmatureTable from "./ArmatureTable"
import AscenseurTable from "./AscenseurTable"
import CarrelageTable from "./CarrelageTable"
import ChapeDallageTable from "./ChapeDallageTable"
import CharpCouvTable from "./CharpCouvTable"
import ChauffageTable from "./ChauffageTable"
import ChauffageVMC from "./ChauffageVMC"
import CloisonTable from "./CloisonTable"
import CuisinisteTable from "./CuisinisteTable"
import CuvelageTable from "./CuvelageTable";
import DemoCurageTable from "./DemoCurageTable"
import DemontageGrueTable from "./DemontageGrueTable"
import DesamiantageTable from "./DesamiantageTable"
import EchafaudageTable from "./EchafaudageTable"
import ElectriciteTable from "./ElectriciteTable"
import EspVertTable from "./EspVertTable"
import EtancheiteTable from "./EtancheiteTable"
import FaçadeTable from "./FaçadeTable"
import FerraillageTable from "./FerraillageTable"
import GOTable from "./GOTable"
import MaçonnerieTable from "./MaçonnerieTable"
import MCPlateforme from "./MCPlateforme";
import MenExtTable from "./MenExtTable"
import MenIntTable from "./MenIntTable"
import MetallerieTable from "./MetallerieTable"
import MontageGrueTable from "./MontageGrueTable"
import PBVTable from "./PBVTable"
import PeintureTable from "./PeintureTable"
import PieuxTable from "./PieuxTable";
import PlacoEnduitTable from "./PlacoEnduitTable"
import PlatrerieTable from "./PlatrerieTable"
import PlomberiePlus from "./PlomberiePlus"
import PlomberieTable from "./PlomberieTable"
import RavalementTable from "./RavalementTable"
import SerrurerieTable from "./SerrurerieTable"
import TerrassementTable from "./TerrassementTable"
import ToitureTable from "./ToitureTable"
import VentilationTable from "./VentilationTable"
import VMCTable from "./VMCTable"
import VPPTable from "./VPPTable"
class AllTables extends React.Component{
    constructor(props){
        super(props)
        this.state={}
    }
    componentDidMount(){
        this.loadDataTables()
    }
    componentDidUpdate(prevProps){
        if(this.props.lotValue!==prevProps.lotValue || this.props.entrepriseValue!==prevProps.entrepriseValue
			|| this.props.dossierValue!==prevProps.dossierValue){
            this.loadDataTables()
        }
    }
    loadDataTables(){
        if(this.props.lotValue==1){
            this.props.filldelIndexes([0,1,5,8,9,13,17,21,24,28,29,31,40,41,46,47,52,53]) 
            this.props.loadData([0,1,5,8,9,13,17,21,24,28,29,31,40,41,46,47,52,53])
        }
        if(this.props.lotValue==2){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47])
        }
        if(this.props.lotValue==3){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43])
        }
        if(this.props.lotValue==4){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45])
        }
        if(this.props.lotValue==5){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47])
        }
        if(this.props.lotValue==6){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47])
        }
        if(this.props.lotValue==7){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,26,30,31,32,36,40,43,44,48,52,57])
            this.props.loadData([0,1,5,9,13,16,17,22,26,30,31,32,36,40,43,44,48,52,57]) 
        }
        if(this.props.lotValue==8){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51])
            this.props.loadData([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
        }
        if(this.props.lotValue==9){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45])
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45])  
        }
        if(this.props.lotValue==10){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47])
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47])   
        }
        // if(prevProps.lot!==this.props.lot && this.props.lotValue==10){
        //     this.props.filldelIndexes([0,1,5,9,10,14,17,21,25,26,27,31,35,42,43,47]) 
        //     this.props.loadData([0,1,5,9,10,14,17,21,25,26,27,31,35,42,43,47])  
        // }
        if(this.props.lotValue==11){
            this.props.filldelIndexes([0,1,5,8,9,13,17,21,25,26,27,31,41,42,46,47]) 
            this.props.loadData([0,1,5,8,9,13,17,21,25,26,27,31,41,42,46,47])  
        }
        if(this.props.lotValue==12){
            this.props.filldelIndexes([0,1,5,9,10,14,15,19,20,21,22,26,31,35]) 
            this.props.loadData([0,1,5,9,10,14,15,19,20,21,22,26,31,35])  
        }
        if(this.props.lotValue==13){
            this.props.filldelIndexes([0,1,5,9,12,13,17,18,22,23,24,28,32,36,37,41]) 
            this.props.loadData([0,1,5,9,12,13,17,18,22,23,24,28,32,36,37,41])
        }
        if(this.props.lotValue==14){
            this.props.filldelIndexes([0,1,5,9,10,14,15,19,20,21,22,26,31,35]) 
            this.props.loadData([0,1,5,9,10,14,15,19,20,21,22,26,31,35])  
        }
        if(this.props.lotValue==15){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47])
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
        }
        if(this.props.lotValue==16){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43])
        }
        if(this.props.lotValue==17){
            this.props.filldelIndexes([1,2,6,10,13,14,18,22,26,27,28,29,33,37,40,41,45,49]) 
            this.props.loadData([1,2,6,10,13,14,18,22,26,27,28,29,33,37,40,41,45,49])
        }
        if(this.props.lotValue==18){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==19){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==20){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==21){
            this.props.filldelIndexes([0,1,5,9,12,13,17,18,22,23,27,31,35,36,40,41]) 
            this.props.loadData([0,1,5,9,12,13,17,18,22,23,27,31,35,36,40,41]) 
        }
        if(this.props.lotValue==22){
            this.props.filldelIndexes([0,1,5,9,12,13,17,18,22,23,24,28,32,36,37,41]) 
            this.props.loadData([0,1,5,9,12,13,17,18,22,23,24,28,32,36,37,41])
        }
        if(this.props.lotValue==23){
            this.props.filldelIndexes([0,1,5,9,12,13,17,18,22,23,24,28,32,36,37,41]) 
            this.props.loadData([0,1,5,9,12,13,17,18,22,23,24,28,32,36,37,41])
        }
        if(this.props.lotValue==24){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
        }
        if(this.props.lotValue==25){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
        }
        if(this.props.lotValue==26){
            this.props.filldelIndexes([0,1,5,8,9,14,18,20,24,25,28,32,35,36,40,44,45]) 
            this.props.loadData([0,1,5,8,9,14,18,20,24,25,28,32,35,36,40,44,45]) 
        }
        if(this.props.lotValue==27){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,22,26,27,31,35,36,40,44,49]) 
            this.props.loadData([0,1,5,9,12,13,17,21,22,26,27,31,35,36,40,44,49]) 
        }
        if(this.props.lotValue==28){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,22,26,27,31,35,36,40,44,49]) 
            this.props.loadData([0,1,5,9,12,13,17,21,22,26,27,31,35,36,40,44,49]) 
        }
        if(this.props.lotValue==29){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
        }
        if(this.props.lotValue==30){
            this.props.filldelIndexes([0,1,5,8,9,13,17,21,24,28,29,31,41,42,46,47,52,53]) 
            this.props.loadData([0,1,5,8,9,13,17,21,24,28,29,31,41,42,46,47,52,53]) 
        }
        if(this.props.lotValue==31){
            this.props.filldelIndexes([0,1,5,8,9,13,17,21,24,28,29,31,41,42,46,47,52,53]) 
            this.props.loadData([0,1,5,8,9,13,17,21,24,28,29,31,41,42,46,47,52,53]) 
        }
        if(this.props.lotValue==32){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
        }
        if(this.props.lotValue==33){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
        }
        if(this.props.lotValue==34){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,22,26,27,31,35,36,40,44,49]) 
            this.props.loadData([0,1,5,9,12,13,17,21,22,26,27,31,35,36,40,44,49]) 
        }
        if(this.props.lotValue==35){
            this.props.filldelIndexes([0,1,5,8,9,13,17,21,22,26,27,31,32,39,49]) 
            this.props.loadData([0,1,5,8,9,13,17,21,22,26,27,31,32,39,49]) 
        }
        if(this.props.lotValue==36){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==37){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==38){
            this.props.filldelIndexes([0,1,5,9,16,17,21,23,27,28,29,32,36,40,43,46,47,51]) 
            this.props.loadData([0,1,5,9,16,17,21,23,27,28,29,32,36,40,43,46,47,51]) 
        }
        if(this.props.lotValue==39){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==40){
            this.props.filldelIndexes([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
            this.props.loadData([0,1,5,9,12,13,17,20,24,25,27,31,35,36,40,44,45]) 
        }
        if(this.props.lotValue==41){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
        }
        if(this.props.lotValue==42){
            this.props.filldelIndexes([0,1,5,8,9,14,18,22,23,24,28,32,33,37,41]) 
            this.props.loadData([0,1,5,8,9,14,18,22,23,24,28,32,33,37,41]) 
        }
        if(this.props.lotValue==43){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51])
            this.props.loadData([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
        }
        if(this.props.lotValue==44){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51])
            this.props.loadData([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
        }
        if(this.props.lotValue==45){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51])
            this.props.loadData([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
        }
        if(this.props.lotValue==46){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47])
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47])  
        }
        if(this.props.lotValue==47){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,42,43,47])  
        }
        if(this.props.lotValue==48){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,23,24,27,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,23,24,27,38,39,43])  
        }
        if(this.props.lotValue==49){
            this.props.filldelIndexes([0,1,5,8,9,13,17,19,24,27,31,38,39,43]) 
            this.props.loadData([0,1,5,8,9,13,17,19,24,27,31,38,39,43])
        }
        if(this.props.lotValue==50){
            this.props.filldelIndexes([0,1,5,9,12,16,17,21,24,28,29,31,35,39,40,44,48,53])
            this.props.loadData([0,1,5,9,12,16,17,21,24,28,29,31,35,39,40,44,48,53]) 
        }
        if(this.props.lotValue==51){
            this.props.filldelIndexes([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47]) 
            this.props.loadData([0,1,5,9,12,13,17,21,25,26,27,31,35,38,39,43,47]) 
        }
        if(this.props.lotValue==52){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51])
            this.props.loadData([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
        }
        if(this.props.lotValue==53){
            this.props.filldelIndexes([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
            this.props.loadData([0,1,5,9,13,16,17,22,23,27,28,29,33,37,41,42,46,51]) 
        }
        if(this.props.lotValue==54){
            this.props.filldelIndexes([0,1,5,9,16,17,21,23,27,28,29,32,36,40,43,46,47,51]) 
            this.props.loadData([0,1,5,9,16,17,21,23,27,28,29,32,36,40,43,46,47,51])
        }
    }
    getTable(){
        if(this.props.lotValue==1){
            return <AgencementTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==2){
            return <ArmatureTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes} lot={this.props.lot}
            />
        }
        else if(this.props.lotValue==3){
            return <AscenseurTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==4){
            return <CarrelageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==5){
            return <ChapeDallageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==6){
            return <CharpCouvTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==7){
            return <ChauffageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==8){
            return <ChauffageVMC handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==9){
            return <CloisonTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==10){
            return <CharpCouvTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==11){
            return <CuisinisteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==13){
            return <CuvelageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==12 || this.props.lotValue==14){
            return <DemoCurageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==15){
            return <DesamiantageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==16){
            return <EchafaudageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==17){
            return <ElectriciteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==18){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==19){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==20){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==21){
            return <EspVertTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==22){
            return <EtancheiteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==23){
            return <EtancheiteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==24){
            return <FaçadeTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==25){
            return <FerraillageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==26){
            return <GOTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==27){
            return <DemontageGrueTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==28){
            return <MontageGrueTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==29){
            return <MaçonnerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==30){
            return <MenExtTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==31){
            return <MenIntTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==32){
            return <MCPlateforme handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==33){
            return <MCPlateforme handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==34){
            return <MetallerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==35){
            return <PeintureTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==36){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==37){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==38){
            return <PieuxTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==39){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==40){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==41){
            return <MCPlateforme handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==42){
            return <PlomberieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==43){
            return <PlomberiePlus handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==44){
            return <PlomberiePlus handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==45){
            return <PlomberiePlus handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==46){
            return <PlatrerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==47){
            return <PBVTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==48){
            return <RavalementTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==49){
            return <SerrurerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==50){
            return <TerrassementTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==51){
            return <ToitureTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==52){
            return <VentilationTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==53){
            return <VMCTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else if(this.props.lotValue==54){
            return <VPPTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}
                 filldelIndexes={this.props.filldelIndexes}
            />
        }
        else return ""
    }
    render(){
        return(
            this.getTable()
        )
    }
}
const mapStateToProps = state => ({
	dossierValue: state.devis.dossierValue,
	lotValue : state.vic.lotValue,
	vic : state.vic.items,
	entrepriseValue: state.entreprise.entrepriseValue,
  });
  export default connect(mapStateToProps)(AllTables);