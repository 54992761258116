import React from 'react' ; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
class SocialNetwork extends React.Component{
    anim = () => {
        let navLinks = document.querySelectorAll('.social-network a');

        navLinks.forEach(link => {
            link.addEventListener('mouseover', (e) => {
                let attrX = e.offsetX - 20;
                let attrY = e.offsetY - 13;

                link.style.transform = `translate(${attrX}px, ${attrY}px)` ; 
            })
        })

    }
    render(){
    return (
        <div className="social-network">
            <ul className="content">
                <a href="https://www.facebook.com" target="_blank"
                rel="noopener noreferrer" className="facebookIcon">
                <li><FontAwesomeIcon icon={faFacebookF}/></li>
                </a>
                <a href="https://www.twitter.com" target="_blank"
                rel="noopener noreferrer" className="twitterIcon">
                   <li><FontAwesomeIcon icon={faTwitter}/></li>
                </a>
                <a href="https://www.coordogec.fr/" target="_blank"
                rel="noopener noreferrer" className="coordogecIcon">
                    <li><FontAwesomeIcon icon={faGlobeEurope}/></li>
                </a>
            </ul>
        </div>
    );
    }
};


export default SocialNetwork;