import React from 'react';
import { connect } from "react-redux";
import Header from '../../Header/Header';
import Button from '@material-ui/core/Button';
import InfosDevis from './InfosDevis';
import DocMenu from '../../DocMenu';
import NumDoss from '../../NumDoss'
import GenPdf from '../../GenPdf';
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import '../../../Styles/Devis/Devis.scss';

/**
 * Récupère le dossier selectionné
 * @param {string} dossierValue 
 * @param {[]} dossier 
 * @returns dossier
 */
const getProps = (dossierValue, dossier) => {
    for (let i = 0; i < dossier.length; i++) {
        if (dossierValue == dossier[i].ndossier) {
            return dossier[i]
        }
    }
    return []
}
class CalcDevis extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataCalc: {
                operation: getProps(this.props.dossierValue, this.props.dossier).operation,
                ouvrage: '',
                coutTrav: getProps(this.props.dossierValue, this.props.dossier).cout_op,
                dureeTrav: getProps(this.props.dossierValue, this.props.dossier).durée_op,
                catOp: getProps(this.props.dossierValue, this.props.dossier).categorie_op,
                nbrLots: null,
                haut: null,
                compArchi: 'Faible',
                compTech: 'Faible',
                rep: 'Faible',
                compEnv: 'Faible',
            },
            calculTab: {
                heureconm: 0,
                heureream: 0,
                heurecon: 0,
                heurerea: 0,
                coefcom: 0,
                heureTotal: 0,
            },
            calcClicked: false,
            isOpen: false,
            file: null,
            pdf: false,
            loading: false,
        }
        this.handleCalcClicked = this.handleCalcClicked.bind(this)
        this.closeDoc = this.closeDoc.bind(this)
        this.showPDF = this.showPDF.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(changePdfFileName(""))
    }
    /**
     * Mise à jour de certaines valeurs lors du chanement de dossier
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (this.props.dossierValue !== prevProps.dossierValue) {
            this.props.dispatch(changePdfFileName(""))
            this.state.dataCalc.operation = getProps(this.props.dossierValue, this.props.dossier).operation
            this.state.dataCalc.coutTrav = getProps(this.props.dossierValue, this.props.dossier).cout_op
            this.state.dataCalc.dureeTrav = getProps(this.props.dossierValue, this.props.dossier).durée_op
            this.state.dataCalc.catOp = getProps(this.props.dossierValue, this.props.dossier).categorie_op
            this.setState(this.state.dataCalc)
            this.setState({ calculTab: { heureconm: 0, heureream: 0, heurecon: 0, heurerea: 0, coefcom: 0, heureTotal: 0 }, file: null, pdf: false })
        }
    }
    /**
     * Envoi les données rentrées et affiche le calcul approximatif du devis
     */
    async handleCalcClicked() {
        this.setState({ calcClicked: !this.state.calcClicked })
        this.closeDoc()
        if (this.state.file != null) {
            this.setState({ file: null })
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "calculIndicatif": this.state.dataCalc, "ndossier": +this.props.dossierValue })
        };
        this.openDoc()
        let blob = await fetch('/api/devis/newExcelDataCalcul', requestOptions).then(res => res.blob())
        if (blob) {
            this.setState({ file: blob })
        }
    }

    isZero() {
        var isZero = false
        var values = Object.values(this.state.calculTab)
        for (let i = 0; i < values.length; i++) {
            if (values[i] === 0) {
                isZero = true
            }
        }
        return isZero
    }
    /**
     * Enregistre les resultats dans la base de données
     */
    handleRegisterData() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "calculTab": this.state.calculTab, "ndossier": this.props.dossierValue })
        };
        fetch("/api/devis/calcHeures", requestOptions)
    }
    /**
     * Affiche le fichier PDF
     */
    openDoc() {
        this.setState({ pdf: true })
    }
    /**
     * Ferme le fichier PDF
     */
    closeDoc() {
        this.setState({ pdf: false })
    }

    showPDF() {
        this.closeDoc()

        if (this.state.file != null) {
            this.setState({ file: null })
        }
        fetch('/api/devis/sendPDFCalc')
            .then(res => res.blob())
            .then(res => this.setState({ file: res }))
        this.openDoc()
        this.setState({ loading: false })
    }

    checkCalculData() {
        var array = Object.values(this.state.dataCalc)
        if (this.state.loading) {
            return true
        }
        for (var i = 0; i < array.length - 3; i++) {
            if (array[i] === '') {
                return true
            }
        }
        return false
    }
    /**
     * Récupère les resultats depuis la génération du document PDF
     */
    getData() {
        let newCalculTab = {}
        if (document.getElementsByClassName('react-pdf__Page__textContent').length > 0) {
            for (let i = 0; i < document.getElementsByClassName('react-pdf__Page__textContent')[0].children.length; i++) {
                if (document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i].textContent == "Nb d'heures en conception") {
                    newCalculTab.heureconm = document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i + 2].textContent
                }
                if (document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i].textContent == "Nb d'heures en réalisation") {
                    newCalculTab.heureream = document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i + 2].textContent
                }
                if (document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i].textContent == "Coefficient de complexité") {
                    newCalculTab.coefcom = document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i + 2].textContent
                }
                if (document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i].textContent == "C x K =") {
                    newCalculTab.heurecon = document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i + 2].textContent
                }
                if (document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i].textContent == "R x K =") {
                    newCalculTab.heurerea = document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i + 2].textContent
                }
                if (document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i].textContent == "Nombre d'heures Total : ") {
                    newCalculTab.heureTotal = document.getElementsByClassName('react-pdf__Page__textContent')[0].children[i + 1].textContent
                }
            }
            this.setState({ calculTab: newCalculTab })
        }
    }

    changeCalc(name, value) {
        var array = Object.keys(this.state.dataCalc)
        for (var i = 0; i < Object.keys(this.state.dataCalc).length; i++) {
            if (name === array[i]) {
                this.state.dataCalc[name] = value
                this.setState(this.state.dataCalc)
            }
        }
    }
    /**
     * Permet de télécharger le fichier afficher précédemment
     */
    downloadFile() {
        let url = window.URL.createObjectURL(this.state.file);
        let a = document.createElement('a');
        a.href = url;
        a.download = "calculIndicatif.pdf";
        a.click();
    }

    async handleAffAppDevis() {
        this.setState({ file: null });
        let response = await this.getFile('pdf');

        if (response.blob.size > 100) {
            this.setState({ file: response.blob });
            this.openDoc();

            const fileName = response.fileName;
            this.props.dispatch(changePdfFileName(fileName))
        }
    }

    async handleGetPdfFile() {
        this.setState({ file: null });
        let response = await this.getFile('pdf');

        if (response.blob.size > 100) {
            await this.setState({ file: response.blob });

            const fileName = response.fileName;
            await this.props.dispatch(changePdfFileName(fileName))
        }
    }

    async handleGetExcelFile() {
        this.setState({ file: null });
        let response = await this.getFile('excel');

        if (response.blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.setState({ file: response.blob });

            const fileName = response.fileName;
            this.props.dispatch(changePdfFileName(fileName))
        }
    }

    async getFile(type) {
        try {
            const res = await fetch("/api/devis/getAppDevis", {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ ndossier: this.props.dossierValue, type })
            });
            const blob = await res.blob();
            const fileName = res.headers.get('X-FileName');
            return { blob, fileName };
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    handleChangeTitleName() {
        this.props.dispatch(changeTitleName("Calcul Approximatif"))
    }
    render() {
        if (this.state.pdf == true && this.state.calculTab.heureconm === 0) {
            setTimeout(() => {
                this.getData()
            }, 1000);
        }
        return (
            <div className="calcDevis">
                <Header />
                <DocMenu
                    file={this.state.file}
                    fileType={"excel"}
                    getSourceFile={this.handleGetExcelFile.bind(this)}
                    getPDF={this.handleGetPdfFile.bind(this)}
                    handleChangeTitleName={this.handleChangeTitleName.bind(this)}
                />
                <h1>Calcul Approximatif du Devis</h1>
                <NumDoss type="devis" proposition={false} />
                <InfosDevis changeCalc={this.changeCalc.bind(this)} dataCalc={this.state.dataCalc} calcValue={this.state.calcClicked} calculTab={this.state.calculTab} />
                <Button className="calculButton gen-btn" onClick={this.handleCalcClicked} variant="contained" color="primary" disabled={this.checkCalculData()}>Générer PDF</Button>
                <Button className="calculButton" variant="contained" color="primary" onClick={this.handleAffAppDevis.bind(this)}>Afficher PDF</Button>
                <Button className="calculButton" onClick={this.downloadFile.bind(this)} variant="contained" color="primary" disabled={this.isZero()}>Télécharger PDF</Button>
                <Button className="calculButton" onClick={this.handleRegisterData.bind(this)} variant="contained" color="primary" disabled={this.isZero()}>Enregistrer</Button>

                {/* <InfosCalc calcValue={this.state.calcClicked} calculTab={this.state.calculTab} /> */}
                <GenPdf file={this.state.file} isOpen={this.state.pdf} closeDoc={this.closeDoc} calcValue={this.state.calcClicked} pageNumber={1} scale={2} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    deviscon: state.devis.deviscon,
    dossierValue: state.devis.dossierValue,
    nprop: state.devis.nprop,
    dossier: state.dossier.items
});
export default connect(mapStateToProps)(CalcDevis);
