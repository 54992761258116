export const getAllQuotas = (quotas, dossierValue, type) => {
  var tab = [];
  for (let i = 0; i < quotas.length; i++) {
    if (type == "Conception") {
      if (
        quotas[i].type == "Conception" &&
        quotas[i].ndossier == dossierValue
      ) {
        tab.push(quotas[i]);
      }
    } else if (type == "Realisation") {
      if (
        quotas[i].type == "Realisation" &&
        quotas[i].ndossier == dossierValue
      ) {
        tab.push(quotas[i]);
      }
    }
  }
  return tab;
};

export const getCurrentQuotas = (quotas, type) => {
  var tab = [];
  for (let i = 0; i < quotas.length; i++) {
    if (type == "Conception") {
      if (
        quotas[i].type == "Conception"
      ) {
        tab.push(quotas[i]);
      }
    } else if (type == "Realisation") {
      if (
        quotas[i].type == "Realisation"
      ) {
        tab.push(quotas[i]);
      }
    }
  }
  return tab;
};

export const getQuota = (quotas, dossierValue, tache) => {
  var quota = {};
  for (let i = 0; i < quotas.length; i++) {
    if (quotas[i].tache == tache && quotas[i].ndossier == dossierValue) {
      quota = quotas[i];
    }
  }
  return quota;
};

export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};


export const getCategoryNum = (dossiers, dossierValue) => {
  for (let i = 0; i < dossiers.length; i++) {
    if (dossiers[i].ndossier == dossierValue) {
      return dossiers[i].categorie_op;
    }
  }
  return "";
};

export const getInfosDossier = (dossiers, dossierValue) => {
  for (let i = 0; i < dossiers.length; i++) {
    if (dossiers[i].ndossier == dossierValue) {
      return dossiers[i];
    }
  }
  return "";
};

export const getCurrentDevis = (dossiers, dossierValue) => {
  for (let i = 0; i < dossiers.length; i++) {
    if (dossiers[i].ndossier == dossierValue) {
      return dossiers[i];
    }
  }
  return "";
};


export const getPDF = async (dossierValue, type) => {
  try {
    const res = await fetch("/api/quota/getPDF", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getHistPDF = async (dossierValue, type) => {
  try {
    const res = await fetch("/api/quota/getHistPDF", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};