export const FETCH_ECH_BEGIN = 'FETCH_ECH_BEGIN';
export const FETCH_ECH_SUCCESS = 'FETCH_ECH_SUCCESS';
export const FETCH_ECH_FAILURE = 'FETCH_ECH_FAILURE';
export const FETCH_ECH_BY_NDOSSIER = 'FETCH_ECH_BY_NDOSSIER';
export const FETCH_ECH_BY_AMENDMENT = 'FETCH_ECH_BY_AMENDMENT';
export const CHANGE_AMENDMENT_VALUE = 'CHANGE_AMENDMENT_VALUE';

export function fetchEcheances() {
  return dispatch => {
    dispatch(fetchEchBegin());
    return fetch("/api/contrat/getEch")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchEchSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchEchFailure(error)));
  };
}

export function fetchEcheancesByNdossier(ndossier) {
  return {
    type: FETCH_ECH_BY_NDOSSIER,
    payload: { ndossier }
  };
}


export function fetchEcheancesByAmendment(ndossier, amendment) {
  return {
    type: FETCH_ECH_BY_AMENDMENT,
    payload: { ndossier, amendment }
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchEchBegin = () => ({
  type: FETCH_ECH_BEGIN
});

export const fetchEchSuccess = echeances => ({
  type: FETCH_ECH_SUCCESS,
  payload: { items: echeances }
});

export const fetchEchFailure = error => ({
  type: FETCH_ECH_FAILURE,
  payload: { error }
});


export const changeAmendmentValue = amendment => ({
  type: CHANGE_AMENDMENT_VALUE,
  payload: { amendment }
});