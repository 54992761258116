import moment from "moment";

export const getCalendarData = async (id_user) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      id_user: id_user
    })
  };
  try {
    const response = await fetch(`/api/agenda/getCalendarData`, requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }

    const data = await response.json(); 
    const { calendar, events } = data;

    return { calendar, events };
  } catch (err) {
    console.error("Error fetching calendar data:", err);
    throw err;
  }
}

export const addEvent = async (newEvent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(newEvent)
  };
  return await fetch("/api/agenda/addEvent", requestOptions).catch((error) => {
    throw error;
  })
}

export const updateEvent = async (updatedEvent) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(updatedEvent)
  };

  try {
    const response = await fetch(`/api/agenda/updateEvent`, requestOptions);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'événement:", error);
    throw error;
  }
}

export const deleteEvent = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      id: id
    })
  };
  try {
    const response = await fetch(`/api/agenda/deleteEvent`, requestOptions);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la suppression de l'événement:", error);
    throw error;
  }
}

export const getAppointments = async (dossierValue, userId) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      id_user: userId,
      ndossier: dossierValue
    })
  };
  try {
    const response = await fetch("/api/agenda/getAppointmentsByDoss", requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching appointments:", err);
    throw err;
  }
}

export const addAppointment = async (dossierValue, newAppointment, userId) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      id_user: userId,
      ndossier: dossierValue,
      newAppointment
    })
  };
  return await fetch("/api/agenda/addAppointment", requestOptions).catch((error) => {
    throw error;
  })
}

export const updateAppointment = async (updatedAppointment) => {
  const formatedDateAppointment = {
    ...updatedAppointment,
    appointment_start: updatedAppointment.appointment_start ? moment.utc(updatedAppointment.appointment_start).format("YYYY-MM-DD HH:mm") : null,
    appointment_end: updatedAppointment.appointment_end ? moment.utc(updatedAppointment.appointment_end).format("YYYY-MM-DD HH:mm") : null
  }
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(formatedDateAppointment)
  };
  return await fetch("/api/agenda/updateAppointment", requestOptions).catch((error) => {
    throw error;
  })
}

export const deleteAppointment = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      id: id
    })
  };

  try {
    const response = await fetch(`/api/agenda/deleteAppointment`, requestOptions);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la suppression de l'événement:", error);
    throw error;
  }
}