import {
  FETCH_REMINDER_BEGIN,
  FETCH_REMINDER_SUCCESS,
  FETCH_REMINDER_FAILURE,
} from "../actions/reminderActions";
const initialState = {
  items: [],
  error: null,
};

export default function reminderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REMINDER_BEGIN: {
      return {
        ...state,
        error: null,
      };
    }
    case FETCH_REMINDER_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
      };
    }
    case FETCH_REMINDER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        items: [],
      };
    }
    default:
      return state;
  }
}
