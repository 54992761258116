import React from 'react';
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { numberToFormat } from "../../Utils";

class FacAmendmentInfos extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { numclient, dateEch, datecomptable, bdcValue, company, nameMo, operation, adressOp, cpOp, cityOp, tauxh, paymentMethod, nbHours, nbAmendments, dates, handleChangeValues } = this.props
    const amountHt = isNaN((nbHours * tauxh) / dates.length) ? 0 : (nbHours * tauxh) / dates.length
    return (
      <div className="fac-amendment-infos">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead className="table-head">
              <TableRow className="head-row">
                <TableCell className="MuiTableCell-head" colSpan={2}>
                  Données
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Code client:</TableCell>
                <TableCell>
                  {numclient}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Responsable:</TableCell>
                <TableCell>
                  {nameMo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Entreprise:</TableCell>
                <TableCell>
                  {company}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Opération:</TableCell>
                <TableCell>
                  {operation}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse:</TableCell>
                <TableCell>
                  {adressOp && `${adressOp}, ${cpOp} ${cityOp}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date de Facture:</TableCell>
                <TableCell>
                  <input
                    type="date"
                    name="dateEch"
                    style={{ marginLeft: "0" }}
                    value={dateEch || ''}
                    onChange={(event) => handleChangeValues(event)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date d'établissement:</TableCell>
                <TableCell>
                  <input
                    type="date"
                    name="datecomptable"
                    style={{ marginLeft: "0" }}
                    value={datecomptable || ''}
                    onChange={(event) => handleChangeValues(event)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>N° BDC:</TableCell>
                <TableCell>
                  <input
                    type="text"
                    name="bdcValue"
                    placeholder="Bon de commande"
                    style={{ marginLeft: "0" }}
                    value={bdcValue || ''}
                    onChange={(event) => handleChangeValues(event)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mode de paiement:</TableCell>
                <TableCell>
                  {paymentMethod !== 0 && `${paymentMethod} %`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Taux horaire:</TableCell>
                <TableCell>
                  {tauxh !== 0 && `${tauxh.toFixed(2)} €`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant HT:</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {amountHt !== 0 && `${numberToFormat(amountHt)} €`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant TTC:</TableCell>
                <TableCell>
                  {amountHt !== 0 && `${numberToFormat(amountHt * 1.2)} €`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button className="create-btn" variant="contained" color="primary" onClick={this.props.hundleCreateFacAmendment} disabled={nbAmendments === dates.length}>Créer nouvelle facture</Button>
        </TableContainer>
      </div>
    )
  }
}
export default FacAmendmentInfos;