import React from 'react';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import GenPdf from '../GenPdf';
import Header from '../Header/Header';
import NumDoss from '../NumDoss';
import DocMenu from "../DocMenu";
import { fetchAllFacAmendment, fetchFacAmendmentByDoss, changeFacAmendmentNumber } from "../../redux/actions/facAmendmentActions";
import { fetchAllFacAmendmentReminder } from "../../redux/actions/reminderFacAmendmentActions";
import FacAmendmentReminderDetail from "./FacAmendmentReminder/FacAmendmentReminderDetail";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import { getContact, getEch } from '../Factures/Utils';
import { getRefFacture, genFacAmendmentReminder, getFacAmendmentReminder, genFacAmendmentReminderTable, getFacAmendmentReminderTable } from './Utils';
import "../../Styles/FactureAmendment/FacAmendmentReminder.scss";


class FacAmendmentReminder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataFacture: { date_ech: new Date() },
      maitreOuvrage: {},
      first_reminder: true,
      disableButton: false,
      clientResponse: false,
      comptableSexe: "male",
      comptableName: "",
      avenantEcheance: {},
      file: null,
      openFile: false,
    }
  }

  async componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { dossierValue, facAmendmentIndex, echeances } = this.props;
    await this.props.dispatch(fetchAllFacAmendment())
    await this.props.dispatch(fetchAllFacAmendmentReminder())

    if (dossierValue) {
      if (echeances) {
        const avenantEcheance = await getEch(echeances, dossierValue)
        this.setState({ avenantEcheance });
      }
      await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
      await this.props.dispatch(changeFacAmendmentNumber(facAmendmentIndex))
      await this.loadData()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { first_reminder } = this.state;
    const { dossierValue, facAmendmentIndex, facAmendmentsByDoss, echeances } = this.props;

    if (prevProps.dossierValue !== dossierValue) {
      await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
    }

    if (dossierValue !== prevProps.dossierValue || echeances !== prevProps.echeances) {
      const avenantEcheance = await getEch(echeances, dossierValue)
      this.setState({ avenantEcheance });
    }

    if (prevProps.facAmendmentIndex !== facAmendmentIndex || facAmendmentsByDoss !== prevProps.facAmendmentsByDoss) {
      await this.loadData()
    }

    if(first_reminder !== prevState.first_reminder) {
      this.setState({ file: null });
    }
  }

  handleDateReminderChange(date) {
    let dataFacture = this.state.dataFacture
    dataFacture.date_reminder = date
    this.setState({ dataFacture, comptableSexe: "male", comptableName: "" });
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { facAmendmentIndex, facAmendmentsByDoss, dossierValue, dossiers, contact } = this.props;
    const maitreOuvrage = getContact(dossiers, contact, dossierValue)
    let dataFacture = facAmendmentIndex > 0 && facAmendmentIndex <= facAmendmentsByDoss.length ? { ...facAmendmentsByDoss[facAmendmentIndex - 1] } : { date_ech: new Date() };

    if (dataFacture.dossier !== undefined && Object.keys(dataFacture.dossier).length > 6) {
      dataFacture.dossier = JSON.parse(dataFacture.dossier)
      dataFacture.date_ech = dataFacture.date_ech
      dataFacture.date_reminder = new Date().toLocaleDateString('en-CA')
      dataFacture.total_tva = parseFloat((dataFacture.total_ht * (dataFacture.tva / 100)).toFixed(2))
      dataFacture.total_ttc = parseFloat((dataFacture.total_ht * (1 + (dataFacture.tva / 100))).toFixed(2))
    }
    this.setState({ dataFacture, maitreOuvrage, file: null })
  }

  handleChangeSwitch() {
    this.setState({ first_reminder: !this.state.first_reminder, clientResponse: false });
  }

  handleChangeClientResponse(response) {
    this.setState({ clientResponse: response })
  }

  handleChangeCompatbleInfos(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getSignatureDate(numdossier) {
    const { factures } = this.props
    const firstFacture = factures.find(fac => fac.ndossier === numdossier && fac.numclient.includes('C1'))
    return new Date(firstFacture.date_ech).toLocaleDateString('en-CA')
  }

  getFirstReminder(numclient) {
    const { reminders } = this.props
    const firstDateReminder = reminders.find(r => r.numclient === numclient && r.first_reminder === 1)
    return firstDateReminder !== undefined ? new Date(firstDateReminder.date_reminder).toLocaleDateString('en-CA') : ""
  }

  getDevisCon() {
    var arr = [];
    for (let i = 0; i < this.props.deviscon.length; i++) {
      if (
        this.props.deviscon[i].ndossier == this.props.dossierValue
      ) {
        arr.push(this.props.deviscon[i]);
      }
    }
    var max = Math.max.apply(
      Math,
      arr.map((item) => item.iddeviscon)
    );
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].iddeviscon == max) {
        return arr[i];
      }
    }
    return "";
  }

  formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "";
    }
    const parts = inputDate.split('-');
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate;
  }

  getAllReminders() {
    const { facAmendments, reminders, dossierValue } = this.props
    const filteredAndCalculatedData = [];
    const clientData = {};

    reminders.forEach((item) => {
      if (item.ndossier === dossierValue) {
        const numclient = item.numclient;

        if (!clientData[numclient]) {
          clientData[numclient] = {
            nfacture: "",
            date_ech: "",
            first_reminder: "",
            second_reminder: "",
            date_first_reminder: "",
            date_second_reminder: "",
            validation: facAmendments.filter((element) => element.numclient === numclient)[0].payment_date,
          };
        }

        clientData[numclient].nfacture = `${(item.nfacture).replace(' ', '/A-')} ${getRefFacture(item.numclient)}`;

        if (!clientData[numclient].date_ech) {
          clientData[numclient].date_ech = new Date(item.date_ech).toLocaleDateString('en-CA');
        }

        if (item.first_reminder === 1) {
          const first_reminder = new Date(clientData[numclient].date_ech);
          first_reminder.setDate(first_reminder.getDate() + 15);
          clientData[numclient].first_reminder = first_reminder.toLocaleDateString('en-CA');
          clientData[numclient].date_first_reminder = new Date(item.date_reminder).toLocaleDateString('en-CA');
        }

        if (item.first_reminder === 0) {
          const second_reminder = new Date(clientData[numclient].date_ech);
          second_reminder.setDate(second_reminder.getDate() + 30);
          clientData[numclient].second_reminder = second_reminder.toLocaleDateString('en-CA');
          clientData[numclient].date_second_reminder = new Date(item.date_reminder).toLocaleDateString('en-CA');
        } else if (!clientData[numclient].second_reminder) {
          clientData[numclient].second_reminder = "";
          clientData[numclient].date_second_reminder = ""
        }
      }
    });

    for (const numclient in clientData) {
      if (clientData.hasOwnProperty(numclient)) {
        const clientInfo = clientData[numclient];
        filteredAndCalculatedData.push({
          numclient: numclient,
          nfacture: clientInfo.nfacture,
          date_ech: this.formatDate(clientInfo.date_ech),
          first_reminder: this.formatDate(clientInfo.first_reminder),
          date_first_reminder: this.formatDate(clientInfo.date_first_reminder),
          second_reminder: clientInfo.second_reminder !== "" ? this.formatDate(clientInfo.second_reminder) : "",
          date_second_reminder: clientInfo.date_second_reminder !== "" ? this.formatDate(clientInfo.date_second_reminder) : "",
          validation: clientInfo.validation !== null ? this.formatDate(clientInfo.validation.slice(0, 10)) : ""
        });
      }
    }
    filteredAndCalculatedData.sort((a, b) => a.numclient.localeCompare(b.numclient))
    return filteredAndCalculatedData
  }

  handleSaveData = async () => {
    const { dataFacture, maitreOuvrage, first_reminder, clientResponse } = this.state
    this.setState({ disableButton: true });
    const date_ech = new Date(dataFacture.date_ech).toLocaleDateString('en-CA')
    const json = {
      ndossier: dataFacture.ndossier,
      numclient: dataFacture.numclient,
      nfacture: dataFacture.nfacture,
      date_ech: date_ech,
      idcontact: maitreOuvrage.idcontact,
      company: dataFacture.dossier.sci_sccv,
      date_reminder: dataFacture.date_reminder,
      amount_ht: dataFacture.total_ht,
      amount_tva: dataFacture.total_tva,
      amount_ttc: dataFacture.total_ttc,
      first_reminder: first_reminder,
      client_response: clientResponse
    };

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    };

    await fetch("/api/facamendment/saveFacAmendmentReminder", requestOptions)
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchAllFacAmendmentReminder())
    await this.setState({ disableButton: false });
  }

  handleGenFacAmendmentReminder = async () => {
    this.setState({ file: null, openFile: true, disableButton: true });
    const { dataFacture, maitreOuvrage, first_reminder, clientResponse, comptableSexe, comptableName, avenantEcheance } = this.state

    const json = {
      ndossier: dataFacture.ndossier,
      numclient: dataFacture.numclient,
      nfacture: dataFacture.nfacture,
      ref_facture: getRefFacture(dataFacture.numclient),
      date_ech: new Date(dataFacture.date_ech).toLocaleDateString('en-CA'),
      date_signature: this.getSignatureDate(dataFacture.ndossier),
      maitre_ouvrage: maitreOuvrage,
      operation: dataFacture.dossier.operation,
      sci_sccv: dataFacture.dossier.sci_sccv,
      operation_adress: dataFacture.dossier.adresse_op + ", " + dataFacture.dossier.cp_op + " - " + dataFacture.dossier.ville_op,
      date_reminder: dataFacture.date_reminder,
      amount_ht: dataFacture.total_ht,
      first_reminder: first_reminder,
      client_response: clientResponse,
      ndossier_: dataFacture.ndossier.toString().split('').join('-'),
      nprop: this.getDevisCon().nprop.toString().length === 1 ? "0" + this.getDevisCon().nprop : this.getDevisCon().nprop,
      date_contrat: avenantEcheance.datepgcp,
      date_first_reminder: this.getFirstReminder(dataFacture.numclient),
      comptable: `${comptableSexe === "male" ? "mon collaborateur" : "ma collaboratrice"} ${comptableName}`
    }

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    }

    let blob = await genFacAmendmentReminder(requestOptions);
    await this.handleSaveData()
    if (blob) {
      this.setState({ file: blob, disableButton: false });
    }
  }

  handleShowFacAmendmentReminder = async () => {
    const { dossierValue } = this.props
    const { dataFacture, first_reminder } = this.state
    this.setState({ file: null, disableButton: true });

    let blob = await getFacAmendmentReminder(dossierValue, first_reminder, getRefFacture(dataFacture.numclient));
    if (blob) {
      this.setState({ file: blob.size > 100 ? blob : null, openFile: blob.size > 100 ? true : false });
    }
    this.setState({ file: null, disableButton: true });

    let response = await getFacAmendmentReminder(dossierValue, first_reminder, getRefFacture(dataFacture.numclient), 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, openFile: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
    await this.setState({ disableButton: false });
  };


  handleGetPdfFile = async () => {
    const { dossierValue } = this.props
    const { dataFacture, first_reminder } = this.state
    this.setState({ file: null });

    let response = await getFacAmendmentReminder(dossierValue, first_reminder, getRefFacture(dataFacture.numclient), 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { dossierValue } = this.props
    const { dataFacture, first_reminder } = this.state
    this.setState({ file: null });

    let response = await getFacAmendmentReminder(dossierValue, first_reminder, getRefFacture(dataFacture.numclient), 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleGenFacAmendmentReminderTable = async () => {
    this.setState({ file: null, openFile: true, disableButton: true });
    const { dataFacture, maitreOuvrage } = this.state

    const data = this.getAllReminders()

    const json = {
      ndossier: dataFacture.ndossier,
      ref_facture: getRefFacture(dataFacture.numclient),
      maitre_ouvrage: maitreOuvrage,
      dossier: dataFacture.dossier,
      date_table: new Date(),
      data
    }

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    }

    let blob = await genFacAmendmentReminderTable(requestOptions);
    if (blob) {
      this.setState({ file: blob, disableButton: false });
    }
  }

  handleShowFacAmendmentReminderTable = async () => {
    const { dossierValue } = this.props
    this.setState({ file: null, openFile: true, disableButton: true });
    let blob = await getFacAmendmentReminderTable(dossierValue);
    if (blob) {
      this.setState({ file: blob.size > 100 ? blob : null, openFile: blob.size > 100 ? true : false });
    }
    await this.setState({ disableButton: false });
  };

  closeDoc() {
    this.setState({ openFile: false })
  }

  handleChangeTitleName() {
    const { facAmendmentIndex } = this.props
    this.props.dispatch(changeTitleName(`Relance Facture Avenant AR${facAmendmentIndex}`))
  }

  render() {
    const { maitreOuvrage, dataFacture, first_reminder, clientResponse, comptableSexe, comptableName, disableButton, file } = this.state

    return (
      <div className="reminder-fac-amendment">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <NumDoss type="facAmendment" />
        <h1 className="title">
          Relance Facture Avenant
        </h1>
        <div className="switch-reminder">
          <span className={first_reminder ? "active1" : "no-active"}>1<sup>ère </sup>Relance</span>
          <Switch color="primary" onChange={this.handleChangeSwitch.bind(this)} />
          <span className={first_reminder ? "no-active" : "active2"}>2<sup>ème </sup>Relance</span>
        </div>
        <FacAmendmentReminderDetail
          first_reminder={first_reminder}
          disableButton={disableButton}
          maitreOuvrage={maitreOuvrage}
          dataFacture={dataFacture}
          clientResponse={clientResponse}
          comptableSexe={comptableSexe}
          comptableName={comptableName}
          handleDateReminderChange={this.handleDateReminderChange.bind(this)}
          handleChangeClientResponse={this.handleChangeClientResponse.bind(this)}
          handleChangeCompatbleInfos={this.handleChangeCompatbleInfos.bind(this)}
          handleSaveData={this.handleSaveData.bind(this)}
          handleGenFacAmendmentReminder={this.handleGenFacAmendmentReminder.bind(this)}
          handleShowFacAmendmentReminder={this.handleShowFacAmendmentReminder.bind(this)}
          handleGenFacAmendmentReminderTable={this.handleGenFacAmendmentReminderTable.bind(this)}
          handleShowReminderTablePDF={this.handleShowFacAmendmentReminderTable.bind(this)}
        />
        <GenPdf isOpen={this.state.openFile} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1 : 0.5} />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  factures: state.facture.allfacture,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  dossiers: state.dossier.items,
  contact: state.contact.items,
  echeances: state.echeances.items,
  echeance: state.echeances.item,
  amendment: state.echeances.amendment,
  facAmendments: state.facAmendment.facAmendments,
  facAmendmentsByDoss: state.facAmendment.facAmendmentsByDoss,
  facAmendmentIndex: state.facAmendment.facAmendmentIndex,
  reminders: state.reminderFacAmendment.items,
});
export default connect(mapStateToProps)(FacAmendmentReminder)