import React from 'react';
import Header from './Header/Header';
import GenPdf from './GenPdf';
import GererNotif from './GererNotif';


class detailNotif extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pdf: false,
            file: null,
        }
    }

    openDoc() {
        this.setState({ pdf: true })
    }
    closeDoc() {
        this.setState({ pdf: false })
    }

    showPDF() {
        this.openDoc()
        fetch('/api/facture/PDF')
            .then(res => res.blob())
            .then(res => this.setState({ file: res }))
    }
    componentDidMount() {
        this.showPDF()
    }
    render() {

        return (
            <div>
                <GererNotif />
            </div>
        )

    }
}

export default detailNotif
