import React from 'react';
import Header from '../../Header/Header';
import { newDevisReaValue } from "../../../redux/actions/devisActions";
import { fetchDossier } from '../../../redux/actions/dossierActions';
import { connect } from "react-redux";
import NumDoss from '../../NumDoss';
import DevisReaTable2 from './DevisReaTable2';
import { getDureeRea, getTauxHRea, getValidation } from '../Utils';
import { newNpropValue } from "../../../redux/actions/devisActions";
import { Slider } from '@material-ui/core';

class DevisRea extends React.Component{
    constructor(props){
        super(props)
        this.state={
          devis: {nbrHeures:[], ht:[]},
          tauxh:Number(getTauxHRea(this.props.devisrea,this.props.nprop,this.props.dossierValue)),
          duree:Number(getDureeRea(this.props.devisrea,this.props.nprop,this.props.dossierValue)),
          validation:getValidation(this.props.devisrea,this.props.dossierValue, this.props.nprop)
        }
        this.registerView=this.registerView.bind(this)
    }

    componentDidMount(){
      this.props.dispatch(fetchDossier())
    }

    async componentDidUpdate(prevProps){
      if (
        this.props.dossierValue !== prevProps.dossierValue
      ){
        await this.props.dispatch(newNpropValue(1))
      }
      if(this.props.dossierValue !== prevProps.dossierValue || this.props.nprop !== prevProps.nprop){
        await this.setState({
          tauxh:getTauxHRea(this.props.devisrea,this.props.nprop,this.props.dossierValue),
          duree:getDureeRea(this.props.devisrea,this.props.nprop,this.props.dossierValue),
          validation:getValidation(this.props.devisrea,this.props.dossierValue, this.props.nprop)
        })
      }
    }
  registerView(devis) {
    for (let i = 0; i < this.props.devisrea.length; i++) {
      if (this.props.devisrea[i].ndossier == devis.ndossier && this.props.devisrea[i].nprop == devis.nprop) {
        let newLine = {
          iddevisrea: this.props.devisrea[i].iddevisrea,
          devisTab: JSON.stringify(devis.devisTab),
          totalh: devis.totalh,
          totalht: devis.totalht,
          ndossier: devis.ndossier,
          nprop: devis.nprop,
          tauxh: devis.tauxh,
          duree: this.state.duree
        }
        let newDevis = this.props.devisrea
        newDevis[i] = newLine
        this.props.dispatch(newDevisReaValue(newDevis))
      }
    }
  } 
  handleChange(event){
    this.setState({tauxh:event.target.value});
  }

  handleChangeDuree(event){
    this.setState({duree : event.target.value})
  }
  handleSliderTauxChange(event) {
    const newValue = event.target.value;
    const regex = /^[0-9,.\b]+$/;
  
    if (newValue === '' || !regex.test(newValue)) {
      this.setState({ tauxh: '' });
    } else {
      const formattedValue = newValue.replace(/,/g, '.');
      this.setState({ tauxh: formattedValue });
    }
  }
  
  
  handleSliderDureeChange(event){
    const newValue = event.target.value;
    const regex = /^[0-9,.\b]+$/;
  
    if (newValue === '' || !regex.test(newValue)) {
      this.setState({ duree: '' });
    } else {
      const formattedValue = newValue.replace(/,/g, '.');
      this.setState({ duree: formattedValue });
    }
  }

    replaceValues(){
        var result = null
        for(let i=0;i<this.props.devisrea.length;i++){
          if(this.props.dossierValue==this.props.devisrea[i].ndossier && this.props.nprop==this.props.devisrea[i].nprop){
            result = this.props.devisrea[i].devisTab
          }
        }

        if(result===null){
          if(
          //   getCategorieOp(this.props.dossier,this.props.dossierValue) !== 1 
          // ||  getCategorieOp(this.props.dossier,this.props.dossierValue) !== 2 
          true ){
              const object = {
                nbrHeures:[0,0,0,0,0,0,0,0,0],
                ht:[0,0,0]
              }
            return object
          }  
        }
        return JSON.parse(result)
    }
    render(){
      const data = this.replaceValues()
      const tauxh = isNaN(this.state.tauxh) ? 0 : parseFloat(this.state.tauxh);
      const duree = isNaN(this.state.duree) ? 0 : parseFloat(this.state.duree);
        return(
            <div className="devisRea">
                <Header/>
                <h1 className="title">
                    Décomposition du temps prévisionnel d'intervention et de prix<br/>
                    Réalisation
                </h1>
                <NumDoss type="devis"/>
                <div style={{display: 'flex', justifyContent: "center"}}>
                  <div className="tauxh">
                    <label>Taux Horaire : </label>
                    <Slider value={this.state.tauxh} step={5} min={0} max={200}
                    disabled={this.state.validation} onChange={(_, value) => this.setState({ tauxh: value })}/>
                    <input name="tauxh" value={isNaN(this.state.tauxh) ? '' : this.state.tauxh} onChange={this.handleSliderTauxChange.bind(this)} /> €
                  </div>
                  <div className="duree">
                    <label>Durée de la phase réalisation : </label>
                    <Slider value={this.state.duree} step={0.5} min={0} max={48}
                    disabled={this.state.validation} onChange={(_, value) => this.setState({ duree: value })}/>
                    <input name="duree" value={isNaN(this.state.duree) ? '' : this.state.duree} onChange={this.handleSliderDureeChange.bind(this)}/> Mois
                  </div>
                </div>
                {
                  // getCategorieOp(this.props.dossier,this.props.dossierValue) === 1 ||
                  // getCategorieOp(this.props.dossier,this.props.dossierValue) === 2 ?
                  //    <DevisReaTable registerView={this.registerView} devisTab={this.replaceValues(dossierValue, nprop)} tauxh={this.state.tauxh} duree={this.state.duree} validation={this.state.validation}/>
                  // : 
                  <DevisReaTable2 key={JSON.stringify(data)} registerView={this.registerView} devisTab={data} tauxh={tauxh} duree={duree} validation={this.state.validation}/>
                }
            </div>
        )
    }
  }
const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  dossier: state.dossier.items
});
export default connect(mapStateToProps)(DevisRea)