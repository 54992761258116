import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Header from "../../../Header/Header";
import AffaireTable from "./AffaireTable";
import TravauxTable from "./TravauxTable";
import CompTable from "./CompTable";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import history from "../../../Routes/history";
import InformationDialog from "../../../InformationDialog";
import { getCompleteName, getRespEntreprise, getEntrepriseFromName } from "../../../Utils";
import { getVicValues, genVicPDF, getVicPDF } from "../../Utils";
import { fetchEntreprise, newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { changeVicValue, fetchCompaniesByDossierLot } from "../../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../../Styles/VIC/Page-1.scss";

class Page1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isOpen: false,
      openInformation: false,
      advancement: 0,
      date: new Date().toLocaleDateString('en-CA'),
      company: "",
      adresse: "",
      ville: "",
      tel: "",
      nomres: "",
      mail: "",
      cp: "",
      fax: "",
      port: "",
      resp_chan: "",
      taches: "",
      intervention: "",
      resp_tel: "",
      contractor: "",
      dureeJour: 0,
      dureeMois: 0,
      mineff: 0,
      maxeff: 0,
      id_respcomp: "",
      id_respchan: "",
      identreprise: "",
      txtInformation: "",
      ppsps: { answer: 0, observation: false },
      soustraitant: { answer: 0, observation: false },
      pgc: { answer: 0, observation: false },
      loading: false,
      disableButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleCreateVic = this.handleCreateVic.bind(this);
    this.handleUpdateVic = this.handleUpdateVic.bind(this);
  }

  async componentDidMount() {
    const { dossierValue, lotsValue, entrepriseValue } = this.props;

    if (dossierValue, lotsValue, entrepriseValue) {
      this.loadData();
    } else if (dossierValue, lotsValue) {
      await this.props.dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
    }
  }

  async componentDidUpdate(prevProps) {
    const { dossierValue, lotsValue, companies, entrepriseValue, dispatch } = this.props;

    const valuesChanged = dossierValue !== prevProps.dossierValue || lotsValue !== prevProps.lotsValue;
    const companiesChanged = companies !== prevProps.companies;
    const entrepriseValueChanged = entrepriseValue !== prevProps.entrepriseValue;

    if (!dossierValue || !lotsValue) return;

    try {
      if (valuesChanged) {
        await dispatch(newEntrepriseValue());
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
      }

      if (companiesChanged) {
        if (companies.length === 0) {
          await dispatch(newEntrepriseValue());
        } else {
          await dispatch(newEntrepriseValue(JSON.stringify(companies[companies.length - 1])));
        }
      }else if (entrepriseValueChanged) {
        await this.loadData();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadData() {
    this.props.dispatch(changePdfFileName(""));
    const { dossierValue, lotsValue, entrepriseValue } = this.props;
    
    if (!entrepriseValue) {
      await this.resitValues();
      return;
    }

    const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);

    if (result.length !== 0) {
      this.loadValues(result)
    } else {
      this.resitValues();
    }
  }

  loadValues(values) {
    this.props.dispatch(changeVicValue(values.id))
    const work_informations = JSON.parse(values.work_informations);
    this.setState({
      company: values.company.nom,
      identreprise: values.id_company,
      adresse: values.company.adresse,
      ville: values.company.ville,
      cp: values.company.code_postal,
      tel: values.company.tel,
      fax: values.company.fax,
      nomres: values.respcomp.prenom + " " + values.respcomp.nom,
      port: values.respcomp.tel_port,
      mail: values.respcomp.mail,
      resp_chan: values.respchan.prenom + " " + values.respchan.nom,
      resp_tel: values.respchan.tel_port,
      advancement: values.advancement,
      contractor: values.contractor,
      convention: values.convention,
      date: new Date(values.date).toLocaleDateString('en-CA'),
      id_respcomp: values.id_respcomp,
      id_respchan: values.id_respchan,
      pgc: JSON.parse(values.pgc),
      ppsps: JSON.parse(values.ppsps),
      soustraitant: JSON.parse(values.subcontractor),
      taches: work_informations.task,
      intervention: work_informations.intervention_date,
      dureeJour: work_informations.duration_days,
      dureeMois: work_informations.duration_months,
      mineff: work_informations.min_staff,
      maxeff: work_informations.max_staff
    });
  }

  resitValues() {
    this.props.dispatch(changeVicValue(""));
    this.setState({
      advancement: 0,
      date: new Date().toLocaleDateString('en-CA'),
      company: "",
      adresse: "",
      ville: "",
      tel: "",
      nomres: "",
      mail: "",
      cp: "",
      fax: "",
      port: "",
      resp_chan: "",
      taches: "",
      intervention: "",
      resp_tel: "",
      contractor: "",
      dureeJour: 0,
      dureeMois: 0,
      mineff: 0,
      maxeff: 0,
      pgc: { answer: 0, observation: false },
      ppsps: { answer: 0, observation: false },
      soustraitant: { answer: 0, observation: false },
    })
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (name === "company") {
      const regex = /[\\/*^?"'&@#ç!§(°%$£;:€)={}\[\]]/g;
      if (regex.test(value)) {
        this.setState({ txtInformation: `Le caractère que vous avez saisi ${value.charAt(value.length - 1)} n'est pas autorisé !`, openInformation: true })
        return;
      }
      this.setState({ [name]: value });

      if (getEntrepriseFromName(value, this.props.entreprise).id === undefined) {
        this.props.dispatch(changeVicValue(""));
        this.setState({
          file: null,
          isOpen: false,
          advancement: 0,
          date: new Date().toLocaleDateString('en-CA'),
          adresse: "",
          ville: "",
          tel: "",
          nomres: "",
          mail: "",
          cp: "",
          fax: "",
          port: "",
          ppsps: { answer: 0, observation: false },
          id_respcomp: "",
          identreprise: "",
          loading: false,
          disableButton: false,
        });
        this.setState({
          resp_chan: "",
          soustraitant: { answer: 0, observation: false },
          taches: "",
          intervention: "",
          pgc: { answer: 0, observation: false },
          resp_tel: "",
          contractor: "",
          dureeJour: 0,
          dureeMois: 0,
          mineff: 0,
          maxeff: 0,
          id_respchan: "",
        });
      }
    } else if (
      name === "cp" ||
      name === "tel" ||
      name === "fax" ||
      name === "port" ||
      name === "resp_tel" ||
      name === "dureeJour" ||
      name === "dureeMois" ||
      name === "mineff" ||
      name === "maxeff"
    ) {
      if (Number.isNaN(Number(value))) return
      this.setState({ [name]: value });
    } else if (name === "nomres") {
      let prevIdRespComp = this.state.id_respcomp;
      this.setState({ [name]: value, id_respcomp: prevIdRespComp !== "" ? prevIdRespComp : "" });
    } else if (name === "resp_chan") {
      let prevIdRespChan = this.state.id_respchan;
      this.setState({ [name]: value, id_respchan: prevIdRespChan !== "" ? prevIdRespChan : "" });
    } else this.setState({ [name]: value });
  }

  handleCheckChange(event) {
    const { name, value } = event.target;

    if (name.includes("answer")) {
      this.setState((prevState) => ({
        [name.substring(0, name.indexOf("-"))]: {
          ...prevState[name.substring(0, name.indexOf("-"))],
          answer: value,
        },
      }));
      if (name == "soustraitant-answer" && value == 0) {
        this.setState({ contractor: "" });
      }
    } else if (name.includes("observation")) {
      this.setState((prevState) => ({
        [name.substring(0, name.indexOf("-"))]: {
          ...prevState[name.substring(0, name.indexOf("-"))],
          observation: event.target.checked,
        },
      }));
    }
  }

  setAllState(suggestion) {
    const resp = getRespEntreprise(suggestion.id_resp, this.props.contact);
    this.setState({
      company: suggestion.nom,
      adresse: suggestion.adresse,
      ville: suggestion.ville,
      tel: suggestion.tel,
      nomres: resp.nom !== "" ? resp.prenom + " " + resp.nom : resp.prenom,
      mail: resp.mail,
      cp: suggestion.code_postal,
      fax: suggestion.fax,
      port: resp.tel_port,
      id_respcomp: suggestion.id_resp,
      identreprise: suggestion.id,
    });
  }

  setRespComp(suggestion) {
    if (suggestion.nom == "") {
      this.setState({ nomres: suggestion.prenom });
    } else {
      this.setState({
        nomres:
          suggestion.nom !== ""
            ? suggestion.prenom + " " + suggestion.nom
            : suggestion.prenom,
        port: suggestion.tel_port,
        mail: suggestion.mail,
        id_respcomp: suggestion.idcontact,
      });
    }
  }

  setRespChan(suggestion) {
    this.setState({
      resp_chan:
        suggestion.nom !== ""
          ? suggestion.prenom + " " + suggestion.nom
          : suggestion.prenom,
      resp_tel: suggestion.tel_port,
    });
  }

  async handleCreateVic(compInfos, travInfos) {
    this.setState({ disableButton: true });
    const { dossierValue, lotsValue } = this.props;
    const { identreprise, company } = this.state;

    const currentCompany = identreprise !== "" ? { id: identreprise, nom: company } : "";

    compInfos.nomres = `${getCompleteName(compInfos.nomres).prenom} ${getCompleteName(compInfos.nomres).nom}`
    compInfos.company = compInfos.company.toUpperCase()
    travInfos.resp_chan = `${getCompleteName(travInfos.resp_chan).prenom} ${getCompleteName(travInfos.resp_chan).nom}`

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossierValue,
        lotValue: lotsValue,
        advancement: this.state.advancement,
        date: this.state.date,
        compInfos: compInfos,
        travInfos: travInfos,
      }),
    };
    try {
      const response = await fetch("/api/vic/create", requestOptions)
      const data = await response.json()
      if (data.success) {
        await this.props.dispatch(fetchEntreprise());
        await this.props.dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
        if (currentCompany) {
          await this.props.dispatch(newEntrepriseValue(currentCompany));
        }
      }
    } catch (err) {
      console.log(err)
    }
    this.setState({ disableButton: false });

  }

  async handleUpdateVic(compInfos, travInfos) {
    this.setState({ disableButton: true });
    const { dossierValue, lotsValue } = this.props;
    compInfos.nomres = `${getCompleteName(compInfos.nomres).prenom} ${getCompleteName(compInfos.nomres).nom}`.trim()
    compInfos.company = compInfos.company.toUpperCase()
    travInfos.resp_chan = `${getCompleteName(travInfos.resp_chan).prenom} ${getCompleteName(travInfos.resp_chan).nom}`.trim()

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        vicValue: this.props.vicValue,
        ndossier: dossierValue,
        lotValue: lotsValue,
        advancement: this.state.advancement,
        date: this.state.date,
        compInfos: compInfos,
        travInfos: travInfos,
      }),
    };
    try {
      const response = await fetch("/api/vic/update", requestOptions)
      const data = await response.json()
      if (data.success) {
        await this.loadData();
      }
    } catch (err) {
      console.log(err)
    }
    this.setState({ disableButton: false });

  }

  async handleGenClick() {
    this.setState({ disableButton: true, file: null });
    const { vicValue } = this.props;
    let blob = await genVicPDF(vicValue);
    if (blob) {
      this.setOpen(true);
      this.setState({ file: blob });
    }
    this.setState({ disableButton: false });
  }


  async handleShowPdf() {
    this.setState({ disableButton: true });
    this.setState({ file: null});
    const { vicValue } = this.props;
    let response = await getVicPDF(vicValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, isOpen: true  });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
    this.setState({ disableButton: false });
  }


  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  handleGetPdfFile = async () => {
    this.setState({ disableButton: true });
    this.setState({ file: null });
    const { vicValue } = this.props;
    let response = await getVicPDF(vicValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
    this.setState({ disableButton: false });
  };

  async handleGetWordFile() {
    const { vicValue } = this.props;
    let response = await getVicPDF(vicValue, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  disableValidButton(compInfos, travInfos) {
    const { vicValue } = this.props;
    const { company, adresse, ville, cp, tel, nomres, port, mail } = compInfos;
    const { resp_chan, resp_tel, taches, intervention, dureeJour, dureeMois, mineff, maxeff } = travInfos

    if (vicValue ||
      company === "" ||
      adresse === "" ||
      ville === "" ||
      cp === "" ||
      tel === "" ||
      nomres === "" ||
      port === "" ||
      mail === "" ||
      resp_chan === "" ||
      resp_tel === "" ||
      taches === "" ||
      intervention === "" ||
      dureeJour == 0 && dureeMois == 0) {
      return true;
    }
    return false;
  }

  nextPage = () => {
    history.push("/vic/page-2");
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    const compInfos = {
      company: this.state.company.toUpperCase(),
      adresse: this.state.adresse,
      ville: this.state.ville,
      tel: this.state.tel,
      nomres: this.state.nomres,
      mail: this.state.mail,
      cp: this.state.cp,
      fax: this.state.fax,
      port: this.state.port,
      ppsps: this.state.ppsps,
      id_respcomp: this.state.id_respcomp,
      identreprise: this.state.identreprise,
    };
    const travInfos = {
      resp_chan: this.state.resp_chan,
      soustraitant: this.state.soustraitant,
      taches: this.state.taches,
      intervention: this.state.intervention,
      pgc: this.state.pgc,
      resp_tel: this.state.resp_tel,
      contractor: this.state.contractor,
      dureeJour: this.state.dureeJour,
      dureeMois: this.state.dureeMois,
      mineff: this.state.mineff,
      maxeff: this.state.maxeff,
      id_respchan: this.state.id_respchan,
    };
    
    return (
      <div className="page-1">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 1)</h1>
        <VICSMenu />
        <div className="data-1">
          <AffaireTable
            advancement={this.state.advancement}
            date={this.state.date}
            handleChange={this.handleChange}
          />
          <CompTable
            handleChange={this.handleChange}
            handleCheckChange={this.handleCheckChange}
            setAllState={this.setAllState.bind(this)}
            setRespComp={this.setRespComp.bind(this)}
            compInfos={compInfos}
          />
          <TravauxTable
            handleChange={this.handleChange}
            handleCheckChange={this.handleCheckChange}
            company={this.state.company}
            setAllState={this.setAllState.bind(this)}
            setRespChan={this.setRespChan.bind(this)}
            travInfos={travInfos}
          />
          <div className="buttons-container">
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleCreateVic(compInfos, travInfos)}
              disabled={this.disableValidButton(compInfos, travInfos) || this.state.disableButton}
            >
              Créer la VIC
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleUpdateVic(compInfos, travInfos)}
              disabled={this.props.vicValue === "" || this.state.disableButton}
            >
              Modifier
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleGenClick()}
              disabled={this.props.vicValue === "" || this.state.disableButton}
            >
              Générer le PDF
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleShowPdf()}
              disabled={this.props.vicValue === "" || this.state.disableButton}
            >
              Afficher le PDF
            </Button>
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight />}
              onClick={this.nextPage}
              disabled={this.props.vicValue === "" || this.state.disableButton}
            >
              Page suivante
            </Button>
            {this.state.disableButton && (
              <CircularProgress className="spinner" color="inherit" />
            )}
          </div>
        </div>
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={1}
        />
        <InformationDialog
          isOpen={this.state.openInformation}
          title={'INFORMATION'}
          information={this.state.txtInformation}
          handleClose={() => this.setState({ openInformation: false })}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  companies: state.vic.companies,
  vics: state.vic.vics,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page1);
