import {
  FETCH_VIC_BEGIN,
  FETCH_VIC_SUCCESS,
  FETCH_VIC_FAILURE,
  FETCH_VICS_BEGIN,
  FETCH_VICS_SUCCESS,
  FETCH_VICS_FAILURE,
  FETCH_COMPANIES_BEGIN,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_LOTS_BEGIN,
  FETCH_LOTS_SUCCESS,
  FETCH_LOTS_FAILURE,
  FETCH_ALLLOTS_SUCCESS,
  FETCH_ALLLOTS_FAILURE,
  FETCH_ALLLOTITEMS_SUCCESS,
  FETCH_ALLLOTITEMS_FAILURE,
  CHANGE_LOT_VALUE,
  CHANGE_LOTS_VALUE,
  CHANGE_VIC_VALUE,
  CHANGE_CONVENTION_VALUE
} from '../actions/vicActions';

const initialState = {
  vicValue: '',
  conventionValue: '',
  items: [],
  vics: [],
  companies: [],
  lots: [],
  allLots: [],
  lotItems: [],
  lotValue: 1,
  lotsValue: 1,
  loading: false,
  error: null,
}

export default function vicReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VIC_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_VIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.items
      }
    }
    case FETCH_VIC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_VICS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_VICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        vics: action.payload.vics
      }
    }
    case FETCH_VICS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_COMPANIES_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        companies: action.payload.companies
      }
    }
    case FETCH_COMPANIES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_LOTS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_LOTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        lots: action.payload.lots,
        lotValue: action.payload.lotValue
      }
    }
    case FETCH_LOTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        lots: []
      };
    }
    case FETCH_ALLLOTS_SUCCESS: {
      return {
        ...state,
        allLots: action.payload.lots
      }
    }
    case FETCH_ALLLOTS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        allLots: []
      };
    }
    case FETCH_ALLLOTITEMS_SUCCESS: {
      return {
        ...state,
        lotItems: action.payload.lots
      }
    }
    case FETCH_ALLLOTITEMS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        lotItems: []
      };
    }
    case CHANGE_LOT_VALUE: {
      return {
        ...state,
        lotValue: action.payload.lotValue
      };
    }
    case CHANGE_LOTS_VALUE: {
      return {
        ...state,
        lotsValue: action.payload.value
      };
    }
    case CHANGE_VIC_VALUE: {
      return {
        ...state,
        vicValue: action.payload.vicValue
      };
    }
    case CHANGE_CONVENTION_VALUE: {
      return {
        ...state,
        conventionValue: action.payload.convValue
      };
    }
    default:
      return state
  }
}