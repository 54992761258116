export const FETCH_CONTRAT_BEGIN   = 'FETCH_CONTRAT_BEGIN';
export const FETCH_CONTRAT_SUCCESS = 'FETCH_CONTRAT_SUCCESS';
export const FETCH_CONTRAT_FAILURE = 'FETCH_CONTRAT_FAILURE';

export function fetchContrat(ndossier) {
    return dispatch => {
      dispatch(fetchContratBegin());
      return fetch("/api/contrat/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          const filteredContrats = json.filter(contrat => contrat.ndossier == ndossier);
          dispatch(fetchContratSuccess(filteredContrats[0]));
          return filteredContrats;
        })
        .catch(error => dispatch(fetchContratFailure(error)));
    };
  }

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

export const fetchContratBegin = () => ({
  type: FETCH_CONTRAT_BEGIN
});

export const fetchContratSuccess = dossier => ({
  type: FETCH_CONTRAT_SUCCESS,
  payload: { items:dossier }
});

export const fetchContratFailure = error => ({
  type: FETCH_CONTRAT_FAILURE,
  payload: { error }
});