import { FETCH_CONTACT_BEGIN, FETCH_CONTACT_SUCCESS, FETCH_CONTACT_FAILURE, CHANGE_MO_DETAILS } from '../actions/contactActions';
const initialState = {
  items: [],
  loading: false,
  error: null,
  moInfos: {},
}

// Use the initialState as a default value
export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTACT_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.items
      }
    }
    case FETCH_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case CHANGE_MO_DETAILS: {
      return {
        ...state,
        moInfos: action.payload.moDetails
      }
    }
    default:
      return state
  }
}