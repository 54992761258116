import '../Styles/OptionsList.scss';
import React from 'react';
import history from './Routes/history';
class OptionsList extends React.Component{
    changeToAbout = () =>{
        history.push("/about");
      }
      changeToLogin = () =>{
        history.push("/login");
      }
      changeToRegister = () =>{
        history.push("/register");
      }
    render(){
        return(
            <div className="nav">
                <input type="checkbox" id="nav-check"/>
                <div className="nav-btn">
                    <label htmlFor="nav-check">
                        <span></span>
                        <span></span>
                        <span></span>
                </label>
                </div>
                <div className="nav-links">
                    <button className="About" onClick={this.changeToAbout}>About</button>
                    <button className="Login" onClick={this.changeToLogin}>Login</button>
                    <button className="Register" onClick={this.changeToRegister}>Register</button>
                </div>        
            </div>
        );
    }

}
export default OptionsList;