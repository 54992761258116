import React from "react";
import SignatureCanvas from "react-signature-canvas";
import Header from "../../Header/Header";
import VICMenu from "../VICMenu";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { fetchVIC } from "../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import "../../../Styles/VIC/Page5.scss";
import { genPDF, getClient, getLotNbrPages, getPDF } from "../Utils";
import GenPdf from "../../GenPdf";
import DocEntreprise from "./DocEntreprise";
import AmiantePlomb from "./AmiantePlomb";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import DocMenu from "../../DocMenu";

class Page5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueArray: Array.apply(null, Array(5)).map(function (x, i) {
        return { answer: false, comment: "" };
      }),
      travSpec: Array.apply(null, Array(11)).map(function (x, i) {
        return { answer: false, organisme: "" };
      }),
      file: null,
      isOpen: false,
      loading: false,
      tampon: null,
      disableButton: false,
      sigLot: null,
    };
    this.sigCanvas = React.createRef();
  }
  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.lotValue !== prevProps.lotValue ||
      this.props.entrepriseValue !== prevProps.entrepriseValue
    ) {
      this.loadData();
    }
  }
  loadData() {
    this.props.dispatch(changePdfFileName(""))
    var vic = this.props.vic.filter((vic) => {
      if (
        this.props.dossierValue == vic.ndossier &&
        this.props.lotValue == vic.lots
      ) {
        if (this.props.entrepriseValue !== undefined) {
          var entreprise = JSON.parse(this.props.entrepriseValue);
          if (entreprise.id == vic.identreprise) {
            return true;
          }
        }
      } else return false;
    })[0];
    if (vic !== undefined) {
      const compte_rendu_parsed = JSON.parse(vic.compte_rendu)
      var page4 = compte_rendu_parsed.page4;
      var page5 = compte_rendu_parsed.page5;
      var travSpec = compte_rendu_parsed.travSpec;
      this.setState({ sigLot: compte_rendu_parsed.sigLot });
      if (this.getLotNumPages() == 4) {
        if (page4 !== undefined) {
          this.setState({ valueArray: page4 });
        }
      } else {
        if (page5 !== undefined) {
          this.setState({ valueArray: page5 });
        } else
          this.setState({
            valueArray: Array.apply(null, Array(5)).map(function (x, i) {
              return { answer: false, comment: "" };
            }),
          });
      }
      if (travSpec !== undefined) {
        this.setState({ travSpec: travSpec });
      } else
        this.setState({
          travSpec: Array.apply(null, Array(11)).map(function (x, i) {
            return { answer: false, organisme: "" };
          }),
        });

    } else {
      this.setState({
        valueArray: Array.apply(null, Array(5)).map(function (x, i) {
          return { answer: false, comment: "" };
        }),
      });
      this.setState({
        travSpec: Array.apply(null, Array(11)).map(function (x, i) {
          return { answer: false, organisme: "" };
        }),
      });
      this.setState({ tampon: null });
    }
  }
  handleChange(event, index) {
    let temp_state = [...this.state.valueArray];
    let temp_element = { ...temp_state[index] };
    if (event.target.name === "comment") {
      temp_element.comment = event.target.value;
    } else {
      temp_element.answer = event.target.checked;
    }
    temp_state[index] = temp_element;
    this.setState({ valueArray: temp_state });
  }
  handleChangeTrav(event, index) {
    if (event.target.name == "answer") {
      this.state.travSpec[index].answer = event.target.checked;
    } else {
      this.state.travSpec[index].organisme = event.target.value;
    }
    this.setState(this.state.travSpec);
  }
  handleChangeTampon = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({ tampon: null });
    } else {
      this.setState({ tampon: e.target.files[0] });
    }
  };
  async handleValidClick(entreprise) {
    this.setState({ loading: true, disableButton: true });
    var formData = new FormData();
    formData.append("ndossier", this.props.dossierValue);
    formData.append("lotValue", this.props.lotValue);
    formData.append("identreprise", entreprise.id);
    formData.append("page4", JSON.stringify(this.state.valueArray));
    formData.append("page5", JSON.stringify(this.state.valueArray));
    formData.append("travSpec", JSON.stringify(this.state.travSpec));
    if (!this.sigCanvas.current?.isEmpty()) {
      formData.append("sigLot", this.sigCanvas.current.getTrimmedCanvas().toDataURL());
    } else formData.append("sigLot", this.state.sigLot);
    formData.append("numPage", this.getLotNumPages());
    formData.append("tampon", this.state.tampon);
    if (this.getLotNumPages() == 4) {
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch("/api/vic/page4", requestOptions)
        .then(async (res) => {
          if (res.ok) {
            await this.props.dispatch(fetchVIC(this.props.dossierValue));
            await this.setState({ loading: false, disableButton: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch("/api/vic/page5", requestOptions)
        .then(async (res) => {
          if (res.ok) {
            await this.props.dispatch(fetchVIC(this.props.dossierValue));
            await this.setState({ loading: false, disableButton: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }
  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  clear() {
    this.sigCanvas.current.clear();
  }
  getLotNumPages() {
    if (
      getLotNbrPages(this.props.lots, this.props.lotValue) == 5 ||
      getLotNbrPages(this.props.lots, this.props.lotValue) == 7
    ) {
      return 4;
    } else return 5;
  }
  nextPage = () => {
    history.push("/vic/page6");
  };
  prevPage = () => {
    history.push("/vic/page4");
  };
  disableSaveButton(entreprise) {
    if (entreprise.id === undefined) return true;
    if (this.state.loading) return true;
    return false;
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    var entreprise = "";
    if (
      this.props.entrepriseValue !== undefined &&
      this.props.entrepriseValue !== ""
    ) {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }
    return (
      <div className="page5">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 5)</h1>
        <VICMenu />
        <div className="data">
          {this.props.lotValue == 14 ||
            this.props.lotValue == 15 ||
            this.props.lotValue == 50 ? (
            <AmiantePlomb
              travSpec={this.state.travSpec}
              handleChangeTrav={this.handleChangeTrav.bind(this)}
            />
          ) : (
            ""
          )}
          <DocEntreprise
            handleChange={this.handleChange.bind(this)}
            valueArray={this.state.valueArray}
          />
          <div className="signature">
            <label>Signature : </label>
            <SignatureCanvas
              ref={this.sigCanvas}
              penColor="black"
              backgroundColor="rgb(255,255,255)"
              canvasProps={{ width: 200, height: 100, className: "sigCanvas" }}
            />
            <Button onClick={this.clear.bind(this)}>Effacer</Button>
          </div>
          <br />
          <div className="tampon">
            <label>Tampon de l'entreprise : </label>
            <input type="file" onChange={this.handleChangeTampon} />
            {this.state.tampon !== null ? (
              <img
                src={URL.createObjectURL(this.state.tampon)}
                height="200px"
                width="400px"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(entreprise)}
            disabled={this.disableSaveButton(entreprise)}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick(entreprise)}
            disabled={
              this.state.disableButton || entreprise.id === undefined
                ? true
                : false
            }
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf()}
            disabled={
              this.state.disableButton || entreprise.id === undefined
                ? true
                : false
            }
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={this.state.disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={this.state.disableButton}
          >
            Page suivante
          </Button>
          {this.state.disableButton && (
            <CircularProgress className="spinner" color="success" />
          )}
        </div>
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={this.getLotNumPages()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page5);
