import React from 'react';
//import Login from './Login';
import Routes from './Routes/Routes';
document.title = "ERP Coordogec";
class ERPSite extends React.Component{ 
    render(){
      return(
        <Routes/>  
      );
    }
  }
  
  export default ERPSite;