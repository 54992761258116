import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class MenExtTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            iterator: 0
        }
    }
    render() {
        const column = [
            {
                key: 'title1',
                dataKey: 'title1',
                width: 300,
                flexGrow: 1,
                flexShrink: 0,
            },
            {
                key: 'value1',
                dataKey: 'value1',
                width: 300,
                align: Column.Alignment.CENTER,
                flexGrow: 2,
                cellRenderer: ({ rowData, rowIndex }) => {
                    if (rowIndex !== 28) return (
                        <div>
                            <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].answer != 1 ? false : true} />
                            <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].answer != 0 ? false : true} />
                            <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].observation} />
                        </div>
                    )
                },
            }, {
                key: 'title2',
                dataKey: 'title2',
                width: 300,
                flexGrow: 3,
            }, {
                key: 'value2',
                dataKey: 'value2',
                width: 300,
                flexGrow: 4,
                align: Column.Alignment.CENTER,
                cellRenderer: ({ rowIndex }) => {
                    if (rowIndex !== 23) return (<div>
                        <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex + 29)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 29].answer != 1 ? false : true} />
                        <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex + 29)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 29].answer != 0 ? false : true} />
                        <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex + 29)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 29].observation} />
                    </div>)
                }
            }
        ]
        const data = [
            {
                id: 1,
                title1: "Port des EPI obligatoire RESPECTER",
                title2: 'Circulation intérieure à flécher et unité de passage plus zone de sécurité'
            },
            {
                id: 2,
                title1: "Base vie",
                title2: "Propre à l’entreprise"
            },
            {
                id: 3,
                title1: 'Existant',
                title2: 'Travail en Hauteur : Mise en place / Utilisation'
            },
            {
                id: 4,
                title1: 'Raccordé',
                title2: 'A mettre en place'
            },
            {
                id: 5,
                title1: 'Propre',
                title2: 'Collectifs existants'
            },
            {
                id: 6,
                title1: 'Aires de stockage',
                title2: 'Propre à l’entreprise'
            },
            {
                id: 7,
                title1: 'Existantes',
                title2: "Les échelles, escabeaux"
            },
            {
                id: 8,
                title1: 'Attribuées',
                title2: 'PIR (Plates - formes - individuelles) '
            },
            {
                id: 9,
                title1: 'Moyens de mise en commun A RESPECTER',
                title2: 'Nacelles à ciseaux / Bras télescopique'
            },
            {
                id: 10,
                title1: "Maintenir et respecter les protections collectives",
                title2: 'Echafaudages'
            },
            {
                id: 11,
                title1: "Existante",
                title2: "Plates-formes suspendues"
            },
            {
                id: 12,
                title1: 'A mettre en place',
                title2: 'Moyens de levage mécanique'
            },
            {
                id: 13,
                title1: 'Zone définie',
                title2: 'Circulation intérieure à flécher et unité de passage plus zone de sécurité extérieure A RESPECTER'
            },
            {
                id: 14,
                title1: 'Zone d’intervention - absence de superposition',
                title2: "Moyens de circulation existante"
            },
            {
                id: 15,
                title1: 'Existant',
                title2: 'A mettre en place'
            },
            {
                id: 16,
                title1: 'A baliser',
                title2: 'Propre à l’entreprise'
            },
            {
                id: 17,
                title1: 'A ne pas générer',
                title2: 'A demander au donneur d’ordre'
            },
            {
                id: 18,
                title1: 'Moyens de déchargement prévus',
                title2: 'Mesures à prendre contre TMS ( Troubles musculo- squelettiques)'
            },
            {
                id: 19,
                title1: 'Collectifs existants',
                title2: 'Auriez-Vous de la coupe ?'
            },
            {
                id: 20,
                title1: 'A mettre en place',
                title2: 'Charges lourdes'
            },
            {
                id: 21,
                title1: 'Propre à l’entreprise',
                title2: 'Chute de plain Pieds'
            },
            {
                id: 22,
                title1: 'Approvisionnement sur le lieu de travail',
                title2: 'Appareil aspirant'
            },
            {
                id: 23,
                title1: 'Existants',
                title2: 'Gestes répétitifs'
            },
            {
                id: 24,
                title1: 'A mettre en place',
                title2: ''
            },
            {
                id: 25,
                title1: 'Quelles mesures de sécurité prévoyez – vous en cas d’accident'
            },
            {
                id: 26,
                title1: 'Secourisme',
                title2: "Appeler le : 17"
            },
            {
                id: 27,
                title1: 'Extincteur',
                title2: "Appeler le : 18"
            },
            {
                id: 28,
                title1: 'Pompier',
                title2: "Appeler le : 112"
            },
            {
                id: 29,
                title2: "Appeler le : 06.80.68.14.82 CSPS"
            },
        ];

        var spanIndex = 2
        column[0].colSpan = ({ rowData, rowIndex }) => 2
        column[0].align = Column.Alignment.CENTER
        column[2].colSpan = ({ rowData, rowIndex }) => 2
        column[2].align = Column.Alignment.CENTER

        const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
            const span = columns[spanIndex].colSpan({ rowData, rowIndex })
            if (rowIndex === 1 || rowIndex === 5 || rowIndex === 9
                || rowIndex === 13 || rowIndex === 17 || rowIndex === 21) {
                let width = cells[0].props.style.width
                for (let i = 1; i < span; i++) {
                    width += cells[0 + i].props.style.width
                    cells[0 + i] = null
                }
                const style = {
                    ...cells[0].props.style,
                    width,
                    color: "rgb(204, 102, 0)",
                    fontStyle: "italic",
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    border: "1px solid black",
                }
                cells[0] = React.cloneElement(cells[0], { style })
            }
            if (rowIndex === 2 || rowIndex === 13 || rowIndex === 18) {
                let width = cells[2].props.style.width
                for (let i = 1; i < span; i++) {
                    width += cells[2 + i].props.style.width
                    cells[2 + i] = null
                }
                const style = {
                    ...cells[2].props.style,
                    width,
                    color: "rgb(204, 102, 0)",
                    fontStyle: "italic",
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    border: "1px solid black"
                }
                cells[2] = React.cloneElement(cells[2], { style })
            }
            if (rowIndex === 0 || rowIndex === 8) {
                let width = cells[0].props.style.width
                for (let i = 1; i < 2; i++) {
                    width += cells[0 + i].props.style.width
                    cells[0 + i] = null
                }
                const style = {
                    ...cells[0].props.style,
                    width,
                    color: "red",
                    border: "1px solid black"
                }
                cells[0] = React.cloneElement(cells[0], { style })
            }
            if (rowIndex === 0 || rowIndex === 12 || rowIndex === 17) {
                let width = cells[2].props.style.width
                for (let i = 1; i < 2; i++) {
                    width += cells[2 + i].props.style.width
                    cells[2 + i] = null
                }
                const style = {
                    ...cells[2].props.style,
                    width,
                    color: "red",
                    border: "1px solid black"
                }
                cells[2] = React.cloneElement(cells[2], { style })
            }
            if (rowIndex === 24) {
                let width = cells[0].props.style.width
                for (let i = 1; i < 4; i++) {
                    width += cells[0 + i].props.style.width
                    cells[0 + i] = null
                }
                const style = {
                    ...cells[0].props.style,
                    width,
                    color: "rgb(204, 102, 0)",
                    fontStyle: "italic",
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    border: "1px solid black"
                }
                cells[0] = React.cloneElement(cells[0], { style })
            }
            return cells
        }

        return (
            <div className="testTable">
                <BaseTable rowKey="id" columns={column} width={1200} height={1450} data={data} headerHeight={0} rowRenderer={rowRenderer} />
            </div>
        )
    }
}
export default MenExtTable