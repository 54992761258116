import React from 'react';
import ChantierImage from '../LoginPage/ChantierImage.js';
import FacImage from '../LoginPage/FacImage.js';
import ContactImage from '../LoginPage/ContactImage.js';
import ProcessImage from '../LoginPage/ProcessImage.js';
class ImagesLogin extends React.Component{
    render(){
        return(
            <div className="logos">
                <ChantierImage/>
                <ProcessImage/>
                <ContactImage/>
                <FacImage/>
            </div>
        );
    }
}
export default ImagesLogin;