import React, { useState } from "react";
import "../../../Styles/VIC/Page5.scss";

class AmiantePlomb extends React.Component{
	constructor(props){
		super(props)
		this.state={
		}
	}
	render(){
        // console.log(this.props.travSpec);
		return (
			<div className="amiante_plomb">
                <h2 style={{textDecoration:"underline"}}>TRAVAUX SPÉCIFIQUES AMIANTE ET PLOMB</h2>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,0)} 
                checked={this.props.travSpec[0].answer}/>
                Analyse d’empoussièrement préliminaire en META ou point 0</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,1)} 
                checked={this.props.travSpec[1].answer}/>
                Fournir votre Plan de Retrait, certificats d’Acceptation Préalable (CAP), B.S.D.A,
                 Procès-verbaux de consignation des réseaux, Résultats des tests de fumés et délibératoires</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,2)} 
                checked={this.props.travSpec[2].answer}/>
                E.P.I spécifiques (masque à ventilation assistée, combinaison, gants, etc…) aux travaux en milieu amiante</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,3)} 
                checked={this.props.travSpec[3].answer}/>
                Aptitude médicale à jour</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,4)} 
                checked={this.props.travSpec[4].answer}/>
                Décrire le mode opératoire de l’intervention dans votre PPSPS.</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,5)} 
                checked={this.props.travSpec[5].answer}/>
                Confinement de la zone préciser le nombre de SAS</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,6)} 
                checked={this.props.travSpec[6].answer}/>
                Pas de coactivité</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,7)} 
                checked={this.props.travSpec[7].answer}/>
                Déchets (conditionnements des déchets dans des emballages réglementés,
                 aspiration des emballages avant évacuation, etc…)</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,8)} 
                checked={this.props.travSpec[8].answer}/>
                Décret n° 2006-474 du 25 avril 2006 relatif à la lutte contre le saturnisme. </p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,9)} 
                checked={this.props.travSpec[9].answer}/>
                Décliner les modes opératoires du CREP dans votre PPSPS suivant le document remis par l’organisme 
                 <input name="organisme" onChange={(event) => this.props.handleChangeTrav(event,9)} 
                 value={this.props.travSpec[9].organisme}/> agrée par la Préfecture.</p>
                <p><input type="checkbox" name="answer" onChange={(event) => this.props.handleChangeTrav(event,10)} 
                checked={this.props.travSpec[10].answer}/>
                Sous-section 3 et 4 R. 4412-139 et 140 ; R. 4412-142 à 147 l’absence d’un plan retrait n’exclut pas l’envoi du mode opératoire à la CRAMIF etc…</p>
			</div>
		);
	}
}
  export default AmiantePlomb;