import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import ZoomInSharpIcon from "@material-ui/icons/ZoomInSharp";
import { v4 as uuidv4 } from "uuid";

class ObservationTable extends React.Component {
  render() {
    const { employmentLawArray } = this.props;
    const column = [
      {
        title: "Numéro d'observation",
        key: "npage",
        dataKey: "npage",
        width: 155,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
              fontFamily: "Tahoma",
            }}
            name="npage"
            value={this.props.valueArray[rowIndex].npage}
          >
            {this.props.valueArray[rowIndex].npage}
          </p>
        ),
      },
      {
        title: "Date d'observation",
        key: "date",
        dataKey: "date",
        width: 195,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <input
            type="date"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChange(event, rowIndex)}
            name="date"
            value={this.props.valueArray[rowIndex].date}
          />
        ),
      },
      {
        title: "Titre",
        key: "title",
        dataKey: "title",
        width: 335,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) =>
          this.props.valueArray[rowIndex].title &&
          this.props.valueArray[rowIndex].npage.includes("-") ? (
            <p
              style={{
                width: "100%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "13px",
              }}
              name="title"
              key={uuidv4()}
            >
              {this.props.valueArray[rowIndex].title}
            </p>
          ) : (
            <textarea
              style={{
                width: "100%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "13px",
              }}
              onChange={(event) => this.props.handleChange(event, rowIndex)}
              name="title"
              value={this.props.valueArray[rowIndex].title}
            ></textarea>
          ),
      },
      {
        title: "Observation",
        key: "observation",
        dataKey: "observation",
        width: 400,
        flexGrow: 4,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            name="observation"
            style={{ width: "100%", height: "90%" }}
            onChange={(event) => this.props.handleChange(event, rowIndex)}
            value={this.props.valueArray[rowIndex].observation}
          ></textarea>
        ),
      },
      {
        title: "Article du code du travail",
        key: "code_travail",
        dataKey: "code_travail",
        width: 250,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          let selectedItemIndex = -1;
          return employmentLawArray?.[rowIndex]?.length > 1 ? (
            <>
              <select
                name="code_travail"
                style={{
                  width: "100%",
                  pointerEvents: "visible",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                value={rowData.code_travail}
                onChange={(event) => {
                  let selectedItem = employmentLawArray[rowIndex].find(
                    (item) => item.name === event.target.value
                  );
                  let title = selectedItem ? selectedItem.title : "";
                  this.props.handleChange(event, rowIndex, title);
                }}
              >
                {employmentLawArray[rowIndex].map((item, index) => {
                  if (rowData.code_travail === item.name) {
                    selectedItemIndex = index;
                  }
                  return (
                    <option value={item.name} key={uuidv4()}>
                      {rowData.code_travail === item.name
                        ? item.name
                        : item.title}
                    </option>
                  );
                })}
              </select>
              <ZoomInSharpIcon
                onClick={() => {
                  let name =
                    employmentLawArray[rowIndex][selectedItemIndex].name;
                  let detail =
                    employmentLawArray[rowIndex][selectedItemIndex].detail;
                  this.props.handleClickOpen(name, detail);
                }}
              />
            </>
          ) : (
            <input
              style={{
                width: "100%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "14px",
              }}
              onChange={(event) => this.props.handleChange(event, rowIndex)}
              name="code_travail"
              value={this.props.valueArray[rowIndex].code_travail}
            />
          );
        },
      },
      {
        title: "Prévu-le",
        key: "prevu",
        dataKey: "prevu",
        width: 195,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <input
            type="date"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChange(event, rowIndex)}
            name="prevu"
            value={this.props.valueArray[rowIndex].prevu}
          />
        ),
      },
      {
        key: "del",
        dataKey: "del",
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              this.props.deleteObservation(rowIndex);
            }}
            key={uuidv4()}
            style={{ backgroundColor: "transparent" }}
            className="delete"
          >
            <Delete />
          </IconButton>
        ),
      },
    ];
    const data = this.props.valueArray;
    return (
      <div className="testTable">
        <BaseTable
          columns={column}
          width={1135}
          height={50 + 50 * this.props.valueArray.length}
          data={data}
        ></BaseTable>
        <IconButton
          aria-label="delete"
          size="small"
          style={{ backgroundColor: "transparent" }}
          onClick={this.props.addObservation}
        >
          <Add />
        </IconButton>
      </div>
    );
  }
}
export default ObservationTable;
