import React from 'react'
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { getEntrepriseFromName } from '../../../Utils';

const getSuggestionValue = suggestion => suggestion.prenom + " " + suggestion.nom;

const renderSuggestion = suggestion => (
  <div style={{ padding: "auto" }}>
    {suggestion.prenom + " " + suggestion.nom}
  </div>
);

class AutoSuggestRespChan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.contact.filter(item => {
      if (item.nom.toLowerCase().slice(0, inputLength) === inputValue && item.fonction === "Responsable Chantier" && item.identreprise === getEntrepriseFromName(this.props.company, this.props.entreprise).id) return true
      if (item.prenom.toLowerCase().slice(0, inputLength) === inputValue && item.fonction === "Responsable Chantier" && item.identreprise === getEntrepriseFromName(this.props.company, this.props.entreprise).id) return true
    }
    )
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.setRespChan(suggestion)
  };

  render() {
    const inputProps = {
      placeholder: "Prénom NOM",
      name: "resp_chan",
      value: this.props.resp_chan,
      onChange: this.props.handleChange,
      style: {
        border: "none", height: "100%", width: "100%", pointerEvents: "visible", backgroundColor: "transparent"
        , textAlign: "center", fontSize: "14px"
      }
    };
    return (
      <React.Fragment>
        <Autosuggest
          onSuggestionSelected={this.onSuggestionSelected}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps} />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  contact: state.contact.items,
  entreprise: state.entreprise.items
});
export default connect(mapStateToProps)(AutoSuggestRespChan)