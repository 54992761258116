import React from 'react'
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
                        
// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.nom;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div style={{ padding: "auto" }}>
    {suggestion.nom}
  </div>
);


class AutoSuggestComp extends React.Component{
    constructor(props){
        super(props)
        this.state={
            suggestions: [],
        }
    }
      // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.entreprise.filter(item =>
      item.nom.toLowerCase().slice(0, inputLength) === inputValue
    )
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };


  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.props.setAllState(suggestion)
  };
    render(){
        const inputProps = {
            name: "company",
            value: this.props.company,
            onChange: this.props.handleChange,
            style:{ border:"none", height:"100%", width:"300%", pointerEvents : "visible", backgroundColor:"transparent"
            , textAlign :"left", fontSize:"14px"}
        };
        return(
            <React.Fragment>
            <Autosuggest
              onSuggestionSelected={this.onSuggestionSelected}
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}/>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    contact: state.contact.items,
    entreprise: state.entreprise.items
  });
  export default connect(mapStateToProps)(AutoSuggestComp)