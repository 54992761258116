import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

class FormDialog extends React.Component {
  constructor(props){
    super(props)
    this.state={
      isOpen:true,
      email: "",
      mdp: ""
    }
    this.handleClose=this.handleClose.bind(this)
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
  }
  handleChange(event){
    this.setState({[event.target.name]:event.target.value})
  }
  handleClose(){
      this.setState({isOpen:false})
  }
  handleOpen(){
    this.setState({isOpen:true})
}
async checkIfAdmin(){
    const logs = {}
    logs.email = this.state.email
    logs.password = this.state.mdp
    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
      body: JSON.stringify(logs)
    }
      const check = await fetch("/api/checkAdmin",requestOptions).then(res => res.json())
      if(check=="OK") {
          this.handleClose()
          this.props.showModif()
      }
  }
    render() {
      return (
        <div>
        <Dialog open={this.state.isOpen} onClose={this.props.closeDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Vérification Administrateur</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veulliez vous connecter avec le compte d'un administrateur si vous voulez modifier ce devis validé.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label="Adresse Email"
              type="email"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              name="mdp"
              label="Mot de Passe"
              type="password"
              onChange={this.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary">
              Annuler
            </Button>
            <Button onClick={this.checkIfAdmin.bind(this)} color="primary">
              S'identifier
            </Button>
          </DialogActions>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
    devisrea : state.devis.devisrea,
    deviscon : state.devis.deviscon,
    dossierValue : state.devis.dossierValue,
    nprop : state.devis.nprop,
    attachment: state.email.file
  });
export default connect(mapStateToProps)(FormDialog)