import React from "react"
import NumsDoss from "../NumsDoss"
import { connect } from "react-redux"
import { fetchRJ, newRjValue } from "../../redux/actions/rjActions"
class RJMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    async componentDidMount() {
        if ((this.props.rj.length === 0 || this.props.rj === undefined || this.props.rjValue === undefined) && this.props.dossierValue) {
            await this.props.dispatch(fetchRJ(this.props.dossierValue))
            const newRj = this.props.rj[this.props.rj.length - 1];
            await this.props.dispatch(newRjValue(newRj));
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.dossierValue !== prevProps.dossierValue) {
            await this.props.dispatch(fetchRJ(this.props.dossierValue))
            const newRj = this.props.rj[this.props.rj.length - 1];
            await this.props.dispatch(newRjValue(newRj));
        }
        if (this.props.rjValue == undefined && prevProps.rjValue) {
            await this.props.dispatch(newRjValue(this.props.rj[prevProps.rjValue.numero]))
        }

        if (JSON.stringify(this.props.rjValue) !== JSON.stringify(prevProps.rjValue)) {
            this.props.rj.map(async (rj) => {
                if (this.props.rjValue !== undefined && rj.numero == this.props.rjValue.numero) {
                    await this.props.dispatch(newRjValue(rj))
                }
            })
        }
    }

    // filterRJ = () => {
    //     return this.props.rj.filter(rj => {
    //         if (rj.ndossier == this.props.dossierValue) return true
    //         else return false
    //     })
    // }
    handleRjChange = async (event) => {
        await this.props.dispatch(newRjValue(JSON.parse(event.target.value)))
    }
    render() {
        const pourcentage = [0, 10, 20, 30, 45, 50, 60, 75, 85, 100]
        return (
            <div className="rjmenu">
                <NumsDoss />
                <div className="numero">
                    <label>Numéro du Registre Journal : </label>
                    <select onChange={this.handleRjChange} value={JSON.stringify(this.props.rjValue)}>
                        {this.props.rj.map((rj, index) => {
                            return <option value={JSON.stringify(rj)} key={index}>{rj.numero}</option>
                        })}
                    </select>
                </div>
                <div className="advancement">
                    <label>Avancement : </label>
                    <select name="avancement" value={this.props.advancement} onChange={typeof this.props.handleResetValueArray === "function" ? this.props.handleResetValueArray : null} disabled={this.props.handleResetValueArray === "" ? true : false}>
                        {pourcentage.map((item, index) => {
                            return <option value={item} key={index}>{item}</option>
                        })}
                    </select>
                    <label> % </label>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    vic: state.vic.items,
    rj: state.rj.items,
    rjValue: state.rj.rjValue,
    photos: state.rj.photos,
    dossierValue: state.devis.dossierValue
});
export default connect(mapStateToProps)(RJMenu);