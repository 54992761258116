import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getCspsInformationsData, genPgcPDF, getPgcFile, getMoCompanyTel } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPGC from "../../NumDossPGC";
import GenPdf from "../../GenPdf";
import { documentToProvideConst, contactsConst, technicalLotsConst } from "./Const";
import LoadingOverlay from "../../LoadingOverlay";
import "../../../Styles/PGC/Page6.scss";

const PgcPage6 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [distribution, setDistribution] = useState(new Date().toLocaleDateString("en-CA"));
  const [distributor, setDistributor] = useState('');
  const [documentToProvide, setDocumentToProvide] = useState(documentToProvideConst);
  const [technicalLots, setTechnicalLots] = useState([technicalLotsConst]);

  const [contacts, setContacts] = useState(contactsConst);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!dossierValue) return
    loadData()

  }, [pgcValue]);

  const loadData = async () => {
    if (!pgcValue) return resetData();
    setIsLoading(true);
    const responseCspsInformationsData = await getCspsInformationsData(pgcValue);

    if (responseCspsInformationsData) {
      const distributionLoaded = responseCspsInformationsData.distribution ? new Date(responseCspsInformationsData.distribution).toLocaleDateString("en-CA") : new Date().toLocaleDateString("en-CA");
      const documentToProvideLoaded = responseCspsInformationsData.document_to_provide ? JSON.parse(responseCspsInformationsData.document_to_provide) : documentToProvideConst;
      const technicalLotsLoaded = responseCspsInformationsData.technical_lots ? JSON.parse(responseCspsInformationsData.technical_lots) : [technicalLotsConst];
      
      const tel = await getMoCompanyTel(pgcValue)
      const updatedContacts = {
        ...contactsConst,
        company: tel
      };
      const contactsLoaded = responseCspsInformationsData.contacts ? JSON.parse(responseCspsInformationsData.contacts) : updatedContacts;
      const distributorLoaded = responseCspsInformationsData.distributor;

      unstable_batchedUpdates(() => {
        setDistribution(distributionLoaded);
        setDistributor(distributorLoaded);
        setDocumentToProvide(documentToProvideLoaded);
        setTechnicalLots(technicalLotsLoaded);
        setContacts(contactsLoaded);
        setIsLoading(false);
      });
    } else {
      unstable_batchedUpdates(() => {
        resetData();
        setIsLoading(false);
      });
    }
  };

  const resetData = () => {
    unstable_batchedUpdates(() => {
      setDistribution(new Date().toLocaleDateString("en-CA"));
      setDistributor('');
      setDocumentToProvide(documentToProvideConst);
      setTechnicalLots([technicalLotsConst]);
      setContacts(contactsConst);
    })
  }

  const handleAddTechnicalLot = () => {
    setTechnicalLots(prevState => [...prevState, technicalLotsConst])
  }

  const handleDeleteTechnicalLot = (index) => {
    if (technicalLots.length === 1) {
      setTechnicalLots([technicalLotsConst])
    } else {
      const newArray = [...technicalLots];
      newArray.splice(index, 1);
      setTechnicalLots(newArray);
    }
  }

  const handleChangeDocumentToProvideValues = (event, index) => {
    const { name, checked } = event.target;

    setDocumentToProvide(prevState => {
      const element = [...prevState];
      element[index] = {
        ...element[index],
        [name]: checked,
        [name === "yes" ? "no" : "yes"]: !checked
      }
      return element
    })
  }

  const handleTechnicalLotsValues = (event, index, prevValue) => {
    const { name, value, checked } = event.target;

    setTechnicalLots(prevState => {
      const element = [...prevState];
      element[index] = {
        ...element[index],
        [name]: (name === 'ppsps' || name === 'vic') ? !prevValue : checked === undefined ? value : name === "index" ? value : checked
      }
      return element
    })
  }

  const handleSaveData = async () => {
    setDisableButton(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pgcValue,
        distribution,
        distributor,
        documentToProvide: JSON.stringify(documentToProvide),
        technicalLots: JSON.stringify(technicalLots),
        contacts: JSON.stringify(contacts),
      }),
    };
    try {
      const response = await fetch("/api/pgc/updateCspsInformationsData", requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDisableButton(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPgcPDF(pgcValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`PGC`));
  }, [pgcValue]);

  const prevPage = () => {
    history.push("/pgc/page5");
  }

  const nextPage = () => {
    history.push("/pgc/validation");
  }

  return (
    <div className="pgc-page6">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Plan Général de Coordination (Information nécessaire au CSPS)</h1>
      <NumDossPGC />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={4}><strong>Diffusion</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td className="custom-cell-label">Date</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="date" value={distribution} onChange={(e) => setDistribution(e.target.value)} /></td>
              <td className="custom-cell-label">Diffuseur</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={distributor} onChange={(e) => setDistributor(e.target.value)} style={{ width: '95%' }} placeholder="Maître d'ouvrage..." /></td>
            </tr>
          </tbody>
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={4}><strong>Document et pièce fournis au CSPS</strong></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" style={{ width: '50px' }}><strong>Oui</strong></td>
              <td className="custom-cell-label" style={{ width: '50px' }}><strong>Non</strong></td>
              <td className="custom-cell-label" colSpan={2}><strong>Type de document</strong></td>
            </tr>
          </thead>
          <tbody>
            {documentToProvide.map((item, index) => (
              <tr className="custom-cell" key={`document-to-provide-${index}`}>
                <td className="custom-cell-label"><input name="yes" type="checkbox" checked={item.yes} onChange={(event) => handleChangeDocumentToProvideValues(event, index)} /></td>
                <td className="custom-cell-label"><input name="no" type="checkbox" checked={item.no} onChange={(event) => handleChangeDocumentToProvideValues(event, index)} /></td>
                <td className="custom-cell-label" colSpan={2}><span>{item.type}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={7}><strong>Liste des lots techniques</strong></td>
            </tr>
            <tr className="custom-cell-title-lot">
              <td className="custom-cell-label-lot" rowSpan={2}><strong></strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}><strong>TRAVAUX </strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}><strong>NOM ET ADRESSE<br />ENTREPRISE</strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}><strong>CONTACT</strong></td>
              <td className="custom-cell-label-lot" style={{ width: '50px' }}><strong>VIC</strong></td>
              <td className="custom-cell-label-lot" style={{ width: '50px' }}><strong>PPSPS</strong></td>
              <td className="custom-cell-label-lot" rowSpan={2}></td>
            </tr>
            <tr className="custom-cell-title-lot">
              <td className="custom-cell-label-lot" colSpan={2}><strong style={{ color: 'green' }}>OUI</strong><strong style={{ color: 'black' }}> / </strong><strong style={{ color: 'red' }}>NON</strong></td>
            </tr>
          </thead>
          <tbody>
            {technicalLots.map((item, i) => (
              <tr className="custom-cell-lot" key={`technical-ots-${i}`}>
                <td className="custom-cell-label-lot"><input type="text" name="index" value={item.index} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="0..." /></td>
                <td className="custom-cell-label-lot"><textarea name="works" value={item.works} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="Lot" /></td>
                <td className="custom-cell-label-lot"><textarea name="company" value={item.company} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="Entreprise Dupont&#10;123 Rue de la République&#10;75001 - Paris" /></td>
                <td className="custom-cell-label-lot"><textarea name="contact" value={item.contact} onChange={(event) => handleTechnicalLotsValues(event, i)} placeholder="Jean Dupont&#10;01 23 45 67 89&#10;jean.dupont@exemple.fr" /></td>
                <td className="custom-cell-label-lot"><input type="checkbox" name="vic" checked={true} onChange={(event) => handleTechnicalLotsValues(event, i, item.vic)} style={{ width: '17px', height: '17px', accentColor: item.vic ? 'green' : 'red' }} /></td>
                <td className="custom-cell-label-lot"><input type="checkbox" name="ppsps" checked={true} onChange={(event) => handleTechnicalLotsValues(event, i, item.ppsps)} style={{ width: '17px', height: '17px', accentColor: item.ppsps ? 'green' : 'red' }}/></td>
                <td className="custom-cell-label-lot">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    disabled={technicalLots.length === 1 && technicalLots.every((item) => JSON.stringify(item) === JSON.stringify(technicalLotsConst))}
                    onClick={() => handleDeleteTechnicalLot(i)}
                  >
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            ))}
            <tr className="add-technical-lot">
              <td>
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddTechnicalLot}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={4}><strong>Contacts en cas d'accident</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td className="custom-cell-label">Inspection Du Travail</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={contacts.workInspection} onChange={(event) => setContacts({ ...contacts, workInspection: event.target.value })} /></td>
              <td className="custom-cell-label">O.P.P.B.T.P</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={contacts.oppbtp} onChange={(event) => setContacts({ ...contacts, oppbtp: event.target.value })} /></td>
            </tr>
            <tr className="custom-cell">
              <td className="custom-cell-label">CRAM Sce Prévention</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={contacts.cram} onChange={(event) => setContacts({ ...contacts, cram: event.target.value })} /></td>
              <td className="custom-cell-label">Medecine Du Travail</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={contacts.occupationalHealth} onChange={(event) => setContacts({ ...contacts, occupationalHealth: event.target.value })} /></td>
            </tr>
            <tr className="custom-cell">
              <td className="custom-cell-label">COORDOGEC</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={contacts.coordogec} onChange={(event) => setContacts({ ...contacts, coordogec: event.target.value })} /></td>
              <td className="custom-cell-label">L’entreprise</td>
              <td className="custom-cell-label" style={{ textAlign: 'left' }}><input type="text" value={contacts.company} onChange={(event) => setContacts({ ...contacts, company: event.target.value })} /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !pgcValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !pgcValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !pgcValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !pgcValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !pgcValue}
        >
          Validation
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PgcPage6;