import React from "react";
import { connect } from "react-redux";
import { newDossierValue } from "../redux/actions/devisActions";

class NumsDoss extends React.Component {
  constructor(props) {
    super(props);
    this.handleDossierChange = this.handleDossierChange.bind(this);
  }

  handleDossierChange(event) {
    this.props.dispatch(newDossierValue(event.target.value));
  }

  render() {
    const { dossier, dossierValue } = this.props;
    // console.log("NumsDoss - N° Dossier", dossierValue)

    return (
      <div className="ndossier">
        <label>N° Dossier : </label>
        <select
          id="lang"
          onChange={(event) => this.handleDossierChange(event)}
          value={dossierValue}
        >
          {dossier.map(({ ndossier }, i) => (
            <option key={i} value={ndossier}>
              {ndossier}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items
});
export default connect(mapStateToProps)(NumsDoss);
