import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class SuppQuotaDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      // rowSelected: this.props.rowSelected,
    };
  }

  // componentDidUpdate(prevProps) {
  //   console.log("componentDidUpdate", this.props.rowSelected);
  //   if (this.props.rowSelected !== prevProps.rowSelected) {
  //     this.setState({ rowSelected: this.props.rowSelected });
  //   }
  // }

  render() {
    return (
      <React.Fragment >
        <Dialog
          open={this.props.open}
          onClose={() => this.props.setOpen(false)}
          aria-labelledby="form-dialog-title"
          disableScrollLock= {true}
        >
          <DialogTitle id="form-dialog-title">SUPPRESSION TACHE</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Êtes vous sûr de vouloir supprimer la tâche: {this.props.rowSelected !== null ? this.props.rowSelected.tache : ""} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              style={{ backgroundColor: "#FF7F50", color: "white" }}
              onClick={() => {
                // if ((this.state.rowSelected.type = "Conception")) {
                //   this.props.onConfirm( this.props.dataCon, this.state.rowSelected, this.state.rowSelected._original.id );
                // }
                // if ((this.state.rowSelected.type = "Realisation")) {
                //   this.props.onConfirm( this.props.dataReal, this.state.rowSelected, this.state.rowSelected._original.id );
                // }
                // console.log("this.state.rowSelected.type", this.state.rowSelected.type);
                // if(this.props.rowSelected.type === "Conception"){
                  //   console.log("this.state.1", this.props.rowSelected);
                  //   this.props.onConfirm( this.props.dataCon, this.props.rowSelected, this.props.rowSelected.id );
                  // } else {
                    //   console.log("this.state.2");
                    //   this.props.onConfirm( this.props.dataReal, this.props.rowSelected, this.props.rowSelected.id );
                    // }
                    
                    
                this.props.onConfirm( this.props.rowSelected.type === "Conception" ? this.props.dataCon : this.props.dataReal, this.props.rowSelected, this.props.rowSelected.id );
                this.props.setOpen(false);
              }}
            >
              Oui
            </Button>
            <Button
              color="primary"
              style={{ backgroundColor: "#f5b039", color: "white" }}
              onClick={() => this.props.setOpen(false)}
            >
              Non
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default SuppQuotaDialog;
