import React from 'react'
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { getEntrepriseFromContact, getEntrepriseFromName } from '../../Utils';
                        
// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.prenom+" "+suggestion.nom;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div style={{ padding: "auto" }}>
    {suggestion.prenom+" "+suggestion.nom}
  </div>
);


class AutoSuggestRespComp extends React.Component{
    constructor(props){
        super(props)
        this.state={
            suggestions: [],
        }
    }
      // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.contact.filter(item =>{
       if(item.nom.toLowerCase().slice(0, inputLength) === inputValue && 
       item.identreprise===getEntrepriseFromName(this.props.company,this.props.entreprise).id) return true
       if(item.prenom.toLowerCase().slice(0, inputLength) === inputValue && 
       item.identreprise===getEntrepriseFromName(this.props.company,this.props.entreprise).id) return true
    }
    )
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };


  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.props.setRespComp(suggestion)
  };
    render(){
        const inputProps = {
            placeholder:"Prénom NOM",
            name: "nomres",
            value: this.props.nomres,
            onChange: this.props.handleChange,
            style:{ border:"none", height:"100%", width:"100%", pointerEvents : "visible", backgroundColor:"transparent"
            , textAlign :"center", fontSize:"14px"}
        };
        return(
            <React.Fragment>
              <Autosuggest
                onSuggestionSelected={this.onSuggestionSelected}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}/>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    contact: state.contact.items,
    entreprise: state.entreprise.items
  });
  export default connect(mapStateToProps)(AutoSuggestRespComp)