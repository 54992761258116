import {
  FETCH_RJ_BEGIN,
  FETCH_RJ_SUCCESS,
  FETCH_RJ_FAILURE,
  FETCH_RJ_FR_CATEGORIES_BEGIN,
  FETCH_RJ_FR_CATEGORIES_SUCCESS,
  FETCH_RJ_FR_CATEGORIES_FAILURE,
  FETCH_PHOTOS_BEGIN,
  FETCH_PHOTOS_SUCCESS,
  FETCH_PHOTOS_FAILURE,
  CHANGE_RJ_VALUE
} from '../actions/rjActions';
const initialState = {
  items: [],
  listFactors: [],
  rjValue: {},
  photos: [],
  loading: false,
  error: null,
}

export default function rjReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RJ_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_RJ_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.items,
        rjValue: action.payload.rjValue
      }
    }
    case FETCH_RJ_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_RJ_FR_CATEGORIES_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_RJ_FR_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        listFactors: action.payload.listFactors,
      }
    }
    case FETCH_RJ_FR_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        listFactors: []
      };
    }
    case FETCH_PHOTOS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_PHOTOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        photos: action.payload.photos,
      }
    }
    case FETCH_PHOTOS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        photos: []
      };
    }
    case CHANGE_RJ_VALUE: {
      return {
        ...state,
        rjValue: action.payload.rjValue
      };
    }
    default:
      return state
  }
}