export const getDossier = (dossier, dossierValue) => {
  for (let i = 0; i < dossier.length; i++) {
    if (dossier[i].ndossier == dossierValue) {
      return dossier[i];
    }
  }
  return "";
};

export const getContact = (contacts, dossier) => {
  for (let i = 0; i < contacts.length; i++) {
    if (dossier.idcontact == contacts[i].idcontact) {
      return contacts[i];
    }
  }
  return "";
};

export const getEntreprise = (entreprises, contact) => {
  for (let i = 0; i < entreprises.length; i++) {
    if (contact.identreprise == entreprises[i].id) {
      return entreprises[i];
    }
  }
  return "";
};

export const getClient = (props) => {
  var dossier = getDossier(props.dossier, props.dossierValue);
  if (dossier !== "") {
    var contact = getContact(props.contact, dossier);
    if (contact !== "") {
      var entreprise = getEntreprise(props.entreprise, contact);
      if (entreprise !== "") {
        return entreprise;
      }
    }
  }
  return "";
};

export const getRespComp = (contacts, identreprise) => {
  for (const contact of contacts) {
    if (contact.fonction == "Responsable Entreprise" && contact.identreprise == identreprise) {
      return contact;
    }
  }
  return { prenom: "", nom: "" };
};

export const getRespChan = (contacts, idcontact) => {
  for (const contact of contacts) {
    if (contact.idcontact == idcontact) {
      return contact;
    }
  }
  return "";
};

export const getVIC = (vic, ndossier, lot, identreprise) => {
  for (const item of vic) {
    if (
      item.ndossier == ndossier &&
      item.lots == lot &&
      item.identreprise == identreprise
    ) {
      return item;
    }
  }
  return {};
};

export const genPDF = async (
  dossierValue,
  identreprise,
  lotValue,
  nbrPages,
  client
) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      ndossier: dossierValue,
      lotValue: lotValue,
      identreprise: identreprise,
      nbrPages: nbrPages,
      client: client,
    }),
  };
  return await fetch("/api/vic/genPDF", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const genVicPDF = async (vicValue) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ vicValue }),
  };
  return await fetch("/api/vic/genVicPDF", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getPDF = async (dossierValue, entreprise, lotValue, type) => {
  try {
    const res = await fetch("/api/vic/getPDF", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, lotValue: lotValue, entreprise: entreprise, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getVicPDF = async (vicValue, type) => {
  try {
    const res = await fetch("/api/vic/getVicPDF", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ vicValue, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getLot = (lots, lotValue) => {
  if (lots.length !== 0) {
    for (const lot of lots) {
      if (lot.code == lotValue) {
        return lot;
      }
    }
  }
  return {};
};

export const getLotNbrPages = (lots, lotValue) => {
  if (lots.length !== 0) {
    return lots.filter((lot) => {
      if (lotValue == lot.code) return true;
      else return false;
    })[0].nbr_pages;
  }
};

export const getQuestionsPage = async (page, vicValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      page,
      vicValue
    })
  };

  try {
    const response = await fetch("/api/vic/getQuestionsPage", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    if (data.length === 0) {
      return []
    } else return (page === 7 || page === 8) ? data[0] : JSON.parse(data[0].answers)
  } catch (err) {
    console.log(err);
  }
}

export const updateQuestionsPage = async (page, vicValue, answers) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      page,
      vicValue,
      answers
    })
  };

  try {
    const response = await fetch("/api/vic/updateQuestionsPage", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
  } catch (err) {
    console.log(err);
  }
}

export const getQuestionsPage5 = async (page, vicValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      page,
      vicValue
    })
  };

  try {
    const response = await fetch("/api/vic/getQuestionsPage", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();
    if (data.length === 0) {
      return []
    } else return data[0]
  } catch (err) {
    console.log(err);
  }
}

export const updateQuestionsPage5 = async (formData) => {
  const requestOptions = {
    method: "POST",
    body: formData
  };

  try {
    const response = await fetch("/api/vic/updateQuestionsPage5", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
  } catch (err) {
    console.log(err);
  }
}

export const updateQuestionsPage8 = async (formData) => {
  const requestOptions = {
    method: "POST",
    body: formData
  };

  try {
    const response = await fetch("/api/vic/updateQuestionsPage8", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
  } catch (err) {
    console.log(err);
  }
}

export const getVicValues = async (dossierValue, lotsValue, entrepriseValue) => {
  const id = typeof entrepriseValue === 'string' ? JSON.parse(entrepriseValue).id : entrepriseValue.id
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      ndossier: dossierValue,
      idLot: lotsValue,
      idCompany: id,
    }),
  };

  try {
    const response = await fetch("/api/vic/getCurrentVic", requestOptions);
    if (!response.ok) {
      throw new Error("Échec de la requête.");
    }
    const data = await response.json();

    if (data.length === 0) {
      return []
    } else return data[0];
  } catch (err) {
    console.log(err);
  }
};

export const getEmploymentLaws = async (observationIdArray) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      idArray: observationIdArray
    }),
  };

  try {
    const response = await fetch("/api/vic/getEmploymentLaws", requestOptions);
    if (!response.ok) {
      throw new Error("Échec de la requête.");
    }
    const data = await response.json();
    
    if (data.length === 0) {
      return []
    } else return data;
  } catch (err) {
    console.log(err);
  }
};


export const getObservationData = async (vicValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ vicValue }),
  };

  try {
    const response = await fetch("/api/vic/getObservationsData", requestOptions);
    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des données: " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (err) {
    throw err;
  }
};

export const saveObservationsPage6 = async (vicValue, observations) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      vicValue: vicValue,
      observations: observations
    }),
  };

  try {
    const response = await fetch("/api/vic/updateObservationsPage6", requestOptions);
    if (!response.ok) {
      throw new Error("Resource not found");
    }
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const saveInformationsPage7 = async (vicValue, titles, informations) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      vicValue: vicValue,
      titles,
      informations
    }),
  };

  try {
    const response = await fetch("/api/vic/updateInformationsPage7", requestOptions);
    if (!response.ok) {
      throw new Error("Resource not found");
    }
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
}


export const deleteVic = async (vicValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      vicValue: vicValue,
    }),
  };

  try {
    const response = await fetch("/api/vic/deleteVic", requestOptions);
    if (!response.ok) {
      throw new Error("Erreur lors de la suppression");
    }
    return response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getConventionValue = async (vicValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      vicId: vicValue,
    }),
  };

  try {
    const response = await fetch("/api/vic/getConventionValue", requestOptions);
    if (!response.ok) {
      throw new Error("Erreur lors de la recuperation de la convention");
    }
    const data = await response.json();
    return data.convention;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateConvention = async (vicValue, conv) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      vicId: vicValue,
      conv: conv
    })
  };

  try {
    const response = await fetch("/api/vic/updateConvention", requestOptions);
    if (!response.ok) {
      throw new Error("Erreur lors de l'ajout de la convention");
    }
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDossierInfos = async (dossierValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ dossierValue }),
  };

  try {
    const response = await fetch("/api/devis/getDossierInfos", requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching dossier info:", err);
    throw err;
  }
};

