import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Switch from '@material-ui/core/Switch';
import Header from "../../Header/Header";
import QuotaTableCon from "./QuotaTableCon";
import QuotaTableReal from "./QuotaTableReal";
import NumDoss from "../../NumDoss";
import DocMenu from "../../DocMenu";
import GenPdf from "../../GenPdf";
import { connect } from "react-redux";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getCurrentQuotas, getPDF, getInfosDossier, getCurrentDevis } from "../Utils";
import SuppQuotaDialog from "./SuppQuotaDialog";
import ConfSaveDialog from "./ConfSaveDialog";
import TaskList from "./TaskList";
import PopupPdf from "./PopupPdf";
import "../../../Styles/Heures/QuotaH.scss";
import tasksArray from "./tasksArray.json";
import history from '../../Routes/history';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from "uuid";

class QuotaH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category1: true,
      userName: "",
      accept: false,
      code: "",
      tasks: [],
      task: "",
      startH: "",
      endH: "",
      hprev: 0,
      hree: 0,
      dateTask: null,
      rowSelected: null,
      allData: [],
      dataCon: [],
      dataReal: [],
      devisHours: [],
      isOpenSupp: false,
      isOpenSave: false,
      actions: [],
      file: null,
      isOpen: false,
      openDialog: false,
      withDetails: false
    };
    this.handleSuppRowClick = this.handleSuppRowClick.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.showSuppDialog = this.showSuppDialog.bind(this);

    this.handleUpdateTasks = this.handleUpdateTasks.bind(this);
    this.saveData = this.saveData.bind(this);
    this.genPDF = this.genPDF.bind(this);
    this.genDetailPDF = this.genDetailPDF.bind(this);
    this.showPDF = this.showPDF.bind(this);
  }
  async componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    if (this.props.user.length < 1) {
      history.push("/login")
    } else {
      // const category = getCategoryNum(dossiers, dossierValue) === 1;
      const category = true;
      const userName = `${this.props.user[0].name} ${this.props.user[0].surname}`;
      this.setState({ category1: category, userName });
      this.loadQuotas()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.length < 1) {
      history.push("/login")
    } else {
      const { dossierValue } = this.props;
      if (dossierValue !== prevProps.dossierValue) {
        // const category = getCategoryNum(dossiers, dossierValue) === 1;
        const category = true;
        const userName = `${this.props.user[0].name} ${this.props.user[0].surname}`;
        this.setState({ category1: category, userName });
        this.loadQuotas();
      }
    }
  }

  handleUpdateTasks(task) {
    const { type } = task;
    const { dataCon, dataReal, category1 } = this.state;
    let prefix = category1
      ? type === "Conception"
        ? task.order_task < 5
          ? "1.".concat(`${task.order_task + 1}. `)
          : "2.".concat(`${task.order_task - 4}. `)
        : task.order_task < 3
          ? "1.".concat(`${task.order_task + 1}. `)
          : task.order_task < 6
            ? "2.".concat(`${task.order_task - 2}. `)
            : "3.".concat(`${task.order_task - 5}. `)
      : `${task.order_task + 1}. `;
    task.tache = prefix.concat(task.tache);
    const tasks = type === "Conception" ? dataCon : dataReal;
    let index = tasks.length;
    if (index !== 0) {
      for (let i = tasks.length - 1; i >= 0; i--) {
        if (tasks[i].order_task > task.order_task) {
          index = i;
        }
        if (tasks[i].order_task === task.order_task) {
          tasks.splice(i + 1, 0, task)
          break
        }
        if (i === 0) {
          tasks.splice(index, 0, task)
        }
      }
    } else {
      tasks.push(task);
    }
    this.setState({ [type === "Conception" ? "dataCon" : "dataReal"]: tasks });
  }

  async loadQuotas() {
    this.props.dispatch(changePdfFileName(""))
    const queryParams = new URLSearchParams({ ndossier: this.props.dossierValue });
    const url = "/api/quota/getQuotasByNdossier?" + queryParams.toString();

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = await response.json();

      this.setState({
        allData: data,
        dataCon: getCurrentQuotas(data, "Conception"),
        dataReal: getCurrentQuotas(data, "Realisation"),
        file: null
      });
    } catch (err) {
      console.log(err);
    }
  }

  /**
   *
   * @param {*} dataTable
   * @param {*} value
   * @returns
   */
  verifyDataTable(dataTable, value) {
    var findSim = 0;
    var newTable = dataTable.map((data, index) => {
      if (data.code === this.state.code) {
        findSim++;
        data = value;
        return data;
      }
      return data;
    });
    if (findSim !== 0) return newTable;
    else {
      newTable.push(value);
      return newTable;
    }
  }

  formateNbToHour(number) {
    const hours = Math.floor(number);
    const minutes = Math.round((number - hours) * 60);
    const time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    return time;
  }

  /**
   * Convertit une String de l'heure en nombre réel
   * @param {string} time
   * @returns {float}
   */
  timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  /**
   * Récupère la différence entre l'heure de début et l'heure de fin d'une tâche
   * @param {string} start
   * @param {string} end
   * @returns {float}
   */
  getDuration(start, end) {
    var duration = this.timeStringToFloat(end) - this.timeStringToFloat(start);
    return duration;
  }

  /**
   * Additionne la valeur des heures réelles et les heures indiquées. Vérifie si l'addition renvoie un nombre réel
   * @param {float} hree
   * @returns {float}
   */
  checkDuration(hree) {
    if (!isNaN(this.getDuration(this.state.startH, this.state.endH))) {
      return (
        hree + parseFloat(this.getDuration(this.state.startH, this.state.endH))
      );
    } else return hree;
  }

  /**
   * Affiche ou ferme la fenêtre de dialogue concernant la suppression d'une ligne
   * @param {boolean} open
   */
  setOpenSupp(open) {
    this.setState({ isOpenSupp: open });
  }

  /**
   * Affiche ou ferme la fenêtre de dialogue concernant la sauvegarde des tableaux
   * @param {boolean} open
   */
  setOpenSave(open) {
    this.setState({ isOpenSave: open });
  }


  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  /**
   * Affiche la fenêtre de dialogue et récupère les données de la ligne selectionnée dans le tableau
   * @param {*} row
   */
  async showSuppDialog(row) {
    row = row._original
    await this.setState({ rowSelected: row });
    await this.setOpenSupp(true);
  }

  /**
   * Supprime la ligne selectionnée
   * @param {[]} quotas
   * @param {{}} row
   */
  async handleSuppRowClick(quotas, row, indexToDelete) {
    for (let i = 0; i < quotas.length; i++) {
      if (
        row.tache === quotas[i].tache &&
        row.hree === quotas[i].hree &&
        quotas[i].ndossier == this.props.dossierValue
      ) {
        quotas.forEach((quota, index) => {
          if (
            i < index &&
            row.tache === quota.tache &&
            quota.ndossier == this.props.dossierValue
          ) {
            quota.hree = quota.hree - this.getDuration(row.hdebut, row.hfin);
            quota.hres = quota.hres + this.getDuration(row.hdebut, row.hfin);
            quota.hdep = quota.hdep - this.getDuration(row.hdebut, row.hfin);
          }
        });
        quotas.splice(i, 1);
        if (row.type == "Conception") {
          this.setState({ dataCon: quotas });
        }
        if (row.type == "Realisation") {
          this.setState({ dataReal: quotas });
        }
      }
    }
  }

  saveActions(actions) {
    if (actions.length === 0) return;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        actions: actions,
      }),
    };
    fetch("/api/quota/saveHist", requestOptions).catch((err) =>
      console.log(err)
    );
  }


  /**
   * Sauvegarde les données des 2 tableaux
   */

  async saveData() {
    let allData = [...this.state.allData];
    let data = this.state.dataCon.concat(this.state.dataReal);
    const username = `${this.props.user[0].name} ${this.props.user[0].surname}`;
    const now = new Date();
    const dateaction = now.toLocaleDateString();
    const timezoneOffset = now.getTimezoneOffset() * 60 * 1000; // Convert minutes to milliseconds
    const timeaction = new Date(now.getTime() - timezoneOffset).toISOString().slice(11, 16);

    allData = allData.map((element) => {
      const formattedDate = element.date.substring(0, 10);
      return {
        ...element,
        date: formattedDate,
      }
    })

    data = data.map((element) => {
      const formattedDate = element.date.substring(0, 10);
      return {
        ...element,
        date: formattedDate,
      }
    })

    const addedElements = data
      .filter((element) => !allData.some(item => isEqual(item, element)))
      .map((element) => {
        return {
          ...element,
          iduser: this.props.user[0].iduser,
          username: username.trim(),
          action: "add",
          dateaction,
          timeaction,
        }
      });

    const removedElements = allData
      .filter((element) => !data.some(item => isEqual(item, element)))
      .map((element) => {
        return {
          ...element,
          iduser: this.props.user[0].iduser,
          username: username.trim(),
          action: "remove",
          dateaction,
          timeaction,
        }
      });

    const actions = [...addedElements, ...removedElements];

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        quotas: data,
      }),
    };

    try {
      await fetch("/api/quota/saveQuota", requestOptions);
      await this.saveActions(actions);
      await this.loadQuotas();
    } catch (err) {
      console.log(err);
    }
  }


  async genPDF() {
    this.saveData();
    this.setOpen(true);
    this.setState({ file: null });
    let dossier = getInfosDossier(this.props.dossiers, this.props.dossierValue)
    let data = this.state.dataCon.concat(this.state.dataReal);
    const getQuotas = (type, devis, data) => {
      const devisHourPrev = JSON.parse(getCurrentDevis(devis, this.props.dossierValue).devisTab).nbrHeures;
      const quotas = [];
      for (let i = 0; i < devisHourPrev.length; i++) {
        const item = {
          type: type,
          tache: tasksArray.tasks.category1[type === 'Conception' ? 'con' : 'rea'][i],
          hdebut: "",
          hfin: "",
          hprev: this.formateNbToHour(devisHourPrev[i]),
          hree: "",
          hres: "",
          hdep: "",
          date: "",
        };
        for (let j = data.length - 1; j >= 0; j--) {
          if (data[j].order_task === i && data[j].type === type) {
            item.hdebut = data[j].hdebut.length > 5 ? data[j].hdebut.slice(0, -3) : data[j].hdebut;
            item.hfin = data[j].hfin.length > 5 ? data[j].hfin.slice(0, -3) : data[j].hfin;
            item.hree = this.formateNbToHour(data[j].hree);
            item.hres = data[j].hres > 0 ? this.formateNbToHour(data[j].hres) : "";
            item.hdep = data[j].hdep > 0 ? this.formateNbToHour(data[j].hdep) : "";
            const date = new Date(data[j].date);
            item.date = date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
            quotas.push(item);
            break;
          }
          if (j === 0) {
            quotas.push(item);
          }
        }
      }
      return quotas;
    };

    const quotas = [...getQuotas('Conception', this.props.deviscon, data), ...getQuotas('Realisation', this.props.devisrea, data)];

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossier.ndossier,
        operation: dossier.operation,
        adresse_op: dossier.adresse_op,
        cp_op: dossier.cp_op,
        ville_op: dossier.ville_op,
        quotas: quotas,
        currentdate: new Date().toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })
      }),
    };
    let blob = await fetch("/api/quota/genPDF", requestOptions).then((res) =>
      res.blob()
    );
    if (blob) {
      await this.setState({ file: blob });
    }
  }

  async genDetailPDF() {
    this.saveData();
    this.setOpen(true);
    this.setState({ file: null });
    let dossier = getInfosDossier(this.props.dossiers, this.props.dossierValue)
    let data = this.state.dataCon.concat(this.state.dataReal);
    data = data.map((quota) => {
      quota = { ...quota };
      quota.hdebut = quota.hdebut.length > 5 ? quota.hdebut.slice(0, -3) : quota.hdebut;
      quota.hfin = quota.hfin.length > 5 ? quota.hfin.slice(0, -3) : quota.hfin;
      quota.hprev = this.formateNbToHour(quota.hprev);
      quota.hree = this.formateNbToHour(quota.hree);
      quota.hres = quota.hres > 0 ? this.formateNbToHour(quota.hres) : "";
      quota.hdep = quota.hdep > 0 ? this.formateNbToHour(quota.hdep) : "";
      quota.date = new Date(quota.date);
      quota.date = quota.date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
      return quota
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossier.ndossier,
        operation: dossier.operation,
        adresse_op: dossier.adresse_op,
        cp_op: dossier.cp_op,
        ville_op: dossier.ville_op,
        quotas: data,
        currentdate: new Date().toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })
      }),
    };
    let blob = await fetch("/api/quota/genDetailPDF", requestOptions).then((res) =>
      res.blob()
    );
    if (blob) {
      await this.setState({ file: blob });
    }
  }

  handleChangeSwitch = () => {
    this.setState({ withDetails: !this.state.withDetails });
  }

  handleClickButton = () => {
    if (this.state.withDetails) {
      this.genDetailPDF();
    } else {
      this.genPDF();
    }
    this.handleClose();
    this.setState({ withDetails: false });
  }

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false, withDetails: false });
  };

  async showPDF() {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, isOpen: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Quota d'heures`))
  }

  render() {
    return (
      <div className="quotaH">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Quota d'heures</h1>
        <NumDoss />
        <TaskList
          key={uuidv4()}
          category1={this.state.category1}
          dataCon={this.state.dataCon}
          dataReal={this.state.dataReal}
          getDuration={this.getDuration}
          handleUpdateTasks={this.handleUpdateTasks}
          saveData={this.saveData}
          genPDF={this.handleClickOpen}
          showPDF={this.showPDF}
        />
        <div className="quotaTables">
          <QuotaTableCon
            quotaData={this.state.dataCon}
            showSuppDialog={this.showSuppDialog}
          />
          <QuotaTableReal
            quotaData={this.state.dataReal}
            showSuppDialog={this.showSuppDialog}
          />
        </div>
        <SuppQuotaDialog
          dataReal={this.state.dataReal}
          dataCon={this.state.dataCon}
          rowSelected={this.state.rowSelected}
          open={this.state.isOpenSupp}
          setOpen={this.setOpenSupp.bind(this)}
          onConfirm={this.handleSuppRowClick}
        />
        <ConfSaveDialog
          open={this.state.isOpenSave}
          setOpen={this.setOpenSave.bind(this)}
          onConfirm={this.saveData.bind(this)}
        />
        <GenPdf
          isOpen={this.state.isOpen}
          file={this.state.file}
          closeDoc={() => this.setOpen(false)}
          scale={1}
        />
        <Dialog open={this.state.openDialog} onClose={this.handleClose}>
          <DialogTitle>GÉNÉRER PDF</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous générer le quota d'heures du dossier N° {this.props.dossierValue} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1">Détails</Typography>
              <Switch color="primary" onChange={this.handleChangeSwitch} />
            </Box>
            <Box>
              <Button variant="contained" style={{ color: "white", backgroundColor: "#FF7F50" }} onClick={this.handleClickButton}>
                Oui
              </Button>
              <Button variant="contained" style={{ color: "white", backgroundColor: "#F5B039", marginLeft: "10px" }} onClick={this.handleClose}>
                Non
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossiers: state.dossier.items,
  dossierValue: state.devis.dossierValue,
  user: state.user.items,
});
export default connect(mapStateToProps)(QuotaH);
