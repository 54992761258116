import '../../Styles/LoginPage/Login.scss';
import '../../Styles/LoginPage/Chantier.scss';
import '../../Styles/LoginPage/Contact.scss';
import '../../Styles/LoginPage/Process.scss';
import '../../Styles/LoginPage/Facturation.scss';
import '../../Styles/LoginPage/SocialNetwork.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import LogoCoordo from '../../Styles/Pictures/logo_coordogec.jpg';
import React from 'react';
import SocialNetwork from './SocialNetwork.js';
import ImagesLogin from './ImagesLogin.js';
import RegisterForm from './RegisterForm.js';
//import PropTypes from 'prop-types';

class Register extends React.Component{

    render() {
      return (    
      <div className="login">
         <style>
            @import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
         </style>
            <div className="header">
               <img src={LogoCoordo} alt="Bonjour"></img><br></br>
               <span className="text">Bienvenue à notre ERP</span>
            </div>
            <RegisterForm/>
         <ImagesLogin/>
      <div className="footer">
         <SocialNetwork/>
         <div className="copyright">
            <FontAwesomeIcon icon={faCopyright}/>
            <label>  Coordogec, 2020 - Tous droits Réservés</label>
         </div>
      </div>
   </div>



   );
      }
    }
export default Register;

