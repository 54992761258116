import {
  FETCH_ECH_BEGIN,
  FETCH_ECH_SUCCESS,
  FETCH_ECH_FAILURE,
  FETCH_ECH_BY_NDOSSIER,
  FETCH_ECH_BY_AMENDMENT,
  CHANGE_AMENDMENT_VALUE
} from '../actions/echeancesActions';
const initialState = {
  items: [],
  itemNdossier: {},
  item: {},
  amendment: 1,
  loading: false,
  error: null,
}

// Use the initialState as a default value
export default function echeancesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ECH_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_ECH_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.items
      }
    }
    case FETCH_ECH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_ECH_BY_NDOSSIER: {
      const { ndossier } = action.payload;
      const item = state.items
        .filter(echeance => echeance.ndossier === ndossier)
        .sort((a, b) => b.amendment - a.amendment)[0]
      return {
        ...state,
        itemNdossier: item ? item : {},
      };
    }
    case FETCH_ECH_BY_AMENDMENT: {
      const { ndossier, amendment } = action.payload;
      const item = state.items.find(echeance => echeance.ndossier == ndossier && echeance.amendment == amendment);
      return {
        ...state,
        item: item ? item : {},
      };
    }
    case CHANGE_AMENDMENT_VALUE: {
      return {
        ...state,
        amendment: action.payload.amendment
      };
    }
    default:
      return state
  }
}