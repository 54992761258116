import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import PopperLevels from "./PopperLevels";
import ZoomInSharpIcon from "@material-ui/icons/ZoomInSharp";
import { v4 as uuidv4 } from "uuid";

class ObservationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkType(type) {
    if (type === "fort") return "red";
    if (type === "faible") return "green";
    else return "gray";
  }

  render() {
    const { observationsByFloor, employmentLawArray } = this.props;
    const column = [
      {
        title: "Date d'observation",
        key: "date_obs",
        dataKey: "date_obs",
        width: 150,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <input
            type="date"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeObs(event, rowIndex)}
            name="date_obs"
            value={rowData.date_obs}
          />
        ),
      },
      {
        title: "Titre",
        key: "titre",
        dataKey: "titre",
        width: 140,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
              fontFamily: "Tahoma",
            }}
            name="titre"
          >
            {rowData.title}
          </p>
        ),
      },
      {
        title: "N° De Figure",
        key: "num_figure",
        dataKey: "num_figure",
        width: 70,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
              fontFamily: "Tahoma",
            }}
            name="titre"
          >
            {rowData.nfigure.join(", ")}
          </p>
        ),
      },
      {
        title: "Observation",
        key: "observation",
        dataKey: "observation",
        width: 300,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            style={{ width: "100%", height: "90%" }}
            name="observation"
            onChange={(event) => this.props.handleChangeObs(event, rowIndex)}
            value={rowData.observation}
          ></textarea>
        ),
      },
      {
        title: "Article du code du travail",
        key: "code_travail",
        dataKey: "code_travail",
        width: 170,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          let selectedItemIndex = -1;
          return employmentLawArray[this.props.floor]?.[rowIndex]?.length >
            1 ? (
            <>
              <select
                name="code_travail"
                key={uuidv4()}
                style={{
                  width: "100%",
                  pointerEvents: "visible",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                value={rowData.code_travail}
                onChange={(event) => {
                  let selectedItem = employmentLawArray[this.props.floor][
                    rowIndex
                  ].find((item) => item.name === event.target.value);
                  let title = selectedItem ? selectedItem.title : "";
                  this.props.handleChangeObs(event, rowIndex, title);
                }}
              >
                {employmentLawArray[this.props.floor][rowIndex].map(
                  (item, index) => {
                    if (rowData.code_travail === item.name) {
                      selectedItemIndex = index;
                    }
                    return (
                      <option value={item.name} key={uuidv4()}>
                        {rowData.code_travail === item.name
                          ? item.name
                          : item.title}
                      </option>
                    );
                  }
                )}
              </select>
              <ZoomInSharpIcon
                cursor="pointer"
                onClick={() => {
                  let name =
                    employmentLawArray[this.props.floor][rowIndex][selectedItemIndex].name;
                  let detail =
                    employmentLawArray[this.props.floor][rowIndex][selectedItemIndex]
                      .detail;
                  this.props.handleClickOpenDetail(name, detail);
                }}
              />
            </>
          ) : (
            <input
              name="code_travail"
              style={{
                width: "100%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "14px",
              }}
              value={rowData.code_travail}
              onChange={(event) =>
                this.props.handleChangeObs(event, rowIndex, "")
              }
            />
          );
        },
      },
      {
        title: "Importés",
        key: "importes",
        dataKey: "importes",
        width: 80,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="imported"
                onChange={
                  rowData.imported !== ""
                    ? () => {
                      this.props.delLevel(rowIndex, true);
                    }
                    : (event) => {
                      this.props.showLevels(event, rowIndex);
                    }
                }
                style={{
                  color: this.checkType(rowData.imported),
                }}
                checked={rowData.imported !== ""}
              />
            </div>
          );
        },
      },
      {
        title: "Exportés",
        key: "exportes",
        dataKey: "exportes",
        width: 80,
        name: "exported",
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="exported"
                onChange={
                  rowData.exported !== ""
                    ? () => {
                      this.props.delLevel(rowIndex, false);
                    }
                    : (event) => {
                      this.props.showLevels(event, rowIndex);
                    }
                }
                style={{
                  color: this.checkType(rowData.exported),
                }}
                checked={rowData.exported !== ""}
              />
            </div>
          );
        },
      },
      {
        title: "Fait le",
        key: "done_date_obs",
        dataKey: "done_date_obs",
        width: 150,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <input
            type="date"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            name="done_date_obs"
            onChange={(event) => this.props.handleChangeObs(event, rowIndex)}
            value={rowData.done_date_obs}
          />
        ),
      },
      {
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => this.props.handleClickOpenObs(rowIndex)}
            style={{ backgroundColor: "transparent" }}
            className="delete"
          >
            <Delete />
          </IconButton>
        ),
      },
    ];

    return (
      <div className="obsTable">
        <BaseTable
          columns={column}
          width={1190}
          height={(observationsByFloor.length + 1) * 50}
          data={observationsByFloor}
        />
        <PopperLevels
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          hidLevels={this.props.hidLevels}
          levelsWrapper={this.props.levelsWrapper}
          handleLevelClick={this.props.handleLevelClick}
          index={this.props.index}
        />
      </div>
    );
  }
}
export default ObservationTable;
