import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class PeinturePlus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            iterator: 0
        }
    }
    render() {
        const column = [
            {
                key: 'title1',
                dataKey: 'title1',
                width: 300,
                flexGrow: 1,
                flexShrink: 0,
            },
            {
                key: 'value1',
                dataKey: 'value1',
                width: 300,
                align: Column.Alignment.CENTER,
                flexGrow: 2,
                cellRenderer: ({ rowData, rowIndex }) => {
                    if (rowIndex !== 0) return (
                        <div>
                            <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].answer != 1 ? false : true} />
                            <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].answer != 0 ? false : true} />
                            <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].observation} />
                        </div>
                    )
                },
            }, {
                key: 'title2',
                dataKey: 'title2',
                width: 300,
                flexGrow: 3,
            }, {
                key: 'value2',
                dataKey: 'value2',
                width: 300,
                flexGrow: 4,
                align: Column.Alignment.CENTER,
                cellRenderer: ({ rowIndex }) => {
                    return (<div>
                        <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex + 5)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 5].answer != 1 ? false : true} />
                        <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex + 5)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 5].answer != 0 ? false : true} />
                        <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex + 5)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 5].observation} />
                    </div>)
                }
            }
        ]
        const data = [
            {
                id: 1,
                title1: "Travail en Hauteur : Mise en place / Utilisation",
                title2: "PIR (Plates –formes-individuelles) ",
            },
            {
                id: 2,
                title1: "A mettre en place",
                title2: "Nacelles à ciseaux / Bras télescopique ",
            },
            {
                id: 3,
                title1: 'Collectifs existants',
                title2: 'Echafaudages',
            },
            {
                id: 4,
                title1: 'Propre à l’entreprise',
                title2: 'Plates-formes suspendues ',
            },
            {
                id: 5,
                title1: 'Les Echelles, escabeaux, ',
                title2: 'Moyens de levage mécanique ',
            },
        ];

        var spanIndex = 2
        column[0].colSpan = ({ rowData, rowIndex }) => 2
        column[0].align = Column.Alignment.CENTER
        column[2].colSpan = ({ rowData, rowIndex }) => 2
        column[2].align = Column.Alignment.CENTER

        const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
            const span = columns[spanIndex].colSpan({ rowData, rowIndex })
            if (rowIndex === 0) {
                let width = cells[0].props.style.width
                for (let i = 1; i < 2; i++) {
                    width += cells[0 + i].props.style.width
                    cells[0 + i] = null
                }
                const style = {
                    ...cells[0].props.style,
                    width,
                    color: "rgb(204, 102, 0)",
                    fontStyle: "italic",
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    border: "1px solid black"
                }
                cells[0] = React.cloneElement(cells[0], { style })
            }
            return cells
        }

        return (
            <div className="testTable">
                <BaseTable rowKey="id" columns={column} width={1200} height={250} data={data} headerHeight={0} rowRenderer={rowRenderer} />
            </div>
        )
    }
}
export default PeinturePlus