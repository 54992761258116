import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { getLot } from '../Utils';
import { fetchVIC } from "../../../redux/actions/vicActions"

class ConvDialog extends React.Component {
  constructor(props){
    super(props)
    this.state={
    }
  }

  async addGrueConv(){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
        body: JSON.stringify({ndossier:this.props.dossierValue, lotValue:this.props.lotValue, identreprise:this.props.entreprise.id,
            typeConv:"grue"})
    }; 
     fetch('/api/vic/addConv',requestOptions)
    .catch((err) => {console.log(err)})
    await this.props.dispatch(fetchVIC(this.props.dossierValue))
    this.props.setOpen(false)
  }
  async addEchafaudageConv(){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
        body: JSON.stringify({ndossier:this.props.dossierValue, lotValue:this.props.lotValue, identreprise:this.props.entreprise.id,
            typeConv:"echafaudage"})
    }; 
     fetch('/api/vic/addConv',requestOptions)
    .catch((err) => {console.log(err)})
    await this.props.dispatch(fetchVIC(this.props.dossierValue))
    this.props.setOpen(false)
  }
  async addGOConv(){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
        body: JSON.stringify({ndossier:this.props.dossierValue, lotValue:this.props.lotValue, identreprise:this.props.entreprise.id,
            typeConv:"go"})
    }; 
     fetch('/api/vic/addConv',requestOptions)
    .catch((err) => {console.log(err)})
    await this.props.dispatch(fetchVIC(this.props.dossierValue))
    this.props.setOpen(false)
  }
  async suppConv(){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
        body: JSON.stringify({ndossier:this.props.dossierValue, lotValue:this.props.lotValue, identreprise:this.props.entreprise.id,
            typeConv:""})
    }; 
     fetch('/api/vic/addConv',requestOptions)
    .catch((err) => {console.log(err)})
    await this.props.dispatch(fetchVIC(this.props.dossierValue))
    this.props.setOpen(false)
  }

    render() {   
      return (
        <div className='convDialog'>
        <Dialog open={this.props.isOpen} onClose={() => this.props.setOpen(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Ajout d'une convention</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Quel type de convention voulez-vous ajouter ou modifier à la VIC du lot  {getLot(this.props.lots,this.props.lotValue).nom} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.addGrueConv()} color="primary" variant='contained' style={{backgroundColor:"rgba(255, 127, 80, 0.73)",fontSize:"12px"}}>
              Convention Grue
            </Button>
            <Button onClick={() => this.addEchafaudageConv()} color="primary" variant='contained' style={{backgroundColor:"rgba(255, 127, 80, 0.73)",fontSize:"12px"}}>
              Convention Echafaudage
            </Button>
            <Button onClick={() => this.addGOConv()} color="primary" variant='contained' style={{backgroundColor:"rgba(255, 127, 80, 0.73)",fontSize:"12px"}}>
              Convention Gros Oeuvre
            </Button>
            <Button onClick={() => this.suppConv()} color="primary" variant='contained' style={{backgroundColor:"rgba(255, 127, 80, 0.73)",fontSize:"12px"}}>
              Supprimer la convention
            </Button>
            <Button onClick={() => this.props.setOpen(false)} color="primary" variant='contained' style={{backgroundColor:"rgba(255, 127, 80, 0.73)",fontSize:"12px"}}>
              Annuler
            </Button>
          </DialogActions>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
	dossierValue: state.devis.dossierValue,
	dossier: state.dossier.items,
	lotValue : state.vic.lotValue,
	lots : state.vic.lots,
	vic : state.vic.items,
	contact: state.contact.items,
	// entreprise: state.entreprise.items,
	entrepriseValue: state.entreprise.entrepriseValue,
  });
export default connect(mapStateToProps)(ConvDialog)