import { FETCH_ENTREPRISE_BEGIN,
    FETCH_ENTREPRISE_SUCCESS,
     FETCH_ENTREPRISE_FAILURE,
     CHANGE_ENTREPRISE_VALUE
     } from '../actions/entrepriseActions';
 const initialState = {
     items:[],
     entrepriseValue:"",
     loading:false,
     error:null,
   }
   
   // Use the initialState as a default value
   export default function entrepriseReducer(state = initialState, action) {
     switch (action.type) {
       case FETCH_ENTREPRISE_BEGIN: {
         return {
           ...state,
           loading:true,
           error:null  
         }
       }
       case FETCH_ENTREPRISE_SUCCESS:{
           return {
               ...state,
               loading:false,
               items:action.payload.items
           }
       }
       case FETCH_ENTREPRISE_FAILURE:{
         return {
             ...state,
             loading: false,
             error: action.payload.error,
             items: []
           }; 
       }
       case CHANGE_ENTREPRISE_VALUE:{
        return {
            ...state,
            entrepriseValue: action.payload.entrepriseValue
          }; 
      }
       default:
         return state
     }
   }