import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import ReactTable from "react-table-6";
import { Slider } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import { numberToFormat } from "../../Utils";

class FactureConDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { puHt, quantity, amountHt, totalht, tva, disableButton } = this.props;
    const data = [
      {
        actType: (
          <div className="titleDevisCon2">
            1. Stade Avant-Projet/Permis de construire:
          </div>
        ),
        heures: <div className="titleDevisCon2"></div>,
        ht: "",
      },
      {
        actType: "1.1. Ouverture du Registre-Journal de la coordination",
        heures: "",
        ht: "",
      },
      {
        actType: "1.2. Visite du site",
        heures: "",
        ht: "",
      },
      {
        actType: "1.3. Assistance Déclaration Préalable",
        heures: "",
        ht: "",
      },
      {
        actType: "1.4. Examen dossier conception/Analyse de risques",
        heures: "",
        ht: "",
      },
      {
        actType: "1.5. Réunions Maîtrise de l'ouvrage/Maîtrise d'OEuvre",
        heures: "",
        ht: "",
      },
      {
        actType: <div className="titleDevisCon2">2 . Projet/Consultation:</div>,
        heures: "",
        ht: "",
      },
      {
        actType: "2.1. Réunions Maîtrise de l'ouvrage/Maîtrise d'OEuvre",
        heures: "",
        ht: "",
      },
      {
        actType: "2.2. Elaboration du P.G.C",
        heures: "",
        ht: "",
      },
      {
        actType: "2.3. Elaboration pré D.I.U.O & du D.I.U.O",
        heures: "",
        ht: "",
      },
      {
        actType: "2.4. Elaboration du règlement de C.I.S.S.C.T",
        heures: "",
        ht: "",
      },
      {
        actType: (
          <div className="titleDevisCon2">TOTAL HT :</div>
        ),
        heures: "",
        ht: "",
      },
    ];
    const columns = [
      {
        Header: "PHASE CONCEPTION",

        columns: [
          {
            Header: "ACTES TYPES",
            accessor: "actType",
          },
          {
            Header: "QUANTITE",
            accessor: "quantite",
            width: 120,
            Cell: (props) => {
              if (
                props.row._index === 0 ||
                props.row._index === 6 ||
                props.row._index === 11
              )
                return <p>{""}</p>;
              else
                return (
                  <input
                    style={{
                      border: "none",
                      height: "100%",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                    value={quantity[this.props.checkIndex(props.row._index)]}
                    name={"quantity"}
                    onChange={(event) =>
                      this.props.handleChange(event.target.value, props.row._index)
                    }
                  />
                );
            },
          },
          {
            Header: "PU HT",
            accessor: "puht",
            width: 70,
            Cell: (props) => {
              if (
                props.row._index === 0 ||
                props.row._index === 6 ||
                props.row._index === 11
              )
                return "";
              else
                return (
                  <span style={{ display: "block", textAlign: "center" }}>{puHt}</span>
                );
            },
          },
          {
            Header: "Montant HT",
            accessor: "ht",
            width: 120,
            Cell: (props) => {
              if (
                props.row._index === 11
              )
                return <span style={{ display: "block", textAlign: "center" }}>{numberToFormat(totalht)}</span>
              if (
                props.row._index === 0 ||
                props.row._index === 6
              )
                return <p>{""}</p>;
              else
                return (
                  <span style={{ display: "block", textAlign: "center" }}>{numberToFormat(amountHt[this.props.checkIndex(props.row._index)])}</span>
                );
            },
          },
        ],
      },
    ];
    return (
      <div className="facture-condetail">
        <ReactTable data={data} columns={columns} defaultPageSize={12} />
        <div className='tva'>
          <label>TVA : </label>
          <Slider value={tva} style={{ marginLeft: "7px" }} step={0.5} min={1} max={35} onChange={(_, value) => this.props.handleChangeTva(value)} />
          <input value={tva} style={{ width: "25px", height: "15px", margin: "0 5px 0 7px" }} onChange={(event) => this.props.handleChangeTva(event.target.value !== "" ? parseFloat(event.target.value) : event.target.value)} /> %
        </div>
        <div className="group-btn">
          {disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
          <Button className="gen-btn" disabled={disableButton} variant="contained" color="primary" onClick={this.props.handleSaveFactureClick}>
            Enregistrer
          </Button>
          <Button className="gen-btn" disabled={disableButton} variant="contained" color="primary" onClick={this.props.handleGenClick}>
            Générer PDF
          </Button>
          <Button className="gen-btn" disabled={disableButton} variant="contained" color="primary" onClick={this.props.handleShowPdf}>
            Afficher PDF
          </Button>
          <Button className="gen-btn" disabled={disableButton} variant="contained" color="primary" onClick={this.props.handleResetQantity}>
            Annuler
          </Button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  facture: state.facture.items,
  nfacvalue: state.facture.nfacvalue,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  itemNdossier: state.echeances.itemNdossier,
  deviscon: state.devis.deviscon,
  contact: state.contact.items
});
export default connect(mapStateToProps)(FactureConDetail);