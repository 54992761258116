import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import axios from "axios";


class DialogModifPGC extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      datepgce: "",
    }
    this.handleCloseProcess = this.handleCloseProcess.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }



  handleSubmitvvv(event) {
    axios.post("/idee", { datepgce: this.state.datepgce })
      .then((res) => {
        console.log(res.data.rep);
      })
    //generer pdf function
    //event.preventDefault();
  }


  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleCloseProcess() {
    this.setState({ isOpen: false })
    this.props.closeDialog()
  }
  handleOpen() {
    this.setState({ isOpen: true })
  }


  handleModif(event) {
    axios.put('http://localhost:3000/idee').then(response => {
      response.json();
    })

      .then(err => {
        console.log(err);
      });
    this.props.showNextDialog(1)
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseProcess} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modification Entreprise Représentante</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez modifier le nom de l'entreprise représentante du maître d'ouvrage
              pour le dossier N°{this.props.dossierValue}.
            </DialogContentText>

            <div className="datepgce">
              <label>Date d'envoie du PGC : </label>
              <input name="datepgce"  type ="date" onChange={this.handleChange} />
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.showNextDialog(1)} color="primary">
              Ne pas enregistrer
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleSubmitvvv.bind(this)}>Enregistrer</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items
});
export default connect(mapStateToProps)(DialogModifPGC)