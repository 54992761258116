import {
  FETCH_CONTRACT_FAC_AMENDMENT_BEGIN,
  FETCH_CONTRACT_FAC_AMENDMENT_SUCCESS,
  FETCH_CONTRACT_FAC_AMENDMENT_FAILURE,
  CHANGE_CONTRACT_FAC_AMENDMENT_NUM_VALUE,
  FETCH_FAC_AMENDMENT_BEGIN,
  FETCH_FAC_AMENDMENT_SUCCESS,
  FETCH_FAC_AMENDMENT_FAILURE,
  CHANGE_FAC_AMENDMENT_NUM_VALUE,
  FETCH_FAC_AMENDMENT_BY_DOSS_BEGIN,
  FETCH_FAC_AMENDMENT_BY_DOSS_SUCCESS,
  FETCH_FAC_AMENDMENT_BY_DOSS_FAILURE,
  FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_BEGIN,
  FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_SUCCESS,
  FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_FAILURE,
} from '../actions/facAmendmentActions';

const initialState = {
  contractFacAmendments: [],
  facAmendments: [],
  facAmendmentsByDoss: [],
  facAmendmentsRecapByDoss: [],
  contractFacAmendmentIndex: 0,
  facAmendmentIndex: 1,
  loading: false,
  error: null,
}

export default function facAmendmentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTRACT_FAC_AMENDMENT_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_CONTRACT_FAC_AMENDMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        contractFacAmendments: action.payload.items
      }
    }
    case FETCH_CONTRACT_FAC_AMENDMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        contractFacAmendments: []
      };
    }
    case CHANGE_CONTRACT_FAC_AMENDMENT_NUM_VALUE: {
      return {
        ...state,
        contractFacAmendmentIndex: action.payload.newValue,
      };
    }
    case FETCH_FAC_AMENDMENT_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_FAC_AMENDMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        facAmendments: action.payload.items
      }
    }
    case FETCH_FAC_AMENDMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        facAmendments: []
      };
    }
    case CHANGE_FAC_AMENDMENT_NUM_VALUE: {
      return {
        ...state,
        facAmendmentIndex: action.payload.newValue,
      };
    }
    case FETCH_FAC_AMENDMENT_BY_DOSS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_FAC_AMENDMENT_BY_DOSS_SUCCESS: {
      return {
        ...state,
        loading: false,
        facAmendmentsByDoss: action.payload.items
      }
    }
    case FETCH_FAC_AMENDMENT_BY_DOSS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        facAmendments: []
      };
    }
    case FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_SUCCESS: {
      return {
        ...state,
        loading: false,
        facAmendmentsRecapByDoss: action.payload.items
      }
    }
    case FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        facAmendmentsRecapByDoss: []
      };
    }
    default:
      return state
  }
}