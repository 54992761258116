import { FETCH_CONTRAT_BEGIN,
  FETCH_CONTRAT_SUCCESS,
   FETCH_CONTRAT_FAILURE,
   } from '../actions/contratActions';
const initialState = {
   items:{},
   loading:false,
   error:null,
 }
 
 // Use the initialState as a default value
 export default function contratReducer(state = initialState, action) {
   switch (action.type) {
     case FETCH_CONTRAT_BEGIN: {
       return {
         ...state,
         loading:true,
         error:null  
       }
     }
     case FETCH_CONTRAT_SUCCESS:{
         return {
             ...state,
             loading:false,
             items:action.payload.items
         }
     }
     case FETCH_CONTRAT_FAILURE:{
       return {
           ...state,
           loading: false,
           error: action.payload.error,
           items: []
         }; 
     }
     default:
       return state
   }
 }