import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmRelance extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        return(
            <Dialog
            open={this.props.open}
            onClose={() => this.props.setOpen(false)}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">{"Confirmation envoi de la relance"}</DialogTitle>
            <DialogContent>{"Comment voulez-vous envoyer votre relance ?"}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                    this.props.onAutoConfirm();
                }}
                color="secondary"
              >
                Envoyer un message Pré-Fait
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                    this.props.onManConfirm();
                }}
                color="default"
              >
                Créer votre message
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                }}
                color="default"
              >
                Annuler
              </Button>
            </DialogActions>
          </Dialog>
        )
    }
}
export default ConfirmRelance;