import React from "react";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';

class InfosDevis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operation: "",
      ouvrage: "",
      coutTrav: "",
      dureeTrav: "4",
      catOp: "",
      nbrLots: "",
      haut: "",
      compArchi: "Faible",
      compEnv: "Faible",
      compTech: "Faible",
      rep: "Faible",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Permet le changement de la valeur de certains champs
   * @param {*} event
   */
  handleChange(event) {
    const regex = new RegExp("[^0-9.]");
    if (regex.test(event.target.value)) {
      if (
        event.target.name === "coutTrav" ||
        event.target.name === "nbrLots" ||
        event.target.name === "haut"
      ) {
        event.target.value = "";
        document.getElementsByName(event.target.name)[0].style.border =
          "solid red 1px";
      } else {
        this.props.changeCalc(event.target.name, event.target.value);
      }
    } else {
      document.getElementsByName(event.target.name)[0].style.border =
        "solid green 1px";
      this.props.changeCalc(event.target.name, +event.target.value);
    }
  }
  render() {
    return (
      <div className="infosDevis" style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer style={{ width: "500px" }} component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className="subtitle" colSpan={2}>
                  Données
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Operation
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <Typography variant="body1" style={{ marginRight: "13px", textAlign: "center" }}>
                   {this.props.dataCalc.operation}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Catégorie de l'opération
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <Typography variant="body1" style={{ marginRight: "33%" }}>
                   {this.props.dataCalc.catOp}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Type d'ouvrage
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "10px" }}
                    name="ouvrage"
                    onChange={this.handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Coût de travaux (en Millions €)
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <Typography variant="body1" style={{ marginRight: "33%" }}>
                    {this.props.dataCalc.coutTrav}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Durée des travaux
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <Typography variant="body1" style={{ marginRight: "33%" }}>
                    {this.props.dataCalc.dureeTrav}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Nombre de lots (entreprises)
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="number"
                    style={{ marginRight: "10px" }}
                    name="nbrLots"
                    onChange={this.handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Hauteur du plancher bas du dernier niveau
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="number"
                    style={{ marginRight: "10px" }}
                    name="haut"
                    onChange={this.handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Complexité architecturale
                </TableCell>
                <TableCell align="right">
                    <select
                    className="items-select"
                    name="compArchi"
                    onChange={this.handleChange}
                    >
                        <option value="Faible">Faible</option>
                        <option value="Moyenne">Moyenne</option>
                        <option value="Forte">Forte</option>
                    </select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Complexité technique
                </TableCell>
                <TableCell align="right">
                    <select
                    className="items-select"
                    name="compTech"
                    onChange={this.handleChange}
                    >
                      <option value="Faible">Faible</option>
                      <option value="Moyenne">Moyenne</option>
                      <option value="Forte">Forte</option>
                    </select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Répétitivité de l'opération
                </TableCell>
                <TableCell align="right">
                    <select
                    className="items-select"
                    name="rep"
                    onChange={this.handleChange}
                    >
                      <option value="Faible">Faible</option>
                      <option value="Moyenne">Moyenne</option>
                      <option value="Forte">Forte</option>
                    </select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Complexité environnementale du chantier
                </TableCell>
                <TableCell align="right">
                    <select
                    className="items-select"
                    name="compEnv"
                    onChange={this.handleChange}
                    >
                        <option value="Faible">Faible</option>
                        <option value="Moyenne">Moyenne</option>
                        <option value="Forte">Forte</option>
                    </select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer style={{ width: "550px", height: "377px" }} component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className="subtitle" colSpan={2}>
                  Résultats
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" style={{ width: '325px' }}>
                    Nombre d'heures en conception (mission de base)
                </TableCell>
                <TableCell align="right" style={{ padding: "0", marginRight: "13px" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "13px" }}
                    value={this.props.calculTab.heureconm}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                    Nombre d'heures en réalisation (mission de base)
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "13px" }}
                    inputProps={{ min: "0", step: "0.5" }}
                    value={this.props.calculTab.heureream}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                    Coéfficient de complexité
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "13px" }}
                    inputProps={{ min: "0", step: "0.5" }}
                    value={this.props.calculTab.coefcom}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                    Nombre d'heures en conception
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "13px" }}
                    inputProps={{ min: "0", step: "0.5" }}
                    value={this.props.calculTab.heurecon}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                    Nombre d'heures en réalisation
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "13px" }}
                    inputProps={{ min: "0", step: "0.5" }}
                    value={this.props.calculTab.heurerea}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                    Nombre d'heures Total
                </TableCell>
                <TableCell align="right" style={{ padding: "0" }}>
                  <TextField
                    type="string"
                    style={{ marginRight: "13px" }}
                    inputProps={{ min: "0", step: "0.5" }}
                    value={this.props.calculTab.heureTotal}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
export default InfosDevis;
