import React from 'react'
import { faCommentDots, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux'
import NotifList from './NotifList';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import { getLocation } from '../../Utils';
import history from '../../Routes/history';

class Notif extends React.Component {
    constructor(props) {
        super(props)
        this.notifWrapper = React.createRef()
        this.changeNotifState = this.changeNotifState.bind(this);

    }
    MoveToNotif = () => {
        history.push("/detail");
    }
    changeNotifState() {
        const notifwrapper = this.notifWrapper.current;
        notifwrapper.classList.toggle("is-notif-enabled");
        //history.push("/detail");
        fetch('/notifVu', { method: 'POST' })
    }


    showNotifNumber() {
        if (this.props.notifs.length === 0) {
            return "notif-not-active";
        }
        else {
            return "notif-active";
        }
    }
    // componentDidMount() {

    //     if (this.props.location != undefined) this.changeNotifState()
    //     if (this.props.location === undefined) this.changeNotifState()

    // }

    render() {
        const { notifs } = this.props

        return (
            <React.Fragment>
                <div className="notification" onClick={this.MoveToNotif}>
                    <span><FontAwesomeIcon icon={faCommentDots} /></span>
                    <span className={this.showNotifNumber()}>{notifs.length}</span>
                    <span className="downArrow" onClick={this.changeNotifState} ><FontAwesomeIcon icon={faCaretDown} /></span>
                </div>
                <NotifList notifWrapper={this.notifWrapper} />
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    notifs: state.notif.items
});
export default connect(mapStateToProps)(Notif)