import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from "react-redux";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Grid } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from "uuid";

const pages = [
  { id: 2, name: "Page 2" },
  { id: 3, name: "Page 3" },
  { id: 4, name: "Page 4" }
]

const EmploymentLawQuestionsTable = ({ allQuestions, currentPage, idCatQuestion, resetIdCarQuestion, handleAddQuestion, handleUpdateQuestion, updateQuestionsLot, handleCloseComponent }) => {
  const [page, setPage] = useState("");
  const [catQuestion, setCatQuestion] = useState("");
  const [questionsArray, setQuestionsArray] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setPage(currentPage);
    if (catQuestion.length === 0) {
      setQuestionsArray([])
    }
  }, [catQuestion]);

  useEffect(() => {
    if (idCatQuestion) {
      const index = allQuestions.findIndex((question) => question.id === idCatQuestion);
      setCatQuestion(allQuestions[index].cat_question);
      setQuestionsArray(allQuestions[index].questions);
    }
  }, [idCatQuestion]);

  const handleChangePage = (event) => {
    setPage(event.target.value);
  };

  const handleAddCatQuestion = () => {
    if (inputValue.trim() !== '') {
      setQuestionsArray(prevQuestions => [
        ...prevQuestions,
        { question: inputValue, answer: false, observation: false }
      ]);
      setInputValue('');
    }
  };

  const handleDeleteQuestion = (indexToDelete) => {
    setQuestionsArray(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(indexToDelete, 1);
      return updatedQuestions;
    });
  };

  const addNewQuestion = async () => {
    await updateQuestionsLot(page, idCatQuestion, catQuestion, questionsArray)
    await handleAddQuestion({ page, catQuestion, questionsArray })
    resetState();
  }

  const updateQuestion = async () => {
    await updateQuestionsLot(page, idCatQuestion, catQuestion, questionsArray)
    await handleUpdateQuestion({ catQuestion, questionsArray })
    resetState();
  }

  const resetState = () => {
    setPage("");
    setCatQuestion("");
    setQuestionsArray([]);
    resetIdCarQuestion();
    handleCloseComponent();
  };

  return (
    <div className="display-questions-lot">
      <Grid container justifyContent="center">
        <Grid>
          <Card>
            <CardContent>
              {idCatQuestion === null ? (
                <h2>Ajouter une question</h2>
              ) : (
                <h2>Modifier une question</h2>
              )}
              <form>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Page :</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category"
                    name="page"
                    value={page}
                    onChange={handleChangePage}
                  >
                    <MenuItem value="" />
                    {pages.map((item) => {
                      return (
                        <MenuItem key={uuidv4()} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* {idCatQuestion === null && ( */}
                {page !== "" && (
                  <>
                    <TextField
                      fullWidth
                      label="Titre"
                      id="catQuestion"
                      name="catQuestion"
                      value={catQuestion}
                      onChange={(e) => setCatQuestion(e.target.value)}
                    />
                    {/* {idCatQuestion === null && (<TextField */}
                    {catQuestion && (<TextField
                      fullWidth
                      label="Question"
                      id="titleQuestion"
                      name="titleQuestion"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddCatQuestion();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="add"
                            size="small"
                            style={{ backgroundColor: 'transparent' }}
                            onClick={handleAddCatQuestion}
                          >
                            <Add />
                          </IconButton>
                        )
                      }}
                    />
                    )}
                  </>)}


                {(page && catQuestion) && (
                  <div style={{ marginBottom: '1em' }}>
                    <h4 style={{ fontWeight: 'bold', marginBottom: '0.5em' }}>{catQuestion}</h4>
                    {questionsArray.length > 0 && (
                      questionsArray.map((item, index) => {
                        return (
                          <div key={uuidv4()} style={{ display: 'flex', alignItems: 'center', marginLeft: '1em' }}>
                            <span style={{ fontWeight: 'normal', marginRight: '0.5em', flex: '1' }}>- {item.question}</span>
                            <IconButton onClick={() => handleDeleteQuestion(index)} size="small">
                              <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1rem', color: 'red' }} />
                            </IconButton>
                          </div>
                        );
                      })
                    )}
                  </div>
                )}


                <div className="buttons">
                  {idCatQuestion === null ? (
                    <Button
                      className="ct-button"
                      variant="contained"
                      color="primary"
                      onClick={addNewQuestion}
                    >
                      Ajouter
                    </Button>
                  ) : (
                    <Button
                      className="ct-button"
                      variant="contained"
                      color="primary"
                      onClick={updateQuestion}
                    >
                      Modifier
                    </Button>
                  )}
                  <Button
                    className="ct-button"
                    variant="contained"
                    color="primary"
                    onClick={resetState}
                  >
                    Annuler
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(EmploymentLawQuestionsTable);
