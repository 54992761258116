export const FETCH_FACTURE_BEGIN = 'FETCH_FACTURE_BEGIN';
export const FETCH_FACTURE_SUCCESS = 'FETCH_FACTURE_SUCCESS';
export const FETCH_FACTURE_FAILURE = 'FETCH_FACTURE_FAILURE';
export const FETCH_ALL_FACTURE_BEGIN = 'FETCH_ALL_FACTURE_BEGIN';
export const FETCH_ALL_FACTURE_SUCCESS = 'FETCH_ALL_FACTURE_SUCCESS';
export const FETCH_ALL_FACTURE_FAILURE = 'FETCH_ALL_FACTURE_FAILURE';
export const CHANGE_NFAC_VALUE = 'CHANGE_NFAC_VALUE';
export const CHANGE_FACINDEX_VALUE = 'CHANGE_FACINDEX_VALUE';
export const FETCH_FACBYDOSS_BEGIN = 'FETCH_FACBYDOSS_BEGIN';
export const FETCH_FACBYDOSS_SUCCESS = 'FETCH_FACBYDOSS_SUCCESS';
export const FETCH_FACBYDOSS_FAILURE = 'FETCH_FACBYDOSS_FAILURE';

export function fetchFac(type) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({})
  };
  return dispatch => {
    dispatch(fetchFacBegin());
    return fetch("/api/facture/" + type, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchFacSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchFacFailure(error)));
  };
}
export function fetchAllFac() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({})
  };
  return dispatch => {
    dispatch(fetchAllFacBegin());
    return fetch("/api/facture/allfacture", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        const filteredFactures = json.sort((a, b) => a.nfacture.localeCompare(b.nfacture))
        dispatch(fetchAllFacSuccess(filteredFactures));
        return filteredFactures
      })
      .catch(error => dispatch(fetchAllFacFailure(error)));
  };
}

export function fetchFacByDoss(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({})
  };
  return dispatch => {
    dispatch(fetchFacByDossBegin());
    return fetch("/api/facture/allfacture", requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        const filteredFactures = json.filter(facture => facture.ndossier == ndossier);
        dispatch(fetchFacByDossSuccess(filteredFactures));
        return filteredFactures;
      })
      .catch(error => dispatch(fetchFacByDossFailure(error)));
  };
}

export function fetchFacNoType() {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
  //   body: JSON.stringify({})
  //   }; 
  //   return dispatch => {
  //     dispatch(fetchFacBegin());
  //     return fetch("/api/facture/realisation",requestOptions)
  //       .then(handleErrors)
  //       .then(res => res.json())
  //       .then(json => {
  //         dispatch(fetchFacSuccess(json));
  //         return json;
  //       })
  //       // .then(facture =>{
  //       //   operationsTab(facture)
  //       //   sortNfac(facture)
  //       // })
  //       .catch(error => dispatch(fetchFacFailure(error)));
  //   };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
export function newNfacValue(newValue) {
  return dispatch => dispatch(changeNfacValue(newValue))
}

export const fetchFacBegin = () => ({
  type: FETCH_FACTURE_BEGIN
});

export const fetchFacSuccess = facture => ({
  type: FETCH_FACTURE_SUCCESS,
  payload: { facture: facture, nfacvalue: facture[0].new_nfac != "" ? facture[0].new_nfac : facture[0].old_nfac }
});

export const fetchFacFailure = error => ({
  type: FETCH_FACTURE_FAILURE,
  payload: { error }
});
export const fetchAllFacBegin = () => ({
  type: FETCH_ALL_FACTURE_BEGIN
});

export const fetchAllFacSuccess = allfacture => ({
  type: FETCH_ALL_FACTURE_SUCCESS,
  payload: { allfacture }
});

export const fetchAllFacFailure = error => ({
  type: FETCH_ALL_FACTURE_FAILURE,
  payload: { error }
});


export const changeNfacValue = nfacvalue => ({
  type: CHANGE_NFAC_VALUE,
  payload: { nfacvalue }
});

export const changeFacIndexValue = index => ({
  type: CHANGE_FACINDEX_VALUE,
  payload: { index }
});

export const fetchFacByDossBegin = () => ({
  type: FETCH_FACBYDOSS_BEGIN
});

export const fetchFacByDossSuccess = facs => ({
  type: FETCH_FACBYDOSS_SUCCESS,
  payload: { facturesbydoss: facs }
});

export const fetchFacByDossFailure = error => ({
  type: FETCH_FACBYDOSS_FAILURE,
  payload: { error }
});