import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "../../../Styles/Heures/Htp.scss";

class HtpCategory2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { conHourCat2, reaHourCat2, totalConHourCat2, totalReaHourCat2 } =
      this.props;
    return (
      <div className="htp-table">
        <div className="htp-subtable">
          <h2>Conception</h2>
          <TableContainer style={{ width: "500px" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="table-head">
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">Tâches</TableCell>
                  <TableCell className="MuiTableCell-head">HTP /h</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Visite préalable du site
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat2[0] !== 0 ? conHourCat2[0] : ""}
                      onChange={(e) => this.props.handleChange(e, 0)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Ouverture du RJ, transmission au maître d'ouvrage et au
                    maître d'œuvre
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat2[1] !== 0 ? conHourCat2[1] : ""}
                      onChange={(e) => this.props.handleChange(e, 1)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Réunion préliminaire avec le maître d'ouvrage et le maître
                    d'œuvre :<br />- Avis sur les choix architecturaux en
                    matière de SPS
                    <br />- Analyse des diagnotics fournis par le maître
                    d'ouvrage
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat2[2] !== 0 ? conHourCat2[2] : ""}
                      onChange={(e) => this.props.handleChange(e, 2)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Ouverture du DIUO, transmission au maître d'ourvage et au
                    maître d'œuvre:
                    <br />- Contrôle des pièces du DCE en matière de SPS
                    <br />- Avis en matière de SPS
                    <br />- Analyse des éventuelles corrections
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat2[3] !== 0 ? conHourCat2[3] : ""}
                      onChange={(e) => this.props.handleChange(e, 3)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Rédaction du PGC SPS, transmission au maître d'ouvrage et au
                    maître d'œuvre
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat2[4] !== 0 ? conHourCat2[4] : ""}
                      onChange={(e) => this.props.handleChange(e, 4)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Réunion pour les coactivités et autres mises au point
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat2[5] !== 0 ? conHourCat2[5] : ""}
                      onChange={(e) => this.props.handleChange(e, 5)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">TOTAL</TableCell>
                  <TableCell className="MuiTableCell-head">
                    {totalConHourCat2} /h
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="htp-subtable">
          <h2>Réalisation</h2>
          <TableContainer style={{ width: "500px" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="table-head">
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">Tâches</TableCell>
                  <TableCell className="MuiTableCell-head">HTP /h</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Inspections communes avec les entreprises adjudicataires
                    avant interventions (présentation du PGCSPS et avertissement
                    pour la création du PPSPS)
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[0] !== 0 ? reaHourCat2[0] : ""}
                      onChange={(e) => this.props.handleChange(e, 0)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Collecte et analyse des PPSPS des entreprises
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[1] !== 0 ? reaHourCat2[1] : ""}
                      onChange={(e) => this.props.handleChange(e, 1)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Mise à jour et diffusion du PGCSPS, harmonisation avec les
                    PPSPS des entreprises
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[2] !== 0 ? reaHourCat2[2] : ""}
                      onChange={(e) => this.props.handleChange(e, 2)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Mise à jour des DIUO
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[3] !== 0 ? reaHourCat2[3] : ""}
                      onChange={(e) => this.props.handleChange(e, 3)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Tenu du registre journal
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[4] !== 0 ? reaHourCat2[4] : ""}
                      onChange={(e) => this.props.handleChange(e, 4)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Participation aux réunions de chantier :<br />- Premier mois
                    de démarrage du chantier (hebdomadaire)
                    <br />- Phase chantier
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[5] !== 0 ? reaHourCat2[5] : ""}
                      onChange={(e) => this.props.handleChange(e, 5)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Visite inopinée de suivi de chantier (hebdomadaire)
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[6] !== 0 ? reaHourCat2[6] : ""}
                      onChange={(e) => this.props.handleChange(e, 6)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Participation à la visite de chantier (hebdomadaire)
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[7] !== 0 ? reaHourCat2[7] : ""}
                      onChange={(e) => this.props.handleChange(e, 7)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Compléments au DIUO
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[8] !== 0 ? reaHourCat2[8] : ""}
                      onChange={(e) => this.props.handleChange(e, 8)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Finalisation du DIUO et transmission au maître d'ouvrage
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat2"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat2[9] !== 0 ? reaHourCat2[9] : ""}
                      onChange={(e) => this.props.handleChange(e, 9)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">TOTAL</TableCell>
                  <TableCell className="MuiTableCell-head">
                    {totalReaHourCat2} /h
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="subtable-btn">
            <Button
              variant="contained"
              className="btn-valid"
              onClick={() => this.props.handleClick()}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              className="btn-reset"
              onClick={this.props.handleResetArray}
            >
              Annuler
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default HtpCategory2;
