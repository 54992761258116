import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { fetchPgcByDoss } from "../../../redux/actions/pgcActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { checkRjExists, getDossierInfos, getCoverPageData, genPgcPDF, getPgcFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPGC from "../../NumDossPGC";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import YesNoDialog from "../../YesNoDialog";
import "../../../Styles/PGC/Page1.scss";

const PgcPage1 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const pgc = useSelector((state) => state.pgc.pgcByDoss);
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dossier, setDossier] = useState({
    categorie_op: '',
    operation: '',
    adresse_op: '',
    cp_op: '',
    ville_op: '',
    sci_sccv: '',
    nomMoComplement: '',
    adressMo: '',
    cpMo: '',
    cityMo: '',
    telMo: ''
  });
  const [disableButton, setDisableButton] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogRj, setOpenDialogRj] = useState(false);
  const [rjExists, setRjExists] = useState(false);
  const [imgCoverPage, setImgCoverPage] = useState(null);
  const [logoProjectOwner, setLogoProjectOwner] = useState(null);
  const [logoProjectManager, setLogoProjectManager] = useState(null);
  const [logoOffice, setLogoOffice] = useState(null);
  const [imgTableOfContents, setImgTableOfContents] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [state, setState] = useState({
    date: new Date().toISOString().slice(0, 10),
    nameMoe: '',
    nameMoeComplement: '',
    adressMoe: '',
    cpMoe: '',
    cityMoe: '',
    telMoe: '',
    officeType: '',
    nameOffice: '',
    nameOfficeComplement: '',
    adressOffice: '',
    cpOffice: '',
    cityOffice: '',
    telOffice: ''
  });

  const setStateField = (event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const imageHandlers = {
    'cover_page.jpg': setImgCoverPage,
    'logo_project_owner.jpg': setLogoProjectOwner,
    'logo_project_manager.jpg': setLogoProjectManager,
    'logo_office.jpg': setLogoOffice,
    'img_table_of_contents.jpg': setImgTableOfContents
  };

  useEffect(() => {
    if (!dossierValue) return;

    const fetchData = async () => {
      const dossierInfos = await getDossierInfos(dossierValue);
      const updatedDossierInfos = { ...dossierInfos, nomMoComplement: '' };

      setDossier(updatedDossierInfos);
    };

    const checkRj = async () => {
      const response = await checkRjExists(dossierValue);

      if (!response) {
        setOpenDialogRj(true)
      };
      setRjExists(response);
      fetchData();
    }

    checkRj()
  }, [dossierValue]);

  useEffect(() => {
    if (!dossierValue) return;
    resetData();
    loadData();
  }, [pgcValue]);

  const loadData = async () => {
    setIsLoading(true);
    const response = await getCoverPageData(pgcValue);

    if (Object.keys(response).length > 3) {
      const constructionSite = JSON.parse(response.construction_site);
      const projectOwner = JSON.parse(response.project_owner);
      const projectManager = JSON.parse(response.project_manager);
      const office = JSON.parse(response.office);

      unstable_batchedUpdates(() => {
        setState(prevState => ({
          ...prevState,
          date: response.date,
          nameMoe: projectManager.name,
          nameMoeComplement: projectManager.complement,
          adressMoe: projectManager.adress,
          cpMoe: projectManager.cp,
          cityMoe: projectManager.city,
          telMoe: projectManager.tel,
          officeType: office.type,
          nameOffice: office.name,
          nameOfficeComplement: office.complement,
          adressOffice: office.adress,
          cpOffice: office.cp,
          cityOffice: office.city,
          telOffice: office.tel
        }))
        setDossier(prevState => ({
          ...prevState,
          nomMoComplement: projectOwner.complement,
          telMo: projectOwner.tel,
          categorie_op: constructionSite.category
        }))

        if (response.images.length > 0) {
          response.images.forEach((image) => {
            const buffer = new Uint8Array(image.img.buffer.data).buffer;
            const blob = new Blob([buffer]);
            const file = new File([blob], image.name, { type: 'image/png' });

            const handler = imageHandlers[image.name];
            if (handler) {
              handler(file);
            }
          });
        }
        setShowInput((office.type !== 'Contrôle' && office.type !== 'Etude thermique' && office.type !== '') ? true : false);
        setIsLoading(false);
      })
    } else {
      unstable_batchedUpdates(() => {
        resetData();
        setShowInput(false);
        setIsLoading(false);
      })
    }
  };

  const resetData = () => {
    setState(prevState => ({
      ...prevState,
      date: new Date().toISOString().slice(0, 10),
      nameMoe: '',
      nameMoeComplement: '',
      adressMoe: '',
      cpMoe: '',
      cityMoe: '',
      telMoe: '',
      officeType: '',
      nameOffice: '',
      nameOfficeComplement: '',
      adressOffice: '',
      cpOffice: '',
      cityOffice: '',
      telOffice: ''
    }))
    setDossier(prevState => ({
      ...prevState,
      nomMoComplement: '',
      telMo: ''
    }))
    setImgCoverPage(null);
    setLogoProjectOwner(null);
    setLogoProjectManager(null);
    setLogoOffice(null);
    setImgTableOfContents(null);
  }

  const incrementString = (str) => {
    if (!str) return 'A';
    let charCode = str.charCodeAt(str.length - 1);
    if (charCode === 90) {
      return incrementString(str.slice(0, -1)) + 'A';
    } else {
      return str.slice(0, -1) + String.fromCharCode(charCode + 1);
    }
  }

  const handleDeleteImg = useCallback((name) => {
    switch (name) {
      case "img-cover-page":
        setImgCoverPage(null);
        break;
      case "logo-project-owner":
        setLogoProjectOwner(null);
        break;
      case "logo-project-manager":
        setLogoProjectManager(null);
        break;
      case "logo-office":
        setLogoOffice(null);
        break;
      case "img-table-of-contents":
        setImgTableOfContents(null);
        break;
      default:
        break;
    }
  }, []);

  const handleCreatePgc = async (e) => {
    e.preventDefault();
    setOpenDialogCreate(false);
    setDisableButton(true);

    // Générer un nouvel index
    const newIndex = incrementString(pgc.length > 0 ? pgc[pgc.length - 1].pgc_index : '');

    // Préparer les données du formulaire
    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', newIndex);
    formData.append('dossier', JSON.stringify(dossier));
    formData.append('dataMoeOffice', JSON.stringify(state));

    // Ajouter les images et logos si disponibles
    if (imgCoverPage) formData.append('imgCoverPage', imgCoverPage);
    if (logoProjectOwner) formData.append('logoProjectOwner', logoProjectOwner);
    if (logoProjectManager) formData.append('logoProjectManager', logoProjectManager);
    if (logoOffice) formData.append('logoOffice', logoOffice);
    if (imgTableOfContents) formData.append('imgTableOfContents', imgTableOfContents);

    try {
      const response = await fetch('/api/pgc/create', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        dispatch(fetchPgcByDoss(dossierValue));
      } else {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setDisableButton(false);
    }
  };

  const handleUpdatePgc = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    // Préparer les données du formulaire
    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('dossier', JSON.stringify(dossier));
    formData.append('dataMoeOffice', JSON.stringify(state));
    formData.append('index', index);
    formData.append('pgcValue', pgcValue);

    // Ajouter les images et logos si disponibles
    if (imgCoverPage) formData.append('imgCoverPage', imgCoverPage);
    if (logoProjectOwner) formData.append('logoProjectOwner', logoProjectOwner);
    if (logoProjectManager) formData.append('logoProjectManager', logoProjectManager);
    if (logoOffice) formData.append('logoOffice', logoOffice);
    if (imgTableOfContents) formData.append('imgTableOfContents', imgTableOfContents);

    try {
      // Envoyer la requête au serveur
      const response = await fetch('/api/pgc/update', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setDisableButton(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPgcPDF(pgcValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`PGC`));
  }, [pgcValue]);

  const nextPage = () => {
    history.push("/pgc/page2");
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (value === 'Autre...') {
      setShowInput(true);
      setState(prevState => ({
        ...prevState,
        [name]: ''
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleReturnToSelect = () => {
    setShowInput(false);
    setState(prevState => ({
      ...prevState,
      officeType: ''
    }));
  };

  return (
    <div className="pgc-page1">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Plan Général de Coordination (Page de garde)</h1>
      <NumDossPGC />
      <div className="container">
        <table className="custom-table">
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>CHANTIER</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Dossier</td>
              <td><span>{dossierValue}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Indice</td>
              <td><span>{index}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Date</td>
              <td><input type="date" name="date" value={state.date} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Catégorie</td>
              <td><select name="categ" value={dossier?.categorie_op} onChange={(e) => setDossier(prevDossier => ({ ...prevDossier, categorie_op: e.target.value }))}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </select></td>
            </tr>
            <tr className="custom-cell">
              <td>Opération</td>
              <td><span>{dossier?.operation}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><span>{`${dossier?.adresse_op}, ${dossier?.cp_op} - ${dossier?.ville_op}`}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Image</td>
              <td>
                <input
                  type="file"
                  name="img-cover-page"
                  key={`img-cp`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setImgCoverPage(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>MAÎTRE D'OUVRAGE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><span>{dossier?.sci_sccv}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="nomMoComplement" value={dossier?.nomMoComplement} placeholder="Facultatif" onChange={(e) => setDossier(prevDossier => ({ ...prevDossier, nomMoComplement: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><span>{`${dossier?.adressMo}, ${dossier?.cpMo} - ${dossier?.cityMo}`}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telMo" value={dossier?.telMo} onChange={(e) => setDossier(prevDossier => ({ ...prevDossier, telMo: e.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-project-owner"
                  key={`logo-po`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoProjectOwner(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>MAÎTRE D’ŒUVRE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><input type="text" name="nameMoe" value={state.nameMoe} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="nameMoeComplement" value={state.nameMoeComplement} placeholder="Facultatif" onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="adressMoe" value={state.adressMoe} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="cpMoe" value={state.cpMoe} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="cityMoe" value={state.cityMoe} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telMoe" value={state.telMoe} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-project-manager"
                  key={`logo-pm`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoProjectManager(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>BUREAU</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Type</td>
              <td>
                {!showInput ? (
                  <select name="officeType" value={state.officeType} onChange={handleChange}>
                    <option value=""></option>
                    <option value="Contrôle">Contrôle</option>
                    <option value="Etude thermique">Etude thermique</option>
                    <option value="Autre...">Autre...</option>
                  </select>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      name="officeType"
                      value={state.officeType}
                      onChange={handleInputChange}
                      style={{ width: '150px' }}
                      placeholder="Entrez un type de bureau"
                    />
                    <button
                      onClick={handleReturnToSelect}
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    >
                      X
                    </button>
                  </div>
                )}
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><input type="text" name="nameOffice" value={state.nameOffice} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="nameOfficeComplement" value={state.nameOfficeComplement} placeholder="Facultatif" onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="adressOffice" value={state.adressOffice} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="cpOffice" value={state.cpOffice} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="cityOffice" value={state.cityOffice} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="telOffice" value={state.telOffice} onChange={(e) => setStateField(e)} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-office"
                  key={`logo-of`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoOffice(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>SOMMAIRE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Image</td>
              <td>
                <input
                  type="file"
                  name="img-table-of-contents"
                  key={`img-table-of-contents`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setImgTableOfContents(e.target.files[0])}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="imgs">
          {renderImageItem(imgCoverPage, "img-cover-page", handleDeleteImg, "img page de garde", "Page de garde")}
          {renderImageItem(logoProjectOwner, "logo-project-owner", handleDeleteImg, "logo maitre d ouvrage", "Maître d'ouvrage")}
          {renderImageItem(logoProjectManager, "logo-project-manager", handleDeleteImg, "logo maitre d oeuvre", "Maître d'oeuvre")}
          {renderImageItem(logoOffice, "logo-office", handleDeleteImg, "logo bureau", "Bureau")}
          {renderImageItem(imgTableOfContents, "img-table-of-contents", handleDeleteImg, "image sommaire", "Sommaire")}
        </div>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialogCreate(true)}
          disabled={disableButton || !rjExists}
        >
          Nouveau PGC
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdatePgc}
          disabled={disableButton || !rjExists || !pgcValue}
        >
          Modifier
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !rjExists || !pgcValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !rjExists || !pgcValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !rjExists || !pgcValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <YesNoDialog
        isOpen={openDialogCreate}
        title={'CRÉATION PGC'}
        question={`Voulez-vous créer un nouvel indice PGC du dossier ${dossierValue} ?`}
        handleYesClick={handleCreatePgc}
        handleClose={() => setOpenDialogCreate(false)}
      />
      <YesNoDialog
        isOpen={openDialogRj}
        title={'RJ INEXISTANT'}
        question={`Voulez-vous créer un nouveau RJ pour le dossier ${dossierValue} ?`}
        handleYesClick={() => history.push("/rj/evaluation")}
        handleClose={() => setOpenDialogRj(false)}
      />
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};
const renderImageItem = (image, name, handleDelete, altText, description) => (
  image && (
    <div className="img-item">
      <IconButton
        className="item-delete"
        style={{
          color: "white",
          position: "absolute",
          top: 0,
          right: 0
        }}
        onClick={() => handleDelete(name)}
      >
        <HighlightOffIcon />
      </IconButton>
      <img
        src={URL.createObjectURL(image)}
        alt={altText}
        style={{ height: "200px" }}
      />
      <p>{description}</p>
    </div>
  )
);

export default PgcPage1;