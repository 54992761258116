import React, { useState } from "react";
import Header from "../../Header/Header";
import VICMenu from "../VICMenu";
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import "../../../Styles/VIC/Page7.scss";
import { fetchVIC, newLotValue } from "../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import {
  genPDF,
  getDossier,
  getLotNbrPages,
  getPDF,
  getClient,
  getVIC,
} from "../Utils";
import ConvGrue from "./ConvGrue";
import GenPdf from "../../GenPdf";
import ConvEchPlat from "./ConvEchPlat";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import DocMenu from "../../DocMenu";
import ConvGO from "./ConvGO";

class Page7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueArray: Array.apply(null, Array(11)).map(function (x, i) {
        return false;
      }),
      compProp: "",
      adrProp: "",
      compUtil: "",
      adrUtil: "",
      compClient: "",
      adrClient: "",
      dateConv: "",
      duree: "",
      nature: "",
      travaux: [
        { nom: "VMC", value: false },
        { nom: "Chauffage", value: false },
        { nom: "Etanchéité", value: false },
        { nom: "Electricité", value: false },
        { nom: "Plomberie", value: false },
        { nom: "Menuiserie Extérieure", value: false },
        { nom: "Menuiserie Intérieure", value: false },
        { nom: "Autres...", value: false },
      ],
      file: null,
      isOpen: false,
      loading: false,
      disableButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.lotValue !== prevProps.lotValue ||
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.entrepriseValue !== prevProps.entrepriseValue
    ) {
      this.loadData();
    }
  }
  loadData() {
    this.props.dispatch(changePdfFileName(""))
    var vic = this.props.vic.filter((vic) => {
      if (
        this.props.dossierValue == vic.ndossier &&
        this.props.lotValue == vic.lots
      ) {
        if (this.props.entrepriseValue !== undefined) {
          var entreprise = JSON.parse(this.props.entrepriseValue);
          if (entreprise.id == vic.identreprise) {
            return true;
          }
        }
      } else return false;
    })[0];
    if (vic !== undefined) {
      var page7 = JSON.parse(vic.compte_rendu).page7;
      var convPage1 = JSON.parse(vic.compte_rendu).convPage1;
      var infosConv = JSON.parse(vic.compte_rendu).infosConv;
      var travaux = JSON.parse(vic.compte_rendu).travaux;
      var emptyTravaux = [
        { nom: "VMC", value: false },
        { nom: "Chauffage", value: false },
        { nom: "Etanchéité", value: false },
        { nom: "Electricité", value: false },
        { nom: "Plomberie", value: false },
        { nom: "Menuiserie Extérieure", value: false },
        { nom: "Menuiserie Intérieure", value: false },
        { nom: "Autres...", value: false },
      ];
      if (page7 !== undefined) {
        this.setState({ valueArray: page7 });
      } else if (convPage1 !== undefined) {
        this.setState({ valueArray: convPage1 });
      } else
        this.setState({
          valueArray: Array.apply(null, Array(11)).map(function (x, i) {
            return false;
          }),
        });
      if (travaux !== undefined) {
        this.setState({ travaux: travaux });
      } else {
        this.setState({ travaux: emptyTravaux });
      }
      if (infosConv !== undefined) {
        this.setState({ compProp: infosConv.compProp });
        this.setState({ adrProp: infosConv.adrProp });
        this.setState({ compUtil: infosConv.compUtil });
        this.setState({ adrUtil: infosConv.adrUtil });
        this.setState({ compClient: infosConv.compClient });
        this.setState({ adrClient: infosConv.adrClient });
        this.setState({ dateConv: infosConv.dateConv });
        this.setState({ duree: infosConv.duree });
        this.setState({ nature: infosConv.nature });
      } else {
        this.setState({ compProp: "" });
        this.setState({ adrProp: "" });
        this.setState({ compUtil: "" });
        this.setState({ adrUtil: "" });
        this.setState({ compClient: getClient(this.props).nom });
        this.setState({ adrClient: getClient(this.props).adresse });
        this.setState({ dateConv: "" });
        this.setState({ duree: "" });
        this.setState({ nature: "" });
      }
    } else {
      this.setState({
        valueArray: Array.apply(null, Array(11)).map(function (x, i) {
          return false;
        }),
      });
      // this.setState({travaux:emptyTravaux})
      this.setState({ compProp: "" });
      this.setState({ adrProp: "" });
      this.setState({ compUtil: "" });
      this.setState({ adrUtil: "" });
      this.setState({ compClient: getClient(this.props).nom });
      this.setState({ adrClient: getClient(this.props).adresse });
      this.setState({ dateConv: "" });
      this.setState({ duree: "" });
      this.setState({ nature: "" });
    }
    this.setState({ file: null });
  }

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  
  handleChange(event, index) {
    if (event.target.name == "travaux") {
      this.state.travaux[index].value = event.target.checked;
      this.setState(this.state.travaux);
    } else if (event.target.name == "conv") {
      this.state.valueArray[index] = event.target.checked;
      this.setState(this.state.valueArray);
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }
  async handleValidClick(entreprise) {
    this.setState({ disableButton: true });
    const { lots, dossierValue, lotValue } = this.props;
    const infosConv = {
      compProp: this.state.compProp,
      adrProp: this.state.adrProp,
      compUtil: this.state.compUtil,
      adrUtil: this.state.adrUtil,
      compClient: this.state.compClient,
      adrClient: this.state.adrClient,
      dateConv: this.state.dateConv,
      duree: this.state.duree,
      nature: this.state.nature,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossierValue,
        lotValue: lotValue,
        identreprise: entreprise.id,
        page7: this.state.valueArray,
        infosConv: infosConv,
        travaux: this.state.travaux,
        nbrPages: getLotNbrPages(lots, lotValue),
      }),
    };
    this.setState({ loading: true });
    fetch("/api/vic/page7", requestOptions)
      .then(async (res) => {
        if (res.ok) {
          await this.props.dispatch(fetchVIC(this.props.dossierValue));
          await this.setState({ loading: false });
          await this.setState({ disableButton: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    
    if (blob) {
      this.setState({ file: blob });
    }
  }
  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  getConv(lot, entreprise) {
    const { vic, dossierValue, lotValue } = this.props;
    var vicValue = "";
    if (entreprise !== "" && entreprise !== undefined) {
      vicValue = getVIC(vic, dossierValue, lotValue, entreprise.id);
    }
    if (
      lot == 16 ||
      lot == 24 ||
      lot == 32 ||
      lot == 33 ||
      lot == 41 ||
      lot == 48 ||
      vicValue.typeConv == "echafaudage"
    ) {
      return (
        <ConvEchPlat
          handleChange={this.handleChange}
          valueArray={this.state.valueArray}
        />
      );
    }
    if (
      lot == 5 ||
      lot == 6 ||
      lot == 10 ||
      lot == 21 ||
      lot == 23 ||
      lot == 27 ||
      lot == 28 ||
      lot == 30 ||
      lot == 40 ||
      lot == 53 ||
      vicValue.typeConv == "grue"
    ) {
      return (
        <ConvGrue
          handleChange={this.handleChange}
          valueArray={this.state.valueArray}
        />
      );
    }
    if (lot == 26 || vicValue.typeConv == "go") {
      return (
        <div style={{ display: "flex" }}>
          <ConvGO
            handleChange={this.handleChange}
            valueArray={this.state.valueArray}
          />
        </div>
      );
    }
  }
  nextPage = () => {
    history.push("/vic/page8");
  };
  prevPage = () => {
    history.push("/vic/page6");
  };
  disableSaveButton(entreprise) {
    if (entreprise.id === undefined) return true;
    if (this.state.loading) return true;
    return false;
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    var entreprise = "";
    var dossier = getDossier(this.props.dossier, this.props.dossierValue);
    if (
      this.props.entrepriseValue !== undefined &&
      this.props.entrepriseValue !== ""
    ) {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }
    return (
      <div className="page7">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 7)</h1>
        <VICMenu />
        <div className="data">
          <div className="titleConv">
            <h2>CONVENTION D’UTILISATION ENTRE 2 ENTREPRISES</h2>
            <p>Equipement de travail temporaire en hauteur ou de levage</p>
          </div>
          {this.getConv(this.props.lotValue, entreprise)}
          <div className="infoData">
            <div key="info1">
              <label>
                Cette convention d'utilisation est mise en place sur le chantier de :{" "}
                {dossier.ville_op}, {dossier.adresse_op}, {dossier.cp_op}
              </label>
            </div>
            <div key="info2">
              <label>Entreprise Propriétaire : </label>
              <input
                name="compProp"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.compProp}
              />
            </div>
            <div key="info3">
              <label>Adresse Entreprise Propriétaire : </label>
              <input
                name="adrProp"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.adrProp}
              />
            </div>
            <div key="info4">
              <label>Entreprise Utilisatrice : </label>
              <input
                name="compUtil"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.compUtil}
              />
            </div>
            <div key="info5">
              <label>Adresse Entreprise Utilisatrice : </label>
              <input
                name="adrUtil"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.adrUtil}
              />
            </div>
            <div key="info6">
              <label>Entreprise Cliente : </label>
              <input
                name="compClient"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.compClient}
              />
            </div>
            <div key="info7">
              <label>Adresse du Client : </label>
              <input
                name="adrClient"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.adrClient}
              />
            </div>
            <div key="info8">
              <label>Date Présentation Convention : </label>
              <input
                type="date"
                name="dateConv"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.dateConv}
              />
            </div>
            <div key="info9">
              <label>Durée d'utilisation : </label>
              <input
                type="time"
                name="duree"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.duree}
              />
            </div>
            <div key="info10">
              <label>Nature des opérations : </label>
              <input
                name="nature"
                onChange={(event) => this.handleChange(event, 0)}
                value={this.state.nature}
              />
            </div>
            <div key="info11">
              <label>Travaux à réaliser : </label>
              {this.state.travaux.map((item, index) => {
                return (
                  <div key={`travaux_${index}`}>
                    <input
                      type="checkbox"
                      name="travaux"
                      onChange={(event) => this.handleChange(event, index)}
                      checked={this.state.travaux[index].value}
                    />
                    {item.nom}
                  </div>
                )
              })}
            </div>
          </div>

          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(entreprise)}
            disabled={this.disableSaveButton(entreprise)}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick(entreprise)}
            disabled={this.state.disableButton || entreprise.id === undefined ? true : false}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf()}
            disabled={this.state.disableButton || entreprise.id === undefined ? true : false}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={this.state.disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={this.state.disableButton}
          >
            Page suivante
          </Button>
          {this.state.disableButton && <CircularProgress className="spinner" color="success" />}
        </div>
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={7}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page7);
