import {
  CHANGE_URL_FILE,
  CHANGE_TITLE_NAME,
  CHANGE_FILE_NAME,
  CHANGE_TYPE_FILE
} from '../actions/emailAction';
const initialState = {
  file: null,
  loading: false,
  error: null,
  title: "",
  filename: "",
  typefile: ""
}

// Use the initialState as a default value
export default function emailReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_URL_FILE: {
      return {
        ...state,
        loading: true,
        error: null,
        file: action.payload.url
      }
    }
    case CHANGE_TITLE_NAME: {
      return {
        ...state,
        title: action.payload.titleName
      }
    }
    case CHANGE_FILE_NAME: {
      return {
        ...state,
        filename: action.payload.fileName
      }
    }
    case CHANGE_TYPE_FILE: {
      return {
        ...state,
        typefile: action.payload.typeFile
      }
    }
    default:
      return state
  }
}