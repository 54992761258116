import React from "react"
import { connect } from "react-redux";
import ArmatureTable from "./ArmatureTable"
import AscenseurTable from "./AscenseurTable"
import CharpenteTable from "./CharpenteTable"
import ChauffageVMC from "./ChauffageVMC";
import CloisonTable from "./CloisonTable"
import CouvertureTable from "./CouvertureTable"
import CuvelageTable from "./CuvelageTable";
import DemontageGrueTable from "./DemontageGrueTable"
import DesamiantageTable from "./DesamiantageTable"
import EchafaudageTable from "./EchafaudageTable"
import ElectriciteTable from "./ElectriciteTable"
import EspVertTable from "./EspVertTable"
import EtancheiteTable from "./EtancheiteTable"
import FaçadeTable from "./FaçadeTable"
import FerraillageTable from "./FerraillageTable"
import GOTable from "./GOTable"
import MaçonnerieTable from "./MaçonnerieTable"
import MCPlateforme from "./MCPlateforme";
import MetallerieTable from "./MetallerieTable"
import MontageGrueTable from "./MontageGrueTable"
import PBVTable from "./PBVTable"
import PeinturePlus from "./PeinturePlus";
import PieuxTable from "./PieuxTable";
import PlacoEnduitTable from "./PlacoEnduitTable"
import PlatrerieTable from "./PlatrerieTable"
import PlomberiePlus from "./PlomberiePlus";
import PlomberieTable from "./PlomberieTable"
import RavalementTable from "./RavalementTable"
import TechnicotTable from "./TechnicotTable"
import ToitureTable from "./ToitureTable"
import VentilationTable from "./VentilationTable";
import VMCTable from "./VMCTable"
import VPPTable from "./VPPTable"
class AllTables extends React.Component{
    constructor(props){
        super(props)
        this.state={}
    }

    componentDidMount(){
        this.loadDataTables()
    }

    componentDidUpdate(prevProps){
        if(this.props.lotValue!==prevProps.lotValue || this.props.entrepriseValue!==prevProps.entrepriseValue
			|| this.props.dossierValue!==prevProps.dossierValue){
            this.loadDataTables()
        }
    }

    loadDataTables(){
        if(this.props.lotValue==2){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32]) 
            this.props.loadData([0,10,13,17,18,23,27,32])
        }
        if(this.props.lotValue==3){
            this.props.filldelIndexes([0,10,14,19,23]) 
            this.props.loadData([0,10,14,19,23])
        }
        if(this.props.lotValue==6){
            this.props.filldelIndexes([0,17]) 
            this.props.loadData([0,17])
        }
        if(this.props.lotValue==8){
            this.props.filldelIndexes([0,10,13,17,21,26]) 
            this.props.loadData([0,10,13,17,21,26])
        }
        if(this.props.lotValue==9){
            this.props.filldelIndexes([0,6,11]) 
            this.props.loadData([0,6,11])
        }
        if(this.props.lotValue==10){
            this.props.filldelIndexes([0,3,9,12,17]) 
            this.props.loadData([0,3,9,12,17])
        }
        if(this.props.lotValue==13){
            this.props.filldelIndexes([0,10,21,26]) 
            this.props.loadData([0,10,21,26])
        }
        if(this.props.lotValue==15){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32]) 
            this.props.loadData([0,10,13,17,18,23,27,32])
        }
        if(this.props.lotValue==16){
            this.props.filldelIndexes([0]) 
            this.props.loadData([0]) 
        }
        if(this.props.lotValue==17){
            this.props.filldelIndexes([0,6,11]) 
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==18){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==19){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==20){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11])  
        }
        if(this.props.lotValue==21){
            this.props.filldelIndexes([0,6,11]) 
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==22){
            this.props.filldelIndexes([0,10,21,26]) 
            this.props.loadData([0,10,21,26])
        }
        if(this.props.lotValue==23){
            this.props.filldelIndexes([0,10,21,26]) 
            this.props.loadData([0,10,21,26])
        }
        if(this.props.lotValue==24){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==25){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32]) 
            this.props.loadData([0,10,13,17,18,23,27,32]) 
        }
        if(this.props.lotValue==26){
            this.props.filldelIndexes([0,8,22,27]) 
            this.props.loadData([0,8,22,27]) 
        }
        if(this.props.lotValue==27){
            this.props.filldelIndexes([0,10,17,27]) 
            this.props.loadData([0,10,17,27]) 
        }
        if(this.props.lotValue==28){
            this.props.filldelIndexes([0,10,17,27]) 
            this.props.loadData([0,10,17,27]) 
        }
        if(this.props.lotValue==29){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32])
            this.props.loadData([0,10,13,17,18,23,27,32])  
        }  
        if(this.props.lotValue==32){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11]) 
        } 
        if(this.props.lotValue==33){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11])
        } 
        if(this.props.lotValue==34){
            this.props.filldelIndexes([0,6,11]) 
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==36){
            this.props.filldelIndexes([0]) 
            this.props.loadData([0]) 
        }
        if(this.props.lotValue==37){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11]) 
        }
        if(this.props.lotValue==38){
            this.props.filldelIndexes([0,7,8]) 
            this.props.loadData([0,7,8])
        }
        if(this.props.lotValue==39){
            this.props.filldelIndexes([0,6,11])
            this.props.loadData([0,6,11])  
        }
        if(this.props.lotValue==40){
            this.props.filldelIndexes([0,5])
            this.props.loadData([0,5])  
        }
        if(this.props.lotValue==41){
            this.props.filldelIndexes([0,6,11]) 
            this.props.loadData([0,6,11])  
        }
        if(this.props.lotValue==42){
            this.props.filldelIndexes([0,10,17]) 
            this.props.loadData([0,10,17]) 
        }
        if(this.props.lotValue==44){
            this.props.filldelIndexes([0,10,13,17,21,26]) 
            this.props.loadData([0,10,13,17,21,26])
        }
        if(this.props.lotValue==45){
            this.props.filldelIndexes([0,10,13,17,21,26]) 
            this.props.loadData([0,10,13,17,21,26])
        }
        if(this.props.lotValue==46){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32]) 
            this.props.loadData([0,10,13,17,18,23,27,32]) 
        }
        if(this.props.lotValue==47){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32]) 
            this.props.loadData([0,10,13,17,18,23,27,32]) 
        }
        if(this.props.lotValue==48){
            this.props.filldelIndexes([0,10,13,17,18,23,27,32]) 
            this.props.loadData([0,10,13,17,18,23,27,32]) 
        }
        // if(this.props.lotValue==33){
        //     this.props.filldelIndexes([0,5]) 
        //     this.props.loadData([0,5]) 
        // }
        if(this.props.lotValue==51){
            this.props.filldelIndexes([0,3,9,12,17]) 
            this.props.loadData([0,3,9,12,17]) 
        }
        if(this.props.lotValue==52){
            this.props.filldelIndexes([0,10,13,17,21,26]) 
            this.props.loadData([0,10,13,17,21,26])
        }
        if(this.props.lotValue==53){
            this.props.filldelIndexes([0,10,13,17,21,26]) 
            this.props.loadData([0,10,13,17,21,26]) 
        }
        if(this.props.lotValue==54){
            this.props.filldelIndexes([4]) 
            this.props.loadData([4]) 
        }
    }

    getTable(){
        if(this.props.lotValue==2){
            return <ArmatureTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==3){
            return <AscenseurTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==6){
            return <CharpenteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==8){
            return <ChauffageVMC handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==9){
            return <CloisonTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==10){
            return <CouvertureTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==13){
            return <CuvelageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==15){
            return <DesamiantageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==16){
            return <EchafaudageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==17){
            return <ElectriciteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==18){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==19){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==20){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==21){
            return <EspVertTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==22){
            return <EtancheiteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==23){
            return <EtancheiteTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==24){
            return <FaçadeTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==25){
            return <FerraillageTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==26){
            return <GOTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==27){
            return <DemontageGrueTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==28){
            return <MontageGrueTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==29){
            return <MaçonnerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==32){
            return <MCPlateforme handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==33){
            return <MCPlateforme handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==34){
            return <MetallerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==36){
            return <PeinturePlus handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==37){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==38){
            return <PieuxTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==39){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray} />
        }
        else if(this.props.lotValue==40){
            return <PlacoEnduitTable handleChange={this.props.handleChange} valueArray={this.props.valueArray} />
        }
        else if(this.props.lotValue==41){
            return <MCPlateforme handleChange={this.props.handleChange} valueArray={this.props.valueArray} />
        }
        else if(this.props.lotValue==42){
            return <PlomberieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==44){
            return <PlomberiePlus handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==45){
            return <PlomberiePlus handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==46){
            return <PlatrerieTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==47){
            return <PBVTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==48){
            return <RavalementTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        // else if(this.props.lotValue==33){
        //     return <TechnicotTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        // }
        else if(this.props.lotValue==51){
            return <ToitureTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==52){
            return <VentilationTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==53){
            return <VMCTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else if(this.props.lotValue==54){
            return <VPPTable handleChange={this.props.handleChange} valueArray={this.props.valueArray}/>
        }
        else return ""
    }
    render(){
        return(
            this.getTable()
        )
    }
}
const mapStateToProps = state => ({
    dossierValue: state.devis.dossierValue,
	lotValue : state.vic.lotValue,
	vic : state.vic.items,
	entrepriseValue: state.entreprise.entrepriseValue,
  });
  export default connect(mapStateToProps)(AllTables);