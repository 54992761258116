import {
  FETCH_DOSSIER_BEGIN,
  FETCH_DOSSIER_SUCCESS,
  FETCH_DOSSIER_FAILURE,
  FETCH_DOSSIER_INFOS_SUCCESS
} from '../actions/dossierActions';
const initialState = {
  items: [],
  item: {},
  loading: false,
  error: null,
}

// Use the initialState as a default value
export default function dossierReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOSSIER_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_DOSSIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.items
      }
    }
    case FETCH_DOSSIER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_DOSSIER_INFOS_SUCCESS: {
      return {
        ...state,
        item: action.payload.item
      }
    }
    default:
      return state
  }
}