import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import authHeader from '../Utils';

class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            graphres : [],
            chartData : {
                labels : [],
                datasets : [{
                    label : 'Phase chantier',
                    data : [],
                    backgroundColor : [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 99, 132, 0.5)'
                    ]
                    }
                ]
            },          
        }
    }
    /**
     * Initialisation des données du graphique vià une requete fetch
     */
    componentDidMount() {
        fetch('/api/dashboardData',{
            headers : authHeader(),
      })
      .then(res => res.json())
      .then(res => this.setState({graphres:res.graphres}))
      .then(res => this.setState({chartData:{
        labels: this.setLabels(this.state.graphres),
        datasets:[{
            label:"Phase Chantier",
            data:this.setData(this.state.graphres),
            backgroundColor : [
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 99, 132, 0.5)'
            ]
        }]
      }
    }))
}
/**
 * Initialise les libellés du graphique
 * @param {[]} res 
 * @returns le nom des villes
 */
    setLabels(res){
        const sortedPlaces = []
        const datePhase = {
            ville:[],
            phase: [],
            date:[]
        }
        res.map(res =>{
            if(Date.parse(this.props.date)>=Date.parse(res.datevalidation)){
                datePhase.ville.push(res.lieu)
                datePhase.phase.push(res.phase)
                datePhase.date.push(res.datevalidation)
            }    
        })
        for(var i=0; i<datePhase.ville.length; i++){
            if(datePhase.ville[i] !== datePhase.ville[i+1]){
                sortedPlaces.push(datePhase.ville[i])
            }
        }
        // console.log(sortedPlaces)
        return sortedPlaces
    }
    /**
     * Initialise les données du graphiques
     * @param {[]} res 
     * @returns phases des chantiers
     */
    setData(res){
        const sortedPhases = []
        const datePhase = {
            ville:[],
            phase: [],
            date:[]
        }
        res.map(res =>{
            if(Date.parse(this.props.date)>=Date.parse(res.datevalidation)){
                datePhase.ville.push(res.lieu)
                datePhase.phase.push(res.phase)
                datePhase.date.push(res.datevalidation)
            }    
        })
        for(var i=0; i<datePhase.ville.length; i++){
            if(datePhase.ville[i] !== datePhase.ville[i+1]){
                sortedPhases.push(datePhase.phase[i])
            }
        }
        // console.log(sortedPhases)
        return sortedPhases
    }
    /**
     * Change les donénes du graphique en fonction de la date choisi sur le calendrier
     */
    newSetData(){
    if(Date.parse(this.props.date)!==Date.parse(this.state.date))
        this.setState({chartData:{
            labels:this.setLabels(this.state.graphres),
            datasets:[{
                label:"Phase Chantier",
                data:this.setData(this.state.graphres)
            }]
        },date:this.props.date})
    }

    render() {
        this.newSetData()
        return (
            <div className="chart">
                <Bar 
                data={this.state.chartData}
                width={15}
                height={ window.innerWidth < 800 ? 8 :4}
                options={{responsive:true}}
                />
            </div>
        )
    }
}


export default Chart;