export const FETCH_REMINDER_BEGIN = "FETCH_REMINDER_BEGIN";
export const FETCH_REMINDER_SUCCESS = "FETCH_REMINDER_SUCCESS";
export const FETCH_REMINDER_FAILURE = "FETCH_REMINDER_FAILURE";
export function fetchAllReminder() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({})
  };
  return async (dispatch) => {
    dispatch(fetchAllReminderBegin());
    return fetch("/api/facture/getAllReminder", requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchAllReminderSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchAllReminderFailure(error)));
  };
}

export function fetchAllReminderByDoss(ndossier) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({})
  };
  return async (dispatch) => {
    dispatch(fetchAllReminderBegin());
    return fetch("/api/facture/getAllReminder", requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const filteredReminder = json.filter(reminder => reminder.ndossier == ndossier).sort((a, b) => a.numclient.localeCompare(b.numclient));
        dispatch(fetchAllReminderSuccess(filteredReminder));
        return filteredReminder;
      })
      .catch((error) => dispatch(fetchAllReminderFailure(error)));
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchAllReminderBegin = () => ({
  type: FETCH_REMINDER_BEGIN,
});

export const fetchAllReminderSuccess = (items) => ({
  type: FETCH_REMINDER_SUCCESS,
  payload: { items: items },
});

export const fetchAllReminderFailure = (error) => ({
  type: FETCH_REMINDER_FAILURE,
  payload: { error },
});
