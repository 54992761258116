import React from 'react';
//import { Button } from '@material-ui/core';
import config from "../Chatbot/config";
import ActionProvider from "../Chatbot/ActionProvider";
import MessageParser from "../Chatbot/MessageParser";
import Chatbot from 'react-chatbot-kit';
import "../Chatbot/Chatbot.scss";
import Header from '../Header/Header';
import GererNotif from '../GererNotif';
import history from '../Routes/history';
//import { Button } from '@material-ui/core';

class Chat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    clickToGererNotif = () => {
        history.push("/chatbott");
    }
    render() {
        return (
            <div >
                <Header />
                <GererNotif />

                <div onClick={this.clickToGererNotif} className="chat" /*style={{ width:"10px",height: "10px"}}*/>
                    <Chatbot
                      
                        config={config}
                        actionProvider={ActionProvider}
                        messageParser={MessageParser}
                    />
                </div>
            </div>
        );
    }
}
export default Chat; 