import React from 'react';
import { connect } from 'react-redux'
import { fetchUser } from "../../redux/actions/userActions";
import { fetchNotifs } from '../../redux/actions/notifActions';
import LeftHeader from './LeftHeader/LeftHeader';
import RightHeader from './RightHeader/RightHeader';
import { fetchRjFrCategories } from "../../redux/actions/rjActions"
import { fetchDevis, newDossierValue } from "../../redux/actions/devisActions";
import { fetchDossier } from "../../redux/actions/dossierActions";
import { fetchContact } from "../../redux/actions/contactActions";
import { fetchEcheances, fetchEcheancesByNdossier } from "../../redux/actions/echeancesActions";
import { fetchEntreprise } from "../../redux/actions/entrepriseActions";
import { changeFacIndexValue, fetchAllFac } from "../../redux/actions/facActions";
import { fetchAllContractFacAmendment } from "../../redux/actions/facAmendmentActions";

import "../../Styles/Header.scss"
class Header extends React.Component {
    constructor(props) {
        super(props)
    }
    /**
     * Initialise les notificationset les données de l'utilisateur
     */
    async componentDidMount() {
        const { dossierValue, dossier, devisrea, deviscon, echeances, entreprise } = this.props;

        await this.props.dispatch(fetchAllContractFacAmendment());
        if (!dossier.length) {
            await this.props.dispatch(fetchDossier());
            await this.props.dispatch(fetchContact());
        } else {
            await this.props.dispatch(newDossierValue(dossierValue));
        }

        if (dossierValue != "") {
            await this.props.dispatch(fetchEcheancesByNdossier(dossierValue));
        }

        if (!entreprise.length) {
            await this.props.dispatch(fetchEntreprise());
        }

        if (!deviscon.length || !devisrea.length) {
            await this.props.dispatch(fetchDevis());
        }

        if (!echeances.length) {
            await this.props.dispatch(fetchEcheances());
        }
        await this.props.dispatch(changeFacIndexValue(1))
        await this.props.dispatch(fetchAllFac())
        await this.props.dispatch(fetchNotifs())
        await this.props.dispatch(fetchUser())
        await this.props.dispatch(fetchRjFrCategories())
    }

    render() {

        return (
            <div className="headerco">
                <LeftHeader />
                <RightHeader location={this.props.location} />
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    devisrea: state.devis.devisrea,
    deviscon: state.devis.deviscon,
    dossierValue: state.devis.dossierValue,
    dossier: state.dossier.items,
    echeances: state.echeances.items,
    entreprise: state.entreprise.items
});
export default connect(mapStateToProps)(Header)