import React, { Component } from "react";
import "../../Styles/RJ/RjEmploymentLaw.scss";
import Header from "../Header/Header";
import { connect } from "react-redux";
import { fetchRjFrCategories } from "../../redux/actions/rjActions";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import RjEmploymentLawTable from "./RjEmploymentLawTable";
import RjCategoriesTable from "./RjCategoriesTable";
import { v4 as uuidv4 } from "uuid";

class RjEmploymentLaw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allEmploymentLaw: [],
      name: "",
      title: "",
      detail: "",
      category_id: "",
      employmentLawName: "",
      employmentLawDetail: "",
      employmentLawTitle: "",
      currentElementId: "",
      openDialogRead: false,
      openDialogDel: false,
      ctCategories: true,
      createState: true,
      createCat: true,
      _isMounted: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.addNewEmploymentLaw = this.addNewEmploymentLaw.bind(this);
    this.addNewFactor = this.addNewFactor.bind(this);
    this.loadRjEmploymentLaw = this.loadRjEmploymentLaw.bind(this);
    this.getEmploymentLawInfos = this.getEmploymentLawInfos.bind(this);
    this.getFactorInfos = this.getFactorInfos.bind(this);
    this.updateEmploymentLaw = this.updateEmploymentLaw.bind(this);
    this.updateFactor = this.updateFactor.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadRjEmploymentLaw();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  loadRjEmploymentLaw = async () => {
    this.setState({ loading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await fetch("/api/rj/allRjEmploymentLaw", requestOptions);
      const data = await res.json();
      if (this._isMounted) {
        this.setState({ allEmploymentLaw: data });
        this.setState({ loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async addNewEmploymentLaw() {
    const { name, title, detail, category_id } = this.state;

    if (name && title && detail && category_id) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: name.trim(),
          title: title.trim(),
          detail,
          category_id,
        }),
      };
      try {
        const response = await fetch(
          "/api/rj/newEmploymentLaw",
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Failed to add new employment law");
        }

        this.setState({ name: "", title: "", detail: "", category_id: "" });
      } catch (error) {
        console.log(error);
      }
      await this.loadRjEmploymentLaw();
    } else {
      this.setState({
        openDialogRead: true,
        employmentLawName: "INFORMATIONS MANQUANTES",
        employmentLawDetail: "Veuillez remplir tous les champs obligatoires",
      });
    }
  }

  async addNewFactor() {
    const { name } = this.state;

    if (name) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: name.trim(),
        }),
      };
      try {
        const response = await fetch("/api/rj/newFactor", requestOptions);

        if (!response.ok) {
          throw new Error("Failed to add new employment law");
        }

        this.setState({ name: "" });
      } catch (error) {
        console.log(error);
      }
      await this.props.dispatch(fetchRjFrCategories());
    } else {
      this.setState({
        openDialogRead: true,
        employmentLawName: "INFORMATIONS MANQUANTES",
        employmentLawDetail: "Veuillez remplir le champs facteur de risque",
      });
    }
  }

  getEmploymentLawInfos(data) {
    this.setState({
      name: data.name,
      title: data.title,
      detail: data.detail,
      category_id: data.category_id,
      currentElementId: data.id,
      createState: false,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  getFactorInfos(data) {
    this.setState({
      name: data.name,
      category_id: data.id,
      createCat: false,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  async updateEmploymentLaw() {
    const { name, title, detail, category_id, currentElementId } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        title: title,
        detail: detail,
        category_id: category_id,
        id: currentElementId,
      }),
    };
    try {
      const response = await fetch(
        "/api/rj/updateEmploymentLaw",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
    } catch (error) {
      console.log(error);
    }
    await this.resetState();
    await this.loadRjEmploymentLaw();
  }

  async updateFactor() {
    const { name, category_id } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        id: category_id,
      }),
    };
    try {
      const response = await fetch("/api/rj/updateFactor", requestOptions);

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
    } catch (error) {
      console.log(error);
    }
    await this.resetState();
    await this.props.dispatch(fetchRjFrCategories());
  }

  async deleteEmploymentLaw(id) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ id }),
    };
    try {
      const response = await fetch("/api/rj/suppEmploymentLaw", requestOptions);

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
    } catch (error) {
      console.log(error);
    }
    await this.setState({ currentElementId: "" });
    await this.loadRjEmploymentLaw();
    await this.handleCloseDel();
  }

  async deleteFactor(id) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ id }),
    };
    try {
      const response = await fetch("/api/rj/suppFactor", requestOptions);

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
    } catch (error) {
      console.log(error);
    }
    await this.setState({ currentElementId: "" });
    await this.props.dispatch(fetchRjFrCategories());
    await this.handleCloseDel();
  }

  resetState() {
    this.setState({
      name: "",
      title: "",
      detail: "",
      category_id: "",
      currentElementId: "",
      createState: true,
      createCat: true,
    });
  }

  handleClickOpenRead = (employmentLaw) => {
    this.setState({
      openDialogRead: true,
      employmentLawName: employmentLaw.name,
      employmentLawTitle: employmentLaw.title,
      employmentLawDetail: employmentLaw.detail,
    });
  };

  handleCloseRead = () => {
    this.setState({
      openDialogRead: false,
      employmentLawName: "",
      employmentLawTitle: "",
      employmentLawDetail: "",
    });
  };

  handleClickOpenDel = (employmentLaw) => {
    this.setState({
      openDialogDel: true,
      employmentLawName: employmentLaw.name,
      employmentLawTitle: employmentLaw.title,
      employmentLawDetail: employmentLaw.detail,
      currentElementId: employmentLaw.id,
    });
  };

  handleCloseDel = () => {
    this.setState({
      openDialogDel: false,
      employmentLawName: "",
      employmentLawTitle: "",
      employmentLawDetail: "",
      currentElementId: "",
    });
  };

  render() {
    const {
      name,
      title,
      detail,
      category_id,
      allEmploymentLaw,
      createState,
      createCat,
      currentElementId,
      openDialogDel,
      openDialogRead,
      employmentLawName,
      employmentLawTitle,
      employmentLawDetail,
      ctCategories,
      loading,
    } = this.state;
    const { listFactors } = this.props;
    return (
      <div className="rjEmploymentLaw">
        <Header />
        <h1 className="title">Gérer les codes du travail (RJ)</h1>
        <div className="ct-categories">
          <Button
            className="cat-button"
            variant="contained"
            style={{
              color: ctCategories ? "black" : "white",
              fontWeight: ctCategories ? "bold" : "",
              backgroundColor: ctCategories
                ? "rgb(253, 181, 142)"
                : "rgba(255, 127, 80, 0.73)",
            }}
            onClick={() => this.setState({ ctCategories: true })}
          >
            Code du travail
          </Button>
          <Button
            className="cat-button"
            variant="contained"
            style={{
              color: !ctCategories ? "black" : "white",
              fontWeight: !ctCategories ? "bold" : "",
              backgroundColor: !ctCategories
                ? "rgb(253, 181, 142)"
                : "rgba(255, 127, 80, 0.73)",
            }}
            onClick={() => this.setState({ ctCategories: false })}
          >
            Facteur de risque
          </Button>
        </div>
        <Grid container justifyContent="center">
          <Grid item xs={10} sm={6} md={4}>
            <Card>
              {ctCategories ? (
                <CardContent>
                  {createState ? (
                    <h2>Ajouter un code du travail</h2>
                  ) : (
                    <h2>Modifier le code du travail</h2>
                  )}
                  <form>
                    <FormControl fullWidth>
                      <InputLabel id="category-label">Catégorie :</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category"
                        name="category_id"
                        onChange={this.handleChange}
                        value={category_id || ""}
                      >
                        <MenuItem value="" />
                        {listFactors.map((item) => {
                          return (
                            <MenuItem key={uuidv4()} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Code du travail"
                      id="name"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Titre"
                      id="title"
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Détail"
                      id="detail"
                      name="detail"
                      multiline
                      rows={8}
                      value={detail}
                      onChange={this.handleChange}
                    />
                    <div className="buttons">
                      {createState ? (
                        <Button
                          className="ct-button"
                          variant="contained"
                          color="primary"
                          onClick={this.addNewEmploymentLaw}
                        >
                          Ajouter
                        </Button>
                      ) : (
                        <Button
                          className="ct-button"
                          variant="contained"
                          color="primary"
                          onClick={this.updateEmploymentLaw}
                        >
                          Modifier
                        </Button>
                      )}
                      <Button
                        className="ct-button"
                        variant="contained"
                        color="primary"
                        onClick={this.resetState}
                      >
                        Annuler
                      </Button>
                    </div>
                  </form>
                </CardContent>
              ) : (
                <CardContent>
                  {createCat ? (
                    <h2>Ajouter un facteur de risque</h2>
                  ) : (
                    <h2>Modifier le facteur de risque</h2>
                  )}
                  <form>
                    <TextField
                      fullWidth
                      label="Facteur de risque"
                      id="name"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                    <div className="buttons">
                      {createCat ? (
                        <Button
                          className="ct-button"
                          variant="contained"
                          color="primary"
                          onClick={this.addNewFactor}
                        >
                          Ajouter
                        </Button>
                      ) : (
                        <Button
                          className="ct-button"
                          variant="contained"
                          color="primary"
                          onClick={this.updateFactor}
                        >
                          Modifier
                        </Button>
                      )}
                      <Button
                        className="ct-button"
                        variant="contained"
                        color="primary"
                        onClick={this.resetState}
                      >
                        Annuler
                      </Button>
                    </div>
                  </form>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
        {ctCategories ? (
          <RjEmploymentLawTable
            allEmploymentLaw={allEmploymentLaw}
            handleClickOpenRead={this.handleClickOpenRead}
            handleClickOpenDel={this.handleClickOpenDel}
            getEmploymentLawInfos={this.getEmploymentLawInfos}
          />
        ) : (
          <RjCategoriesTable
            allEmploymentLaw={allEmploymentLaw}
            handleClickOpenDel={this.handleClickOpenDel}
            getFactorInfos={this.getFactorInfos}
          />
        )}
        {loading && <CircularProgress className="spinner" color="inherit" />}
        <div>
          <Dialog open={openDialogRead} onClose={this.handleCloseRead}>
            <DialogTitle style={{ textAlign: "center" }}>
              {employmentLawName}
            </DialogTitle>
            <DialogContent>
              <h4>{employmentLawTitle}</h4>
              <div>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {employmentLawDetail}
                </pre>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={this.handleCloseRead}
                style={{ color: "white", backgroundColor: "#FF7F50" }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog open={openDialogDel} onClose={this.handleCloseDel}>
            <DialogTitle style={{ textAlign: "center" }}>
              SUPPRESSION -{" "}
              {ctCategories ? "CODE DU TRAVAIL" : "FACTEUR DE RISQUE"}
            </DialogTitle>
            <DialogContent>
              {ctCategories ? (
                <h5>{employmentLawName}</h5>
              ) : (
                <h4>{employmentLawName}</h4>
              )}
              <h5>{employmentLawTitle}</h5>
              <div>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {employmentLawDetail}
                </pre>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() =>
                  ctCategories
                    ? this.deleteEmploymentLaw(currentElementId)
                    : this.deleteFactor(currentElementId)
                }
                style={{ color: "white", backgroundColor: "#FF7F50" }}
              >
                oui
              </Button>
              <Button
                variant="contained"
                onClick={this.handleCloseDel}
                style={{ color: "white", backgroundColor: "#F5B039" }}
              >
                non
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listFactors: state.rj.listFactors,
});
export default connect(mapStateToProps)(RjEmploymentLaw);
