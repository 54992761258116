class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    
  }
  greet = () => {
      const message = this.createChatBotMessage("rendez-vous dans la rubrique facture");
      this.addMessageToState(message);

  };

  handleJavascriptQuiz = () => {
      const message = this.createChatBotMessage(
          "Vous pouvez nous répondre à quelques questions avant de vous fournir la pièce demandée?",
          {
              widget: "javascriptQuiz",
          }
      );

      this.addMessageToState(message);
  };



addMessageToState = (message) => {
  this.setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message],
  }));

};

}

export default ActionProvider;