import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { fetchNewPropo, newNpropValue, fetchDevis } from "../../../../redux/actions/devisActions";

class DialogDelValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleCloseProcess = this.handleCloseProcess.bind(this);
  }

  async deleteValidation() {
    const { dossierValue, nprop } = this.props;
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    };

    try {
      const response = await fetch(`/api/devis/deleteValidation?nprop=${nprop}&ndossier=${dossierValue}`, requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement:", error);
      throw error;
    } finally {
      await this.props.dispatch(fetchDevis());
      await this.props.dispatch(fetchNewPropo(dossierValue));
      await this.props.dispatch(newNpropValue(nprop));
      await this.props.showNextDialog(1);
    }
  }

  handleCloseProcess() {
    this.setState({ isOpen: false });
    this.props.closeDialog();
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleCloseProcess}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            SUPPRESSION
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous supprimer la validation de la proposition {this.props.nprop} du dossier N° {this.props.dossierValue} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseProcess} color="primary">
              Annuler la Modification
            </Button>
            <Button
              onClick={() => this.props.showNextDialog(1)}
              color="primary"
            >
              Non
            </Button>
            <Button
              onClick={() => this.deleteValidation()}
              color="primary"
            >
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
});
export default connect(mapStateToProps)(DialogDelValidation);
