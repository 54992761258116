import { faCommentDots, faExclamationTriangle, faUserCheck, faUserCog } from '@fortawesome/free-solid-svg-icons';

export const fetchWithTimeout = async (resource, options) => {
    const { timeout = 8000 } = options;
    //this.setState(loading=true)
    const controller = new AbortController();
    // const id = setTimeout(() => {
    //   controller.abort()
    //   //this.setState({loading:false})
    // }, timeout);
  
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    //clearTimeout(id);
  
    return response;
  }

  export const changeFac = (nfacvalue) => {
    const requestOptions = {
      method: 'POST',
      timeout: 2000,
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
      body: JSON.stringify({ref: nfacvalue})
      };   
      fetchWithTimeout("/api/facture/changeData",requestOptions)
      .finally(findFacData())
  }
  
  export const findFacData = () =>{
    fetch('/api/facture/exceldata')
   .then(response =>  response.json())
   .catch((err) => console.log(err))
 }

 export const getLocation = (location) =>{
  var path = location.pathname.split('').reverse().join('');
  path = path.substring(0,path.indexOf('/'));
  path = path.split('').reverse().join('');
  return path;
 }

 export const convertDate = (date) =>{
  var annee = date.slice(0,4)
  var mois = date.slice(5,7)
  var jour = date.slice(8,10)
  var finalDate=jour+"/"+mois+"/"+annee
  return finalDate
}
export const verifNotifIcon = (type_notif) => {
  if(/(pdf)|(word)|(excel)/g.test(type_notif)){
      return faCommentDots;
  }
  else if(/(relance)/g.test(type_notif)){
      return faExclamationTriangle;
  }
  else if(/(demande)/g.test(type_notif)){
    return faUserCog;
  }
  else if(/(reponse)/g.test(type_notif)){
    return faUserCheck;
  }
}
export const verifNotifType = (type_notif) => {
  if(/(pdf)|(word)|(excel)/g.test(type_notif)){
      return "Informations";
  }
  else if(/(relance)/g.test(type_notif)){
      return "Relance";
  }
  else if(/(demande)/g.test(type_notif)){
    return "Demande";
  }
  else if(/(reponse)/g.test(type_notif)){
    return "Réponse";
  }
}

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log("user", user?.id);
  
  if (user && user.access_token) {
    // for Node.js Express back-end
    return { 'x-access-token': user.access_token, id: user.id };
  } else {
    return {};
  }
}

export const getISOString = (date) => {
  const dateObj = new Date(date)
  dateObj.setMinutes(dateObj.getMinutes() - dateObj.getTimezoneOffset())
  return dateObj.toISOString()
}

export const getRespEntreprise = (id_resp,contact) => {
  for(const con of contact){
    if(con.idcontact==id_resp && con.fonction=="Responsable Entreprise"){
      return con
    }
  }
  return {}
}

export const getEntrepriseFromName = (nom_entreprise,entreprise) => {
    for(const company of entreprise){
      if(nom_entreprise.toLowerCase().trim()==company.nom.toLowerCase().trim()){
        return company
      }
    }
    return {}
}


export const getContactFromEntreprise = (identreprise,nom_contact,contacts) => {
  for(const contact of contacts){
    if(contact.identreprise == identreprise && (contact.prenom.toLowerCase()+" "+contact.nom.toLowerCase()).trim() == nom_contact.toLowerCase().trim()){
      return contact
    }
  }
  return {}
}

export const getCompleteName = (nom) => {
  const array = nom.trim().split(" ");
  let prenomFormatted =
    array[0].split("-")[0].charAt(0).toUpperCase() +
    array[0].split("-")[0].slice(1).toLowerCase();
  if (array[0].split("-")[1]) {
    prenomFormatted +=
      "-" +
      array[0].split("-")[1].charAt(0).toUpperCase() +
      array[0].split("-")[1].slice(1).toLowerCase();
  }
  const completeName = {
    prenom: prenomFormatted,
    nom: array
      .filter((item, index) => {
        return index !== 0;
      })
      .join(" ").toUpperCase(),
  };
  return completeName;
};

export const numberToFormat = amount => amount.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const handleChangeDateToMonth = (dateString) => {
  const months = ["JANVIER", "FÉVRIER", "MARS", "AVRIL", "MAI", "JUIN", "JUILLET", "AOÛT", "SEPTEMBRE", "OCTOBRE", "NOVEMBRE", "DÉCEMBRE"];
  const date = new Date(dateString);
  const monthIndex = date.getMonth();
  const year = date.getFullYear()
  const month = months[monthIndex];

  return `${month} ${year}`;
}

export function getContractSigningDate(echeances, dossierValue) {
  const dossierItems = echeances.filter(item => item.ndossier === dossierValue);

  if (dossierItems.length === 0) {
    return null;
  }

  dossierItems.sort((a, b) => b.amendment - a.amendment);
  return dossierItems[0]?.datepgcp;
}

  /**
   * Récupère les données du contact choisi
   * @param {[]} dossier 
   * @param {[]} contact 
   * @param {number} dossierValue 
   * @returns 
   */
  export function getContact(dossier,contact,dossierValue){
    for(let i=0;i<dossier.length;i++){
      if(dossierValue==dossier[i].ndossier){
        for(let j=0;j<contact.length;j++){
          if(dossier[i].idcontact==contact[j].idcontact){
            return contact[j]
          }
        }
      }
    }
    return []
  }
