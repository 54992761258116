import React from 'react';
import ReactTable from 'react-table-6';
class OppTable extends React.Component{
    render(){
        const data = [{
            nature: 'Opportunités en Attente',
            type: this.props.data.map(opp => opp.typeenattente),
            nbr: this.props.data.map(opp => opp.enattente)
          },{
            nature: 'Opportunités Date Tardive',
            type: this.props.data.map(opp => opp.typetardive),
            nbr: this.props.data.map(opp => opp.tardive)
          },{
            nature: 'Opportunités Non Confirmées',
            type: this.props.data.map(opp => opp.typenonconfirmee),
            nbr: this.props.data.map(opp => opp.nonconfirmees)
          },{
            nature: 'Opportunités Annulés',
            type: this.props.data.map(opp => opp.typeannulee),
            nbr: this.props.data.map(opp => opp.annulees)
          }
        ]     
          const columns = [{
            Header: 'Nature',
            accessor: 'nature'
          },{
            Header: 'Type',
            accessor: 'type'
          },{
            Header: 'Nombre',
            accessor: 'nbr'
          }]
      
          return (
                
                    <ReactTable
                      data={data}
                      columns={columns}
                      defaultPageSize = {4}
                      //pageSizeOptions = {[3, 6]}
                    />
                      
          );
    }
}
export default OppTable;