export const appointmentConst = {
  type: 'VIC',
  company: '',
  lot: '',
  representative: '',
  tel: '',
  mail: '',
  intervention_date: '',
  appointment_start: null,
  appointment_end: null,
  ppsps: false
}