export const FETCH_DOSSIER_BEGIN   = 'FETCH_DOSSIER_BEGIN';
export const FETCH_DOSSIER_SUCCESS = 'FETCH_DOSSIER_SUCCESS';
export const FETCH_DOSSIER_FAILURE = 'FETCH_DOSSIER_FAILURE';
export const FETCH_DOSSIER_INFOS_BEGIN   = 'FETCH_DOSSIER_INFOS_BEGIN';
export const FETCH_DOSSIER_INFOS_SUCCESS = 'FETCH_DOSSIER_INFOS_SUCCESS';
export const FETCH_DOSSIER_INFOS_FAILURE = 'FETCH_DOSSIER_INFOS_FAILURE';
export const GET_DOSSIER_INFOS = 'GET_DOSSIER_INFOS';

export function fetchDossier() {
    return dispatch => {
      dispatch(fetchDossierBegin());
      return fetch("/api/devis/dossier/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch(fetchDossierSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchDossierFailure(error)));
    };
  }

  export function fetchDossierInfos(dossierValue) {
    return dispatch => {
      dispatch(fetchDossierInfosBegin());
      return fetch("/api/devis/dossier/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          const filteredDossiers = json.filter(dossier => dossier.ndossier == dossierValue);
          dispatch(fetchDossierInfosSuccess(filteredDossiers[0]));
          return json;
        })
        .catch(error => dispatch(fetchDossierInfosFailure(error)));
    };
  }
  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

export const fetchDossierBegin = () => ({
  type: FETCH_DOSSIER_BEGIN
});

export const fetchDossierSuccess = dossier => ({
  type: FETCH_DOSSIER_SUCCESS,
  payload: { items:dossier }
});

export const fetchDossierFailure = error => ({
  type: FETCH_DOSSIER_FAILURE,
  payload: { error }
});

export const fetchDossierInfosBegin = () => ({
  type: FETCH_DOSSIER_INFOS_BEGIN
});

export const fetchDossierInfosSuccess = dossierInfos => ({
  type: FETCH_DOSSIER_INFOS_SUCCESS,
  payload: { item :dossierInfos }
});

export const fetchDossierInfosFailure = error => ({
  type: FETCH_DOSSIER_INFOS_FAILURE,
  payload: { error }
});


export const getDossierInfos = dossierInfos => ({
  type: GET_DOSSIER_INFOS,
  payload: { dossierInfos }
});