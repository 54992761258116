export const FETCH_CONTRACT_FAC_AMENDMENT_BEGIN = 'FETCH_CONTRACT_FAC_AMENDMENT_BEGIN';
export const FETCH_CONTRACT_FAC_AMENDMENT_SUCCESS = 'FETCH_CONTRACT_FAC_AMENDMENT_SUCCESS';
export const FETCH_CONTRACT_FAC_AMENDMENT_FAILURE = 'FETCH_CONTRACT_FAC_AMENDMENT_FAILURE';
export const CHANGE_CONTRACT_FAC_AMENDMENT_NUM_VALUE = 'CHANGE_CONTRACT_FAC_AMENDMENT_NUM_VALUE';
export const FETCH_FAC_AMENDMENT_BEGIN = 'FETCH_FAC_AMENDMENT_BEGIN';
export const FETCH_FAC_AMENDMENT_SUCCESS = 'FETCH_FAC_AMENDMENT_SUCCESS';
export const FETCH_FAC_AMENDMENT_FAILURE = 'FETCH_FAC_AMENDMENT_FAILURE';
export const CHANGE_FAC_AMENDMENT_NUM_VALUE = 'CHANGE_FAC_AMENDMENT_NUM_VALUE';
export const FETCH_FAC_AMENDMENT_BY_DOSS_BEGIN = 'FETCH_FAC_AMENDMENT_BY_DOSS_BEGIN';
export const FETCH_FAC_AMENDMENT_BY_DOSS_SUCCESS = 'FETCH_FAC_AMENDMENT_BY_DOSS_SUCCESS';
export const FETCH_FAC_AMENDMENT_BY_DOSS_FAILURE = 'FETCH_FAC_AMENDMENT_BY_DOSS_FAILURE';
export const FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_BEGIN = 'FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_BEGIN';
export const FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_SUCCESS = 'FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_SUCCESS';
export const FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_FAILURE = 'FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_FAILURE';

export function fetchAllContractFacAmendment() {
  return dispatch => {
    dispatch(fetchAllContractFacAmendmentBegin());
    return fetch("/api/facamendment/getAllContractFac")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchAllContractFacAmendmentSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchAllContractFacAmendmentFailure(error)));
  };
}

export function fetchAllFacAmendment() {
  return dispatch => {
    dispatch(fetchAllFacAmendmentBegin());
    return fetch("/api/facamendment/getAllFac")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchAllFacAmendmentSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchAllFacAmendmentFailure(error)));
  };
}

export function fetchFacAmendmentByDoss(ndossier) {
  return dispatch => {
    dispatch(fetchFacAmendmentByDossBegin());
    return fetch("/api/facamendment/getAllFac")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        const filteredFacs = json.filter(fac => fac.ndossier == ndossier).sort((a, b) => a.nfacture.localeCompare(b.nfacture));
        dispatch(fetchFacAmendmentByDossSuccess(filteredFacs));
        return filteredFacs;
      })
      .catch(error => dispatch(fetchFacAmendmentByDossFailure(error)));
  };
}

export function fetchFacAmendmentRecapByDoss(ndossier) {
  return dispatch => {
    dispatch(fetchFacAmendmentRecapByDossBegin());
    return fetch("/api/facamendment/getAllFacRecap")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        const filteredFacs = json.filter(fac => fac.ndossier == ndossier);
        dispatch(fetchFacAmendmentRecapByDossSuccess(filteredFacs));
        return filteredFacs;
      })
      .catch(error => dispatch(fetchFacAmendmentRecapByDossFailure(error)));
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchAllContractFacAmendmentBegin = () => ({
  type: FETCH_CONTRACT_FAC_AMENDMENT_BEGIN
});

export const fetchAllContractFacAmendmentSuccess = contractFacAmendment => ({
  type: FETCH_CONTRACT_FAC_AMENDMENT_SUCCESS,
  payload: { items: contractFacAmendment }
});

export const fetchAllContractFacAmendmentFailure = error => ({
  type: FETCH_CONTRACT_FAC_AMENDMENT_FAILURE,
  payload: { error }
});

export const changeContractFacAmendmentNumber = (newValue) => ({
  type: CHANGE_CONTRACT_FAC_AMENDMENT_NUM_VALUE,
  payload: { newValue }
});

export const fetchAllFacAmendmentBegin = () => ({
  type: FETCH_FAC_AMENDMENT_BEGIN
});

export const fetchAllFacAmendmentSuccess = facAmendment => ({
  type: FETCH_FAC_AMENDMENT_SUCCESS,
  payload: { items: facAmendment }
});

export const fetchAllFacAmendmentFailure = error => ({
  type: FETCH_FAC_AMENDMENT_FAILURE,
  payload: { error }
});

export const changeFacAmendmentNumber = (newValue) => ({
  type: CHANGE_FAC_AMENDMENT_NUM_VALUE,
  payload: { newValue }
});

export const fetchFacAmendmentByDossBegin = () => ({
  type: FETCH_FAC_AMENDMENT_BY_DOSS_BEGIN
});

export const fetchFacAmendmentByDossSuccess = facAmendment => ({
  type: FETCH_FAC_AMENDMENT_BY_DOSS_SUCCESS,
  payload: { items: facAmendment }
});

export const fetchFacAmendmentByDossFailure = error => ({
  type: FETCH_FAC_AMENDMENT_BY_DOSS_FAILURE,
  payload: { error }
});

export const fetchFacAmendmentRecapByDossBegin = () => ({
  type: FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_BEGIN
});

export const fetchFacAmendmentRecapByDossSuccess = facAmendment => ({
  type: FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_SUCCESS,
  payload: { items: facAmendment }
});

export const fetchFacAmendmentRecapByDossFailure = error => ({
  type: FETCH_FAC_AMENDMENT_RECAP_BY_DOSS_FAILURE,
  payload: { error }
});