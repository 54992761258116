import React from 'react';
import { connect } from 'react-redux';
import GenPdf from '../GenPdf';
import Header from '../Header/Header';
import NumDoss from '../NumDoss';
import DocMenu from "../DocMenu";
import ListTable from './FacAmendmentRecap/ListTable';
import { getLastContractAmendmentInfos, getTauxhRea, genFacAmendmentRecap, getFacAmendmentRecap } from "./Utils";
import { fetchAllFacAmendment, fetchFacAmendmentByDoss, fetchFacAmendmentRecapByDoss } from "../../redux/actions/facAmendmentActions";
import { fetchAllFacAmendmentReminder, fetchAllFacAmendmentReminderByDoss } from "../../redux/actions/reminderFacAmendmentActions";
import { fetchEcheances, fetchEcheancesByNdossier } from "../../redux/actions/echeancesActions";
import { fetchContrat } from "../../redux/actions/contratActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import "../../Styles/FactureAmendment/FacAmendmentRecap.scss";

class FacAmendmentRecap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      facturesData: [],
      tauxh: 0,
      totalCumulativeAmount: 0,
      totalAmount: 0,
      refFacRecap: "",
      bdcFacRecap: "",
      file: null,
      isFileOpen: false,
      disableButton: false,
    }
  }

  async componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { dossierValue } = this.props;
    await this.props.dispatch(fetchAllFacAmendment())
    await this.props.dispatch(fetchEcheances())
    await this.props.dispatch(fetchAllFacAmendmentReminder())

    if (!dossierValue) {
      return
    }
    await this.props.dispatch(fetchContrat(dossierValue))
    await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
    await this.props.dispatch(fetchFacAmendmentRecapByDoss(dossierValue))
    await this.props.dispatch(fetchEcheancesByNdossier(dossierValue))
    await this.props.dispatch(fetchAllFacAmendmentReminderByDoss(dossierValue))
    await this.loadData()
  }

  async componentDidUpdate(prevProps) {
    const { dossierValue, facAmendmentIndex } = this.props;

    if (prevProps.dossierValue !== dossierValue) {
      await this.props.dispatch(fetchContrat(dossierValue))
      await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
      await this.props.dispatch(fetchFacAmendmentRecapByDoss(dossierValue))
      await this.props.dispatch(fetchEcheancesByNdossier(dossierValue))
      await this.props.dispatch(fetchAllFacAmendmentReminderByDoss(dossierValue))
      await this.loadData()
    }

    if (prevProps.facAmendmentIndex !== facAmendmentIndex) {
      await this.loadData()
    }
  }

  getCumulativeAmount(indexItem, facAmendmentIndex) {
    const { facAmendmentsByDoss } = this.props
    if (indexItem - 1 > facAmendmentIndex) return 0
    return facAmendmentsByDoss.slice(0, indexItem).reduce((acc, item) => acc + item.total_ht, 0)
  }

  handleGetDateReminder = (refFacture, firstReminder, index) => {
    const { reminders, facAmendmentIndex } = this.props;
    if (index + 1 > facAmendmentIndex) return ""
    const formatRefFacture = `${refFacture} `;
    const currentReminders = reminders
      .filter(item => item.first_reminder === firstReminder && item.numclient.includes(formatRefFacture))
    const formatedDate = currentReminders.length > 0 ? new Date(currentReminders[0].date_reminder).toLocaleDateString('en-CA') : ""
    return formatedDate
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { devisrea, dossierValue, facAmendmentsByDoss, contractFacAmendments, facAmendmentIndex } = this.props
    const tauxh = getTauxhRea(devisrea, dossierValue)
    const bdcFacRecap = facAmendmentsByDoss[facAmendmentIndex - 1] ? facAmendmentsByDoss[facAmendmentIndex - 1].bdc_facture : ""
    let dates = []
    let amountEch = 0
    const currentContractAmendmentInfos = getLastContractAmendmentInfos(contractFacAmendments, dossierValue)
    if (currentContractAmendmentInfos !== null) {
      dates = currentContractAmendmentInfos.dates !== undefined ? JSON.parse(currentContractAmendmentInfos.dates) : []
      amountEch = facAmendmentsByDoss.length > 0 ? parseFloat(((currentContractAmendmentInfos.hours * facAmendmentsByDoss[0].pu_ht) / dates.length).toFixed(2)) : 0
    }

    const facturesData = dates.map((item, index) => {
      return ({
        month_ech: `${this.handleChangeDateToMonth(item.dateech)} FAC: ${index < facAmendmentIndex ? "A-" + facAmendmentsByDoss[index].nfacture.split(" ")[1] : ""} AR${index + 1}`,
        date_ech: this.formatDate(item.dateech),
        first_reminder: "",
        second_reminder: "",
        total_ht: facAmendmentsByDoss[index]?.total_ht ? facAmendmentsByDoss[index].total_ht : amountEch,
        cumulative_amount: this.getCumulativeAmount(index + 1, facAmendmentIndex - 1),
        first_reminder: this.handleGetDateReminder(`AR${index + 1}`, 1, index),
        second_reminder: this.handleGetDateReminder(`AR${index + 1}`, 0, index),
        payment_date: this.formatDate(index > facAmendmentIndex - 1 ? null : facAmendmentsByDoss[index]?.payment_date),
        validation: index > facAmendmentIndex - 1 ? false : facAmendmentsByDoss[index].payment_date === null ? false : true
      })
    });

    const totalCumulativeAmount = facturesData.reduce((maxValue, item) => {
      return Math.max(maxValue, item.cumulative_amount);
    }, 0)

    const totalAmount = facturesData.slice(0, facAmendmentIndex).reduce((acc, item) => acc + (!item.validation ? item.total_ht : 0), 0)

    this.setState({ facturesData, totalCumulativeAmount, totalAmount, tauxh, refFacRecap: "", bdcFacRecap, file: null })
  }

  handleChangeValues = (e) => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  formatDate = (date) => date ? new Date(date).toLocaleDateString('en-CA') : null;

  handleChangeDateToMonth(dateString) {
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const year = date.getFullYear()
    const month = months[monthIndex];

    return `${month} ${year}`;
  }

  initializeValues = () => {
    this.loadData()
    this.closeDoc()
  }

  handleSaveFacAmendmentReacp = async () => {
    const { facAmendmentsByDoss, dossierValue, facAmendmentIndex } = this.props
    const { facturesData, totalCumulativeAmount, totalAmount, refFacRecap, bdcFacRecap } = this.state
    this.setState({ disableButton: true })

    const id_facturation = facAmendmentsByDoss.filter(item => item.numclient.includes(`AR${facAmendmentIndex} `))[0].id

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: dossierValue,
        id_facturation,
        data: JSON.stringify(facturesData),
        cumulative_amount: totalCumulativeAmount,
        total_amount: totalAmount,
        ref_facture: refFacRecap,
        bdc_facture: bdcFacRecap,
      })
    }

    await fetch("/api/facamendment/createFacAmendmentRecap", requestOptions)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    await this.setState({ disableButton: false })
  }

  handleGetNfacture = () => {
    const { facAmendmentsByDoss, facAmendmentIndex } = this.props;
    let nfactures = ""

    for (let i = 0; i < facAmendmentIndex; i++) {
      const newNfacture = facAmendmentsByDoss[i].nfacture.split(" ").join("/A-")
      nfactures += facAmendmentsByDoss[i].payment_date === null ? nfactures.length > 0 ? " & " + newNfacture : newNfacture : "";
    }

    return nfactures;
  }

  handleGenFacAmendmentRecap = async () => {
    const { echeances, contacts, dossiers, dossierValue, contractFacAmendments, facAmendmentIndex, facAmendments } = this.props;
    this.setState({ file: null, isFileOpen: true, disableButton: true })

    const sortedFac = facAmendments.filter(item => item.ndossier == dossierValue)
    .sort((a, b) => a.numclient.localeCompare(b.numclient));
    const numclient = sortedFac.filter(item => item.numclient.includes(`FR ${dossierValue}/AR${facAmendmentIndex} `))[0].numclient
    const cumulative_total = Math.max(
      ...this.state.facturesData
        .filter((item) => typeof item.cumulative_amount === 'number')
        .map((item) => item.cumulative_amount || 0)
    );

    const amount_to_pay = this.state.facturesData.reduce((total, item, index) => {
      return index < facAmendmentIndex && !item.validation ? total + item.total_ht : total
    }, 0)

    const data = this.state.facturesData.map(item => {
      return {
        ...item,
        total_ht: item.total_ht.toFixed(2),
        validation: item.validation ? "100%" : "",
        cumulative_amount: item.cumulative_amount ? item.cumulative_amount.toFixed(2) : "",
      }
    });

    const currentContractAmendmentInfos = getLastContractAmendmentInfos(contractFacAmendments, dossierValue)
    const nbEchs = JSON.parse(currentContractAmendmentInfos.dates).length
    const echs = `${nbEchs} échéances ${currentContractAmendmentInfos.payment_method === 26 ? "bimestrielles" : "mensuelles"}`
    const amout_ech_ht = (this.state.tauxh * currentContractAmendmentInfos.hours) / nbEchs
    const currentDossier = dossiers.filter(item => item.ndossier === dossierValue)[0]
    const refFacture = `AR${facAmendmentIndex}`
    const maitreDouvrage = contacts.filter(item => item.idcontact === currentDossier.idcontact)[0]

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        dossier_op: currentDossier,
        date_signature: echeances.datepgcp,
        maitre_ouvrage: JSON.stringify(maitreDouvrage),
        ref_fac_recap: this.state.refFacRecap,
        bdc_fac_recap: this.state.bdcFacRecap,
        data,
        cumulative_total: cumulative_total.toFixed(2),
        amount_to_pay: amount_to_pay.toFixed(2),
        amount_to_pay_tva: (amount_to_pay * 0.2).toFixed(2),
        amount_to_pay_ttc: (amount_to_pay * 1.2).toFixed(2),
        totalht: (this.state.tauxh * currentContractAmendmentInfos.hours).toFixed(2),
        totalttc: ((this.state.tauxh * currentContractAmendmentInfos.hours) * 1.2).toFixed(2),
        amout_ech_ht: amout_ech_ht.toFixed(2),
        amout_ech_ttc: (amout_ech_ht * 1.2).toFixed(2),
        duration: currentContractAmendmentInfos.duration,
        echs,
        numclient,
        refFacture,
        nfacture: this.handleGetNfacture(),
      })
    };

    let blob = await genFacAmendmentRecap(requestOptions);
    if (blob) {
      this.setState({ file: blob, disableButton: false });
      await this.handleSaveFacAmendmentReacp();
    }
  }

  handleShowPdf = async () => {
    const { dossierValue, facAmendmentIndex } = this.props

    const refFacture = `AR${facAmendmentIndex}`
    this.setState({ file: null, disableButton: true });

    let response = await getFacAmendmentRecap(dossierValue, refFacture, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, isFileOpen: true }); 

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
    await this.setState({ disableButton: false });
  };

  handleGetPdfFile = async () => {
    const { dossierValue, facAmendmentIndex } = this.props
    const refFacture = `AR${facAmendmentIndex}`
    this.setState({ file: null });

    let response = await getFacAmendmentRecap(dossierValue, refFacture, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { dossierValue, facAmendmentIndex } = this.props
    const refFacture = `AR${facAmendmentIndex}`
    this.setState({ file: null });

    let response = await getFacAmendmentRecap(dossierValue, refFacture, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  closeDoc() {
    this.setState({ isFileOpen: false })
  }

  handleChangeTitleName() {
    const { facAmendmentIndex } = this.props
    this.props.dispatch(changeTitleName(`Facture Avenant Récapitulative AR${facAmendmentIndex}`))
  }

  render() {
    return (
      <div className="fac-recap-amendment">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">
          Facture Avenant Récapitulative
        </h1>
        <NumDoss type="facAmendment" />
        <div className="data">
          <ListTable
            facturesData={this.state.facturesData}
            totalCumulativeAmount={this.state.totalCumulativeAmount}
            totalAmount={this.state.totalAmount}
            refFacRecap={this.state.refFacRecap}
            bdcFacRecap={this.state.bdcFacRecap}
            disableButton={this.state.disableButton}
            handleChangeValues={this.handleChangeValues.bind(this)}
            handleSaveFacAmendmentReacp={this.handleSaveFacAmendmentReacp.bind(this)}
            handleGenFacAmendmentRecap={this.handleGenFacAmendmentRecap.bind(this)}
            handleShowPdf={this.handleShowPdf.bind(this)}
            initializeValues={this.initializeValues.bind(this)}
          />
          <GenPdf isOpen={this.state.isFileOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1 : 0.5} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  dossierValue: state.devis.dossierValue,
  dossiers: state.dossier.items,
  contacts: state.contact.items,
  contractFacAmendments: state.facAmendment.contractFacAmendments,
  facAmendments: state.facAmendment.facAmendments,
  facAmendmentsByDoss: state.facAmendment.facAmendmentsByDoss,
  facAmendmentsRecapByDoss: state.facAmendment.facAmendmentsRecapByDoss,
  facAmendmentIndex: state.facAmendment.facAmendmentIndex,
  infosContract: state.contrat.items,
  echeances: state.echeances.itemNdossier,
  reminders: state.reminderFacAmendment.items,
});
export default connect(mapStateToProps)(FacAmendmentRecap)