import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class PlomberiePlus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            iterator: 0
        }
    }
    render() {
        const column = [
            {
                key: 'title1',
                dataKey: 'title1',
                width: 300,
                flexGrow: 1,
                flexShrink: 0,
            },
            {
                key: 'value1',
                dataKey: 'value1',
                width: 300,
                align: Column.Alignment.CENTER,
                flexGrow: 2,
                cellRenderer: ({ rowData, rowIndex }) => {
                    if (rowIndex !== 0 && rowIndex !== 10 && rowIndex !== 13 && rowIndex !== 17) return (
                        <div>
                            <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].answer != 1 ? false : true} />
                            <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].answer != 0 ? false : true} />
                            <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex)}
                                checked={Object.values(this.props.valueArray)[rowIndex].observation} />
                        </div>
                    )
                },
            }, {
                key: 'title2',
                dataKey: 'title2',
                width: 300,
                flexGrow: 3,
            }, {
                key: 'value2',
                dataKey: 'value2',
                width: 300,
                flexGrow: 4,
                align: Column.Alignment.CENTER,
                cellRenderer: ({ rowIndex }) => {
                    return (<div>
                        <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex + 15)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 15].answer != 1 ? false : true} />
                        <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex + 15)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 15].answer != 0 ? false : true} />
                        <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex + 15)}
                            checked={Object.values(this.props.valueArray)[rowIndex + 15].observation} />
                    </div>)
                }
            }
        ]
        const data = [
            {
                id: 1,
                title1: "Travail en Hauteur : Mise en place / Utilisation",
                title2: 'Propre à l’entreprise',
            },
            {
                id: 2,
                title1: "A mettre en place",
                title2: "A demander au donneur d’ordre",
            },
            {
                id: 3,
                title1: 'Collectifs existants',
                title2: 'Ascenseurs – lift – monte-charge camion - Recette',
            },
            {
                id: 4,
                title1: 'Propre à l’entreprise',
                title2: 'Port harnais envisageable',
            },
            {
                id: 5,
                title1: 'Les Echelles, escabeaux',
                title2: 'Protection platelage à tous les étages',
            },
            {
                id: 6,
                title1: 'PIR (Plates –formes-individuelles)',
                title2: 'Eclairage escaliers-gaine ascenseurs',
            },
            {
                id: 7,
                title1: 'Nacelles à ciseaux / Bras télescopique',
                title2: "L’installation d’une Grue mobile",
            },
            {
                id: 8,
                title1: 'Echafaudages',
                title2: 'Autorisation communale',
            },
            {
                id: 9,
                title1: 'Plates-formes suspendues',
                title2: 'Zone protégée prévue',
            },
            {
                id: 10,
                title1: "Moyens de levage mécanique",
                title2: 'A mettre en place',
            },
            {
                id: 11,
                title1: "Contenaires envisagés",
                title2: "Examen d’Adéquation à faire",
            },
            {
                id: 12,
                title1: 'A mettre en place',
                title2: 'Les Voies De Circulation Extérieure Et Point Chaud',
            },
            {
                id: 13,
                title1: 'Propre à l’entreprise',
                title2: 'A mettre en place',
            },
            {
                id: 14,
                title1: 'Mettre en place un chef manœuvre pour les opérations de guidage',
                title2: "Utilisation d’un chalumeau",
            },
            {
                id: 15,
                title1: 'A mettre en place',
                title2: 'Obligation de maintenir les voies libres',
            },
        ];

        var spanIndex = 2
        column[0].colSpan = ({ rowData, rowIndex }) => 2
        column[0].align = Column.Alignment.CENTER
        column[2].colSpan = ({ rowData, rowIndex }) => 2
        column[2].align = Column.Alignment.CENTER

        const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
            const span = columns[spanIndex].colSpan({ rowData, rowIndex })
            if (rowIndex === 0 || rowIndex === 10 || rowIndex === 13) {
                let width = cells[0].props.style.width
                for (let i = 1; i < span; i++) {
                    width += cells[0 + i].props.style.width
                    cells[0 + i] = null
                }
                const style = {
                    ...cells[0].props.style,
                    width,
                    color: "rgb(204, 102, 0)",
                    fontStyle: "italic",
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    border: "1px solid black",
                }
                cells[0] = React.cloneElement(cells[0], { style })
            }
            if (rowIndex === 2 || rowIndex === 6 || rowIndex === 11) {
                let width = cells[2].props.style.width
                for (let i = 1; i < span; i++) {
                    width += cells[2 + i].props.style.width
                    cells[2 + i] = null
                }
                const style = {
                    ...cells[2].props.style,
                    width,
                    color: "rgb(204, 102, 0)",
                    fontStyle: "italic",
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    border: "1px solid black"
                }
                cells[2] = React.cloneElement(cells[2], { style })
            }
            return cells
        }

        return (
            <div className="testTable">
                <BaseTable rowKey="id" columns={column} width={1200} height={750} data={data} headerHeight={0} rowRenderer={rowRenderer} />
            </div>
        )
    }
}
export default PlomberiePlus