import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';

class ConfirmMail extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    notify = () => toast("Vous avez envoyé un mail");  

    render(){
        return(
            <Dialog
            open={this.props.open}
            onClose={() => this.props.setOpen(false)}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">{"Confirmation envoi du mail"}</DialogTitle>
            <DialogContent>{"Voulez-vous vraiment envoyer ce mail ?"}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                    this.props.onConfirm();
                    this.notify();
                }}
                color="secondary"
              >
                Oui
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                }}
                color="default"
              >
                Non
              </Button>
            </DialogActions>
          </Dialog>
        )
    }
}
export default ConfirmMail;