import React from 'react';
import DialogModifOp from './DialogModifOp';
import DialogOp from './DialogOp';
import FormDialog from './FormDialog';
import DialogComp from './DialogComp';
import DialogModifComp from './DialogModifComp';
import DialogDevis from './DialogDevis';
import DialogModifDevis from './DialogModifDevis';
import DialogDelValidation from './DialogDelValidation';

class Dialogs extends React.Component {
  constructor(props){
    super(props)
    this.state={
        isAdmin: false,
        modifOp: false,
        modifComp:false,
        index:0
    }
    this.showNextDialog=this.showNextDialog.bind(this)
  }
  showModif(){
      this.setState({isAdmin:true})
  }
  showNextDialog(index){
      this.setState({index:index})
  }
  showDialogs(index){
    const dialogs =[
        <DialogDelValidation showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogOp showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogModifOp showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogComp showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogModifComp showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogDevis showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogModifDevis showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>
    ]
    return dialogs[index]
  }
    render() {
      return (
        <div> 
            <FormDialog showModif={this.showModif.bind(this)} closeDialog={this.props.closeDialog}/>
            {this.state.isAdmin && this.showDialogs(this.state.index)}
        </div>       
      )
    }
  }
export default Dialogs