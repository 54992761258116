import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";

const months = [
  "Janvier",
  "Fevrier",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Decembre",
];

class EffectifsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const companyList = [""];
    this.props.companyList.forEach((element) => {
      companyList.push(element.name_company);
    });
    const data = this.props.valueArray;
    const column = [
      {
        title: "Entreprise",
        key: "company",
        dataKey: "company",
        width: 200,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) =>
          rowIndex === 20 ? (
            <p>{rowData.company}</p>
          ) : (
            <select
              name="company"
              style={{
                width: "100%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "14px",
              }}
              value={rowData.company !== "" ? rowData.company : ""}
              onChange={(event) =>
                this.props.handleChangeValue(event, rowIndex)
              }
            >
              {companyList.map((item, index) => (
                <option
                  value={item}
                  key={index}
                  defaultValue={index === 0 && rowData.company === ""}
                >
                  {item}
                </option>
              ))}
            </select>
          ),
      },
    ];

    for (let i = 0; i < 13; i++) {
      if (i !== 12) {
        column.push({
          title: months[i].slice(0, 3),
          key: months[i].toLocaleLowerCase(),
          dataKey: months[i].toLocaleLowerCase(),
          width: 60,
          align: Column.Alignment.CENTER,
          cellRenderer: ({ rowData, rowIndex }) =>
            rowIndex == 20 ? (
              <p>
                {rowData[months[i].toLocaleLowerCase()] !== 0
                  ? rowData[months[i].toLocaleLowerCase()]
                  : ""}
              </p>
            ) : (
              <input
                disabled={rowData.company !== "" ? false : true}
                style={{
                  width: "100%",
                  pointerEvents: "visible",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                name={months[i].toLocaleLowerCase()}
                onChange={(event) =>
                  this.props.handleChangeValue(event, rowIndex)
                }
                value={
                  rowData[months[i].toLocaleLowerCase()] != 0
                    ? rowData[months[i].toLocaleLowerCase()]
                    : ""
                }
              />
            ),
        });
      } else {
        column.push({
          title: "MOYENNE PAR ENTREPRISE",
          key: "average",
          dataKey: "average",
          width: 130,
          align: Column.Alignment.CENTER,
          cellRenderer: ({ rowData, rowIndex }) =>
            rowData.average != 0 ? <p>{rowData.average}</p> : "",
        });
      }
    }

    return (
      <div className="effectifs-table">
        <BaseTable
          columns={column}
          width={1050}
          headerHeight={50}
          rowHeight={30}
          height={this.props.valueArray.length * 30 + 50}
          data={data}
        ></BaseTable>
      </div>
    );
  }
}
export default EffectifsTable;
