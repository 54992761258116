import React from "react";
import ReactTable from "react-table-6";
import { connect } from "react-redux";
import AutoSuggestComp from "./AutoSuggestComp";
import AutoSuggestRespComp from "./AutoSuggestRespComp";

class CompTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const TheadComponent = (props) => null;
    const columns = [
      {
        Header: "",
        accessor: "title1",
      },
      {
        Header: "",
        accessor: "title2",
      },
      {
        Header: "",
        accessor: "value1",
        Cell: (props) => {
          if ([0, 1, 4].indexOf(props.row._index) === -1)
            return (
              <input
                name={Object.keys(this.props.compInfos)[props.row._index]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                type="text"
                onChange={this.props.handleChange}
                value={Object.values(this.props.compInfos)[props.row._index]}
                autoComplete="off"
              />
            );
          if (props.row._index == 1)
            return (
              <input
                name={Object.keys(this.props.compInfos)[props.row._index]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "left",
                  fontSize: "14px",
                }}
                type="text"
                onChange={this.props.handleChange}
                value={Object.values(this.props.compInfos)[props.row._index]}
                autoComplete="off"
              />
            );
          if (props.row._index === 4)
            return (
              <AutoSuggestRespComp
                company={this.props.compInfos.company}
                nomres={this.props.compInfos.nomres}
                handleChange={this.props.handleChange}
                setRespComp={this.props.setRespComp}
              />
            );
          if (props.row._index === 0)
            return (
              <AutoSuggestComp
                company={this.props.compInfos.company}
                handleChange={this.props.handleChange}
                setAllState={this.props.setAllState}
              />
            );
        },
      },
      {
        Header: "",
        accessor: "title3",
      },
      {
        Header: "",
        accessor: "value2",
        Cell: (props) => {
          if ([2, 3, 4].includes(props.row._index))
            return (
              <input
                name={Object.keys(this.props.compInfos)[props.row._index + 4]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                type="text"
                maxLength="10"
                onChange={this.props.handleChange}
                value={Object.values(this.props.compInfos)[props.row._index + 4]}
                autoComplete="off"
              />
            );
          if (props.row._index === 5)
            return (
              <div>
                <label>Oui</label>
                <input
                  name={Object.keys(this.props.compInfos)[props.row._index + 4] + "-answer"}
                  value={1}
                  type="checkbox"
                  onChange={this.props.handleCheckChange}
                  checked={Object.values(this.props.compInfos)[props.row._index + 4].answer != 1 ? false : true}
                />
                <label>Non</label>
                <input
                  name={Object.keys(this.props.compInfos)[props.row._index + 4] + "-answer"}
                  value={0}
                  type="checkbox"
                  onChange={this.props.handleCheckChange}
                  checked={Object.values(this.props.compInfos)[props.row._index + 4].answer != 0 ? false : true}
                />
                <label>Observation</label>
                <input
                  name={Object.keys(this.props.compInfos)[props.row._index + 4] + "-observation"}
                  type="checkbox"
                  onChange={this.props.handleCheckChange}
                  checked={Object.values(this.props.compInfos)[props.row._index + 4].observation}
                />
              </div>
            );
        },
      },
    ];
    const data = [
      {
        title2: "Nom :",
      },
      {
        title2: "Adresse :",
      },
      {
        title2: "Ville :",
        title3: "Code Postal :",
      },
      {
        title1: "Entreprise :",
        title2: "Téléphone :",
        title3: "Fax :",
      },
      {
        title2: "Responsable Entreprise :",
        title3: "Portable :",
      },
      {
        title2: "E-Mail :",
        title3: "PPSPS :",
      },
    ];
    return (
      <div className="comptable">
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={6}
          TheadComponent={TheadComponent}
        />
      </div>
    );
  }
}
export default connect()(CompTable);
