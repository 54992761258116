import React, { useEffect } from "react";
import List from "./List";
import history from "../../Routes/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faHome } from "@fortawesome/free-solid-svg-icons";

export default class LeftHeader extends React.Component {
  constructor(props) {
    super(props);
    this.listWrapper = React.createRef();
    this.changeListState = this.changeListState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      //display:[],
    };
  }

  /**
   * Renvoie vers la page dashboard
   */
  clickToHome = () => {
    history.push("/dashboard");
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Affiche la liste des différentes options de l'ERP
   */
  changeListState(event) {
    const wrapper = this.listWrapper.current;
    wrapper.classList.toggle("is-list-enabled");
  }

  handleClickOutside(event) {
    if (
      this.listWrapper &&
      !this.listWrapper.current.contains(event.target) &&
      event.target.parentElement &&
      event.target.parentElement.className !== "listButton" &&
      this.listWrapper.current.classList[1] === "is-list-enabled"
    ) {
      const wrapper = this.listWrapper.current;
      wrapper.classList.toggle("is-list-enabled");
    }
  }

  render() {
    return (
      <div className="leftheader">
        <span className="listButton" onClick={this.changeListState}>
          <FontAwesomeIcon icon={faList} />
        </span>
        <span onClick={this.clickToHome}>
          <FontAwesomeIcon icon={faHome} />
        </span>
        <List
          listWrapper={this.listWrapper}
          changeListState={this.changeListState}
        />
      </div>
    );
  }
}
