import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmDoss extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        return(
            <Dialog
            open={this.props.open}
            onClose={() => this.props.setOpen(false)}
            aria-labelledby="confirm-dialog">
             <DialogTitle id="confirm-dialog">{"Confirmation création d'un dossier"}</DialogTitle>
             <DialogContent>{"Voulez-vous vraiment créer un dossier ?"}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                    this.props.onConfirm();
                }}
                style={{ backgroundColor:"#FF7F50", color:"white"}}
              >
                Confirmer
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                    this.props.setOpen(false);
                }}
              >
                Annuler
              </Button>
            </DialogActions>
          </Dialog>
        )
    }
}
export default ConfirmDoss;