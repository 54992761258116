import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import history from "../../Routes/history";
import { numberToFormat } from "../../Utils";

class FactureConData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datecomptable: null,
    }
  }

  render() {
    const { datepgcp, datepgce, bdcValue, is26Percent, first_slice, datecomptable, handleChange, sortedFac, mo, dossier } = this.props;
    const ht = this.props.deviscon?.totalht * (is26Percent ? first_slice ? 0.26 : 0.74 : 1);
    
    return (
      <div className="facture-condata">
        <TableContainer className="table-data" component={Paper}>
          <Table aria-label="simple table">
            <TableHead className="table-head">
              <TableRow className="head-row">
                <TableCell className="MuiTableCell-head" colSpan={2}>
                  Données de Conception
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Réference:</TableCell>
                <TableCell>
                  <Typography>{dossier.operation}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Code Client:</TableCell>
                <TableCell>
                  <Typography name="codeclient">{sortedFac.numclient}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>N° de Facture:</TableCell>
                <TableCell>
                  <Typography name="numfacture">{sortedFac.new_nfac}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nom de l'entreprise:</TableCell>
                <TableCell>
                  <Typography name="nomentreprise">{dossier.sci_sccv}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nom du responsable:</TableCell>
                <TableCell>
                  <Typography name="nomresponsable">{mo.prenom + " " + mo.nom}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse:</TableCell>
                <TableCell>
                  <Typography name="adresse">{dossier.adresse_op}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Code Postal:</TableCell>
                <TableCell>
                  <Typography name="codepostal">{dossier.cp_op}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ville:</TableCell>
                <TableCell>
                  <Typography name="ville">{dossier.ville_op}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse Mail:</TableCell>
                <TableCell>
                  <Typography name="adressemail">{mo.mail}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date de Facture:</TableCell>
                <TableCell>
                  <input
                    type="date"
                    name="datefacture"
                    style={{ marginLeft: "0" }}
                    value={first_slice ? datepgcp || '' : datepgce || ''}
                    onChange={(event) => handleChange(event)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date d'établissement:</TableCell>
                <TableCell>
                  <input
                    type="date"
                    name="datecomptable"
                    style={{ marginLeft: "0" }}
                    value={datecomptable || ''}
                    onChange={(event) => handleChange(event)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>N° BDC:</TableCell>
                <TableCell>
                  <input
                    type="text"
                    name="bdcValue"
                    value={bdcValue}
                    placeholder="Bon de commande"
                    onChange={(event) => handleChange(event)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant HT:</TableCell>
                <TableCell>
                  <Typography name="montantht">{ht ? numberToFormat(ht) : ''} €</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant TTC:</TableCell>
                <TableCell>
                  <Typography name="montantht">{ht ? numberToFormat(ht * 1.2) : ''} €</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {!sortedFac.new_nfac && <Button className="create-btn" variant="contained" color="primary" onClick={() => {
            if (!this.props.deviscon) {
              history.push("/devis/validation");
            } else this.props.createFact()
          }}>{!this.props.deviscon ? 'Valider le devis' : `Créer la facture de ${!is26Percent ? '34%' : first_slice ? '26%' : '74%'}`}</Button>}
        </TableContainer>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  facture: state.facture.items,
  nfacvalue: state.facture.nfacvalue,
  dossierValue: state.devis.dossierValue,
  itemNdossier: state.echeances.itemNdossier
});
export default connect(mapStateToProps)(FactureConData);