import React from 'react';
import '../../Styles/LoginPage/Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import LogoCoordo from '../../Styles/Pictures/logo_coordogec.jpg';
import SocialNetwork from './SocialNetwork.js';
import {Link} from "react-router-dom"
class Confirm extends React.Component{
    /**
     * Renvoie les données de l'utilisateur correspondant au code de confirmation
     * @param {string} code 
     */
    getConfirmationCode(code){
        fetch("/api/confirm/"+code).then((response) => {
            return response.data;
        })
    }
    render(){
        if (this.props.match.path === "/confirm/:confirmationCode") {
            console.log(this.getConfirmationCode(this.props.match.params.confirmationCode));
          }
        return(
            <div className="confirm">
                <style>
                @import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
                </style>
                <div className="header">
                    <img src={LogoCoordo} alt="Bonjour"></img><br></br>
                    <span className="text">Bienvenue à notre ERP</span>
                </div>
                <div className="verified"><p>Votre Compte est bien vérifié</p>
                <p>Vous pouvez maintenant vous connectez</p>
                <Link to={"/login"}>Login</Link></div>
                <div className="footer">
                    <SocialNetwork/>
                    <div className="copyright">
                        <FontAwesomeIcon icon={faCopyright}/>
                        <label>  Coordogec, 2020 - Tous droits Réservés</label>
                    </div>
                </div>
            </div>
        );
    }
}
export default Confirm;