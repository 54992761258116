import React from "react";
import Header from "../../Header/Header";
import AffaireTable from "./AffaireTable";
import "../../../Styles/VIC/Page1.scss";
import CompTable from "./CompTable";
import TravauxTable from "./TravauxTable";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import VICMenu from "../VICMenu";
import { connect } from "react-redux";
import {
  getCompleteName,
  getRespEntreprise,
  getEntrepriseFromName,
} from "../../Utils";
import {
  genPDF,
  getClient,
  getLotNbrPages,
  getPDF,
  getRespComp,
} from "../Utils";
import { fetchEntreprise } from "../../../redux/actions/entrepriseActions";
import { fetchContact } from "../../../redux/actions/contactActions";
import { fetchVIC } from "../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import GenPdf from "../../GenPdf";
import ArrowRight from "@material-ui/icons/ArrowRight";
import history from "../../Routes/history";
import DocMenu from "../../DocMenu";

class Page1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isOpen: false,
      avancement: 0,
      date: new Date().toISOString().substring(0, 10),
      company: "",
      adresse: "",
      ville: "",
      tel: "",
      nomres: "",
      mail: "",
      cp: "",
      fax: "",
      port: "",
      ppsps: { answer: 0, observation: false },
      resp_chan: "",
      soustraitant: { answer: 0, observation: false },
      taches: "",
      intervention: "",
      pgc: { answer: 0, observation: false },
      resp_tel: "",
      nom_soustraitant: "",
      dureeJour: 0,
      dureeMois: 0,
      mineff: 0,
      maxeff: 0,
      id_respcomp: "",
      id_respchan: "",
      identreprise: "",
      loading: false,
      disableButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleValidClick = this.handleValidClick.bind(this);
  }

  componentDidMount() {
    // this.props.dispatch(fetchEntreprise());
    // this.props.dispatch(fetchContact());
    // this.props.dispatch(fetchVIC());
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.lotValue !== prevProps.lotValue ||
      this.props.entrepriseValue !== prevProps.entrepriseValue ||
      this.props.vic !== prevProps.vic
    ) {
      this.loadData();
    }
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    var entreprise = "";
    const { dossierValue, vic, lotValue, entrepriseValue, contact } = this.props;
    var currentVic = vic.filter((v) => {
      if (dossierValue == v.ndossier && lotValue == v.lots) {
        if (entrepriseValue) {
          entreprise = JSON.parse(entrepriseValue);
          if (entreprise.id == v.identreprise) {
            return true;
          }
        }
      } else return false;
    })[0];
    if (currentVic) {
      var travaux = JSON.parse(currentVic.travaux);
      var resp = getRespComp(contact, entreprise.id);
      this.setState({
        avancement: currentVic.avancement,
        date: new Date(currentVic.date).toISOString().slice(0, 10),
        company: entreprise.nom,
        adresse: entreprise.adresse,
        ville: entreprise.ville,
        tel: entreprise.tel,
        nomres: resp.nom !== "" ? resp.prenom + " " + resp.nom : resp.prenom,
        mail: resp.mail !== null ? resp.mail : "",
        cp: entreprise.code_postal,
        fax: entreprise.fax,
        port: resp.tel_port !== null ? resp.tel_port : "",
        ppsps: JSON.parse(currentVic.ppsps),
        id_respcomp: resp.idcontact,
        id_respchan: currentVic.id_respchan,
        identreprise: entreprise.id,
        file: null,
      });
      if (travaux) {
        this.setState({
          resp_chan: travaux.resp_chan,
          soustraitant: travaux.soustraitant,
          taches: travaux.taches,
          intervention: travaux.intervention,
          pgc: travaux.pgc,
          resp_tel: travaux.resp_tel !== null ? travaux.resp_tel : "",
          nom_soustraitant: travaux.nom_soustraitant,
          dureeJour: travaux.dureeJour,
          dureeMois: travaux.dureeMois,
          mineff: travaux.mineff,
          maxeff: travaux.maxeff,
          file: null,
        });
      } else {
        this.setState({
          resp_chan: "",
          soustraitant: { answer: 0, observation: false },
          taches: "",
          intervention: "",
          pgc: { answer: 0, observation: false },
          resp_tel: "",
          nom_soustraitant: "",
          dureeJour: 0,
          dureeMois: 0,
          mineff: 0,
          maxeff: 0,
          file: null,
        });
      }
    } else {
      this.setState({
        avancement: 0,
        date: new Date().toISOString().substring(0, 10),
        company: "",
        adresse: "",
        ville: "",
        tel: "",
        nomres: "",
        mail: "",
        cp: "",
        fax: "",
        port: "",
        ppsps: { answer: 0, observation: false },
        id_respcomp: "",
        resp_chan: "",
        soustraitant: { answer: 0, observation: false },
        taches: "",
        intervention: "",
        pgc: { answer: 0, observation: false },
        resp_tel: "",
        nom_soustraitant: "",
        dureeJour: 0,
        dureeMois: 0,
        mineff: 0,
        maxeff: 0,
        id_respchan: "",
        file: null,
      });
    }
  }

  handleChange(event) {
    if (event.target.name === "company") {
      const regex = /[\\/*^?"'&@#ç!§(°%$£;:€)={}\[\]]/g;
      const value = event.target.value;
      if (regex.test(value)) {
        alert(`Le caractère que vous avez saisi '${value.charAt(value.length - 1)}' n'est pas autorisé !`);
        return;
      }
      this.setState({ [event.target.name]: event.target.value });
      if (
        getEntrepriseFromName(event.target.value, this.props.entreprise).id ===
        undefined
      ) {
        this.setState({
          file: null,
          isOpen: false,
          avancement: 0,
          date: new Date().toISOString().substring(0, 10),
          adresse: "",
          ville: "",
          tel: "",
          nomres: "",
          mail: "",
          cp: "",
          fax: "",
          port: "",
          ppsps: { answer: 0, observation: false },
          id_respcomp: "",
          identreprise: "",
          loading: false,
          disableButton: false,
        });
        this.setState({
          resp_chan: "",
          soustraitant: { answer: 0, observation: false },
          taches: "",
          intervention: "",
          pgc: { answer: 0, observation: false },
          resp_tel: "",
          nom_soustraitant: "",
          dureeJour: 0,
          dureeMois: 0,
          mineff: 0,
          maxeff: 0,
          id_respchan: "",
        });
      }
    } else if (event.target.name === "nomres") {
      let prevIdRespComp = this.state.id_respcomp;
      this.setState({ [event.target.name]: event.target.value });
      this.setState({
        id_respcomp: prevIdRespComp !== "" ? prevIdRespComp : "",
      });
    } else if (event.target.name === "resp_chan") {
      let prevIdRespChan = this.state.id_respchan;
      this.setState({ [event.target.name]: event.target.value });
      this.setState({
        id_respchan: prevIdRespChan !== "" ? prevIdRespChan : "",
      });
    } else this.setState({ [event.target.name]: event.target.value });
  }

  handleCheckChange(event) {
    if (event.target.name.includes("answer")) {
      var name = event.target.name;
      this.setState((prevState) => ({
        [name.substring(0, name.indexOf("-"))]: {
          ...prevState[name.substring(0, name.indexOf("-"))],
          answer: event.target.value,
        },
      }));
    } else if (event.target.name.includes("observation")) {
      var name = event.target.name;
      this.setState((prevState) => ({
        [name.substring(0, name.indexOf("-"))]: {
          ...prevState[name.substring(0, name.indexOf("-"))],
          observation: event.target.checked,
        },
      }));
    }
  }

  setAllState(suggestion) {
    const resp = getRespEntreprise(suggestion.id_resp, this.props.contact);
    this.setState({
      company: suggestion.nom,
      adresse: suggestion.adresse,
      ville: suggestion.ville,
      tel: suggestion.tel,
      nomres: resp.nom !== "" ? resp.prenom + " " + resp.nom : resp.prenom,
      mail: resp.mail,
      cp: suggestion.code_postal,
      fax: suggestion.fax,
      port: resp.tel_port,
      id_respcomp: suggestion.id_resp,
      identreprise: suggestion.id,
    });
  }

  setRespComp(suggestion) {
    if (suggestion.nom == "") {
      this.setState({ nomres: suggestion.prenom });
    } else {
      this.setState({
        nomres:
          suggestion.nom !== ""
            ? suggestion.prenom + " " + suggestion.nom
            : suggestion.prenom,
        port: suggestion.tel_port,
        mail: suggestion.mail,
        id_respcomp: suggestion.idcontact,
      });
    }
  }

  setRespChan(suggestion) {
    this.setState({
      resp_chan:
        suggestion.nom !== ""
          ? suggestion.prenom + " " + suggestion.nom
          : suggestion.prenom,
      resp_tel: suggestion.tel_port,
    });
  }

  async addNewCompany() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        nom: this.state.company,
        adresse: this.state.adresse,
        ville: this.state.ville,
        code_postal: this.state.cp,
        fax: this.state.fax,
        tel: this.state.tel,
      }),
    };
    return await fetch("/api/entreprise/new", requestOptions).catch((err) => {
      console.log(err);
    });
  }

  async addNewResp() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        company: this.state.company,
      }),
    };
    return await fetch("/api/entreprise/newResp", requestOptions).catch(
      (err) => {
        console.log(err);
      }
    );
  }

  async addNewContact(newContact, newRespComp) {
    const nom = await getCompleteName(newContact).nom;
    const prenom = await getCompleteName(newContact).prenom;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        nom,
        prenom,
        company: this.state.company,
        mail: this.state.mail,
        tel_port: newRespComp ? this.state.port : this.state.resp_tel,
        fonction: newRespComp
          ? "Responsable Entreprise"
          : "Responsable Chantier",
      }),
    };
    return await fetch("/api/contact/new", requestOptions).catch((err) => {
      console.log(err);
    });
  }

  async update(identreprise, compInfos, travInfos) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        lotValue: this.props.lotValue,
        avancement: this.state.avancement,
        date: this.state.date,
        compInfos: compInfos,
        travInfos: travInfos,
        identreprise: identreprise,
        nomResCompany: getCompleteName(this.state.nomres).nom,
        prenomResCompany: getCompleteName(this.state.nomres).prenom,
        nomResChantier: getCompleteName(this.state.resp_chan).nom,
        prenomResChantier: getCompleteName(this.state.resp_chan).prenom,
        resCompanyId: this.state.id_respcomp,
        resChantierId: this.state.id_respchan,
      }),
    };
    await fetch("/api/entreprise/vicupdate", requestOptions).catch((err) => {
      console.log(err);
    });
    await fetch("/api/contact/vicupdate", requestOptions).catch((err) => {
      console.log(err);
    });
    return fetch("/api/vic/page1Modif", requestOptions).catch((err) => {
      console.log(err);
    });
  }

  async updateCompany(compInfos, identreprise) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        compInfos: compInfos,
        identreprise: identreprise,
      }),
    };
    return await fetch("/api/entreprise/vicupdate", requestOptions).catch(
      (err) => {
        console.log(err);
      }
    );
  }

  async updateRespComp(compInfos, idcontact) {
    const prenom = getCompleteName(compInfos.nomres).prenom;
    const nom = getCompleteName(compInfos.nomres).nom;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        prenom,
        nom,
        mail: compInfos.mail,
        port: compInfos.port,
        id: idcontact,
      }),
    };
    return await fetch("/api/contact/vicupdateresp", requestOptions).catch(
      (err) => {
        console.log(err);
      }
    );
  }

  async handleValidClick(compInfos, travInfos, isCreated) {
    this.setState({ loading: true, disableButton: true });
    const { entreprise, contact, dossierValue, lotValue } = this.props;
    const company = await getEntrepriseFromName(this.state.company, entreprise);
    if (isCreated) {
      const respValue = await getRespComp(contact, company.id);
      if (company.id === undefined) {
        await this.addNewCompany();
        await this.addNewContact(compInfos.nomres, true);
        await this.addNewContact(travInfos.resp_chan, false);
        await this.addNewResp();
      } else {
        if (
          compInfos.adresse !== company.adresse ||
          compInfos.ville !== company.ville ||
          compInfos.cp !== company.code_postal ||
          compInfos.tel !== company.tel ||
          compInfos.fax !== company.fax
        ) {
          await this.updateCompany(compInfos, company.id);
        }
        if (
          (
            respValue.prenom.toLowerCase() +
            " " +
            respValue.nom.toLowerCase()
          ).trim() !== compInfos.nomres.toLowerCase().trim() ||
          respValue.tel_port !== compInfos.port ||
          respValue.mail !== compInfos.mail
        ) {
          await this.updateRespComp(compInfos, respValue.idcontact);
        }
        await this.addNewContact(travInfos.resp_chan, false);
      }
      const respChan = await getCompleteName(travInfos.resp_chan);
      travInfos.resp_chan =
        respChan.nom !== ""
          ? respChan.prenom + " " + respChan.nom
          : respChan.prenom;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ndossier: dossierValue,
          lotValue: lotValue,
          avancement: this.state.avancement,
          date: this.state.date,
          ppsps: compInfos.ppsps,
          travInfos: travInfos,
        }),
      };
      await fetch("/api/vic/page1", requestOptions)
        .then(async (res) => {
          if (res.ok) {
            await this.props.dispatch(fetchEntreprise());
            await this.props.dispatch(fetchContact());
            await this.props.dispatch(fetchVIC(dossierValue));
            await this.loadData();
            await this.setState({ loading: false, disableButton: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await this.update(company.id, compInfos, travInfos)
        .then(async (res) => {
          if (res.ok) {
            await this.props.dispatch(fetchEntreprise());
            await this.props.dispatch(fetchContact());
            await this.props.dispatch(fetchVIC(dossierValue));
            await this.loadData();
            await this.setState({ loading: false, disableButton: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }

  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  filterEntreprise() {
    return this.props.entreprise.filter((item) => {
      for (const vic of this.props.vic) {
        if (
          vic.identreprise == item.id &&
          vic.ndossier == this.props.dossierValue &&
          vic.lots == this.props.lotValue
        ) {
          return true;
        }
      }
      return false;
    });
  }

  disableValidButton(compInfos, travInfos) {
    const compValues = Object.values(compInfos);
    const travValues = Object.values(travInfos);
    if (
      this.props.entreprise !== undefined &&
      this.props.entreprise.length !== 0
    ) {
      for (const entreprise of this.filterEntreprise()) {
        if (entreprise.nom.toLowerCase() === this.state.company.toLowerCase()) {
          return true;
        }
      }
    }
    for (let i = 0; i < 9; i++) {
      if (compValues[i] === "" && i !== 7) {
        return true;
      }
    }
    for (let i = 0; i < travValues.length - 1; i++) {
      if (travValues[i] === "" && i !== 1 && i !== 4 && i !== 6) {
        return true;
      }
    }
    if (this.state.loading) {
      return true;
    }
    return false;
  }

  disableModifyButton(entreprise) {
    if (this.state.loading) {
      return true;
    }
    if (entreprise.id === undefined) {
      return true;
    }
    if (
      this.props.entreprise !== undefined &&
      this.props.entreprise.length !== 0
    ) {
      const filteredEntreprise = this.filterEntreprise();
      for (const entrepriseValue of filteredEntreprise) {
        if (
          entrepriseValue.nom.toLowerCase() === this.state.company.toLowerCase()
        ) {
          return false;
        }
      }
    }
    return true;
  }

  nextPage = () => {
    history.push("/vic/page2");
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    var entreprise = "";
    if (this.props.entrepriseValue !== undefined && this.props.entrepriseValue !== "") {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }
    const compInfos = {
      company: this.state.company,
      adresse: this.state.adresse,
      ville: this.state.ville,
      tel: this.state.tel,
      nomres: this.state.nomres,
      mail: this.state.mail,
      cp: this.state.cp,
      fax: this.state.fax,
      port: this.state.port,
      ppsps: this.state.ppsps,
      id_respcomp: this.state.id_respcomp,
      identreprise: this.state.identreprise,
    };
    const travInfos = {
      resp_chan: this.state.resp_chan,
      soustraitant: this.state.soustraitant,
      taches: this.state.taches,
      intervention: this.state.intervention,
      pgc: this.state.pgc,
      resp_tel: this.state.resp_tel,
      nom_soustraitant: this.state.nom_soustraitant,
      dureeJour: this.state.dureeJour,
      dureeMois: this.state.dureeMois,
      mineff: this.state.mineff,
      maxeff: this.state.maxeff,
      id_respchan: this.state.id_respchan,
    };
    
    return (
      <div className="page1">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 1)</h1>
        <VICMenu />
        <div className="data">
          <AffaireTable
            avancement={this.state.avancement}
            date={this.state.date}
            handleChange={this.handleChange}
          />
          <CompTable
            handleChange={this.handleChange}
            handleCheckChange={this.handleCheckChange}
            setAllState={this.setAllState.bind(this)}
            setRespComp={this.setRespComp.bind(this)}
            compInfos={compInfos}
          />
          <TravauxTable
            handleChange={this.handleChange}
            handleCheckChange={this.handleCheckChange}
            company={this.state.company}
            setAllState={this.setAllState.bind(this)}
            setRespChan={this.setRespChan.bind(this)}
            travInfos={travInfos}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(compInfos, travInfos, true)}
            disabled={this.disableValidButton(compInfos, travInfos)}
          >
            Créer la VIC
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(compInfos, travInfos, false)}
            disabled={this.disableModifyButton(entreprise)}
          >
            Modifier
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick(entreprise)}
            disabled={
              this.state.disableButton || this.disableModifyButton(entreprise)
            }
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf()}
            disabled={
              this.state.disableButton || this.disableModifyButton(entreprise)
            }
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={
              this.state.disableButton || this.disableModifyButton(entreprise)
            }
          >
            Page suivante
          </Button>
          {this.state.disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
        </div>

        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={1}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page1);
