import React from 'react';
import ReactTable from 'react-table-6'
import { IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
class QuotaTableReal extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:this.props.quotatData
    }
  }
  componentDidUpdate(prevProps){
    if(this.props.quotaData != prevProps.quotaData){
      this.setState({data:this.props.quotaData})
    }
  }

  convertNumToTime(number) {
    // Check sign of given number
    var sign = (number >= 0) ? 1 : -1;
  
    // Set positive value of number of sign negative
    number = number * sign;
  
    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;
  
    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);
  
    var minute = Math.floor(decpart * 60) + '';
  
    // Add padding if need
    if (minute.length < 2) {
    minute = '0' + minute; 
    }
  
    // Add Sign in final result
    sign = sign == 1 ? '' : '-';
  
    // Concate hours and minutes
    var time = sign + hour + ':' + minute;
  
    return time;
  }

    render(){ 
      const columnsReal = [{
        Header: 'Tâche',
        accessor: 'tache',
        width: 370,
        headerStyle: { height: 45 },
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },{
        Header: 'Date',
        accessor: 'date',
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        Cell: (props) => {
          return new Date(props.row.date).toLocaleDateString("fr-FR")
        }
      },{
        Header: (
          <div>
            Heure <br />
            Début
          </div>
        ),
        accessor: 'hdebut',
        width: 70,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        Cell: (props) => {
          if(props.row.hdebut.length>5){
            return props.row.hdebut.substr(0,5)
          }
          else{
            return props.row.hdebut
          }
        }
      },{
        Header: (
          <div>
            Heure <br />
            Fin
          </div>
        ),
        accessor: 'hfin',
        width: 70,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        Cell: (props) => {
          if(props.row.hfin.length>5){
            return props.row.hfin.substr(0,5)
          }
          else{
            return props.row.hfin
          }
        }
      },{
        Header: (
          <div>
            Heures <br />
            Prévues
          </div>
        ),
        accessor: 'hprev',
        width: 70,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        Cell: (props) => {
          return this.convertNumToTime(props.row.hprev)
        }
      },{
        Header: (
          <div>
            Heures <br />
            Réelles
          </div>
        ),
        accessor: 'hree',
        width: 70,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        Cell: (props) => {
          return this.convertNumToTime(props.row.hree)
        }
      },{
        Header: (
          <div>
            Heures <br />
            Restantes
          </div>
        ),
        accessor: 'hres',
        width: 70,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        Cell: (props) => {
          return props.row.hres > 0 ? this.convertNumToTime(props.row.hres) : ""
        }
      },{
        Header: (
          <div>
            Heures <br />
            Dépassées
          </div>
        ),
        accessor: 'hdep',
        width: 75,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color:"red",
          fontWeight: "bold"
        },
        Cell: (props) => {
          return props.row.hdep > 0 ? this.convertNumToTime(props.row.hdep) : ""
        }
      },{
        Header: '',
        accessor: 'suppRow',
        width: 50,
        Cell: (props) => {
          return <IconButton aria-label="delete" size="small" style={{color:"white"}} onClick={() => this.props.showSuppDialog(props.row)}>
                    <Delete />
                 </IconButton>
        }
      }]
      const parentColumn=[{
        Header: "Phase Réalisation",
        columns:columnsReal
      }]  
        return(
        <div className="quotaTableRea">
            <ReactTable
            data={this.state.data}
            columns={parentColumn}
            defaultPageSize = {5}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            previousText={"Précédent"}
            nextText={"Suivant"}
            loadingText={"Chargement en cours..."}
            noDataText={"Aucune donnée trouvée."}
            rowsText={"lignes"}
            ofText={"de"}
            getTrProps={(state, rowInfo, column, instance) => {
              if (typeof rowInfo !== "undefined") {
                if(rowInfo.row._original.isLastTask==1){
                  return{
                    style:{
                      background:"red",
                      color:"white"
                    } 
                  }
                } 
                if(rowInfo.row.hres>=0){
                  return{
                    style:{
                      background:"green",
                      color:"white"
                    } 
                  }
                }
                if(rowInfo.row.hres<0){
                  return{
                    style:{
                      background:"orange",
                      color:"white"
                    } 
                  }
                } 
              }
              else return{
                style:{
                  background:"transparent",
                  color:"black"
                }
              }
            }}
          />
        </div>)
    }
}
export default QuotaTableReal