import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import "../../../Styles/RJ/Contacts.scss";
import { IconButton } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';

class DisplayContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  checkType(type) {
    if (type === "no") return "red";
    if (type === "yes") return "green";
    else return "gray";
  }

  render() {
    const data = this.props.contactsArray
    const column = [
      {
        title: "Lot",
        key: "lot",
        dataKey: "lot",
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p>{rowData.lot}</p>
        ),
      },
      {
        title: "Travaux",
        key: "travaux",
        dataKey: "travaux",
        width: 235,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p>{rowData.travaux}</p>
        ),
      },
      {
        title: "Nom et adresse de l'entreprise",
        key: "company",
        dataKey: "company",
        width: 365,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div key={rowData.name_company + rowIndex}>
            <span style={{ fontWeight: "bold" }}>{rowData.name_company}</span><br />
            <span>{rowData.adress}</span><br />
            <span>{rowData.cp}</span> - <span>{rowData.city}</span>
          </div>
        ),
      },
      {
        title: "Nom du contact",
        key: "name_contact",
        dataKey: "name_contact",
        width: 550,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div style={{ display: "flex", alignItems: "left", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccountCircleIcon style={{ width: "17px", marginRight: "5px" }} /><span style={{ fontWeight: "bold" }}> {rowData.name_contact}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <PhoneAndroidIcon style={{ width: "17px", marginRight: "5px" }} /><span> {rowData.mobile}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <PhoneIcon style={{ width: "17px", marginRight: "5px" }} /><span> {rowData.tel}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <EmailIcon style={{ width: "17px", marginRight: "5px" }} /><span> {rowData.mail}</span>
            </div>
          </div>
        ),
      },
      {
        title: "VIC",
        key: "vic",
        dataKey: "vic",
        width: 63,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="imported"
                disabled
                style={{
                  color: this.checkType(rowData.vic),
                }}
                checked={rowData.vic !== ""}
              />
            </div>
          );
        },
      },
      {
        title: "PPSPS",
        key: "ppsps",
        dataKey: "ppsps",
        width: 63,
        name: "exported",
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="exported"
                disabled
                style={{
                  color: this.checkType(rowData.ppsps),
                }}
                checked={rowData.ppsps !== ""}
              />
            </div>
          );
        },
      },
      {
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            aria-label="del-contact"
            size="small"
            onClick={() => this.props.handleClickOpen(rowIndex)}
            style={{ backgroundColor: "transparent" }}
            className="del-contact"
          >
            <Delete />
          </IconButton>
        ),
      },
    ];
    return (
      <div className="display-contacts">
        <BaseTable
          columns={column}
          width={1300}
          headerHeight={50}
          rowHeight={100}
          height={(this.props.contactsArray.length * 100) + 50}
          data={data}
          rowStyle={{
            height: '100px',
          }}
        />
      </div>
    );
  }
}
export default DisplayContacts;
