import React, { Fragment, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { connect } from "react-redux";
import Header from "../../../Header/Header";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import history from "../../../Routes/history";
import YesNoDialog from "../../../YesNoDialog";
import InformationDialog from "../../../InformationDialog";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import { changeVicValue, fetchCompaniesByDossierLot } from "../../../../redux/actions/vicActions";
import { newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { getQuestionsPage, updateQuestionsPage, getVicValues, deleteVic, genVicPDF, getVicPDF } from "../../Utils"
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../../Styles/VIC/VicValidations.scss";

const VicValidations = ({ dispatch, vicValue, dossierValue, lotsValue, companies, entrepriseValue }) => {
  const [disableButton, setDisableButton] = useState(false);
  const [questionsLot, setQuestionsLot] = useState([]);
  const [nameCompany, setNameCompany] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogInfo, setOpenDialogInfo] = useState(false);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const prevDossierValue = useRef(dossierValue);
  const prevLotsValue = useRef(lotsValue);
  const prevCompanies = useRef(companies);
  const prevEntrepriseValue = useRef(entrepriseValue);

  // useEffect(() => {
  //   if (!vicValue) {
  //     history.push("/vic/page-1");
  //   }
  // }, []);

  // useEffect(() => {
  //   const fetchQuestionsData = async () => {
  //     const data = await getQuestionsPage(2, vicValue)
  //     setQuestionsLot(data)
  //   };

  //   fetchQuestionsData();
  // }, [vicValue]);

  useEffect(() => {
    const fetchCompaniesData = async () => {
      dispatch(changePdfFileName(""))
      if (prevDossierValue.current !== dossierValue || prevLotsValue.current !== lotsValue) {
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));

        prevDossierValue.current = dossierValue;
        prevLotsValue.current = lotsValue;
      }
    }

    fetchCompaniesData();
  }, [dossierValue, lotsValue])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(changePdfFileName(""))
      if (prevCompanies.current !== companies) {
        if (companies.length !== 0) {
          await dispatch(newEntrepriseValue(companies[0]))
          const result = await getVicValues(dossierValue, lotsValue, companies[0]);
          await dispatch(changeVicValue(result.id))
        } else {
          await dispatch(changeVicValue(""))
        };

        prevCompanies.current = companies;
      }

      if (prevEntrepriseValue.current !== entrepriseValue) {
        const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);

        await dispatch(changeVicValue(result.id))

        prevEntrepriseValue.current = entrepriseValue
      }
    }

    fetchData();
  }, [companies, entrepriseValue]);

  const handleGenClick = async () => {
    setIsOpen(true);
    setFile(null);
    let blob = await genVicPDF(vicValue);
    if (blob) {
      setFile(blob);
    }
  }

  const handleShowPdf = async () => {
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');

    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);

      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
  }

  const handleGetPdfFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetWordFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }

  const deleteCurrentVic = async () => {
    setOpenDialog(false);
    try {
      const result = await deleteVic(vicValue);
      if (result.success) {
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
        setOpenDialogInfo(true);
      }
    } catch (err) {
      console.error("Erreur lors de la suppression du Vic :", err.message);
    }
  };

  const handleChangeTitleName = () => {
    dispatch(changeTitleName(`VIC`));
  };

  return (
    <div className="vicValid">
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Validation de la VIC</h1>
      <VICSMenu />
      <div className="data-vicvalidations">
        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleGenClick()}
            disabled={!vicValue}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={!vicValue}
          >
            Afficher le PDF
          </Button>
          {/* <Button
            color="primary"
            variant="contained"
          disabled={!vicValue}
          >
            Valider la VIC
          </Button> */}
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setNameCompany(typeof entrepriseValue === "string" ? JSON.parse(entrepriseValue).nom : entrepriseValue.nom);
              setOpenDialog(true);
            }}
            disabled={!vicValue}
          >
            Supprimer la VIC
          </Button>
          {disableButton && <CircularProgress className="spinner" color="success" />}
        </div>
        <YesNoDialog
          isOpen={openDialog}
          title={'SUPPRESSION VIC'}
          question={`Êtes vous sûr de vouloir supprimer la VIC de l'entrprise "${nameCompany}" ?`}
          handleYesClick={() => deleteCurrentVic()}
          handleClose={() => setOpenDialog(false)}
        />
        <InformationDialog
          isOpen={openDialogInfo}
          title={'INFORMATION'}
          information={`La VIC a été supprimée avec succès`}
          handleClose={() => setOpenDialogInfo(false)}
        />
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      // pageNumber={1}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(VicValidations);
