/**
 *  Récupère le prix hors taxe du devis
 * @param {[]} deviscon 
 * @param {[]} devisrea 
 * @param {number} nprop 
 * @param {number} dossierValue 
 * @returns 
 */
export function getHTPrice(deviscon, devisrea, nprop, dossierValue) {
  for (let i = 0; i < deviscon.length; i++) {
    if (dossierValue == deviscon[i].ndossier && nprop == deviscon[i].nprop) {
      // console.log(devisrea[i].totalht);
      return deviscon[i].totalht + devisrea[i].totalht
    }
  }
}
/**
 * Récupère les données du devis Conception
 * @returns 
 */
export function getDevisTabCon(deviscon, nprop, dossierValue) {
  for (let i = 0; i < deviscon.length; i++) {
    if (dossierValue == deviscon[i].ndossier && nprop == deviscon[i].nprop) {
      return deviscon[i].devisTab
    }
  }
}
/**
 * Récupère les données du devis Réalisation
 * @returns 
 */
export function getDevisTabRea(devisrea, nprop, dossierValue) {
  for (let i = 0; i < devisrea.length; i++) {
    if (dossierValue == devisrea[i].ndossier && nprop == devisrea[i].nprop) {
      return devisrea[i].devisTab
    }
  }
}
/**
 * Récupère le taux horaire du devis conception
 * @returns 
 */
export function getTauxHCon(deviscon, nprop, dossierValue) {
  for (let i = 0; i < deviscon.length; i++) {
    // console.log(deviscon[i]);
    if (dossierValue == deviscon[i].ndossier && nprop == deviscon[i].nprop) {
      return deviscon[i].tauxh
    }
  }
}
/**
 * Récupère le taux horaire du devis réalisation
 * @returns 
 */
export function getTauxHRea(devisrea, nprop, dossierValue) {
  for (let i = 0; i < devisrea.length; i++) {
    if (dossierValue == devisrea[i].ndossier && nprop == devisrea[i].nprop) {
      return devisrea[i].tauxh
    }
  }
}
/**
 * Récupère la durée de la partie conception du dossier
 * @returns 
 */
export function getDureeCon(deviscon, nprop, dossierValue) {
  for (let i = 0; i < deviscon.length; i++) {
    if (dossierValue == deviscon[i].ndossier && nprop == deviscon[i].nprop) {
      return deviscon[i].duree
    }
  }
}
/**
 * Récupère la durée de la partie réalisation du dossier
 * @returns 
 */
export function getDureeRea(devisrea, nprop, dossierValue) {
  for (let i = 0; i < devisrea.length; i++) {
    if (dossierValue == devisrea[i].ndossier && nprop == devisrea[i].nprop) {
      return devisrea[i].duree
    }
  }
}
/**
 * Récupère le montant total hors taxe de la phase réalisation
 * @returns 
 */
export function getDevisReaHT(devisrea, nprop, dossierValue) {
  for (let i = 0; i < devisrea.length; i++) {
    if (dossierValue == devisrea[i].ndossier && nprop == devisrea[i].nprop) {
      return devisrea[i].totalht
    }
  }

  return 0
}

export function getDevisPaymentMethod(devisRecords, nprop, dossierValue) {
  const matchingRecord = devisRecords.find(record =>
    record.ndossier == dossierValue && record.nprop == nprop
  );

  return matchingRecord ? matchingRecord.payment_method : undefined;
}

/**
 * Récupère le montant total TTC de la phase réalisation
 * @returns 
 */
export function getDevisReaTtc(devisrea, nprop, dossierValue) {

  return getDevisReaHT(devisrea, nprop, dossierValue) * 1.2
}

/**
 * Récupère le montant total hors taxe de la phase conception
 * @returns 
 */
export function getDevisConHT(deviscon, nprop, dossierValue) {
  for (let i = 0; i < deviscon.length; i++) {
    if (dossierValue == deviscon[i].ndossier && nprop == deviscon[i].nprop) {
      return deviscon[i].totalht
    }
  }
  return 0
}

/**
 * Récupère le montant total TTC de la phase conception
 * @returns 
 */
export function getDevisConTtc(deviscon, nprop, dossierValue) {
  return getDevisConHT(deviscon, nprop, dossierValue) * 1.2
}
/**
 * Récupère le montant total hors taxe des 2 phases
 * @returns 
 */
export function getTotalHt(devisrea, deviscon, nprop, dossierValue) {
  return getDevisConHT(deviscon, nprop, dossierValue) + getDevisReaHT(devisrea, nprop, dossierValue)
}
/**
 * Récupère le montant total TTC des 2 phases
 * @returns 
 */
export function getTotalTtc(devisrea, deviscon, nprop, dossierValue) {
  return getDevisReaTtc(devisrea, nprop, dossierValue) + getDevisConTtc(deviscon, nprop, dossierValue)
}
/**
 * Récupère les données du dossier choisi
 * @returns 
 */
export function getDossier(dossier, dossierValue) {
  for (let i = 0; i < dossier.length; i++) {
    if (dossierValue == dossier[i].ndossier) {
      return dossier[i]
    }
  }
}
/**
 * Récupère les donénes du contact choisi
 * @returns 
 */
export function getContact(dossier, contact, dossierValue) {
  for (let i = 0; i < dossier.length; i++) {
    if (dossierValue == dossier[i].ndossier) {
      for (let j = 0; j < contact.length; j++) {
        if (dossier[i].idcontact == contact[j].idcontact) {
          return contact[j]
        }
      }
    }
  }
}
/**
 * Récupère la catégorie de l'opération
 * @returns 
 */
export function getCategorieOp(dossier, dossierValue) {
  for (var i = 0; i < dossier.length; i++) {
    if (dossier[i].ndossier == dossierValue) {
      return dossier[i].categorie_op
    }
  }
}

/**
* Vérifie si le devis conception est validé
* @param {{}} dossier 
* @param {number} nprop 
* @returns 
*/
export function getValidation(deviscon, dossierValue, nprop) {
  for (let i = 0; i < deviscon.length; i++) {
    if (dossierValue == deviscon[i].ndossier && nprop == deviscon[i].nprop) {
      if (deviscon[i].validation != 0 && deviscon[i].validation != null) {
        return true
      }
    }
  }
  return false
}

/**
 * Renvoie le nombre de validation pour le dossier choisi
 * @returns 
 */
export function getNumValidation(deviscon, dossierValue) {
  var count = 0
  for (let i = 0; i < deviscon.length; i++) {
    if (deviscon[i].ndossier == dossierValue && deviscon[i].validation > 0) {
      count++;
    }
  }
  return count
}
export function numberNprop(deviscon, dossierValue) {
  var count = 0
  for (let i = 0; i < deviscon.length; i++) {
    if (deviscon[i].ndossier == dossierValue) {
      count = count < deviscon[i].nprop ? deviscon[i].nprop : count
    }
  }
  return count
}