export const laborCodeScaffoldingCont = [
  {
    name: 'Garde-corps périphériques sur réservation, cf R4323-59 du Code du Travail',
    article: ''
  },
  {
    name: 'Échafaudage et neutralisation de la zone d\'intervention',
    article: ''
  },
  {
    name: 'Garde-corps provisoires dans les réservations des planchers béton sur les baies avec allèges supérieures à 1m',
    article: ''
  },
  {
    name: 'Sol stabilisé en périphérie',
    article: ''
  },
  {
    name: 'PEMP Échafaudage conforme Balisage de la zone d\'évolution',
    article: ''
  },
  {
    name: 'Protection au niveau des ouvertures d’escaliers en étage intérieur',
    article: ''
  }
]

export const coactivityRisksTitles = [
  {
    nature: "Tranchées pour réseaux et fouilles",
    type: "Balisage en tête de talus, blindage pour les tranchées, dispositif de franchissement",
    realise: ""
  },
  {
    nature: "Fouille des fondations",
    type: "Filet de protection ou barrière rigide de 1,00m décalé de 1,00m de la tête de fouille Blindage des fouilles > à 1,30m de profondeur",
    realise: ""
  },
  {
    nature: "Mise en place de poutres béton",
    type: "Échafaudages, plateforme de travail et réservations pour mise en place de garde-corps",
    realise: ""
  },
  {
    nature: "Fouilles pour bâtiment",
    type: "Balisage en tête de talus",
    realise: ""
  },
  {
    nature: "Rives de plancher",
    type: "Garde-corps périphériques",
    realise: ""
  },
  {
    nature: "Trémies ou regard dans dallage",
    type: "Garde-corps périphériques, platelages",
    realise: ""
  },
  {
    nature: "Ferraillage et incorporation dans voile béton",
    type: "Échafaudage roulant se déplaçant sur surface plane devant les banches",
    realise: ""
  },
  {
    nature: "Pose prédalles",
    type: "Garde-corps sur pré dalle fixé sur support dans réservation ou filet sur dallage",
    realise: ""
  },
  {
    nature: "Trémies en toiture",
    type: "Platelage en sous face",
    realise: ""
  },
  {
    nature: "Pose charpente bois",
    type: "Échafaudage ou nacelle élévatrice, balisage et interdiction d'accès dans la zone d’intervention au sol",
    realise: ""
  },
  {
    nature: "Pose support de garde-corps en périphérie et pose garde-corps en périphérie",
    type: "Échafaudage ou nacelle élévatrice à partir des dallages ou aménagements extérieurs, balisage et interdiction d'accès dans la zone d’intervention au sol",
    realise: ""
  },
  {
    nature: "Charpente",
    type: "Balisage de zone, échafaudage ou nacelle sur dallage et sur passage extérieur",
    realise: ""
  },
  {
    nature: "Pose des filets sous charpente",
    type: "Balisage de zone, échafaudage ou nacelle sur dallage et sur passage extérieur",
    realise: ""
  },
  {
    nature: "Couverture",
    type: "Garde-corps périphériques sur réservation, platelage pour se déplacer sur la charpente",
    realise: ""
  },
  {
    nature: "Trémies en toiture ",
    type: "Filets en sous face",
    realise: ""
  },
  {
    nature: "Équipements en toiture",
    type: "Garde-corps périphériques sur supports définitifs",
    realise: ""
  },
  {
    nature: "Équipements en toiture lots techniques",
    type: "Garde-corps périphériques sur supports définitifs ou installés par le lot couverture",
    realise: ""
  },
  {
    nature: "Travaux en façade - bardage",
    type: "Utilisation de l'échafaudage commun du lot gros œuvre",
    realise: ""
  },
  {
    nature: "Travaux en façade - serrurerie",
    type: "Utilisation de l'échafaudage commun du lot gros œuvre",
    realise: ""
  },
  {
    nature: "Bardage Façades depuis le sol",
    type: "Échafaudage ou nacelle élévatrice se déplaçant sur surface plane et stabilisée, balisage et interdiction d'accès dans la zone d’intervention au sol",
    realise: ""
  },
  {
    nature: "Bardage Façades au-dessus toiture terrasse",
    type: "Échafaudage sur toiture terrasse, balisage de la zone d’intervention Protection collective en rive sur les zones de cheminement",
    realise: ""
  },
  {
    nature: "Métallerie",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Plafonds",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Plafonds grande hauteur et grande surface",
    type: "Plate-forme échafaudage et balisage de la zone d’intervention ou neutralisation de la zone par fermeture des accès",
    realise: ""
  },
  {
    nature: "Électricité",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Plomberie",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Chauffage",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Courants faibles",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Peinture",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  },
  {
    nature: "Ventilation",
    type: "Échafaudage ou PIRL et balisage de la zone d’intervention",
    realise: ""
  }
]

export const paragraphsCoactivityRisksDescription = [
  "",
  "Exemple de tableau présentant l’analyse des risques liés aux Co-activité :\nATTENTION : l’utilisation de ce tableau doit être le reflet EXACT du chantier, ce n’est pas un cadre de texte « Passe partout » comme il est souvent constaté dans les audits et supervisions\nSi vous n’avez pas de planning en phase conception, ne remplissez pas ce tableau ou donner les grandes lignes des Co-activité possibles",
  "Compte tenu du planning provisoire du MOE ou du pilote, les entreprises appelées à démarrer le chantier sont :"
]