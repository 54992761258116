import React from 'react';
import Dialog1 from './Dialog1';
import Dialog2 from './Dialog2';
import Dialog3 from './Dialog3';
import DialogModifPGC from './DialogModifPGC';
import DialogModifDem from './DialogModifDem';

class Dialogs extends React.Component {
  constructor(props){
    super(props)
    this.state={
        index:0
    }
    this.showNextDialog=this.showNextDialog.bind(this)
  }
  showNextDialog(index){
      this.setState({index:index})
  }
  showDialogs(index){
    const dialogs =[
        <Dialog1 showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <Dialog2 showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogModifPGC showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <Dialog3 showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>,
        <DialogModifDem showNextDialog={this.showNextDialog} closeDialog={this.props.closeDialog}/>
    ]
    return dialogs[index]
  }
    render() {
      return (
        <React.Fragment>
            {this.showDialogs(this.state.index)}
        </React.Fragment>   
      )
    }
  }
export default Dialogs