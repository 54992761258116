import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";
import ReactLoading from "react-loading";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default class PopupPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      numPages: 0,
      pageNumber: 1,
    };
  }

  nextPage() {
    if (this.state.pageNumber != this.state.numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
    }
  }

  prevPage() {
    if (this.state.pageNumber != 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 });
    }
  }

  verifyPageNumber() {
    if (this.props.pageNumber !== undefined) {
      return this.props.pageNumber;
    } else if (this.state.pageNumber) {
      return this.state.pageNumber;
    } else if (
      this.props.pageNumber > this.state.numPages ||
      this.state.pageNumber > this.state.numPages
    ) {
      return 1;
    } else return 1;
  }

  render() {
    return (
      <div className="genPDF">
        {this.props.isOpen ? (
          <React.Fragment>
            <Document
              file={this.props.file}
              onLoadSuccess={(pdf) => this.setState({ numPages: pdf.numPages })}
              onLoadError={console.error}
              noData={
                <div className="loadingPdf">
                  <ReactLoading type={"spinningBubbles"} color="#fff" />
                </div>
              }
            >
              <IconButton className="closeIcon" onClick={this.props.closeDoc}>
                <ClearIcon />
              </IconButton>
              <Page
                pageNumber={this.verifyPageNumber()}
                scale={this.props.scale}
              />
            </Document>
            {this.props.pageNumber === undefined ? (
              this.state.numPages > 1 ? (
                <div className="numPages">
                  <IconButton
                    style={{ backgroundColor: "transparent" }}
                    onClick={this.prevPage.bind(this)}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <span>
                    {this.state.pageNumber}/{this.state.numPages}
                  </span>
                  <IconButton
                    style={{ backgroundColor: "transparent" }}
                    onClick={this.nextPage.bind(this)}
                  >
                    <ChevronRight />
                  </IconButton>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}
