import React from "react";
import "../../../Styles/RJ/EvaluationRisque.scss";
import Header from "../../Header/Header";
import RJMenu from "../RJMenu";
import DocMenu from '../../DocMenu';
import { connect } from "react-redux";
import moment from "moment";
import RisqueTable from "./RisqueTable";
import GenPdf from "../../GenPdf";
import YesNoDialog from "../../YesNoDialog";
import InformationDialog from "../../InformationDialog";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import { genPDF, getPDF } from "../Utils";
import ArrowRight from "@material-ui/icons/ArrowRight";
import history from "../../Routes/history";
import { fetchRJ, newRjValue } from "../../../redux/actions/rjActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getLastNum } from "../Utils";
import CircularProgress from '@material-ui/core/CircularProgress';

class EvaluationRisque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueArray: Array.apply(null, Array(136)).map(function (x, i) {
        return { answer: false, type: "" };
      }),
      advancement: 0,
      facteurs: [{ facteur: "" }],
      file: null,
      isOpen: false,
      index: "",
      anchorEl: null,
      open: false,
      loading: false,
      valueArrayReset: false,
      nbFloor: 0,
      disableButton: false,
      openDialogCreate: false,
      openDialogEdit: false,
      openDialogDel: false,
      openDialogNbFr: false,
      _isMounted: false,
      indexDel: 0,
      observations: this.createObservationsArray(3),
    };
    this.levelsWrapper = React.createRef();
    this.handleResetValueArray = this.handleResetValueArray.bind(this);
    this.handleFacteursChange = this.handleFacteursChange.bind(this);
    this.addFacteur = this.addFacteur.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    document.addEventListener("mousedown", this.handleClickOutside);

    const { rjValue } = this.props;

    if (rjValue === undefined) {
      this.initializeState();
    } else if (rjValue.risque !== undefined) {
      const { risque, advancement, facteurs, nbfloor, observations } = rjValue;

      try {
        this.setState({
          valueArray: JSON.parse(risque),
          advancement: advancement || 0,
          facteurs: facteurs ? JSON.parse(facteurs) : [{ facteur: "" }],
          nbFloor: nbfloor || 2,
        });

        if (observations && observations !== JSON.stringify(this.state.observations)) {
          this.setState({
            observations: JSON.parse(observations),
          });
        }
      } catch (error) {
        console.error("Error parsing JSON data:", error);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { rjValue, dossierValue } = this.props;
    const { rjValue: prevRjValue, dossierValue: prevDossierValue } = prevProps;

    if (rjValue !== undefined) {
      if ((dossierValue !== prevDossierValue || rjValue !== prevRjValue) && rjValue?.risque !== undefined) {
        this.updateRjValueState(rjValue);
      }
    } else if (!this.state.valueArrayReset) {
      if (rjValue !== prevRjValue) {
        this.resetRjValueState();
      }
      if (dossierValue !== prevDossierValue) {
        this.setState({ advancement: 0 });
      }
      this.setState({ valueArrayReset: true });
    }
  }

  initializeState() {
    const valueArray = Array.from({ length: 136 }, () => ({ answer: false, type: "" }));
    const facteurs = [{ facteur: "" }];
    const nbFloor = 2;

    this.setState({ valueArray, facteurs, nbFloor });
  }


  updateRjValueState(rjValue) {
    this.props.dispatch(changePdfFileName(""))
    const { risque, advancement, facteurs, nbfloor, observations } = rjValue;

    this.setState({
      valueArray: JSON.parse(risque),
      advancement: (advancement !== undefined) ? advancement : this.state.advancement,
      facteurs: facteurs ? JSON.parse(facteurs) : [{ facteur: "" }],
      nbFloor: nbfloor || 2
    });

    if (observations && observations !== JSON.stringify(this.state.observations)) {
      this.setState({
        observations: JSON.parse(observations),
      });
    }
  }

  resetRjValueState() {
    const valueArray = Array.from({ length: 136 }, () => ({ answer: false, type: "" }));
    const facteurs = [{ facteur: "" }];
    const nbFloor = 2;

    this.setState({ valueArray, facteurs, nbFloor });
  }

  resitValueWorkforceArray() {
    return Array.apply(null, Array(21)).map(function (x, i) {
      return {
        company: i == 20 ? "TOTAL" : "",
        average: 0,
        janvier: 0,
        fevrier: 0,
        mars: 0,
        avril: 0,
        mai: 0,
        juin: 0,
        juillet: 0,
        aout: 0,
        septembre: 0,
        octobre: 0,
        novembre: 0,
        decembre: 0,
      };
    });
  }

  createObservationsArray = (length) => {
    const observations = [];
    for (let i = 0; i < length; i++) {
      observations.push([{
        date_obs: "",
        title: "",
        nfigure: [],
        photos: [],
        observation: "",
        code_travail: "",
        imported: "",
        exported: "",
        done_date_obs: ""
      }]);
    }
    return observations;
  };

  handleResetValueArray = (event) => {
    this.setState({ valueArrayReset: false });
    if (event) {
      this.setState({ advancement: event.target.value });
    } else {
      this.setState({ advancement: 0 });
    }
  };

  handleFacteursChange = (event, index) => {
    const { facteurs, observations } = this.state;
    const prevFacteur = facteurs[index].facteur;
    const newFacteur = event._reactName ? event.target.value : event.facteur;
    const newFacteurs = facteurs.map((facteur, i) => (i === index ? { facteur: newFacteur } : facteur));
    const newObservations = observations.map(floor => (
      floor.map(observation => (
        observation.title === prevFacteur ? { ...observation, title: newFacteur } : observation
      ))
    ));
    this.setState({ facteurs: newFacteurs, observations: newObservations });
  };


  addFacteur() {
    if (this.state.facteurs[this.state.facteurs.length - 1].facteur !== "") {
      let newFacteurs = [...this.state.facteurs, { facteur: "" }];
      this.setState({ facteurs: newFacteurs });
    }
  }

  deleteFacteur = () => {
    this.handleCloseDel();
    const indexDel = this.state.indexDel;
    const newFacteurs = [...this.state.facteurs];
    const newObserv = [...this.state.observations];
    let newIndex = 0;

    // Supprimer le facteur dans les observations
    newObserv.forEach(observation => {
      observation.forEach((obs, index) => {
        if (obs.title === newFacteurs[indexDel].facteur) {
          observation.splice(index, 1);
        }
      });
    });

    // Mettre à jour les valeurs du formulaire
    let newValueArray = [...this.state.valueArray];
    if (newFacteurs.length > 1) {
      for (let i = 0; i < newValueArray.length; i++) {
        if (i < 17) {
          newIndex = i;
        } else {
          newIndex = i % 17;
        }
        if (newIndex >= indexDel) {
          if (newIndex === 16) {
            newValueArray[i] = { answer: false, type: "" };
          } else {
            newValueArray[i] = {
              answer: newValueArray[i + 1].answer,
              type: newValueArray[i + 1].type
            };
          }
        }
      }
      newFacteurs.splice(indexDel, 1);
    } else {
      for (let i = 0; i < newValueArray.length; i++) {
        if (i === 0) {
          newIndex = i;
        } else {
          newIndex = i % 17;
        }
        if (newIndex === 0) {
          newValueArray[i] = { answer: false, type: "" };
        }
      }
      newFacteurs[indexDel] = { facteur: "" };
    }
    this.setState({
      valueArray: newValueArray,
      facteurs: newFacteurs,
      observations: newObserv
    });
  };


  addFloor = () => {
    let newObs = [...this.state.observations]
    newObs.push([])
    this.setState({ nbFloor: this.state.nbFloor + 1, observations: newObs });
  };

  removeFloor = () => {
    let newObs = [...this.state.observations]
    newObs.splice(this.state.nbFloor - 1, 1)
    this.setState({ nbFloor: this.state.nbFloor - 1, observations: newObs });
    this.handleResetValueArrayFloor();
  };

  handleResetValueArrayFloor = () => {
    let newValueArray = [...this.state.valueArray];
    for (let i = this.state.nbFloor * 17; i < newValueArray.length; i++) {
      newValueArray[i] = { answer: false, type: "" };
    }
    this.setState({ valueArray: newValueArray });
  };

  handleLevelClick = (event, index, type) => {
    let nbObserv = 0;
    for (let i = 17 * Math.floor(index / 17); i < 17 * (Math.floor(index / 17) + 1); i++) {
      if (this.state.valueArray[i].answer) {
        nbObserv++;
      }
    }

    if (nbObserv < 4 || event.target.value === "") {
      let newObserv = [...this.state.observations];
      let newArrayFacteurs = [];
      for (let i = 0; i < this.state.facteurs.length; i++) {
        newArrayFacteurs.push(this.state.facteurs[i].facteur);
      }
      if (type != "") {
        let indexNewObserv = -1;
        for (let i = 0; i < newObserv[Math.floor(index / 17)].length; i++) {
          let facteurTitle = newObserv[Math.floor(index / 17)][i].title;
          if (newArrayFacteurs.indexOf(newArrayFacteurs[index % 17]) < newArrayFacteurs.indexOf(facteurTitle)) {
            indexNewObserv = i;
            break;
          }
        }
        if (indexNewObserv == -1) {
          newObserv[Math.floor(index / 17)].push({
            date_obs: "",
            title: newArrayFacteurs[index % 17],
            nfigure: [],
            photos: [],
            observation: "",
            code_travail: "",
            imported: "",
            exported: "",
            done_date_obs: "",
          });
        } else
          newObserv[Math.floor(index / 17)].splice(indexNewObserv, 0, {
            date_obs: "",
            title: newArrayFacteurs[index % 17],
            nfigure: [],
            photos: [],
            observation: "",
            code_travail: "",
            imported: "",
            exported: "",
            done_date_obs: "",
          });

        this.setState({ observations: newObserv });
      } else {
        for (let i = 0; i < newObserv[Math.floor(index / 17)].length; i++) {
          if (newObserv[Math.floor(index / 17)][i].title == newArrayFacteurs[index % 17]) {
            newObserv[Math.floor(index / 17)].splice(i, 1);
          }
        }
        this.setState({ observations: newObserv });
      }

      let items = [...this.state.valueArray];
      let item = { ...items[index] };
      if (item.answer) item.answer = false;
      else item.answer = true;
      item.type = type;
      items[index] = item;
      this.setState({ valueArray: items });
      this.hidLevels();
      this.handleFacteursChange(this.state.facteurs[index % 17], index % 17);
    } else {
      this.hidLevels();
      this.handleClickOpenNbFr()
    }
  };

  handleClickOutside = (event) => {
    if (
      this.levelsWrapper &&
      this.levelsWrapper.current !== null &&
      !this.levelsWrapper.current.contains(event.target)
    ) {
      this.hidLevels();
    }
  };

  observationsData() {
    let dataObs = [];
    for (let i = 0; i <= this.state.nbFloor; i++) {
      dataObs.push([]);
      for (let j = 0; j < this.state.facteurs.length; j++) {
        if (this.state.valueArray[j + 17 * i].answer == true) {
          dataObs[i].push({
            date_obs: "",
            title: this.state.facteurs[j].facteur,
            nfigure: [],
            photos: [],
            observation: "",
            code_travail: "",
            imported: "",
            exported: "",
            done_date_obs: "",
          });
        }
      }
    }
    return dataObs;
  }

  handleValidClick = async () => {
    this.setState({ disableButton: true })
    this.handleCloseCreate();
    const obs = this.observationsData();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        valueArray: this.state.valueArray,
        observations: obs,
        numero: getLastNum(this.props.rj, this.props.dossierValue) + 1,
        advancement: this.state.advancement,
        facteurs: this.state.facteurs,
        nbfloor: this.state.nbFloor,
        contacts: getLastNum(this.props.rj, this.props.dossierValue) != -1 && this.props.rjValue.contacts !== undefined ? JSON.parse(this.props.rjValue.contacts) : [],
        workforce: getLastNum(this.props.rj, this.props.dossierValue) != -1 && this.props.rjValue.workforce !== undefined ? JSON.parse(this.props.rjValue.workforce) : this.resitValueWorkforceArray(),
        prev_workforce: getLastNum(this.props.rj, this.props.dossierValue) != -1 && this.props.rjValue.prev_workforce !== null ? JSON.parse(this.props.rjValue.prev_workforce) : this.resitValueWorkforceArray()
      }),
    };
    this.setState({ loading: true });
    await fetch("/api/rj/create", requestOptions)
      .then(async (res) => {
        if (res.ok) {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchRJ(this.props.dossierValue));
    const newRj = this.props.rj[this.props.rj.length - 1];
    await this.props.dispatch(newRjValue(newRj));
    await this.setState({ disableButton: false });
  };

  handleModifClick = async () => {
    this.setState({ disableButton: true });
    this.handleCloseEdit();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        valueArray: this.state.valueArray,
        observations: this.state.observations,
        numero: this.props.rjValue.numero,
        advancement: this.state.advancement,
        facteurs: this.state.facteurs,
        nbfloor: this.state.nbFloor,
      }),
    };
    this.setState({ loading: true });
    await fetch("/api/rj/update", requestOptions)
      .then(async (res) => {
        if (res.ok) {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchRJ(this.props.dossierValue));
    await this.setState({ disableButton: false });
  };

  // filterRJ = () => {
  //   return this.props.rj.filter((rj) => {
  //     if (rj.ndossier == this.props.dossierValue) return true;
  //     else return false;
  //   });
  // };

  handleGenClick = async () => {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(this.props.dossierValue, this.props.rjValue.numero);
    if (blob) {
      this.setState({ file: blob });
    }
  };

  handleShowPdf = async () => {
    this.setOpen(true);
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else this.setOpen(false)
  };

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');

    if (response.blob) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  showLevels = (event, index) => {
    this.setState({ anchorEl: event.currentTarget, open: true, index: index });
    this.handleFacteursChange(this.state.facteurs[index % 17], index % 17);
  };

  hidLevels = () => {
    this.setState({ open: false, anchorEl: null });
  };

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  nextPage = () => {
    history.push("/rj/observations");
  };

  handleClickOpenCreate = () => {
    this.setState({ openDialogCreate: true });
  };

  handleCloseCreate = () => {
    this.setState({ openDialogCreate: false });
  };

  handleClickOpenEdit = () => {
    this.setState({ openDialogEdit: true });
  };

  handleCloseEdit = () => {
    this.setState({ openDialogEdit: false });
  };

  handleClickOpenDel = (index) => {
    this.setState({ openDialogDel: true, indexDel: index });
  };

  handleCloseDel = () => {
    this.setState({ openDialogDel: false });
  };

  handleClickOpenNbFr = () => {
    this.setState({ openDialogNbFr: true });
  };

  handleCloseNbFr = () => {
    this.setState({ openDialogNbFr: false });
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Registre Journal`))
  }

  render() {
    const { rjValue, rj, dossierValue, listFactors } = this.props;
    const {
      advancement,
      facteurs,
      valueArray,
      anchorEl,
      open,
      isOpen,
      file,
      indexDel,
      index,
      nbFloor,
      disableButton,
      loading,
      openDialogCreate,
      openDialogEdit,
      openDialogDel,
      openDialogNbFr
    } = this.state;

    console.log("rjValue", rjValue)

    return (
      <div className="rjEval">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Registre Journal (Evaluation des Risques)</h1>
        <RJMenu
          advancement={advancement}
          handleResetValueArray={this.handleResetValueArray}
        />
        <div className="consignes">
          <div className="blocs">
            <div className="bloc1">
              <input type="checkbox" disabled={true} />
              <label>1</label>
            </div>
            <div className="bloc2">
              <input type="checkbox" disabled={true} />
              <label>2</label>
            </div>
            <div className="bloc3">
              <input type="checkbox" disabled={true} />
              <label>3</label>
            </div>
            <div className="bloc4">
              <input type="checkbox" disabled={true} />
              <label>4</label>
            </div>
          </div>
          <p>
            1. <span style={{ backgroundColor: "red" }}>Fort</span> Espace
            chantier à risque fort. Une action corrective doit être mise en
            place obligatoirement dans les plus brefs délais
          </p>
          <p>
            2. <span style={{ backgroundColor: "purple" }}>Pondéré</span> Espace
            chantier à risque pondéré
          </p>
          <p>
            3. <span style={{ backgroundColor: "yellow" }}>Moyen</span> Espace
            chantier à risque moyen
          </p>
          <p>
            4. <span style={{ backgroundColor: "green" }}>Faible</span> Espace
            chantier correcte, à maintenir en l’état
          </p>
          <p>
            Le CSPS porte ces observations à l’attention de l’entreprise de Gros
            Œuvre et du Maître d’ouvrage, et attend des réponses conformément
            aux PGP et planning.
          </p>
          <div className="displayFloors">
            <RisqueTable
              key={JSON.stringify(valueArray) + JSON.stringify(listFactors)}
              facteurs={facteurs}
              listFactors={listFactors}
              valueArray={valueArray}
              anchorEl={anchorEl}
              open={open}
              index={index}
              nbFloor={nbFloor}
              levelsWrapper={this.levelsWrapper}
              addFacteur={this.addFacteur}
              handleFacteursChange={this.handleFacteursChange}
              handleLevelClick={this.handleLevelClick}
              handleClickOpenDel={this.handleClickOpenDel}
              showLevels={this.showLevels}
              hidLevels={this.hidLevels}
            />
            <div className="floorButton">
              {nbFloor > 2 ?
                <IconButton
                  aria-label="remove"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => this.removeFloor()}
                >
                  <Remove />
                </IconButton> : ""
              }
              {nbFloor < 7 ?
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => this.addFloor()}
                >
                  <Add />
                </IconButton> : ""
              }
            </div>
          </div>
          <Button
            className="rjButton"
            color="primary"
            variant="contained"
            onClick={this.handleClickOpenCreate}
            disabled={facteurs[0].facteur === "" || disableButton || loading}
          >
            Créer le RJ N°{" "}
            {rjValue === undefined
              ? 0
              : getLastNum(rj, dossierValue) + 1}
          </Button>
          <Button
            className="rjButton"
            color="primary"
            variant="contained"
            onClick={this.handleClickOpenEdit}
            disabled={disableButton || loading || rjValue === undefined}
          >
            Modifier
            {rjValue === undefined
              ? ""
              : " le RJ N° " + rjValue.numero}
          </Button>
          <Button
            className="rjButton"
            color="primary"
            variant="contained"
            onClick={this.handleGenClick}
            disabled={disableButton || loading || rjValue === undefined}
          >
            Générer le PDF
          </Button>
          <Button
            className="rjButton"
            color="primary"
            variant="contained"
            onClick={this.handleShowPdf}
            disabled={disableButton || loading || rjValue === undefined}
          >
            Afficher le PDF
          </Button>
          <Button
            className="rjButton"
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={disableButton || rjValue === undefined}
          >
            Page suivante
          </Button>
          {disableButton && <CircularProgress className="spinner" color="inherit" />}
          <YesNoDialog
            isOpen={openDialogCreate}
            title={'CRÉATION DU RJ'}
            question={`Voulez-vous créer le RJ «N°${rjValue !== undefined ? (getLastNum(rj, dossierValue) + 1) : 0}» du dossier «${dossierValue !== undefined ? dossierValue : ""}» ?`}
            handleYesClick={this.handleValidClick}
            handleClose={this.handleCloseCreate}
          />
          <YesNoDialog
            isOpen={openDialogEdit}
            title={'MODIFICATION DU RJ'}
            question={`Voulez-vous modifier le RJ «N°${rjValue !== undefined ? rjValue.numero : ""}» du dossier «${dossierValue !== undefined ? dossierValue : ""}» ?`}
            handleYesClick={this.handleModifClick}
            handleClose={this.handleCloseEdit}
          />
          <YesNoDialog
            isOpen={openDialogDel}
            title={'SUPPRESSION DE L\'OBSERVATION'}
            question={`Êtes vous sûr de vouloir supprimer le facteur de risque «${facteurs[indexDel] !== undefined ? facteurs[indexDel].facteur : ""}» ?`}
            handleYesClick={this.deleteFacteur}
            handleClose={this.handleCloseDel}
          />
          <InformationDialog
            isOpen={openDialogNbFr}
            title={'INFORMATION'}
            information={'Le nombre maximum de facteurs de risque par étage est de 4'}
            handleClose={this.handleCloseNbFr}
          />
        </div>
        <GenPdf
          file={file}
          isOpen={isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={1}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rj: state.rj.items,
  rjValue: state.rj.rjValue,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  listFactors: state.rj.listFactors,
});
export default connect(mapStateToProps)(EvaluationRisque);
