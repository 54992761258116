import React, { useState } from "react";
import Header from "../../Header/Header";
import VICMenu from "../VICMenu";
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import "../../../Styles/VIC/Page3.scss";
import { genPDF, getClient, getLotNbrPages, getPDF } from "../Utils";
import { fetchVIC } from "../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import GenPdf from "../../GenPdf";
import AllTables from "./AllTables/AllTables";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import DocMenu from "../../DocMenu";

class Page3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueArray: Array.apply(null, Array(70)).map(function (x, i) {
        return { answer: 0, observation: false };
      }),
      file: null,
      isOpen: false,
      delIndexes: [],
      loading: false,
      disableButton: false,
    };
  }
  loadData(indexes) {
    this.props.dispatch(changePdfFileName(""))
    var vic = this.props.vic.filter((vic) => {
      if (
        this.props.dossierValue == vic.ndossier &&
        this.props.lotValue == vic.lots
      ) {
        if (this.props.entrepriseValue !== undefined) {
          var entreprise = JSON.parse(this.props.entrepriseValue);
          if (entreprise.id == vic.identreprise) {
            return true;
          }
        }
      } else return false;
    })[0];
    if (vic !== undefined) {
      const data = [];
      var page3 = JSON.parse(vic.compte_rendu).page3;
      var uselessData = 0;
      if (page3 !== undefined) {
        for (let i = 0; i < this.state.valueArray.length; i++) {
          var getIndex = indexes.filter((item) => {
            if (i == item) return true;
            else return false;
          });
          if (i == getIndex[0]) {
            data.push({ answer: 0, observation: false });
            uselessData++;
          } else if (page3[i - uselessData] !== undefined) {
            data.push(page3[i - uselessData]);
          } else data.push({ answer: 0, observation: false });
        }
        data.map((item, index) => {
          this.state.valueArray[index] = item;
        });
        this.setState(this.state.valueArray);
      } else
        this.setState({
          valueArray: Array.apply(null, Array(70)).map(function (x, i) {
            return { answer: 0, observation: false };
          }),
        });
    } else
      this.setState({
        valueArray: Array.apply(null, Array(70)).map(function (x, i) {
          return { answer: 0, observation: false };
        }),
      });
  }
  handleChange(event, index) {
    if (event.target.name === "answer") {
      this.state.valueArray[index].answer = event.target.value;
      this.setState(this.state.valueArray);
    } else if (event.target.name.includes("coff")) {
      delete this.state.valueArray[index].answer;
      delete this.state.valueArray[index].observation;
      if (event.target.name === "coffelec") {
        this.state.valueArray[index].coffelec = event.target.checked;
        this.setState(this.state.valueArray);
      }
      if (event.target.name === "coffgrue") {
        this.state.valueArray[index].coffgrue = event.target.checked;
        this.setState(this.state.valueArray);
      }
      this.state.valueArray[index].answer = event.target.value;
      this.setState(this.state.valueArray);
    } else if (event.target.name === "text") {
      delete this.state.valueArray[index].answer;
      delete this.state.valueArray[index].observation;
      this.state.valueArray[index] = event.target.value;
      this.setState(this.state.valueArray);
    } else {
      this.state.valueArray[index].observation = event.target.checked;
      this.setState(this.state.valueArray);
    }
  }
  filldelIndexes(indexes) {
    this.state.delIndexes = [];
    for (let i = 0; i < indexes.length; i++) {
      this.state.delIndexes.push(indexes[i]);
    }
    this.setState(this.state.delIndexes);
  }
  deleteElements() {
    const tab = this.state.valueArray;
    var i = 0;
    return tab.filter((item, index) => {
      if (
        index == this.state.delIndexes[i] ||
        index > this.state.delIndexes[this.state.delIndexes.length - 1] + 4
      ) {
        i++;
        return false;
      } else return true;
    });
  }
  async handleValidClick(entreprise) {
    const { dossierValue, lotValue } = this.props;
    this.setState({ loading: true });
    this.setState({ disableButton: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossierValue,
        lotValue: lotValue,
        identreprise: entreprise.id,
        page3: this.deleteElements(),
      }),
    };
    fetch("/api/vic/page3", requestOptions)
      .then(async (res) => {
        if (res.ok) {
          await this.props.dispatch(fetchVIC(dossierValue));
          await this.setState({ loading: false });
          await this.setState({ disableButton: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }
  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  nextPage = () => {
    history.push("/vic/page4");
  };
  prevPage = () => {
    history.push("/vic/page2");
  };
  disableSaveButton(entreprise) {
    if (entreprise.id === undefined) return true;
    if (this.state.loading) return true;
    return false;
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }
  render() {
    var entreprise = "";
    if (
      this.props.entrepriseValue !== undefined &&
      this.props.entrepriseValue !== ""
    ) {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }
    return (
      <div className="page3">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 3)</h1>
        <VICMenu />
        <div className="data">
          {/* <InfoTable valueArray={this.state.valueArray} handleChange={this.handleChange.bind(this)}/> */}
          <AllTables
            handleChange={this.handleChange.bind(this)}
            valueArray={this.state.valueArray}
            filldelIndexes={this.filldelIndexes.bind(this)}
            loadData={this.loadData.bind(this)}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(entreprise)}
            disabled={this.disableSaveButton(entreprise)}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick(entreprise)}
            disabled={
              this.state.disableButton || entreprise.id === undefined
                ? true
                : false
            }
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf()}
            disabled={
              this.state.disableButton || entreprise.id === undefined
                ? true
                : false
            }
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={this.state.disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={this.state.disableButton}
          >
            Page suivante
          </Button>
          {this.state.disableButton && <CircularProgress className="spinner" color="success" />}
        </div>
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={3}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page3);
