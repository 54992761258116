import React from 'react';
import { connect } from 'react-redux';
import GenPdf from '../GenPdf';
import Header from '../Header/Header';
import NumDoss from '../NumDoss';
import DocMenu from "../DocMenu";
import ContractAmendmentInfos from "./ContractAmendment/ContractAmendmentInfos";
import ContractAmendmentTable from "./ContractAmendment/ContractAmendmentTable";
import { fetchEcheances } from "../../redux/actions/echeancesActions";
import { fetchAllContractFacAmendment, changeContractFacAmendmentNumber } from "../../redux/actions/facAmendmentActions";
import { fetchContrat } from "../../redux/actions/contratActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import { getLastInfoEcheance, getDossier, getContactInfos, getContactAmendmentInfos, getNbAmendments, getTauxhRea, getDevisRea, genContractFacAmendment, getContractFacAmendment } from "./Utils";
import { getContractSigningDate } from "../Utils";
import "../../Styles/FactureAmendment/ContractAmendment.scss";

class ContractAmendment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: "",
      operation: "",
      adressOp: "",
      cpOp: "",
      cityOp: "",
      nameMo: "",
      sexeMo: "homme",
      adressMo: "",
      cityMo: "",
      cpMo: "",
      dates: [],
      lastEch: null,
      tauxh: 0,
      duration: 0,
      nbHours: 0,
      paymentMethod: 0,
      nbAmendments: 0,
      file: null,
      isFileOpen: false,
      disableButton: false,
    }
    this.loadData = this.loadData.bind(this)
    this.resetValues = this.resetValues.bind(this)
  }

  async componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { echeances, dossierValue } = this.props;
    const lastInfosEcheance = getLastInfoEcheance(echeances, dossierValue);

    if (lastInfosEcheance === null) {
      await this.props.dispatch(fetchEcheances())
      await this.setNbAmendments()
      return
    }
    await this.props.dispatch(fetchContrat(dossierValue))
    await this.loadData(lastInfosEcheance)
    await this.setNbAmendments()
  }

  async componentDidUpdate(prevProps) {
    const { echeances, dossierValue, contractFacAmendmentIndex } = this.props;
    const lastInfosEcheance = getLastInfoEcheance(echeances, dossierValue);

    if (lastInfosEcheance === null) {
      await this.props.dispatch(changePdfFileName(""))
      if (this.state.lastEch !== null) {
        await this.resetValues()
        await this.setNbAmendments()
      }
      return
    }
    if (prevProps.dossierValue !== dossierValue || prevProps.contractFacAmendmentIndex !== contractFacAmendmentIndex) {
      await this.props.dispatch(changePdfFileName(""))
      await this.props.dispatch(fetchContrat(dossierValue))
      await this.loadData(lastInfosEcheance);
      await this.setNbAmendments();
    }
  }

  setNbAmendments() {
    const { dossierValue, contractFacAmendments } = this.props;
    this.setState({
      nbAmendments: getNbAmendments(contractFacAmendments, dossierValue)
    })
  }

  loadData = (lastInfosEcheance) => {
    const { contact, dossiers, dossierValue, devisrea, contractFacAmendments, contractFacAmendmentIndex } = this.props;
    const { lastDateRea, payment_method } = lastInfosEcheance;
    const currentFolder = getDossier(dossiers, dossierValue)
    const idcontact = currentFolder.idcontact;
    const currentContact = getContactInfos(contact, idcontact);

    const currentContactAmendmentInfos = getContactAmendmentInfos(contractFacAmendments, dossierValue, contractFacAmendmentIndex)

    if (currentContactAmendmentInfos !== null) {
      currentContactAmendmentInfos.dates = currentContactAmendmentInfos.dates !== undefined ? JSON.parse(currentContactAmendmentInfos.dates) : []
    }

    if (currentContact) {
      this.setState({
        nameMo: `${currentContact.prenom} ${currentContact.nom}`,
        sexeMo: currentContact.sexe,
        adressMo: currentContact.adress,
        cityMo: currentContact.city,
        cpMo: +currentContact.cp,
        company: currentFolder.sci_sccv,
        operation: currentFolder.operation,
        adressOp: currentFolder.adresse_op,
        cpOp: +currentFolder.cp_op,
        cityOp: currentFolder.ville_op,
        lastEch: lastDateRea,
        paymentMethod: payment_method,
        duration: currentContactAmendmentInfos.duration ? currentContactAmendmentInfos.duration : 0,
        nbHours: currentContactAmendmentInfos.hours ? currentContactAmendmentInfos.hours : 0,
        tauxh: getTauxhRea(devisrea, dossierValue),
        dates: currentContactAmendmentInfos.dates,
        file: null
      })
    }
  }

  resetValues() {
    this.setState({
      company: "",
      operation: "",
      adressOp: "",
      cpOp: "",
      cityOp: "",
      nameMo: "",
      sexeMo: "homme",
      adressMo: "",
      cityMo: "",
      cpMo: "",
      dates: [],
      lastEch: null,
      tauxh: 0,
      duration: 0,
      nbHours: 0,
      paymentMethod: 0,
    })
  }

  handleChange(e) {
    if (e.target.name === "duration" && e.target.value >= 0 && this.state.lastEch !== null) {
      this.createDates(+e.target.value)
    }
    this.setState({ [e.target.name]: (e.target.value === "" || e.target.value < 0 || this.state.lastEch === null) ? 0 : +e.target.value })
  }

  createDates(duration) {
    const { lastEch, paymentMethod } = this.state;
    const dates = [];

    const monthsOffset = paymentMethod === 26 ? 2 : 1;

    for (let i = 1; i <= duration; i++) {
      const dateech = new Date(lastEch);
      dateech.setMonth(dateech.getMonth() + i * monthsOffset);

      dates.push({
        dateech: dateech.toLocaleDateString("en-CA"),
        raison: `Echéance avenant N° ${i}`,
      });
    }
    this.setState({ dates: dates.slice(0, (duration & 1 ? duration + 1 : duration) / monthsOffset) });
  }

  setEcheancesDates(eventValue, index) {
    const { dates } = this.state;
    let newDates = [...dates]
    const day = new Date(eventValue).getDate()
    for (let i = index; i < newDates.length; i++) {
      if (i == index) {
        newDates[i].dateech = eventValue
      } else {
        let newDate = new Date(newDates[i].dateech)
        newDate.setDate(day)
        newDates[i].dateech = newDate.toISOString().slice(0, 10)
      }
    }
    this.setState({ dates: newDates })
  }

  async handleValidClick() {
    try {
      const { dossierValue } = this.props;
      const { paymentMethod, nbAmendments } = this.state;
      this.setState({ duration: 0, dates: [], hours: 0, disableButton: true });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({
          ndossier: dossierValue,
          amendment_number: nbAmendments + 1,
          duration: 0,
          payment_method: paymentMethod,
          dates: '[]',
          hours: 0,
        })
      };

      await fetch("/api/facamendment/createContractAmendment", requestOptions);
      await this.props.dispatch(fetchAllContractFacAmendment());
      await this.props.dispatch(changeContractFacAmendmentNumber(nbAmendments + 1));

    } catch (error) {
      console.error("Erreur lors de la création de l'avenant :", error);
    }
    await this.setState({ disableButton: false });
  };

  async handleUpdateClick() {
    try {
      const { dossierValue, contractFacAmendmentIndex } = this.props;
      const { duration, paymentMethod, nbHours, dates } = this.state;
      this.setState({ disableButton: true });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({
          ndossier: dossierValue,
          amendment_number: contractFacAmendmentIndex,
          duration: duration,
          payment_method: paymentMethod,
          dates: JSON.stringify(dates),
          hours: nbHours,
        })
      };

      await fetch("/api/facamendment/updateContractAmendment", requestOptions);

    } catch (error) {
      console.error("Erreur lors de la mise à jour :", error);
    }
    await this.props.dispatch(fetchAllContractFacAmendment());
    await this.setState({ disableButton: false });
  };

  async handleGenClick() {
    await this.handleUpdateClick();
    this.setState({ file: null, isFileOpen: true, disableButton: true });
    const { dossierValue, echeances, contractFacAmendmentIndex, devisrea } = this.props;
    const { nbHours, dates, tauxh } = this.state;

    const currentDevisRea = getDevisRea(devisrea, dossierValue)

    const json = {
      ndossier: dossierValue,
      ndossier_: dossierValue.toString().split('').join('-'),
      contrat_date: getContractSigningDate(echeances, dossierValue),
      nprop: currentDevisRea.nprop,
      tauxh: +tauxh,
      dates,
      rea_ht: +currentDevisRea.totalht,
      nb_h_rea: currentDevisRea.totalh,
      nb_h_rea_add: nbHours,
      name_mo: this.state.nameMo,
      sexe_mo: this.state.sexeMo,
      adress_mo: this.state.adressMo,
      cp_mo: this.state.cpMo,
      city_mo: this.state.cityMo,
      amendment_index: +contractFacAmendmentIndex,
      company: this.state.company,
      operation: this.state.operation,
      adress_op: this.state.adressOp,
      cp_op: this.state.cpOp,
      city_op: this.state.cityOp,
      last_ech: this.state.lastEch,
    };

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    };

    let blob = await genContractFacAmendment(requestOptions);
    if (blob) {
      this.setState({ file: blob });
    }
    await this.setState({ disableButton: false });
  }

  async handleShowClick() {
    this.setState({ file: null, disableButton: true });
    let response = await getContractFacAmendment(this.props.dossierValue, this.props.contractFacAmendmentIndex, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, isFileOpen: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ isFileOpen: false });
    }
    await this.setState({ disableButton: false });
  }

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getContractFacAmendment(this.props.dossierValue, this.props.contractFacAmendmentIndex, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getContractFacAmendment(this.props.dossierValue, this.props.contractFacAmendmentIndex, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  closeDoc() {
    this.setState({ isFileOpen: false })
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Contrat Avenant Facturation`))
  }

  render() {
    return (
      <div className="contract-amendment">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <NumDoss type="contractFacAmendment" />
        <h1 className="title">
          Contrat Avenant Facturation
        </h1>
        <div className="data">
          <ContractAmendmentInfos
            company={this.state.company}
            nameMo={this.state.nameMo}
            operation={this.state.operation}
            adressOp={this.state.adressOp}
            cpOp={this.state.cpOp}
            cityOp={this.state.cityOp}
            lastEch={this.state.lastEch}
            tauxh={this.state.tauxh}
            paymentMethod={this.state.paymentMethod}
            duration={this.state.duration}
            nbHours={this.state.nbHours}
            nbAmendments={this.state.nbAmendments}
            handleChange={this.handleChange.bind(this)}
          />
          <ContractAmendmentTable
            dates={this.state.dates}
            duration={this.state.duration}
            nbHours={this.state.nbHours}
            disableButton={this.state.disableButton}
            handleValidClick={this.handleValidClick.bind(this)}
            handleUpdateClick={this.handleUpdateClick.bind(this)}
            handleGenClick={this.handleGenClick.bind(this)}
            handleShowClick={this.handleShowClick.bind(this)}
            setEcheancesDates={this.setEcheancesDates.bind(this)}
          />
          <GenPdf isOpen={this.state.isFileOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1 : 0.5} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  dossierValue: state.devis.dossierValue,
  dossiers: state.dossier.items,
  contact: state.contact.items,
  echeances: state.echeances.items,
  contractFacAmendments: state.facAmendment.contractFacAmendments,
  contractFacAmendmentIndex: state.facAmendment.contractFacAmendmentIndex,
  infosContract: state.contrat.items,
});
export default connect(mapStateToProps)(ContractAmendment)