import React from 'react';
import ReactTable from 'react-table-6';
class FacTable extends React.Component{
    totalFac(){
        var total = 0;
        this.props.data.map(facture =>
            total = total + facture.montanttotal
        );
        return total;
    }
    render(){
        const data = [{
            nbrfac: this.props.data.length,
            total: this.totalFac()+"€"
          }
        ]     
          const columns = [{
            Header: 'Nombre de Factures',
            accessor: 'nbrfac'
          },{
            Header: 'Montant Total',
            accessor: 'total'
          }]
      
          return (
                
                    <ReactTable
                      data={data}
                      columns={columns}
                      defaultPageSize = {1}
                      //pageSizeOptions = {[3, 6]}
                    />
                      
          );
    }
}
export default FacTable;