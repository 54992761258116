import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Header from '../../Header/Header';
import { fetchNewPropo, newNpropValue, fetchDevis } from "../../../redux/actions/devisActions";
import GenPdf from '../../GenPdf';
import DocMenu from '../../DocMenu';
import NumDoss from '../../NumDoss';
import history from '../../Routes/history';
import Dialogs from './Dialogs/Dialogs';
import { getNumValidation, getValidation, numberNprop, getDevisPaymentMethod } from '../Utils';

class DevisValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ht: "",
      tva: "",
      ttc: "",
      file: null,
      isOpen: false,
      openDialog: false,
      validation: false,
      numValidation: 0,
      openDialogNprop: false,
      openDialogValidate: false,
      maxNprop: 0
    };
  }

  componentDidMount() {
    const { deviscon, dossierValue, nprop } = this.props;
    if (getValidation(deviscon, dossierValue, nprop)) {
      const updateNumValidation = getNumValidation(deviscon, dossierValue);
      this.setState({ validation: true, numValidation: updateNumValidation });
    }
    if (dossierValue && deviscon) {
      this.setState({ maxNprop: numberNprop(deviscon, dossierValue) });
    }
  }

  componentDidUpdate(prevProps) {
    const { deviscon, dossierValue, nprop } = this.props;
    if (this.props.dossierValue !== prevProps.dossierValue || nprop !== prevProps.nprop || JSON.stringify(deviscon) !== JSON.stringify(prevProps.deviscon)) {
      const updateValidation = getValidation(deviscon, dossierValue, nprop);
      const updateNumValidation = getNumValidation(deviscon, dossierValue);
      this.setState({ validation: updateValidation, numValidation: updateNumValidation, maxNprop: numberNprop(deviscon, dossierValue) });
    }
  }

  async handleAffDevis() {
    this.setState({ file: null});
    let response = await this.getFile('pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });
      this.openDoc();
    }
  }

  verifyValid() {
    if (this.state.validation || this.state.numValidation >= 3) {
      return true;
    }
    return false;
  }

  async handleClickValidate() {
    const { deviscon, dossierValue, nprop } = this.props;
    let formData = new FormData();
    formData.append("ndossier", dossierValue);
    formData.append("nprop", nprop);
    formData.append("paymentMethod", getDevisPaymentMethod(deviscon, nprop, dossierValue));

    await fetch("/api/devis/validerDevis", { method: 'POST', body: formData });
    await this.props.dispatch(fetchDevis());
    await this.props.dispatch(fetchNewPropo(dossierValue));
    await this.props.dispatch(newNpropValue(nprop));
    await this.handleCloseValidate();
  }

  async handleNewPropo() {
    const { dossierValue } = this.props;
    const { maxNprop } = this.state;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue })
    };

    try {
      const response = await fetch("/api/devis/createNewPropo", requestOptions);
      const result = await response.json();

      if (result === true) {
        await this.props.dispatch(fetchNewPropo(dossierValue));
        await history.push("/devis/conception");
        await this.props.dispatch(newNpropValue(maxNprop + 1));
      } else {
        this.setState({ openDialogNprop: true });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getFile(type) {
    try {
      const res = await fetch("/api/devis/getDevisValid", {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ ndossier: this.props.dossierValue, nprop: this.props.nprop, type })
      });
      const blob = await res.blob();
      const fileName = res.headers.get('X-FileName');
      return { blob, fileName };
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  openDoc() {
    this.setState({ isOpen: true });
  }

  closeDoc() {
    this.setState({ isOpen: false });
  }

  clickToModif() {
    this.setState({ openDialog: true });
  }

  closeDialog() {
    this.setState({ openDialog: false });
  }

  handleCloseNprop = () => {
    this.setState({ openDialogNprop: false });
  };

  handleCloseValidate = () => {
    this.setState({ openDialogValidate: false });
  };

  handleOpenValidate = () => {
    this.setState({ openDialogValidate: true });
  };

  render() {
    return (
      <div className="devisValid">
        <Header />
        <NumDoss type="devis" />

        <h1 className="title">Validation de devis</h1>
        <div className="buttons">
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAffDevis.bind(this)}
          >
            Afficher Devis
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleOpenValidate}
            disabled={this.verifyValid()}
          >
            Valider Devis
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.clickToModif.bind(this)}
            disabled={!this.state.validation}
          >
            Modifier le Devis Validé
          </Button>
        </div>
        <GenPdf isOpen={this.state.isOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1.5 : 0.5} />
        {this.state.openDialog && <Dialogs closeDialog={this.closeDialog.bind(this)} />}

        <Dialog open={this.state.openDialogNprop} onClose={this.handleCloseNprop}>
          <DialogTitle>INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vous ne pouvez pas créer plus de 3 nouvelles propositions après avoir valider un devis
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleCloseNprop} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.openDialogValidate} onClose={this.handleCloseValidate}>
          <DialogTitle>VALIDATION DEVIS</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir valider la proposition N°{this.props.nprop} du dossier N°{this.props.dossierValue} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleClickValidate.bind(this)} style={{ color: "white", backgroundColor: "#FF7F50" }} >
              Oui
            </Button>
            <Button variant="contained" onClick={this.handleCloseValidate} style={{ color: "white", backgroundColor: "#F5B039" }} >
              Non
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  attachment: state.email.file,
  facture: state.facture.items
});

export default connect(mapStateToProps)(DevisValidation);
