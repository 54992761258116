import React from "react";
import Button from "@material-ui/core/Button";
import ReactTable from 'react-table-6';
import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuidv4 } from "uuid";
import { numberToFormat } from "../../Utils";

class ListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');

    return year && month && day ? `${day} / ${month} / ${year}` : "";
  }

  render() {
    const { facturesData, totalHt, totalTva, totalTtc, disableButton } = this.props
    const data = facturesData;

    const columns = [
      {
        Header:
          <div>
            Numéro<br />
            Facture
          </div>,
        accessor: 'nfacture',
        minWidth: 100,
        Cell: props => {
          return (
            <div className="centered-cell">
              {(props.value).replace(/ /g, ' / ')}
            </div>
          );
        }
      }, {
        Header: 'Affectation',
        accessor: 'dossier',
        minWidth: 180,
        Cell: props => {
          return <div>{props.value.sci_sccv}<br />{props.value.ville_op}</div>
        }
      }, {
        Header: <div>
          Mois de Facture<br />
          Concept-Réal
        </div>,
        accessor: 'month_facture',
        minWidth: 150,
        Cell: props => {
          return <div>{props.value.month}<br />{props.value.ref}</div>
        }
      }, {
        Header: 'Code Client',
        accessor: 'numclient',
        minWidth: 150,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value}
            </div>
          );
        }
      }, {
        Header: 'Montant HT',
        accessor: 'total_ht',
        minWidth: 110,
        Cell: props => {
          return (
            <div className="centered-cell">
              {numberToFormat(props.value)}
            </div>
          );
        }
      }, {
        Header: 'Montant TVA',
        accessor: 'montant_tva',
        minWidth: 118,
        Cell: props => {
          return (
            <div className="centered-cell">
              {numberToFormat(props.value)}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Bred<br />
            Virement
          </div>,
        accessor: 'amount_bred',
        minWidth: 80,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value}
            </div>
          );
        }
      }, {
        Header:
          <div>
            CA<br />
            Virement
          </div>,
        accessor: 'amount_ca',
        minWidth: 80,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Date de<br />
            l'échéance
          </div>,
        accessor: 'date_ech',
        minWidth: 130,
        Cell: props => {
          return (
            <div className="centered-cell">
              {this.formatDate(props.value)}
            </div>
          )
        }
      }, {
        Header:
          <div>
            1<sup>ère</sup> relance
          </div>,
        accessor: 'first_reminder',
        minWidth: 120,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value !== "" ? "#fd9800" : "", height: "100%", color: props.value !== "À ENVOYER" ? "white" : "black", fontWeight: "bold" }}>
              <div className="centered-cell">
                {props.value !== "" && props.value !== "À ENVOYER" ? this.formatDate(props.value) : props.value}
              </div>
            </div>
          );
        }
      }, {
        Header:
          <div>
            2<sup>ème</sup> relance
          </div>,
        accessor: 'second_reminder',
        minWidth: 120,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value !== "" ? "red" : "", height: "100%", color: props.value !== "À ENVOYER" ? "white" : "black", fontWeight: "bold" }}>
              <div className="centered-cell">
                {props.value !== "" && props.value !== "À ENVOYER" ? this.formatDate(props.value) : props.value}
              </div>
            </div>
          );
        }
      }, {
        Header:
          <div>
            Date de<br />
            Paiement
          </div>,
        accessor: 'payment_date',
        minWidth: 135,
        Cell: props => {
          return <div style={{ backgroundColor: props.value !== null ? "green" : "", height: "100%" }}>
            <input
              style={{
                border: "none", height: "100%", width: "100%", pointerEvents: "visible", backgroundColor: "transparent"
                , textAlign: "center", fontSize: "15px", color: props.value !== null ? "white" : "black", fontWeight: props.value !== null ? "bold" : ""
              }}
              onChange={(event) => this.props.handleChange(event, props.row._index)}
              type="date"
              name="payment_date"
              value={props.value || ""}
              key={uuidv4()}
              required
              autoComplete="off" />
          </div>
        }
      },
      {
        Header:
          <div>
            Payé<br />
            <span style={{ color: "green" }}>Oui</span> - <span style={{ color: "red" }}>Non</span>
          </div>,
        accessor: 'validation',
        minWidth: 100,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value ? "green" : "red" }} className="centered-cell">
              <input
                disabled={facturesData[props.row._index].payment_date === null}
                type="checkbox"
                name="validation"
                checked={props.value || ""}
                onChange={(event) => this.props.handleChange(event, props.row._index)}
              />
            </div>
          );
        }
      }, {
        Header:
          <div>
            Contrat<br />
            <span style={{ color: "green" }}>Oui</span> - <span style={{ color: "red" }}>Non</span>
          </div>,
        accessor: 'contrat_valide',
        minWidth: 100,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value === 1 ? "green" : "red" }} className="centered-cell">
              <input
                type="checkbox"
                name="contrat_valide"
                checked={props.value === 1}
                onChange={(event) => this.props.handleChange(event, props.row._index)}
              />
            </div>
          );
        }
      },
    ];

    return (
      <div className="table-list">
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 50]}
          previousText={"Précédent"}
          nextText={"Suivant"}
          loadingText={"Chargement en cours..."}
          noDataText={"Aucune donnée trouvée."}
          rowsText={"lignes"}
          ofText={"de"}
          style={{
            background: 'white',
            textAlign: 'center',
          }}
          getTheadThProps={() => {
            return {
              style: {
                backgroundColor: '#a0a0a0',
                fontSize: '19px',
              }
            }
          }}
        />
        <div className="total-table">
          <div className="table-container">
            <table>
              <tbody>
                <tr>
                  <th>Total HT</th>
                  <td>{totalHt}</td>
                </tr>
                <tr>
                  <th>Total TVA</th>
                  <td>{totalTva}</td>
                </tr>
                <tr>
                  <th>Total TTC</th>
                  <td>{totalTtc}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn-group">
          {disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
          <Button onClick={this.props.handleUpdateClick} disabled={disableButton} variant="contained" color="primary">Enregistrer</Button>
          <Button onClick={this.props.handleGenClick} disabled={disableButton} variant="contained" color="primary">Générer</Button>
          <Button onClick={this.props.handleShowPdf} disabled={disableButton} variant="contained" color="primary">Afficher</Button>
          <Button onClick={() => this.props.loadData()} disabled={disableButton} variant="contained" color="primary">Annuler</Button>
        </div>

      </div>
    )
  }
}
export default ListTable;