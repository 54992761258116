import React from 'react';
import '../Styles/Accueil.scss';
import OptionsList from './OptionsList.js';
import { convertDate } from './Utils';
class Accueil extends React.Component{
  render(){
    return (
        <div className="Accueil">
          <span className ="ERP">ERP</span>
          <OptionsList/>
          <div className="bienvenue">
            <span>Bienvenue à notre ERP<br></br></span>
            <span className="S-E-C">S-E-C</span>
          </div>
      </div>
  );
  }
}
  
export default Accueil;
