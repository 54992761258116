import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class AscenseurTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      iterator: 0
    }
  }
  render() {
    const column = [
      {
        key: 'title1',
        dataKey: 'title1',
        width: 300,
        flexGrow: 1,
        flexShrink: 0,
      },
      {
        key: 'value1',
        dataKey: 'value1',
        width: 300,
        align: Column.Alignment.CENTER,
        flexGrow: 2,
        cellRenderer: ({ rowData, rowIndex }) => {
          if (rowIndex !== 0 && rowIndex !== 1 && rowIndex !== 5 && rowIndex !== 8 && rowIndex !== 9
            && rowIndex !== 13 && rowIndex !== 23) return (
              <div>
                <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={Object.values(this.props.valueArray)[rowIndex].answer != 1 ? false : true} />
                <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={Object.values(this.props.valueArray)[rowIndex].answer != 0 ? false : true} />
                <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={Object.values(this.props.valueArray)[rowIndex].observation} />
              </div>
            )
        },
      }, {
        key: 'title2',
        dataKey: 'title2',
        width: 300,
        flexGrow: 3,
        cellRenderer: ({ cellData, rowIndex }) => {
          if (rowIndex == 7) return <div>
            <label>Coffret Electrique de chantier</label>
            <input type="checkbox" name="coffelec" value={0} onChange={(event) => this.props.handleChange(event, rowIndex + 24)}
              checked={Object.values(this.props.valueArray)[rowIndex + 24].coffelec == 1 ? true : false} />
            <label>Coffret Grue</label>
            <input type="checkbox" name="coffgrue" value={0} onChange={(event) => this.props.handleChange(event, rowIndex + 24)}
              checked={Object.values(this.props.valueArray)[rowIndex + 24].coffgrue == 1 ? true : false} />
          </div>
          else return cellData
        }
      }, {
        key: 'value2',
        dataKey: 'value2',
        width: 300,
        flexGrow: 4,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowIndex }) => {
          if (rowIndex !== 0 && rowIndex !== 3 && rowIndex !== 15) return (<div>
            <label>Oui</label><input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex + 24)}
              checked={Object.values(this.props.valueArray)[rowIndex + 24].answer != 1 ? false : true} />
            <label>Non</label><input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex + 24)}
              checked={Object.values(this.props.valueArray)[rowIndex + 24].answer != 0 ? false : true} />
            <label>Observation</label><input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex + 24)}
              checked={Object.values(this.props.valueArray)[rowIndex + 24].observation}
            />
          </div>)
        }
      }
    ]
    const data = [
      {
        id: 1,
        title1: "Port des EPI obligatoire RESPECTER",
        title2: 'Circulation intérieure à flécher et unité de passage plus zone de sécurité'
      },
      {
        id: 2,
        title1: "Base vie",
        title2: "Collectifs existants"
      },
      {
        id: 3,
        title1: 'Existant',
        title2: 'Propre à l’entreprise'
      },
      {
        id: 4,
        title1: 'Raccordé',
        title2: 'Approvisionnement sur le lieu de travail'
      },
      {
        id: 5,
        title1: 'Propre',
        title2: 'Existants'
      },
      {
        id: 6,
        title1: 'Aires de stockage',
        title2: 'A mettre en place'
      },
      {
        id: 7,
        title1: 'Existantes',
        title2: "Propre à l’entreprise"
      },
      {
        id: 8,
        title1: 'Attribuées'
      },
      {
        id: 9,
        title1: 'Moyens de mise en commun         A          RESPECTER',
        title2: 'A mettre en place'
      },
      {
        id: 10,
        title1: "Maintenir et respecter les protections collectives",
        title2: 'Prévenir le CSPS pour le contrôle'
      },
      {
        id: 11,
        title1: "Existante",
        title2: "L’Installation sera-t-elle sous traitée"
      },
      {
        id: 12,
        title1: 'A mettre en place',
        title2: 'La prise de terre / Groupe Electro/ Grue'
      },
      {
        id: 13,
        title1: 'Zone définie',
        title2: 'Bureau de contrôle prévu'
      },
      {
        id: 14,
        title1: 'Zone d’intervention       -       absence de superposition',
        title2: "Propre à l’entreprise"
      },
      {
        id: 15,
        title1: 'Existant',
        title2: 'Circulation intérieure à flécher et unité de passage plus zone de sécurité extérieure A RESPECTER'
      },
      {
        id: 16,
        title1: 'A baliser',
        title2: 'Moyens de circulation existante'
      },
      {
        id: 17,
        title1: 'A ne pas générer',
        title2: 'Collectifs'
      },
      {
        id: 18,
        title1: 'Moyens de déchargement prévus',
        title2: 'A mettre en place'
      },
      {
        id: 19,
        title1: 'Collectifs existants',
        title2: 'Propre à l’entreprise'
      },
      {
        id: 20,
        title1: 'Quelles mesures de sécurité prévoyez – vous en cas d’accident'
      },
      {
        id: 21,
        title1: 'Secourisme',
        title2: "Appeler le : 17"
      },
      {
        id: 22,
        title1: 'Extincteur',
        title2: "Appeler le : 18"
      },
      {
        id: 23,
        title1: 'Pompier',
        title2: "Appeler le : 112"
      },
      {
        id: 24,
        title2: "Appeler le : 06.80.68.14.82 CSPS"
      },
    ];

    var spanIndex = 2
    column[0].colSpan = ({ rowData, rowIndex }) => 2
    column[0].align = Column.Alignment.CENTER
    column[2].colSpan = ({ rowData, rowIndex }) => 2
    column[2].align = Column.Alignment.CENTER

    const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
      const span = columns[spanIndex].colSpan({ rowData, rowIndex })
      if (rowIndex === 1 || rowIndex === 5 || rowIndex === 9
        || rowIndex === 13 || rowIndex === 17) {
        let width = cells[0].props.style.width
        for (let i = 1; i < span; i++) {
          width += cells[0 + i].props.style.width
          cells[0 + i] = null
        }
        const style = {
          ...cells[0].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black",
        }
        cells[0] = React.cloneElement(cells[0], { style })
      }
      if (rowIndex === 3 || rowIndex === 7 || rowIndex === 15) {
        let width = cells[2].props.style.width
        for (let i = 1; i < span; i++) {
          width += cells[2 + i].props.style.width
          cells[2 + i] = null
        }
        const style = {
          ...cells[2].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black"
        }
        cells[2] = React.cloneElement(cells[2], { style })
      }
      if (rowIndex === 0 || rowIndex === 8) {
        let width = cells[0].props.style.width
        for (let i = 1; i < 2; i++) {
          width += cells[0 + i].props.style.width
          cells[0 + i] = null
        }
        const style = {
          ...cells[0].props.style,
          width,
          color: "red",
          border: "1px solid black"
        }
        cells[0] = React.cloneElement(cells[0], { style })
      }
      if (rowIndex === 0 || rowIndex === 14) {
        let width = cells[2].props.style.width
        for (let i = 1; i < 2; i++) {
          width += cells[2 + i].props.style.width
          cells[2 + i] = null
        }
        const style = {
          ...cells[2].props.style,
          width,
          color: "red",
          border: "1px solid black"
        }
        cells[2] = React.cloneElement(cells[2], { style })
      }
      if (rowIndex === 19) {
        let width = cells[0].props.style.width
        for (let i = 1; i < 4; i++) {
          width += cells[0 + i].props.style.width
          cells[0 + i] = null
        }
        const style = {
          ...cells[0].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black"
        }
        cells[0] = React.cloneElement(cells[0], { style })
      }
      return cells
    }

    return (
      <div className="testTable">
        <BaseTable rowKey="id" columns={column} width={1200} height={1200} data={data} headerHeight={0} rowRenderer={rowRenderer} />
      </div>
    )
  }
}
export default AscenseurTable