import React from 'react';
import history from '../../Routes/history';
import {Image} from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUserCircle,faCog, faSignOutAlt, faBell} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux'
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from "uuid";

class Profile extends React.Component{
    constructor(props){
        super(props)
        this.profileWrapper = React.createRef()
        this.changeProfileState=this.changeProfileState.bind(this);
    }
    changeProfileState(){
        const profwrapper = this.profileWrapper.current;
        profwrapper.classList.toggle("is-profile-enabled");
    }
    /**
     * Renvoie vers la page de connexion
     */
    logout = () =>{
        localStorage.removeItem("user");
        history.push("/login");
      }
    /**
     * Renvoie vers la page notifications
     */
    clickToNotif = () =>{
        history.push("/notifications");
    }
    render(){
        const {user} = this.props
        return(
            user.length!==0 ?
            <div ref={this.profileWrapper} className="profileWrapper">
            {user.map(user => 
            <div className="profile"  onClick={this.changeProfileState} key={uuidv4()} >
                {user.image==null ? <span className="styleNoIcon"><FontAwesomeIcon icon={faUserCircle}/></span> :
                <Image className="styleIcon" cloudName="ericguillet" publicId={user.image} crop="scale" />}
                <span className="identity">{user.name + " " + user.surname}</span>
                <div className="profParam">
                    <p className="notif" onClick={this.clickToNotif}><span><FontAwesomeIcon icon={faBell}/></span>Gérer les notifications</p>
                    <p className="param"><span><FontAwesomeIcon icon={faCog}/></span>Paramètres</p>
                    <p className="logout" onClick={this.logout}><span><FontAwesomeIcon icon={faSignOutAlt}/></span>Déconnexion</p>
                </div>
            </div>
            )}
            </div>
            : <div className="logout2"><Button onClick={this.logout}>Déconnexion</Button></div>
        )
    }
    
}
const mapStateToProps = state => ({
    user: state.user.items,
  });
  export default connect(mapStateToProps)(Profile)