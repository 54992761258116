import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

class SuppDialog extends React.Component {
  constructor(props){
    super(props)
    this.state={
      isOpen:false,
      index:this.props.index,
    }
  }

componentDidUpdate(prevProps){
  if(this.props.index!==prevProps.index){
    this.setState({index:this.props.index})
  }
}

    render() {
      // console.log(this.props);
      return (
        <React.Fragment>
        <Dialog open={this.props.open} onClose={() => this.props.setOpen(false,this.state.index)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Suppression Dossier</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Voulez-Vous vraiment supprimer le dossier N°{this.props.dossierTab.length !==0 ? this.props.dossierTab[this.state.index].ndossier : ""} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" style={{backgroundColor:"#FF7F50", color:"white"}} onClick={() => {
              this.props.onConfirm()
              this.props.setOpen(false,0)
            }}>
              Oui
            </Button>
            <Button color="primary"  style={{ color:"black"}} onClick={() => this.props.setOpen(false,this.state.index)}>
              Non
            </Button>
          </DialogActions>
        </Dialog>  
        </React.Fragment>       
      )
    }
  }
  const mapStateToProps = state => ({
    dossierTab: state.devis.dossierTab,
  });
export default connect(mapStateToProps)(SuppDialog)