import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";

class PieuxTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iterator: 0,
    };
  }
  render() {
    const column = [
      {
        key: "title1",
        dataKey: "title1",
        width: 300,
        flexGrow: 1,
        flexShrink: 0,
        cellRenderer: ({ cellData, rowIndex }) => {
          if (rowIndex == 0)
            return (
              <div>
                <label>Coffret Electrique de chantier</label>
                <input
                  type="checkbox"
                  name="coffelec"
                  value={0}
                  onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={
                    Object.values(this.props.valueArray)[rowIndex].coffelec == 1
                      ? true
                      : false
                  }
                />
                <label>Coffret Grue</label>
                <input
                  type="checkbox"
                  name="coffgrue"
                  value={0}
                  onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={
                    Object.values(this.props.valueArray)[rowIndex].coffgrue == 1
                      ? true
                      : false
                  }
                />
              </div>
            );
          else return cellData;
        },
      },
      {
        key: "value1",
        dataKey: "value1",
        width: 300,
        align: Column.Alignment.CENTER,
        flexGrow: 2,
        cellRenderer: ({ rowData, rowIndex }) => {
          if (rowIndex !== 0 && rowIndex !== 7)
            return (
              <div>
                <label>Oui</label>
                <input type="checkbox" name="answer" value={1} onChange={(event) => this.props.handleChange(event, rowIndex)} checked={Object.values(this.props.valueArray)[rowIndex].answer != 1 ? false : true} />
                <label>Non</label>
                <input type="checkbox" name="answer" value={0} onChange={(event) => this.props.handleChange(event, rowIndex)} checked={Object.values(this.props.valueArray)[rowIndex].answer != 0 ? false : true} />
                <label>Observation</label>
                <input type="checkbox" name="observation" onChange={(event) => this.props.handleChange(event, rowIndex)} checked={Object.values(this.props.valueArray)[rowIndex].observation} />
              </div>
            );
        },
      },
      {
        key: "title2",
        dataKey: "title2",
        width: 300,
        flexGrow: 3,
        cellRenderer: ({ cellData, rowIndex }) => {
          if (rowIndex == 0) {
            return (
              <div>
                <label>Situation du Sol</label>
                <input
                  type="checkbox"
                  name="sitsol"
                  value={0}
                  onChange={(event) => this.props.handleChange(event, rowIndex + 8)} checked={Object.values(this.props.valueArray)[rowIndex + 8].sitsol == 1 ? true : false}
                />
                <label>Etude géothermique</label>
                <input
                  type="checkbox"
                  name="geothe"
                  value={0}
                  onChange={(event) => this.props.handleChange(event, rowIndex + 8)} checked={Object.values(this.props.valueArray)[rowIndex + 8].geothe == 1 ? true : false}
                />
              </div>
            )
          }
          else return cellData;
        }
      },
      {
        key: "value2",
        dataKey: "value2",
        width: 300,
        flexGrow: 4,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowIndex }) => {
          return (
            <div>
              <label>Oui</label>
              <input
                type="checkbox"
                name="answer"
                value={1}
                onChange={(event) => this.props.handleChange(event, rowIndex + 8)}
                checked={Object.values(this.props.valueArray)[rowIndex + 8].answer != 1 ? false : true}
              />
              <label>Non</label>
              <input
                type="checkbox"
                name="answer"
                value={0}
                onChange={(event) => this.props.handleChange(event, rowIndex + 8)}
                checked={Object.values(this.props.valueArray)[rowIndex + 8].answer != 0 ? false : true}
              />
              <label>Observation</label>
              <input
                type="checkbox"
                name="observation"
                onChange={(event) => this.props.handleChange(event, rowIndex + 8)}
                checked={Object.values(this.props.valueArray)[rowIndex + 8].observation}
              />
            </div>
          );
        },
      },
    ];
    const data = [
      {
        id: 0,
      },
      {
        id: 1,
        title1: "A mettre en place",
        title2: "Prévenir le CSPS pour le contrôle",
      },
      {
        id: 2,
        title1: "Prévenir le CSPS pour le contrôle ",
        title2: "L’Installation sera-t-elle sous traitée",
      },
      {
        id: 3,
        title1: "L’Installation sera-t-elle sous traitée",
        title2: "Ce chantier présente-t-il un risque ",
      },
      {
        id: 4,
        title1: "La prise de terre / Groupe Electro / Grue",
        title2: "Avez-Vous Mentionné ces risques dans votre PPSPS",
      },
      {
        id: 5,
        title1: "Bureau de contrôle prévu",
        title2: "Le sol convient-il pour ce micro pieux",
      },
      {
        id: 6,
        title1: "Propre à l’entreprise",
        title2: "Il y a -t-il des recommandations importantes",
      },
      {
        id: 7,
        title1: "",
        title2: "Pouvez-vous communiquer la date de l’étude ?",
      },
    ];

    var spanIndex = 2;
    column[0].colSpan = ({ rowData, rowIndex }) => 2;
    column[0].align = Column.Alignment.CENTER;
    column[2].colSpan = ({ rowData, rowIndex }) => 2;
    column[2].align = Column.Alignment.CENTER;

    const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
      const span = columns[spanIndex].colSpan({ rowData, rowIndex });
      if (rowIndex === 0) {
        let width = cells[0].props.style.width;
        for (let i = 1; i < 2; i++) {
          width += cells[0 + i].props.style.width;
          cells[0 + i] = null;
        }
        const style = {
          ...cells[0].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black",
        };
        cells[0] = React.cloneElement(cells[0], { style });
      }
      if (rowIndex === 0) {
        let width = cells[2].props.style.width;
        for (let i = 1; i < span; i++) {
          width += cells[2 + i].props.style.width;
          cells[2 + i] = null;
        }
        const style = {
          ...cells[2].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black",
        };
        cells[2] = React.cloneElement(cells[2], { style });
      }
      return cells;
    };
    return (
      <div className="testTable">
        <BaseTable rowKey="id" columns={column} width={1200} height={400} data={data} headerHeight={0} rowRenderer={rowRenderer} />
      </div>
    );
  }
}
export default PieuxTable;
