import React from 'react';
import ReactDOM from 'react-dom';
import ERPSite from './Components/ERPSite';
import 'react-table-6/react-table.css';
import { Provider } from 'react-redux'
import store from './redux/redux_store'
ReactDOM.render(
  <Provider store={store}>
    <ERPSite/>
    </Provider>,
  document.getElementById('root')
);

