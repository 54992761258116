import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";

class FormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleCloseProcess = this.handleCloseProcess.bind(this);
  }

  handleCloseProcess() {
    this.setState({ isOpen: false });
    this.props.closeDialog();
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleCloseProcess}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            MODIFICATION DÉTAILS DE L'OPÉRATION
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous modifier les détails de l'opération du dossier N°
              {this.props.dossierValue} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseProcess} color="primary">
              Annuler la Modification
            </Button>
            <Button
              onClick={() => this.props.showNextDialog(3)}
              color="primary"
            >
              Non
            </Button>
            <Button
              onClick={() => this.props.showNextDialog(2)}
              color="primary"
            >
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
});
export default connect(mapStateToProps)(FormDialog);
