import { useEffect } from "react"
import NumsDoss from "../NumsDoss"
import { connect } from "react-redux"
import { fetchAllLotItems, newLotsValue } from "../../redux/actions/vicActions"
import EntrepriseVICSList from "./EntrepriseVICSList"

const VICSMenu = ({ lotItems, lotsValue, dispatch }) => {

  useEffect(() => {
    const fetchData = async () => {
      if (lotItems.length !== 0) return
      await dispatch(fetchAllLotItems())
    }
    fetchData();
  }, [dispatch, lotItems.length]);

  const handleLotChange = async (event) => {
    await dispatch(newLotsValue(+event.target.value));
  }

  return (
    <div className="vicmenu">
      <NumsDoss />
      <div className="lot">
        <label>Lot : </label>
        <select onChange={handleLotChange} value={lotsValue}>
          {lotItems.map(({ id, name }, i) => {
            return <option value={id} key={i}>{name}</option>
          })}
        </select>
      </div>
      <EntrepriseVICSList />
    </div>
  );
}

const mapStateToProps = state => ({
  lotsValue: state.vic.lotsValue,
  lotItems: state.vic.lotItems,
});

export default connect(mapStateToProps)(VICSMenu);
