import React from "react";
import ReactTable from "react-table-6";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { newNpropValue } from "../../../redux/actions/devisActions";
import { numberToFormat } from "../../Utils";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class DevisReaTable2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nbrHeures: this.props.devisTab.nbrHeures,
      ht: this.props.devisTab.ht,
      approved: false
    };
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.setState((prevState) => ({
      ht: [
        prevState.nbrHeures[0] * this.props.tauxh,
        prevState.nbrHeures[4] * this.props.tauxh,
        prevState.nbrHeures[8] * this.props.tauxh,
      ],
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.nprop !== prevProps.nprop
    ) {
      this.setState({
        nbrHeures: this.props.devisTab.nbrHeures,
        ht: this.props.devisTab.ht,
      });
    }
  }

  handleChange(event, index) {
    let nbrHeures = [...this.state.nbrHeures];
    if (index < 4) {
      nbrHeures[index - 1] = event.target.value;
    } else if (index < 8) {
      nbrHeures[index - 2] = event.target.value;
    } else if (index < 12) {
      nbrHeures[index - 3] = event.target.value;
    }
    this.setState({ nbrHeures });
  }

  heureChantier() {
    const totalHeures = this.state.nbrHeures
      .slice(0, 3)
      .reduce((total, heures) => total + Number(heures), 0) * this.props.tauxh;

    const resultatArrondi = totalHeures;

    return +resultatArrondi;
  }

  heureTravaux() {
    const totalHeures = this.state.nbrHeures
      .slice(3, 6)
      .reduce((total, heures) => total + Number(heures), 0) * this.props.tauxh;

    const resultatArrondi = totalHeures;

    return +resultatArrondi;
  }

  heureRepTravaux() {
    const totalHeures = this.state.nbrHeures
      .slice(6, 9)
      .reduce((total, heures) => total + Number(heures), 0) * this.props.tauxh;

    const resultatArrondi = totalHeures;

    return parseFloat(resultatArrondi);
  }

  calcMontanTotal() {
    const totalHeures = this.state.nbrHeures.reduce((total, heures) => total + Number(heures), 0) * this.props.tauxh
    const resultatArrondi = totalHeures;

    return +resultatArrondi;
  }

  calcHr1(index) {
    const { nbrHeures } = this.state;
    let heureTotal = 0;

    switch (index) {
      case 0:
        heureTotal = nbrHeures
          .slice(0, 3)
          .reduce((total, heures) => total + Number(heures), 0);
        break;
      case 4:
        heureTotal = nbrHeures
          .slice(3, 6)
          .reduce((total, heures) => total + Number(heures), 0);
        break;
      case 8:
        heureTotal = nbrHeures
          .slice(6, 9)
          .reduce((total, heures) => total + Number(heures), 0);
        break;
      case 12:
        heureTotal = this.calcHr1(0) + this.calcHr1(4) + this.calcHr1(8);
        break;
      default:
        break;
    }

    return heureTotal;
  }

  calcHeures() {
    var total = 0;
    for (let i = 0; i < this.state.nbrHeures.length; i++) {
      total += Number(this.state.nbrHeures[i]);
    }
    return total;
  }

  // calcMontanTotal() {
  //   return this.heureChantier() + this.heureTravaux() + this.heureRepTravaux();
  // }


  checkValidation() {
    if (this.props.validation) {
      this.setState({ approved: true })
    } else {
      this.handleClick()
    }
  }

  async handleClick() {
    const { dossierValue, nprop } = this.props;
    this.setState({
      ht: [this.heureChantier(), this.heureTravaux(), this.heureRepTravaux()], approved: false
    });
    const devisTab = {
      nbrHeures: this.state.nbrHeures.map((nbrHeure) => Number(nbrHeure)),
      ht: [this.heureChantier(), this.heureTravaux(), this.heureRepTravaux()],
    };

    const json = {
      duree: this.props.duree,
      devisTab,
      totalh: this.calcHeures(),
      totalht: this.calcMontanTotal(),
      ndossier: dossierValue,
      nprop: nprop,
      tauxh: this.props.tauxh,
    };

    this.props.registerView(json);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(json),
    };

    await fetch("/api/devis/registerNewDataRea", requestOptions).catch((err) =>
      console.log(err)
    );
    // await this.props.dispatch(fetchDevis());
    // await this.props.dispatch(newDossierValue(dossierValue));
    await this.props.dispatch(newNpropValue(nprop));
  }

  getValue(index) {
    if (index <= 3) {
      return this.state.nbrHeures[index - 1];
    } else if (index <= 7) {
      return this.state.nbrHeures[index - 2];
    } else if (index <= 11) {
      return this.state.nbrHeures[index - 3];
    }
  }


  closeDialog() {
    this.setState({ approved: false });
  }

  render() {
    const data = [
      {
        actType: (
          <div className="titleDevisCon2">1. Préparation du chantier:</div>
        ),
        heures: <div className="titleDevisCon2"></div>,
      },
      {
        actType: "1.1. Inspections Communes avec les entreprises",
        heures: 0,
      },
      {
        actType: "1.2. Harmonisation des P.P.S.P.S./Mise à jour du P.G.C",
        heures: 0,
      },
      {
        actType: "1.3. Tenue Registre Journal",
        heures: 0,
      },
      {
        actType: <div className="titleDevisCon2">2. Travaux:</div>,
        heures: 0,
      },
      {
        actType: "2.1. Visites de chantier",
        heures: 0,
      },
      {
        actType: "2.2. Réunions de C.I.S.S.C.T",
        heures: 0,
      },
      {
        actType: "2.3. Participation au rendez-vous de chantier",
        heures: 0,
      },
      {
        actType: (
          <div className="titleDevisCon2">3. Réception des travaux:</div>
        ),
        heures: 0,
      },
      {
        actType: "3.1. Récolement des pièces liées au D.I.U.O",
        heures: 0,
      },
      {
        actType: "3.2. Mise à jour du D.I.U.O.final",
        heures: 0,
      },
      {
        actType: "3.3. Diffusion du P.G.C. et du D.I.U.O",
        heures: 0,
      },
      {
        actType: (
          <div className="titleDevisCon2">TOTAL HEURES PHASE REALISATION:</div>
        ),
        heures: 0,
      },
    ];
    const columns = [
      {
        Header: "PHASE REALISATION",
        columns: [
          {
            Header: "ACTES TYPES",
            accessor: "actType",
          },
          {
            Header: "HEURES",
            accessor: "heures",
            Cell: (props) => {
              if (
                props.row._index === 0 ||
                props.row._index === 4 ||
                props.row._index === 8 ||
                props.row._index === 12
              )
                return <strong>{this.calcHr1(props.row._index)}</strong>;
              else
                return (
                  <input
                    style={{
                      border: "none",
                      height: "100%",
                      width: "100%",
                    }}
                    value={this.getValue(props.row._index)}
                    name={props.column.Header + props.row._index}
                    onChange={(event) =>
                      this.handleChange(event, props.row._index)
                    }
                  />
                );
            },
          },
        ],
      },
    ];

    const data2 = [
      {
        actType: <div>1. Préparation du chantier</div>,
        prix: numberToFormat(this.heureChantier()),
      },
      {
        actType: <div>2. Travaux</div>,
        prix: numberToFormat(this.heureTravaux()),
      },
      {
        actType: <div>3. Réception des travaux</div>,
        prix: numberToFormat(this.heureRepTravaux()),
      },
      {
        actType: (
          <div className="titleDevisCon2">HONORAIRES PHASE REALISATION:</div>
        ),
        prix: (
          <strong>
            {numberToFormat(this.calcMontanTotal())}
          </strong>
        ),
      },
    ];

    const columns2 = [
      {
        Header: "PHASE REALISATION",
        columns: [
          {
            Header: "ACTES TYPES",
            accessor: "actType",
          },
          {
            Header: "PRIX",
            accessor: "prix",
          },
        ],
      },
    ];

    return (
      <div className="devisTable2">
        <ReactTable data={data} columns={columns} defaultPageSize={13} />

        <div className="devisConSuite">
          <ReactTable data={data2} columns={columns2} defaultPageSize={4} />
        </div>
        <Button
          variant="contained"
          onClick={this.checkValidation.bind(this)}
        >
          Enregistrer
        </Button>
        <Dialog open={this.state.approved} onClose={this.closeDialog}>
          <DialogTitle>INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vous ne pouvez pas modifier les heures de la proposition N°{this.props.nprop} déjà validée.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.closeDialog} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
});
export default connect(mapStateToProps)(DevisReaTable2);
