export const CHANGE_DOSSIER_VALUE = 'CHANGE_DOSSIER_VALUE';
export const CHANGE_TYPE_OUVRAGE_VALUE = 'CHANGE_TYPE_OUVRAGE_VALUE';
export const CHANGE_COUT_TRAVAUX_VALUE = 'CHANGE_COUT_TRAVAUX_VALUE';
export const CHANGE_DUREE_TRAVAUX_VALUE = 'CHANGE_DUREE_TRAVAUX_VALUE';
export const CHANGE_CATEGORIE_OPERATION_VALUE = 'CHANGE_CATEGORIE_OPERATION_VALUE';
export const CHANGE_NOMBRE_LOTS_VALUE = 'CHANGE_NOMBRE_LOTS_VALUE';
export const CHANGE_HAUTEUR_VALUE = 'CHANGE_HAUTEUR_VALUE';
export const CHANGE_COMPLEXITE_ARCHITECTURALE_VALUE = 'CHANGE_COMPLEXITE_ARCHITECTURALE_VALUE';
export const CHANGE_COMPLEXITE_ENVIRONNEMENTALE_VALUE = 'CHANGE_COMPLEXITE_ENVIRONNEMENTALE_VALUE';
export const CHANGE_COMPLEXITE_TECHNIQUE_VALUE = 'CHANGE_COMPLEXITE_TECHNIQUE_VALUE';
export const CHANGE_REPETITIVITE_VALUE = 'CHANGE_REPETITIVITE_VALUE';

export function newDossierValue(newValue){
  return dispatch => dispatch(changeDossierValue(newValue))
}

export function newOuvrageValue(newValue){
    return dispatch => dispatch(changeOuvrageValue(newValue))
  }

export function newCoutTravValue(newValue){
  return dispatch => dispatch(changeCoutTravValue(newValue))
}

export function newDureeTravValue(newValue){
    return dispatch => dispatch(changeDureeTravValue(newValue))
  }
  
  export function newCatOpValue(newValue){
    return dispatch => dispatch(changeCatOpValue(newValue))
  }

  export function newNbrLotsValue(newValue){
    return dispatch => dispatch(changeNbrLotsValue(newValue))
  }
  
  export function newHauteurValue(newValue){
    return dispatch => dispatch(changeHauteurValue(newValue))
  }
  export function newCompArchiValue(newValue){
    return dispatch => dispatch(changeCompArchiValue(newValue))
  }
  
  export function newCompEnvValue(newValue){
    return dispatch => dispatch(changeCompEnvValue(newValue))
  }
  export function newCompTechValue(newValue){
    return dispatch => dispatch(changeCompTechValue(newValue))
  }
  
  export function newRepValue(newValue){
    return dispatch => dispatch(changeRepValue(newValue))
  }

export const changeDossierValue = dossier => ({
  type: CHANGE_DOSSIER_VALUE,
  payload: { dossier }
});

export const changeOuvrageValue = ouvrage => ({
  type: CHANGE_TYPE_OUVRAGE_VALUE,
  payload: { ouvrage }
});

export const changeCoutTravValue = coutTrav => ({
    type: CHANGE_COUT_TRAVAUX_VALUE,
    payload: { coutTrav }
  });
  
  export const changeDureeTravValue = dureeTrav => ({
    type: CHANGE_DUREE_TRAVAUX_VALUE,
    payload: { dureeTrav }
  });
  export const changeCatOpValue = catOp => ({
    type: CHANGE_CATEGORIE_OPERATION_VALUE,
    payload: { catOp }
  });
  
  export const changeNbrLotsValue = nbrLots => ({
    type: CHANGE_NOMBRE_LOTS_VALUE,
    payload: { nbrLots }
  });

  export const changeHauteurValue = haut => ({
    type: CHANGE_HAUTEUR_VALUE,
    payload: { haut }
  });
  
  export const changeCompArchiValue = compArchi => ({
    type: CHANGE_COMPLEXITE_ARCHITECTURALE_VALUE,
    payload: { compArchi }
  });

  export const changeCompEnvValue = compEnv => ({
    type: CHANGE_COMPLEXITE_ENVIRONNEMENTALE_VALUE,
    payload: { compEnv }
  });
  
  export const changeCompTechValue = compTech => ({
    type: CHANGE_COMPLEXITE_TECHNIQUE_VALUE,
    payload: { compTech }
  });

  export const changeRepValue = rep => ({
    type: CHANGE_REPETITIVITE_VALUE,
    payload: { rep }
  });