import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class ConvGrue extends React.Component{
    constructor(props){
        super(props)
        this.state={
            iterator:0
        }
    }
    render(){
        const column = [
            {
                key: 'box1',
                dataKey: 'box1',
                width: 300,
                flexGrow:1,
                flexShrink:0,
                cellRenderer: ({cellData,rowIndex}) => (<div>
                    <input type="checkbox"  name="conv" 
                    onChange={(event) => this.props.handleChange(event,rowIndex)} 
                    checked={this.props.valueArray[rowIndex]}/>
                    {cellData}
                </div>)
              },
        ]
        const data =[
            {
                box1: "Grue à Tour",
            },
            {
                box1: "Monte-Charge",
            },
            {
                box1: "Treuil ",
            },
            {
                box1: "Ascenseur",
            },
            {
                box1: "Grue Mobile",
            },
        ]
        return(
            <div className="testTable">
                <BaseTable  columns={column} width={400} height={250} data={data} headerHeight={0}>
                </BaseTable>
            </div>
        )
    }
}
export default ConvGrue