import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import GenPdf from '../GenPdf';
import DocMenu from '../DocMenu';
import NumDoss from '../NumDoss';
import history from '../Routes/history';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchNewPropo, newNpropValue } from "../../redux/actions/devisActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import { getContact, getDevisConHT, getDevisConTtc, getDevisReaTtc, getDevisReaHT, getDevisTabCon, getDevisTabRea, getDossier, getDureeCon, getDureeRea, getTauxHCon, getTauxHRea, getTotalTtc, getTotalHt, numberNprop } from './Utils';
import { numberToFormat } from "../Utils";
import axios from 'axios';

class DevisProposition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ht: "",
      tva: "",
      ttc: "",
      file: "",
      isOpen: false,
      numPages: 2,
      pageNumber: 2,
      file: null,
      openDialog: false,
      openDialogAddProp: false,
      openDialogNprop: false,
      openDialogPaymentMethod: false,
      maxNprop: 0,
      paymentMethod: 0,
      disableButton: false,
      hourlyRateCon: 0,
      hourlyRateRea: 0,
      totalHtCon: 0,
      totalHtRea: 0
    }
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { deviscon, dossierValue } = this.props
    if (dossierValue && deviscon) {
      this.setState({ maxNprop: numberNprop(deviscon, dossierValue), paymentMethod: 0 });
    }
  }

  componentDidUpdate(prevProps) {
    const { deviscon, dossierValue, nprop } = this.props
    if (dossierValue !== prevProps.dossierValue || nprop !== prevProps.nprop) {
      this.props.dispatch(changePdfFileName(""))
      this.setState({ maxNprop: numberNprop(deviscon, dossierValue), paymentMethod: 0, file: null });
    }
  }
  /**
   * Affiche le devis en PDF
   */
  openDoc() {
    this.setState({ isOpen: true })
  }
  /**
   * Ferme le devis en PDF
   */
  closeDoc() {
    this.setState({ isOpen: false })
  }

  nextPage() {
    if (this.state.pageNumber != this.state.numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 })
    }
  }
  /**
  * Permet de passer à la page précédente pour le contrat
  */
  prevPage() {
    if (this.state.pageNumber != 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 })
    }
  }
  clientNotif() {
    axios.post('/notifMesssage/' + this.props.dossierValue)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }

  /**
   * Génère le devis en PDF et choisi la version correspondante en fonction de la catégorie de l'opération
   */
  async handleGenDevis() {
    const { devisrea, deviscon, dossier, contact, dossierValue, nprop } = this.props
    const { paymentMethod } = this.state
    const json = {}
    this.setState({ disableButton: true, file: null })

    json.con = getDevisTabCon(deviscon, nprop, dossierValue)
    json.rea = getDevisTabRea(devisrea, nprop, dossierValue)
    json.ndossier = dossierValue
    json.nprop = nprop
    json.tauxhcon = getTauxHCon(deviscon, nprop, dossierValue)
    json.tauxhrea = getTauxHRea(devisrea, nprop, dossierValue)
    json.dureeCon = getDureeCon(deviscon, nprop, dossierValue)
    json.dureeRea = getDureeRea(devisrea, nprop, dossierValue)
    json.totalttc = getTotalTtc(devisrea, deviscon, nprop, dossierValue)


    const requestPaymentMethodOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, paymentMethod })
    };

    try {
      await fetch("/api/devis/updateDevisPaymentMethod", requestPaymentMethodOptions);
    } catch (error) {
      console.error('Erreur lors de la requête :', error);
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ devis: json, contact: getContact(dossier, contact, dossierValue), dossier: getDossier(dossier, dossierValue) })
    };
    // if (getCategorieOp(dossier, dossierValue) == 3) { //true pour la 2ème template du devis (en attente)
    if (true) {
      await fetch("/api/devis/excelV1", requestOptions)
        .then(res => res.blob())
        .then(res => this.setState({ file: res }))
        .finally(this.openDoc())
        .catch((err) => console.log(err))
    } else {
      fetch("/api/devis/excelV2", requestOptions)
        .then(res => res.blob())
        .then(res => this.setState({ file: res }))
        .finally(this.openDoc())
        .catch((err) => console.log(err))
    }
    await this.props.dispatch(fetchNewPropo(dossierValue));
    await this.props.dispatch(newNpropValue(nprop))
    await this.setState({ disableButton: false })
  }

  /**
   * Génère une nouvelle proposition de devis et nous renvoie vers la page conception de la proposition générée
   */
  async handleNewPropo() {
    const { dossierValue } = this.props;
    const { maxNprop, paymentMethod } = this.state;
    if (paymentMethod === 0) {
      return this.setState({ openDialogAddProp: false, openDialogPaymentMethod: true })
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, payment_method: paymentMethod })
    };

    try {
      const response = await fetch("/api/devis/createNewPropo", requestOptions);
      const result = await response.json();

      if (result) {
        await this.props.dispatch(fetchNewPropo(dossierValue));
        await history.push("/devis/conception");
        await this.props.dispatch(newNpropValue(maxNprop + 1));
      } else this.setState({ openDialogAddProp: false, openDialogNprop: true });
    } catch (error) {
      console.log(error);
    }
  }

  handleUpdateHourlyRate = async () => {
    const { dossierValue, nprop } = this.props
    const { hourlyRateCon, hourlyRateRea, totalHtCon, totalHtRea } = this.state
    this.handleClose()
    this.setState({ disableButton: true })

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: dossierValue,
        nprop,
        hourlyRateCon,
        hourlyRateRea,
        totalHtCon,
        totalHtRea
      })
    }

    await fetch("/api/devis/updateHourlyRate", requestOptions)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchNewPropo(dossierValue));
    await this.props.dispatch(newNpropValue(nprop))
    await this.setState({ disableButton: false, paymentMethod: 34 })
    await this.handleGenDevis()
  }

  handleValidateAmount = async () => {
    const { devisrea, deviscon, dossierValue, nprop } = this.props
    if (this.state.paymentMethod === 0) {
      this.setState({ openDialogPaymentMethod: true })
    } else if (this.state.paymentMethod === 34) {
      const conception = getDevisConHT(deviscon, nprop, dossierValue)
      const realisation = getDevisReaHT(devisrea, nprop, dossierValue)
      const total = conception + realisation

      if ((total * 0.34).toFixed(2) === conception.toFixed(2)) {
        this.handleGenDevis();
      } else {
        const nbrHeuresCon = JSON.parse(getDevisTabCon(deviscon, nprop, dossierValue)).nbrHeures
        const nbrHeuresRea = JSON.parse(getDevisTabRea(devisrea, nprop, dossierValue)).nbrHeures

        const totalHCon = nbrHeuresCon.reduce((acc, heure) => acc + parseFloat(heure), 0)
        const totalHRea = nbrHeuresRea.reduce((acc, heure) => acc + parseFloat(heure), 0)

        const totalHtCon = total * 0.34
        const totalHtRea = total * 0.66

        const hourlyRateCon = parseFloat((totalHtCon / totalHCon).toFixed(4))
        const hourlyRateRea = parseFloat((totalHtRea / totalHRea).toFixed(4))

        await this.setState({ hourlyRateCon, hourlyRateRea, totalHtCon, totalHtRea, openDialog: true })
      }
    } else {
      this.handleGenDevis();
    }
  }

  async handleAffDevis() {
    this.setState({ file: null, disableButton: true });
    let response = await this.getFile('pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });
      this.openDoc();

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
    await this.setState({ disableButton: false });
  }

  async handleGetPdfFile() {
    this.setState({ file: null });
    let response = await this.getFile('pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  async handleGetExcelFile() {
    this.setState({ file: null });
    let response = await this.getFile('excel');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  async getFile(type) {
    try {
      const res = await fetch("/api/devis/getDevisValid", {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ ndossier: this.props.dossierValue, nprop: this.props.nprop, type })
      });
      const blob = await res.blob();
      const fileName = res.headers.get('X-FileName');
      return { blob, fileName };
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  }

  handleCloseAddProp = () => {
    this.setState({ openDialogAddProp: false });
  }

  handleCloseNprop = () => {
    this.setState({ openDialogNprop: false });
  };

  handleClosePaymentMethod = () => {
    this.setState({ openDialogPaymentMethod: false });
  };

  devisPage = () => {
    history.push("/devis/conception");
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName("Proposition de Devis"))
  }

  render() {
    const { deviscon, devisrea, nprop, dossierValue } = this.props
    const { paymentMethod, disableButton, openDialog, openDialogAddProp, openDialogNprop, openDialogPaymentMethod, isOpen, maxNprop } = this.state

    return (
      <div className="devisProp">
        <Header />
        <DocMenu
          file={this.state.file}
          fileType={"excel"}
          getSourceFile={this.handleGetExcelFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <div className="devis-payment">
          <NumDoss type="devis" />
          <div>
            <label>Type de paiement : </label>
            <select
              onChange={(event) => this.setState({ paymentMethod: parseInt(event.target.value) })}
              value={paymentMethod}
            >
              <option key={0} value={0}>
                {""}
              </option>
              <option key={26} value={26}>
                26%
              </option>
              <option key={34} value={34}>
                34%
              </option>
            </select>
          </div>
        </div>

        <h1 className="title">
          <div className="titl">
            Proposition de Devis
          </div>
        </h1>


        <div className="tabDev">
          <table className="customTable">
            <thead>
              <tr>
                <th></th>
                <th>Montant EUR / HT</th>
                <th>Montant EUR / TTC</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Phase de conception</td>
                <td>{numberToFormat(getDevisConHT(deviscon, nprop, dossierValue))}</td>
                <td>{numberToFormat(getDevisConTtc(deviscon, nprop, dossierValue))}</td>
              </tr>
              <tr>
                <td>Phase de réalisation</td>
                <td>{numberToFormat(getDevisReaHT(devisrea, nprop, dossierValue))}</td>
                <td>{numberToFormat(getDevisReaTtc(devisrea, nprop, dossierValue))}</td>
              </tr>
              <tr>
                <td><span>Total</span></td>
                <td> <span>{numberToFormat(getTotalHt(devisrea, deviscon, nprop, dossierValue))}</span></td>
                <td> <span>{numberToFormat(getTotalTtc(devisrea, deviscon, nprop, dossierValue))}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-group">
          {disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
          <Button variant="contained" color="primary" onClick={this.handleValidateAmount} disabled={disableButton}>Générer le Devis</Button>
          <Button variant="contained" color="primary" onClick={this.handleAffDevis.bind(this)} disabled={disableButton}>Afficher le devis</Button>
          <Button className='btn-create' variant="contained" color="primary" onClick={() => this.setState({ openDialogAddProp: true })} disabled={disableButton}>Nouvelle Proposition</Button>
        </div>
        <GenPdf isOpen={isOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1.5 : 0.5} />
        <Dialog open={openDialogNprop} onClose={this.handleCloseNprop}>
          <DialogTitle>INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vous ne pouvez pas créer plus de 3 nouvelles propositions après avoir valider un devis
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleCloseNprop} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialogPaymentMethod} onClose={this.handleClosePaymentMethod} PaperProps={{ style: { minWidth: "450px" } }}>
          <DialogTitle>INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez choisir un type de paiment
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleClosePaymentMethod} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialogAddProp} onClose={this.handleCloseAddProp}>
          <DialogTitle>CREER UNE NOUVELLE PROPOSITION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir créer une nouvelle proposition N°{maxNprop + 1} pour le dossier {dossierValue}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleNewPropo.bind(this)} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Oui
            </Button>
            <Button variant="contained" onClick={this.handleCloseAddProp} style={{ color: "white", backgroundColor: "#F5B039" }}>
              Non
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialog} onClose={this.handleClose}>
          <DialogTitle>ERREUR CALCUL DE DEVIS</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Le montant alloué à la phase de conception ne correspond pas à 34% du montant total.
              Comment vous souhaitez ajuster le calcul ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.devisPage} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Manuellement
            </Button>
            <Button variant="contained" onClick={this.handleUpdateHourlyRate} style={{ color: "white", backgroundColor: "green" }}>
              Automatiquement
            </Button>
            <Button variant="contained" onClick={this.handleClose} style={{ color: "white", backgroundColor: "#F5B039" }}>
              Annuler
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  contact: state.contact.items,
  dossier: state.dossier.items
});
export default connect(mapStateToProps)(DevisProposition)