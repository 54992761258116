import React from 'react';
import LogoProcess from '../../Styles/Pictures/logo_process.png';
import Fleche from '../../Styles/Pictures/down-arrow.svg';
class ProcessImage extends React.Component{
    render(){
        return(
            <div className="process">
                <img className="logoprocess"src={LogoProcess} alt=""></img>
                <img className="flechehaut1"src={Fleche} alt=""></img>
                <label>Process Coordogec</label>
            </div>
        );
    }
}
export default ProcessImage;